import React, { useEffect, useState } from "react";
import {
    Input,
    Button,
    Form,
    Card,
    message,
    ConfigProvider,
    Drawer,
    Row,
    Col,
    Checkbox,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
    check2FA,
    checkOtpStatus,
    // login,
    // check1midAccount,
    loginNric,
} from "../../services/api";
import {
    setToken,
    setUnverifiedAccount,
    setIsAuthorizeToAccessUserManagement,
    getAuthorizedNric,
    removeAuthorizedNric,
    setAuthUid,
    setLoggedInTime,
    getDontShowDownloadApp,
    setDontShowDownloadApp,
    removeDontShowDownloadApp,
} from "../../services/local";
import {
    APP_CONST,
    iv,
    ERROR_MESSAGE_LOGIN_NOT_ACTIVATED,
} from "../../constants";
import {
    HideEyeIcon,
    ShowEyeIcon,
    DigitalIdLogoIcon,
} from "../../components/CustomIcons";
import CryptoJS from "crypto-js";
import dayjs from "dayjs";
// import AES from 'crypto-js/aes';
import useMobileDetect from "../../components/component/Function/UseMobileDetect";
import AppIcon from "../../assets/icon-twoCar-app.png";

export default function LoginPage() {
    console.log("0.0.582");

    const history = useHistory();
    const device = useMobileDetect();

    // const [username, setUsername] = useState("");
    const [nric, setNric] = useState("");
    const [password, setPassword] = useState("");
    const [submittedusernamepassword, setSubmittedusernamepassword] =
        useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoginDisabled, setIsLoginDisabled] = useState(false);
    const [error, setError] = useState("");

    const [showDrawer, setShowDrawer] = useState(false);
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        if (
            (device === "iOS" || device === "Android") &&
            !getDontShowDownloadApp()
        ) {
            setShowDrawer(true);
        }
    }, [device]);

    const currentUsernamePassword = nric + password;

    if (
        isLoginDisabled === true &&
        currentUsernamePassword !== submittedusernamepassword
    ) {
        setIsLoginDisabled(false);
    }

    const onSubmit = () => {
        if (nric.trim() === "" && password.trim() === "") {
            setError("The Mykad number and Password cannot be empty");
            return;
        }
        if (nric.trim() === "") {
            setError("Mykad number cannot be empty");
            return;
        }
        if (password.trim() === "") {
            setError("Password cannot be empty");
            return;
        }

        setError("");

        const _usernamepassword = nric + password;
        setSubmittedusernamepassword(_usernamepassword);

        let passwordCiphertext = CryptoJS.AES.encrypt(password, iv).toString();

        setIsLoading(true);
        // login(username, password)
        loginNric(nric, passwordCiphertext)
            .then(async (res) => {
                if (res.status !== "00") throw res;

                await setToken(res.access_token);
                await setUnverifiedAccount(res.unverifiedAccount);
                await setIsAuthorizeToAccessUserManagement(true); //set to true to disable otp
                await setAuthUid(res.uid);

                let authorizedNric = getAuthorizedNric();

                if (authorizedNric) {
                    await removeAuthorizedNric();
                }

                if (APP_CONST.USE_OTP === true) {
                    await checkOtpStatus().then((res) => {
                        if (res.status === true) {
                            window.location.pathname = "/postlogin";
                        } else {
                            history.push("/otp");
                            window.location.reload();
                        }
                    });
                } else {
                    await check2FA().then((res) => {
                        if (res.status === true) {
                            window.location.pathname = "/postlogin";
                        } else {
                            history.push("/2fa");
                            window.location.reload();
                        }
                    });
                }
            })
            .catch((err) => {
                setIsLoginDisabled(true);
                if (err && err.message === "Account not activated.") {
                    message.error(
                        <div
                            style={{ textAlign: "center", paddingLeft: "10px" }}
                            dangerouslySetInnerHTML={{
                                __html: ERROR_MESSAGE_LOGIN_NOT_ACTIVATED(
                                    nric,
                                    err?.loginName || ""
                                ),
                            }}
                        />,
                        7
                    );

                    err?.loginName &&
                        history.push("/inactive", {
                            activationEmail: err?.loginName,
                            isTempAccount: err?.isTempAccount,
                        });
                } else {
                    err && message.error(err.error_description || err.message);
                }
            })
            .finally(() => {
                setLoggedInTime(dayjs().format("D MMM YYYY h:mm A"));
                setIsLoading(false);
            });
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Card
                style={{
                    width: 300,
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "8px",
                }}
            >
                <div
                    style={{
                        textAlign: "center",
                    }}
                >
                    <h2 style={{ font: "normal normal 600 32px/37px Raleway" }}>
                        Login
                    </h2>
                </div>
                <Form layout={"vertical"}>
                    {/* <Form.Item
                        label='Username (1MID)'
                        name='username'
                        style={{ font: 'italic normal normal 16px/22px Open Sans', color: '#333333' }}
                    >
                        <Input
                            value={username}
                            placeholder={'Enter username'}
                            onChange={e => setUsername(e.target.value.trim())}
                            onPressEnter={onSubmit}
                            style={{

                                border: '1px solid #2B61C4',
                                borderRadius: '4px',
                                font: 'normal normal 600 15px/20px Open Sans'
                            }}
                        />
                    </Form.Item> */}
                    <Form.Item
                        label="Mykad number"
                        name="nric"
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                            }
                        }}
                        style={{
                            font: "italic normal normal 16px/22px Open Sans",
                            color: "#333333",
                        }}
                    >
                        <Input
                            value={nric}
                            placeholder={"Enter mykad number"}
                            onChange={(e) => setNric(e.target.value.trim())}
                            maxLength={12}
                            onPressEnter={onSubmit}
                            style={{
                                border: "1px solid #2B61C4",
                                borderRadius: "4px",
                                font: "normal normal 600 15px/20px Open Sans",
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        placeholder={"Password"}
                        name="password"
                        style={{
                            font: "italic normal normal 16px/22px Open Sans",
                            color: "#333333",
                            marginBottom: "10px",
                        }}
                    >
                        <Input.Password
                            iconRender={(visible) =>
                                visible ? ShowEyeIcon() : HideEyeIcon()
                            }
                            value={password}
                            placeholder={"Enter password"}
                            onChange={(e) => setPassword(e.target.value)}
                            onPressEnter={onSubmit}
                            style={{
                                border: "1px solid #2B61C4",
                                borderRadius: "4px",
                                font: "normal normal 600 15px/20px Open Sans",
                            }}
                        />
                    </Form.Item>
                    {error && (
                        <p
                            style={{
                                color: "red",
                                margin: "0px",
                                marginBottom: "5px",
                            }}
                        >
                            {error}
                        </p>
                    )}

                    <Button
                        id={"forgot-password-link"}
                        style={{
                            width: "100%",
                            background: "white",
                            border: "none",
                            color: "#2B61C4",
                            textTransform: "uppercase",
                        }}
                        type="secondary"
                        onClick={() => history.push("/forgot-password")}
                    >
                        Forgot password
                    </Button>

                    <Form.Item
                        wrapperCol={{
                            span: 24,
                        }}
                        style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                        }}
                    >
                        <Button
                            loading={isLoading}
                            type="primary"
                            onClick={onSubmit}
                        >
                            Login
                        </Button>
                    </Form.Item>

                    {/* Digital ID Login */}
                    <ConfigProvider
                        theme={{
                            components: {
                                Button: {
                                    defaultBorderColor: "#2B61C4",
                                    contentFontSizeLG: "16px",
                                },
                            },
                        }}
                    >
                        <Button
                            size="large"
                            shape="round"
                            icon={<DigitalIdLogoIcon />}
                            onClick={() =>
                                history.push("/mydid", {
                                    verifyType: "Login",
                                })
                            }
                            style={{
                                minWidth: "25%",
                                gap: "5px",
                                marginTop: "10px",
                                padding: "24px 24px",
                                fontWeight: "600",
                                lineHeight: "24px",
                            }}
                        >
                            Login with MyDigital ID
                        </Button>
                    </ConfigProvider>
                </Form>
            </Card>

            <Drawer
                title={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: 18,
                            color: "#FFFFFF",
                        }}
                    >
                        <div>Get The Best Experience!</div>
                        <div onClick={() => setShowDrawer(false)}>
                            <CloseOutlined />
                        </div>
                    </div>
                }
                placement="top"
                onClose={() => setShowDrawer(false)}
                open={showDrawer}
                closable={false}
                height={220}
                styles={{
                    mask: {
                        backdropFilter: "blur(10px)",
                    },
                    content: {
                        boxShadow: "-10px 0 10px #666",
                    },
                    header: {
                        background:
                            "linear-gradient(90deg, #4A9D9B 0%, #442373 100%)",
                    },
                    body: {
                        background: "#F1F1F1",
                        padding: "8px 10px",
                    },
                }}
            >
                <Row
                    wrap={false}
                    gap={10}
                    justify="space-around"
                    align="middle"
                >
                    <Col flex="auto">
                        <div
                            style={{
                                height: 130,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <p
                                    style={{
                                        paddingRight: 5,
                                        fontSize: 16,
                                        fontWeight: 500,
                                    }}
                                >
                                    Use our mobile app for seamless transaction
                                    and faster performance.
                                </p>
                            </div>

                            <div>
                                <a
                                    style={{
                                        textDecoration: "underline",
                                    }}
                                    onClick={() => setShowDrawer(false)}
                                >
                                    Continue to mobile site
                                </a>
                            </div>

                            <Checkbox
                                onChange={() => {
                                    setChecked(!checked);
                                    if (!checked) {
                                        setDontShowDownloadApp(true);
                                    } else {
                                        removeDontShowDownloadApp();
                                    }
                                }}
                                checked={checked}
                                style={{ fontSize: 12 }}
                            >
                                Don't show again
                            </Checkbox>
                        </div>
                    </Col>
                    <Col flex="none">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                const appUrls = {
                                    iOS: "https://apps.apple.com/my/app/twocar-by-myeg/id6550889301",
                                    Android:
                                        "https://play.google.com/store/apps/details?id=my.com.myeg.twocar",
                                };
                                const appUrl = appUrls[device];
                                if (appUrl) {
                                    window.location.href = appUrl;
                                }
                            }}
                        >
                            <img src={AppIcon} height={80} />
                            <a
                                style={{
                                    color: "#2B61C4",
                                    fontSize: 12,
                                    fontWeight: "500",
                                    paddingTop: 4,
                                }}
                            >
                                DOWNLOAD NOW
                            </a>
                        </div>
                    </Col>
                </Row>
            </Drawer>
        </div>
    );
}
