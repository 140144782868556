import {
    Breadcrumb,
    Button,
    Form,
    Image,
    Input,
    message,
    Row,
    Select,
    Tooltip,
    Table,
    Empty,
    DatePicker,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useForm } from "antd/lib/form/Form";
import { useContext, useEffect, useState } from "react";
import export_xls from "../../../assets/export_xls_filled.svg";
import dayjs from "dayjs";
import { postSearchRoadTaxTransaction } from "../../../services/api";
import { SessionContext } from "../../../App";
import {
    SEARCH_QUERY_TOOLTIP_NRIC,
    SEARCH_QUERY_TOOLTIP,
} from "../../../constants";
import ReactExport from "react-export-excel";
import ReactDOM from "react-dom";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function RoadTaxTransactionPage() {
    const [searchForm] = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState({
        count: 1,
        currentPage: 1,
        data: [],
    });
    const { company } = useContext(SessionContext);
    const [searchTerm, setSearchTerm] = useState(dayjs().format("DD-MM-YYYY"));
    const [sortSearch, setSortSearch] = useState("1D");
    const [currentPage, setCurrentPage] = useState(1);
    const [searchBy, setSearchBy] = useState("transactiondate");
    const [timer, setTimer] = useState(null);
    const pageSize = 10;

    const columns = [
        {
            title: "No",
            dataIndex: "id",
            render: (text, record, index) =>
                (dataSource?.currentPage - 1) * pageSize + index + 1,
        },
        {
            title: "Transaction Date",
            dataIndex: "paymentCreatedDate",
            key: "id",
            sorter: (a, b, sortOrder) => {},
            sortDirections: ["ascend", "descend"],
            render: (paymentCreatedDate) =>
                dayjs(paymentCreatedDate).format("DD-MM-YYYY"),
        },
        {
            title: "Vehicle Number",
            dataIndex: "vehicleNo",
        },
        {
            title: "IC Number",
            dataIndex: "idNo",
        },
        {
            title: "Road Tax Exp Date",
            dataIndex: "lkmEndDate",
            render: (lkmEndDate) => dayjs(lkmEndDate).format("DD-MM-YYYY"),
        },
        {
            title: "Merchant ID",
            dataIndex: "merchantTxId",
            sorter: (a, b, sortOrder) => {},
            sortDirections: ["ascend", "descend"],
        },
        {
            title: "Amount (RM)",
            dataIndex: "roadTaxAmount",
            sorter: (a, b, sortOrder) => {},
            sortDirections: ["ascend", "descend"],
            render: (roadTaxAmount) => roadTaxAmount.toFixed(2),
        },

        {
            title: "Receipt Link",
            dataIndex: "receiptUrl",
            render: (receiptUrl) => (
                <a
                    href={receiptUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "underline" }}
                >
                    Click Here
                </a>
            ),
        },
    ];

    const debounceSearch = (value) => {
        // Clear the previous timer if there's any
        if (timer) {
            clearTimeout(timer);
        }

        // Set a new timer to call the handleSearch function after 500ms
        const newTimer = setTimeout(() => {
            handleSearch(value);
        }, 1000);

        // Update the timer state to clear it later
        setTimer(newTimer);
    };

    useEffect(() => {
        init();
    }, [company, sortSearch, currentPage, searchTerm]);

    const init = async (customPage) => {
        if (company && company?.id && company?.coRegNo) {
            try {
                setIsLoading(true);
                const response = await postSearchRoadTaxTransaction({
                    branchId: company?.id,
                    dealerCompanyROC: company?.coRegNo,
                    pageLimit: pageSize,
                    pageNumber: customPage || currentPage,
                    search: searchBy,
                    searchParam: searchTerm, //WAR1132 //980101083333 //13-03-2025
                    sort: sortSearch,
                });
                //For exporting data
                if (customPage) {
                    return response?.data;
                } else {
                    setDataSource(response);
                }
            } catch (error) {
                message.error("Error fetching roadtax transaction data.");
            } finally {
                setIsLoading(false);
            }
        }
    };

    const validateAndTrimSearchQuery = (searchTerm) => {
        if (typeof searchTerm === "undefined" || searchTerm === undefined) {
            searchTerm = ""; // Assign empty string if searchTerm is undefined
        }
        return searchTerm.replace(/[\s]/g, "");
    };

    const handleSearch = async (value) => {
        let trimmedSearchQuery = validateAndTrimSearchQuery(value);
        setSearchTerm(trimmedSearchQuery);
    };

    const handleExport = async () => {
        const totalPages = Math.ceil(dataSource.count / pageSize);

        if (totalPages) {
            const apiLists = Array(totalPages)
                .fill(0)
                .map((_, i) => init(i + 1));

            Promise.all(apiLists)
                .then((data) => {
                    let processedData = data.flat();

                    // Preprocess data to apply date formatting
                    processedData = processedData.map((item, index) => ({
                        ...item,
                        index: index + 1,
                        paymentCreatedDate: dayjs(
                            item?.paymentCreatedDate
                        ).format("DD-MM-YYYY"),
                        lkmEndDate: dayjs(item?.paymentCreatedDate).format(
                            "DD-MM-YYYY"
                        ),
                    }));

                    const columns = [
                        { title: "No", dataIndex: "index", key: "index" },
                        {
                            title: "Transaction Date",
                            dataIndex: "paymentCreatedDate",
                        },
                        {
                            title: "Vehicle Number",
                            dataIndex: "vehicleNo",
                        },
                        {
                            title: "Ic Number",
                            dataIndex: "idNo",
                        },
                        { title: "Road Tax Exp Date", dataIndex: "lkmEndDate" },
                        { title: "Merchant ID", dataIndex: "merchantTxId" },
                        { title: "Amount (RM)", dataIndex: "roadTaxAmount" },
                        { title: "Receipt Link", dataIndex: "receiptUrl" },
                    ];

                    const ExcelFileComponent = (
                        <ExcelFile filename="APT_Listing_Report">
                            <ExcelSheet
                                data={processedData}
                                name="APT Listing Report"
                            >
                                {columns.map((col) => (
                                    <ExcelColumn
                                        key={col.title}
                                        label={col.title}
                                        value={col.dataIndex}
                                    />
                                ))}
                            </ExcelSheet>
                        </ExcelFile>
                    );

                    // Trigger Excel download
                    const exportWrapper = document.createElement("div");
                    document.body.appendChild(exportWrapper);
                    ReactDOM.render(ExcelFileComponent, exportWrapper);
                    exportWrapper.firstChild.click();
                    document.body.removeChild(exportWrapper);
                })
                .catch((error) => {
                    message.error("Error exporting data", error);
                });
        }
    };

    return (
        <>
            <div className="page-content">
                <Breadcrumb
                    separator=">"
                    style={{ marginTop: "60px" }}
                    items={[
                        {
                            title: "Dashboard",
                            href: "/",
                        },
                        {
                            title: "Reporting",
                            href: "/reporting",
                        },
                        {
                            title: "Road Tax Transaction History",
                            href: "/reporting/inventorylisting",
                        },
                    ]}
                ></Breadcrumb>
                <PageHeader
                    className="site-page-header"
                    title={"Road Tax Transaction History"}
                />
                <div className="search-header">Search by</div>
                <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Form
                        layout="inline"
                        className="select-search-wrapper"
                        style={{
                            borderRadius: 4,
                            marginBottom: 25,
                            width: "50vw",
                        }}
                        form={searchForm}
                        initialValues={{
                            searchBy: "transactiondate",
                            transactiondate: dayjs(),
                        }}
                    >
                        <Form.Item
                            name={"searchBy"}
                            style={{ width: "200px", marginRight: 10 }}
                        >
                            <Select
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{
                                    width: "100%",
                                    maxWidth: 200,
                                    height: 40,
                                }}
                                onChange={() => {
                                    setSearchBy(
                                        searchForm.getFieldValue("searchBy")
                                    );
                                }}
                            >
                                <Select.Option value={"transactiondate"}>
                                    Transaction Date
                                </Select.Option>
                                <Select.Option value={"vehiclenumber"}>
                                    Vehicle Number
                                </Select.Option>
                                <Select.Option value={"ownernric"}>
                                    Owner Nric
                                </Select.Option>
                            </Select>
                        </Form.Item>

                        {searchBy === "transactiondate" ? (
                            <Form.Item
                                className="date-wrapper"
                                name={"transactiondate"}
                            >
                                <DatePicker
                                    format={"DD-MM-YYYY"}
                                    maxDate={dayjs()}
                                    onChange={(date) =>
                                        setSearchTerm(
                                            dayjs(date).format("DD-MM-YYYY")
                                        )
                                    }
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                className="form-item"
                                name={"searchTerm"}
                            >
                                <Tooltip
                                    title={
                                        searchForm.getFieldValue("searchBy") ===
                                        "ownernric"
                                            ? SEARCH_QUERY_TOOLTIP_NRIC
                                            : SEARCH_QUERY_TOOLTIP
                                    }
                                    placement="bottom"
                                >
                                    <Input.Search
                                        style={{ maxWidth: "100%" }}
                                        size="large"
                                        placeholder={"Search"}
                                        enterButton
                                        onSearch={handleSearch}
                                        onChange={(e) =>
                                            debounceSearch(
                                                e.target.value.toUpperCase()
                                            )
                                        }
                                    />
                                </Tooltip>
                            </Form.Item>
                        )}
                    </Form>

                    <Button
                        shape="round"
                        className="add-new-user-button export-report"
                        type="primary"
                        style={{ marginBottom: 10 }}
                        onClick={handleExport}
                        disabled={isLoading || dataSource?.data?.length === 0} // Disable the button if no data
                    >
                        <Image
                            src={export_xls}
                            preview={false}
                            width={30}
                            height={30}
                            style={{ paddingRight: 10 }}
                        />
                        Export report
                    </Button>
                </Row>

                <Table
                    className="roadtax-table"
                    dataSource={dataSource?.data}
                    columns={columns}
                    loading={isLoading}
                    pagination={{
                        current: dataSource.currentPage,
                        pageSize: pageSize,
                        total: dataSource.count,
                        showSizeChanger: false, // Remove the page size dropdown
                    }}
                    rowKey={(record) => record.id}
                    locale={{
                        emptyText: (
                            <Empty description="No transaction history found for the given details." />
                        ),
                    }}
                    onChange={(paginate, filters, sorter) => {
                        const field = sorter.field;

                        setCurrentPage(paginate?.current);

                        if (field === "paymentCreatedDate") {
                            sorter?.order === "ascend"
                                ? setSortSearch("1A")
                                : setSortSearch("1D");
                        } else if (field === "merchantTxId") {
                            sorter?.order === "ascend"
                                ? setSortSearch("2A")
                                : setSortSearch("2D");
                        } else if (field === "roadTaxAmount") {
                            sorter?.order === "ascend"
                                ? setSortSearch("3A")
                                : setSortSearch("3D");
                        } else {
                            setSortSearch("1D");
                        }
                    }}
                />
            </div>
            <style>
                {`
                .site-page-header { 
                    padding-left: 0px;
                    padding-bottom: 15px;
                }
                .search-header { 
                    font: italic normal normal 16px/22px Open Sans;
                    color: white;
                    margin-bottom: 7px;
                }
                .export-report.add-new-user-button {
                    // background: #e7e7e7;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 127px !important;  
                    padding: 0px 30px;
                    box-shadow: none;
                }
                .ant-select-single:not(.ant-select-customize-input) .ant-select-selector { 
                    height: 48px !important;
                }
                .ant-select-single .ant-select-selector {
                    align-items: center;
                    color: #00000080;
                    font-weight: 600;
                }
                .ant-select-arrow { 
                    margin: 0px;
                }
                .ant-select-item-option-content { 
                    color: #2B61C4;
                    font-weight: 600;
                }
				.type-wrapper {
					min-width: 12vw;
				}
                .roadtax-table { 
                    margin-top: 20px;
                }
            `}
            </style>
        </>
    );
}
