import {
  EditOutlined,
  PlusOutlined,
  ArrowDownOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
  Tooltip,
  Button,
  Col,
  Divider,
  Modal,
  Checkbox,
  Card,
  Pagination,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useContext, useEffect, useState, useCallback, useRef } from "react";
import moment from "moment";
import {
  getInventoryList,
  getBranchListByCoRegNo,
  saveInventory,
  editInventory,
  getCompanyDetails,
} from "../../../services/api";
import { SessionContext } from "../../../App";
import { HQ_BRANCH, SEARCH_QUERY_TOOLTIP } from "../../../constants";
//Component
import {
  VehicleUpdateTableComponent,
  VehicleSelectedTableComponent,
} from "../../component/Layout/TableComponent";
import { VehicleSearchForm } from "../../component/Layout/FormComponent";
import { UpdateButton, BackButton } from "../../component/UI/Button";
import { ReactComponent as ArrowElbowIcon } from "../../../assets/icon/Icon-arrow-elbow.svg";
import { highlightText } from "../../component/Function/HighlightText";
import { TableBlueShadowCheckbox } from "../../component/UI/Checkbox";

export default function InventoryListingV2Page({
  props,
  setIsVehicleSearched,
  setVehicleSelectedData,
}) {
  const { Search } = Input;
  const [searchForm] = useForm();
  const { company, branch, branchs, user } = useContext(SessionContext);
  const [initialDataSource, setInitialDataSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isEmptySearch, setIsEmptySearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [addVehicleForm] = useForm();
  const [branchId, setBranchId] = useState(null);
  const [addVehicleSearchTerm, setAddVehicleSearchTerm] = useState("");
  const [chasisNoSearchTerm, setChasisNoSearchTerm] = useState("");
  const [engineNoSearchTerm, setEngineNoSearchTerm] = useState("");
  const [isAddVehicleEnable, setIsAddVehicleEnable] = useState(false);

  const [editVehicleForm] = useForm();
  const [vehicleData, setVehicleData] = useState([]);
  const [isEditVehicle, setIsEditVehicle] = useState(false);
  const [isVehicleDisable, setIsVehicleDisable] = useState(false);

  const [isBranchLoading, setIsBranchLoading] = useState(false);
  const [branchDetails, setBranchDetails] = useState([]);

  const [isSelectedModalVisible, setIsSelectedModalVisible] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState([]);
  // const isEnableBranch = isEditVehicle || branch.id === 0;
  const isEnableBranch = branch.id === 0;
  const allBranches = branchs ? [...branchs, HQ_BRANCH] : [HQ_BRANCH];

  const [ownerIdOption, setOwnerIdOption] = useState([company.coRegNo]);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const companyRes = await getCompanyDetails(company.coRegNo);
      if (companyRes.status === "00") {
        const newRegNo = companyRes.companyDetails?.coNewRegNo;
        if (newRegNo) {
          setOwnerIdOption([company.coRegNo, newRegNo]);
        } else {
          setOwnerIdOption([company.coRegNo]);
        }
        
        addVehicleForm.setFieldsValue({
          ownerId: ownerIdOption[0], //default
        });
      }
    };

    if(!isEditVehicle) {
      fetchCompanyDetails();
    }
  }, [company.coRegNo, isModalVisible]);

  //Pagination - START
  //TODO - To refactor to component
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedData, setSortedData] = useState([]);

  const [isEditing, setIsEditing] = useState(false);

  const offsetHeader = isLoading ? 0 : 220;

  const maxPageSize = 30;
  const startItem = (currentPage - 1) * maxPageSize + 1;
  const endItem = Math.min(currentPage * maxPageSize, dataSource.length);
  const totalPages = Math.ceil(dataSource.length / 30);
  const paginatedData = sortedData.slice(
    (currentPage - 1) * maxPageSize,
    Math.min(currentPage * maxPageSize, sortedData.length)
  );

  useEffect(() => {
    setSortedData(dataSource);
  }, [dataSource]);

  const handleDeselectThisPage = () => {
    setSelectedRowKeys((prev) => ({
      ...prev,
      [currentPage]: [],
    }));
    setSelectAll((prev) => ({
      ...prev,
      [currentPage]: false,
    }));
  };

  const handleClearAll = () => {
    setSelectedRowKeys({});
    setSelectAll({});
  };

  const getCurrentPageTotal = () => {
    const currentSelectedKeys = selectedRowKeys[currentPage] || [];
    return currentSelectedKeys.length;
  };

  const getTotalSelectedKeys = () => {
    return Object.values(selectedRowKeys).flat().length;
  };

  const handleSelectAllChange = (page, checked) => {
    const currentSelectedKeys = selectedRowKeys[page] || [];
    const remainingQuota =
      50 - getTotalSelectedKeys() + currentSelectedKeys.length;
    const newSelectedKeys = checked
      ? paginatedData.slice(0, remainingQuota).map((item) => item.key)
      : [];

    setSelectAll((prev) => ({ ...prev, [page]: checked }));
    setSelectedRowKeys((prev) => ({
      ...prev,
      [page]: newSelectedKeys,
    }));
  };

  const handleRowSelectionChange = (page, key) => {
    setSelectedRowKeys((prev) => {
      const newSelectedKeys = prev[page] ? [...prev[page]] : [];
      if (newSelectedKeys.includes(key)) {
        return { ...prev, [page]: newSelectedKeys.filter((k) => k !== key) };
      } else {
        const totalSelectedKeys = getTotalSelectedKeys();
        if (totalSelectedKeys >= 50) {
          message.warning("You can only select up to 50 items.");
          return prev;
        }
        newSelectedKeys.push(key);
        return { ...prev, [page]: newSelectedKeys };
      }
    });
    setSelectAll((prev) => ({
      ...prev,
      [page]: selectedRowKeys[page]?.length === paginatedData.length - 1,
    }));
  };

  const handleSort = (order) => {
    const sorted = [...dataSource].sort((a, b) => {
      return order === "ascend"
        ? moment(a.transactionDate).diff(moment(b.transactionDate))
        : moment(b.transactionDate).diff(moment(a.transactionDate));
    });
    setSortedData(sorted);
  };

  /* custom pagination */
  const handleInputChange = (e) => {
    setCurrentPage(e.target.value);
  };

  const onPageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleInputBlur = () => {
    const page = Math.max(1, Math.min(totalPages, Number(currentPage)));
    onPageChange(page);
    setIsEditing(false);
  };

  const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
    return (
      <div className="custom-pagination">
        <Button
          type="text"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LeftOutlined />
        </Button>
        {isEditing ? (
          <span>
            Page{" "}
            <input
              style={{ maxWidth: "40px" }}
              type="number"
              value={currentPage}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              autoFocus
            />{" "}
            of {totalPages}{" "}
          </span>
        ) : (
          <span onClick={() => setIsEditing(true)}>
            Page {currentPage} of {totalPages}
          </span>
        )}
        <Button
          type="text"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <RightOutlined />
        </Button>
      </div>
    );
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  //Pagination - END
  const columns = [
    {
      title: (
        <TableBlueShadowCheckbox
          checked={selectAll[currentPage] || false}
          onChange={(e) => handleSelectAllChange(currentPage, e.target.checked)}
          disabled={false}
        />
      ),
      width: "60px",
      dataIndex: "selectAll",
      key: "selectAll",
      render: (_, record) => (
        <TableBlueShadowCheckbox
          checked={selectedRowKeys[currentPage]?.includes(record.key) || false}
          onChange={() => handleRowSelectionChange(currentPage, record.key)}
          disabled={false}
        />
      ),
      onCell: (rowData) => ({
        style: {
          backgroundColor: selectedRowKeys[currentPage]?.includes(rowData.key)
            ? "#f0f4fc"
            : "",
        },
      }),
    },
    {
      title: "Date Created",
      dataIndex: "transactionDate",
      render: (transactionDate) => {
        let formattedDate = new Date(transactionDate);
        return moment(formattedDate).format("DD-MMM-YYYY hh:mm:ss");
      },
      sorter: true,
      sortOrder: sortOrder,
      onCell: (rowData) => ({
        style: {
          backgroundColor: selectedRowKeys[currentPage]?.includes(rowData.key)
            ? "#f0f4fc"
            : "",
        },
      }),
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehicleNo",
      render: (text) => highlightText(text, searchTerm),
      onCell: (rowData) => ({
        style: {
          backgroundColor: selectedRowKeys[currentPage]?.includes(rowData.key)
            ? "#f0f4fc"
            : "",
        },
      }),
    },
    {
      title: "Chasis Number",
      dataIndex: "chassisno",
      render: (text) => highlightText(text, searchTerm),
      onCell: (rowData) => ({
        style: {
          backgroundColor: selectedRowKeys[currentPage]?.includes(rowData.key)
            ? "#f0f4fc"
            : "",
        },
      }),
    },
    {
      title: "Engine Number",
      dataIndex: "engineno",
      render: (text) => highlightText(text, searchTerm),
      onCell: (rowData) => ({
        style: {
          backgroundColor: selectedRowKeys[currentPage]?.includes(rowData.key)
            ? "#f0f4fc"
            : "",
        },
      }),
    },
    branch.id === 0 && {
      title: "Branch",
      dataIndex: "branchId",
      render: (branchId) => {
        // let allBranches = branchs ? [...branchs, HQ_BRANCH] : [HQ_BRANCH];
        return allBranches.find((b) => b.id == branchId)?.branchName || "-";
      },
      onCell: (rowData) => ({
        style: {
          backgroundColor: selectedRowKeys[currentPage]?.includes(rowData.key)
            ? "#f0f4fc"
            : "",
        },
      }),
    },
    {
      title: "Action",
      fixed: "right",
      render: (rowData) => {
        return (
          <>
            <Button
              disabled={
                rowData.transactionType === "IN" ||
                !branchDetails.find((branch) => branch.id === rowData.branchId)
              }
              style={{
                border: "1px solid #2B61C4",
                color: "#2B61C4",
                borderRadius: "4px",
                marginBottom: 10,
                marginRight: 10,
              }}
              onClick={(e) => {
                setAddVehicleSearchTerm(rowData.vehicleNo);
                setChasisNoSearchTerm(rowData.chassisno);
                setEngineNoSearchTerm(rowData.engineno);
                setBranchId(rowData.branchId);

                addVehicleForm.setFieldsValue({
                  vehicleRegistrationNo: rowData.vehicleNo,
                  chassisNo: rowData.chassisno,
                  engineNo: rowData.engineno,
                  branchId: rowData.branchId,
                  ownerId: rowData.ownerId,
                });
                // editVehicleForm.setFieldsValue({
                //   chassisNo: rowData.chassisno,
                //   engineNo: rowData.engineno,
                // });
                const vehicle = {
                  id: rowData.id,
                  companyId: rowData.companyId,
                  branchId: rowData.branchId,
                  vehicleNo: rowData.vehicleNo,
                  chassisNo: rowData.chassisno,
                  engineNo: rowData.engineno,
                };
                const dataArray = Array.isArray(vehicle) ? vehicle : [vehicle];
                if (rowData.transactionType === "IN") {
                  setIsVehicleDisable(true);
                }
                setVehicleData(dataArray);
                setIsAddVehicleEnable(true);
                setIsEditVehicle(true);
              }}
            >
              <>
                <EditOutlined />
                <span className="img-btn-text">EDIT</span>
              </>
            </Button>
          </>
        );
      },
      onCell: (rowData) => ({
        style: {
          backgroundColor: selectedRowKeys[currentPage]?.includes(rowData.key)
            ? "#f0f4fc"
            : "",
        },
      }),
    },
  ].filter(Boolean);

  function createRegex(query) {
    const escapedQuery = query.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"); // Escape special characters
    const regexPattern = escapedQuery.split("%").join(".*"); // Replace '%' with '.*' for wildcard matching
    return new RegExp(regexPattern, "i"); // 'i' for case-insensitive matching
  }

  const handleSearch = () => {
    setIsLoading(true);
    const { searchTerm } = searchForm.getFieldsValue();

    setIsLoading(true);
    let trimmedSearchQuery = ""; // Assign empty string if searchTerm is undefined
    if (typeof searchTerm !== "undefined" || searchTerm !== undefined) {
      // trimmedSearchQuery = searchTerm.replace(/\s/g, "");
      trimmedSearchQuery = searchTerm.trim().toUpperCase();
    }

    setSearchTerm(trimmedSearchQuery);
    const regex = createRegex(trimmedSearchQuery);
    getInventoryList(
      branch.id,
      company.coRegNo,
      initialDataSource.length,
      1,
      // trimmedSearchQuery
      branch.id === 0 ? "ALL" : ""
    )
      .then((res) => {
        if (res.length === 0) {
          setIsEmptySearch(true);
        } else {
          const dataWithKey = res
            .filter(
              (item) =>
                regex.test(item.vehicleNo) ||
                regex.test(item.engineno) ||
                regex.test(item.chassisno)
            )
            .map((item, index) => {
              return {
                key: item.id,
                ...item,
              };
            });
          setDataSource(dataWithKey);
        }
      })
      .catch((err) => {
        err && message.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const styles = {
    label: {
      marginBottom: 0,
    },
  };

  const onInputChange = useCallback(() => {
    if (
      addVehicleForm.getFieldValue("vehicleRegistrationNo") !== "" &&
      (!isEnableBranch || addVehicleForm.getFieldValue("branchId") !== "") &&
      addVehicleForm.getFieldValue("chassisNo") !== "" &&
      addVehicleForm.getFieldValue("engineNo") !== ""
    ) {
      setIsAddVehicleEnable(true);
    } else {
      setIsAddVehicleEnable(false);
    }
  }, []);

  const onChangeVehicleInput = useCallback((e) => {
    const newValue = e.target.value.toUpperCase().trim().replace(/\s/g, "");
    setAddVehicleSearchTerm(newValue);

    addVehicleForm.setFieldsValue({
      vehicleRegistrationNo: newValue,
    });
    onInputChange();
  }, []);

  const onChangeChasisInput = useCallback((e) => {
    const newValue = e.target.value.toUpperCase();
    setChasisNoSearchTerm(newValue);
    addVehicleForm.setFieldsValue({
      chassisNo: newValue,
    });
    onInputChange();
  }, []);

  const onChangeEngineInput = useCallback((e) => {
    const newValue = e.target.value.toUpperCase();
    setEngineNoSearchTerm(newValue);
    addVehicleForm.setFieldsValue({
      engineNo: newValue,
    });
    onInputChange();
  }, []);

  const onChangeBranch = (value) => {
    setBranchId(value);
    addVehicleForm.setFieldsValue({
      branchId: value,
    });
    onInputChange();
  };

  const onChangeOwnerId = (value) => {
    addVehicleForm.setFieldsValue({
      ownerId: value,
    });
    onInputChange();
  };

  /* const onUpdateVehicle = () => {
    editInventory(
      vehicleData[0].id,
      vehicleData[0].companyId,
      vehicleData[0].branchId,
      vehicleData[0].vehicleNo,
      editVehicleForm.getFieldValue("chassisNo"),
      editVehicleForm.getFieldValue("engineNo")
    )
      .then((res) => {
        if (!res || res.message !== "Success") throw res.message;
        message.success(
          "The vehicle " +
            vehicleData[0].vehicleNo +
            " has successfully updated"
        );

        setIsEditVehicle(false);
        //refresh data
        handleSearch();

        //clear
        editVehicleForm.setFieldsValue({
          chassisNo: "",
          engineNo: "",
        });
        setVehicleData([]);
      })
      .catch((err) => {
        err && message.error(err);
      })
      .finally(() => setIsBranchLoading(false));
  }; */

  const onBackButtonClick = () => {
    setIsEditVehicle(false);
  };

  const getSelectedDataSource = () => {
    return dataSource
      .filter((item) =>
        Object.values(selectedRowKeys).flat().includes(item.key)
      )
      .map((item, index) => {
        return {
          no: index + 1,
          id: item.id,
          companyId: item.companyId,
          branchId: item.branchId,
          vehicleNo: item.vehicleNo,
          chassisNo: item.chassisno,
          engineNo: item.engineno,
          vehicleTypeNew: item.vehicleTypeNew,
          ownerId: item.ownerId,
          // ...item,
        };
      });
  };

  const onJPJSubmit = () => {
    const selectedDataSource = getSelectedDataSource();
    setVehicleSelectedData(selectedDataSource);
    setIsVehicleSearched(true);
  };

  const onSelectedVehicle = () => {
    const selectedDataSource = getSelectedDataSource();
    setSelectedDataSource(selectedDataSource);
    setIsSelectedModalVisible(true);
  };

  const submitToJPJButton = () => {
    return (
      <Button
        type="primary"
        style={{ border: "5px" }}
        className="rectangle-button to-the-right"
        loading={isLoading}
        disabled={getTotalSelectedKeys() === 0}
        onClick={onJPJSubmit}
      >
        {getTotalSelectedKeys() === 0 ? "Select vehicle" : "Submit to JPJ"}
      </Button>
    );
  };

  const branchForm = () => {
    return (
      <Form.Item
        label={
          <>
            <p style={styles.label}>Branch&nbsp;</p>
            <span
              style={{ color: "red", fontWeight: "bold", paddingTop: "10px" }}
            >
              *
            </span>
          </>
        }
        labelCol={{ span: 24 }}
        name="branchId"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          loading={isBranchLoading}
          placeholder="Select branch"
          style={{ width: "100%" }}
          // className="selectbox-form-style"
          onChange={async (search) => {
            onChangeBranch(search);
          }}
        >
          {/* {branchDetails &&
            branchDetails */}
          {/* {isEdit
            ? branchDetails &&
            branchDetails
                // .filter((branch) => (branch.is_active))
                .map((branch) => (
                  <Select.Option key={branch.id} value={branch.id}>
                    {branch.branchName}
                  </Select.Option>
                ))
            :  */}
          {branchDetails &&
            branchDetails
              .filter((branch) => branch.is_active)
              .map((branch) => (
                <Select.Option key={branch.id} value={branch.id}>
                  {branch.branchName}
                </Select.Option>
              ))}
          {/* } */}
        </Select>
      </Form.Item>
    );
  };

  const vehicleForm = (isVehicleDisable) => {
    return (
      <Form.Item
        label={
          <>
            <p style={styles.label}>Vehicle Registration Number&nbsp;</p>
            <span
              style={{ color: "red", fontWeight: "bold", paddingTop: "10px" }}
            >
              *
            </span>
          </>
        }
        labelCol={{ span: 24 }}
        name="vehicleRegistrationNo"
        rules={[
          {
            required: true,
            pattern: new RegExp("^[a-zA-Z0-9]{2,20}$"),
            message: (
              <ol>
                <li>{"Must be between 2 - 20 characters"}</li>
                <li>{"Allow for (a-z), (A-Z), (0-9)"}</li>
              </ol>
            ),
          },
        ]}
      >
        <Tooltip title={SEARCH_QUERY_TOOLTIP} placement="bottom">
          <Input
            disabled={isVehicleDisable}
            placeholder=""
            style={{ width: "100%" }}
            className="textbox-form-style"
            value={addVehicleSearchTerm}
            onChange={(e) => {
              onChangeVehicleInput(e);
            }}
          />
        </Tooltip>
      </Form.Item>
    );
  };

  const chasisForm = () => {
    return (
      <Form.Item
        label={
          <>
            <p style={styles.label}>Chassis Number&nbsp;</p>
            <span
              style={{ color: "red", fontWeight: "bold", paddingTop: "10px" }}
            >
              *
            </span>
          </>
        }
        labelCol={{ span: 24 }}
        name="chassisNo"
        rules={[
          {
            required: true,
            whitespace: true,
            message: `Chassis Number is required`,
          },
        ]}
      >
        <Input
          style={{ width: "100%" }}
          className="textbox-form-style"
          placeholder=""
          value={chasisNoSearchTerm}
          onChange={async (e) => {
            onChangeChasisInput(e);
          }}
        />
      </Form.Item>
    );
  };

  const engineForm = () => {
    return (
      <Form.Item
        label={
          <>
            <p style={styles.label}>Engine Number&nbsp;</p>
            <span
              style={{ color: "red", fontWeight: "bold", paddingTop: "10px" }}
            >
              *
            </span>
          </>
        }
        labelCol={{ span: 24 }}
        name="engineNo"
        rules={[
          {
            required: true,
            whitespace: true,
            message: `Engine Number is required`,
          },
        ]}
      >
        <Input
          style={{ width: "100%" }}
          className="textbox-form-style"
          placeholder=""
          value={engineNoSearchTerm}
          onChange={async (e) => {
            onChangeEngineInput(e);
          }}
        />
      </Form.Item>
    );
  };

  const ownerid = () => {
    return (
      <Form.Item
        label={
          <>
            <p style={styles.label}>Company Reg No&nbsp;</p>
          </>
        }
        labelCol={{ span: 24 }}
        name="ownerId"
      >
        <Select
          placeholder="Select Company Reg No"
          style={{ width: "100%" }}
          // value={ownerIdSearchTerm}
          // defaultValue={ownerIdOption[0]}
          onChange={async (e) => {
            onChangeOwnerId(e);
          }}
        >
          {ownerIdOption.map((coRegNo) => (
            <Select.Option key={coRegNo} value={coRegNo}>
              {coRegNo}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  //Modal
  const addVehicleModal = () => (
    <Modal
      open={isModalVisible || isEditVehicle}
      onCancel={() => {
        setIsVehicleDisable(false);
        setIsModalVisible(false);
        setIsEditVehicle(false);
        setAddVehicleSearchTerm("");
        setChasisNoSearchTerm("");
        setEngineNoSearchTerm("");
        setBranchId(null);
        addVehicleForm.setFieldsValue({
          vehicleRegistrationNo: "",
          branchId: undefined,
          chassisNo: "",
          engineNo: "",
        });
      }}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      footer={false}
      destroyOnHide
    >
      <h2>
        <b>{isEditVehicle ? "Edit Vehicle" : "Add Vehicle"}</b>
      </h2>
      <div style={{ marginBottom: "5px" }}>
        <span style={{ color: "red", fontWeight: "bold" }}>*</span> indicates
        required field
      </div>

      <Form
        initialValues={{
          vehicleRegistrationNo: "",
          branchId: undefined,
          chassisNo: "",
          engineNo: "",
          ownerId: "",
        }}
        form={addVehicleForm}
        onFinish={async (values) => {
          if (isEditVehicle) {
            editInventory(
              vehicleData[0].id,
              vehicleData[0].companyId,
              values.branchId ?? vehicleData[0].branchId,
              values.vehicleRegistrationNo,
              values.chassisNo.trim(),
              values.engineNo.trim(),
              values.ownerId ?? company.coRegNo
            )
              .then((res) => {
                if (!res || res.message !== "Success") throw res.message;
                message.success(
                  "The vehicle " +
                    vehicleData[0].vehicleNo +
                    " has successfully updated"
                );

                setIsEditVehicle(false);
                setIsAddVehicleEnable(false);
                //refresh data
                handleSearch();

                //clear
                addVehicleForm.setFieldsValue({
                  vehicleRegistrationNo: "",
                  branchId: undefined,
                  chassisNo: "",
                  engineNo: "",
                });
                setAddVehicleSearchTerm("");
                setChasisNoSearchTerm("");
                setEngineNoSearchTerm("");
                setBranchId(null);
                setVehicleData([]);
              })
              .catch((err) => {
                err && message.error(err);
              })
              .finally(() => setIsBranchLoading(false));
          } else {
            saveInventory(
              company.id,
              values.branchId ?? branch.id,
              values.vehicleRegistrationNo,
              values.chassisNo.trim(),
              values.engineNo.trim(),
              user?.name,
              "MANUAL",
              "New",
              values.ownerId ?? company.coRegNo
            )
              .then((res) => {
                if (!res || res.message !== "Success") throw res.message;
                message.success(
                  "The vehicle " +
                    values.vehicleRegistrationNo +
                    " has successfully added into the inventory listing"
                );

                setIsModalVisible(false);
                setIsAddVehicleEnable(false);
                //refresh data
                handleSearch();

                //clear
                addVehicleForm.setFieldsValue({
                  vehicleRegistrationNo: "",
                  branchId: undefined,
                  chassisNo: "",
                  engineNo: "",
                });
                setAddVehicleSearchTerm("");
                setChasisNoSearchTerm("");
                setEngineNoSearchTerm("");
                setBranchId(null);
              })
              .catch((err) => {
                err && message.error(err);
              })
              .finally(() => setIsBranchLoading(false));
          }
        }}
      >
        <Row justify="end">
          <Col
            className="gutter-row"
            span={12}
            style={{ paddingRight: "10px" }}
          >
            {isEnableBranch ? branchForm() : vehicleForm(isVehicleDisable)}
          </Col>
          <Col className="gutter-row" span={12} style={{ paddingLeft: "10px" }}>
            {isEnableBranch ? vehicleForm(isVehicleDisable) : chasisForm()}
          </Col>
        </Row>
        <Row justify="end">
          <Col
            className="gutter-row"
            span={12}
            style={{ paddingRight: "10px" }}
          >
            {isEnableBranch ? chasisForm() : engineForm()}
          </Col>
          <Col className="gutter-row" span={12} style={{ paddingLeft: "10px" }}>
            {isEnableBranch ? engineForm() : ownerid()}
          </Col>
        </Row>
        <Row justify="end">
          <Col
            className="gutter-row"
            span={12}
            style={{ paddingRight: "10px" }}
          >
            {isEnableBranch ? ownerid() : <></>}
          </Col>
          <Col className="gutter-row" span={12} style={{ paddingLeft: "10px" }}>
            <></>
          </Col>
        </Row>
        <Row justify="end">
          <Button
            size="large"
            className="rectangle-button"
            htmlType="submit"
            type="primary"
            disabled={!isAddVehicleEnable}
            // loading={saveSellerLoading}
            onClick={() => {
              // setSearchTerm(
              //   vehicleInfoForm.getFieldValue("vehicleRegistrationNo")
              // );
            }}
          >
            {isEditVehicle ? (
              <>Save & Update</>
            ) : isAddVehicleEnable ? (
              <>Save</>
            ) : (
              <>Enter details</>
            )}
          </Button>
        </Row>
      </Form>
    </Modal>
  );

  const selectedVehicleTableModal = () => (
    <Modal
      open={isSelectedModalVisible}
      onCancel={() => {
        setIsSelectedModalVisible(false);
      }}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      footer={submitToJPJButton()}
      width={"80%"}
    >
      <p style={{ fontSize: "18px" }}>
        <b>{getTotalSelectedKeys()} Vehicle</b>
      </p>
      {VehicleSelectedTableComponent(
        selectedDataSource,
        branchs,
        false,
        false,
        branch.id === 0
      )}
    </Modal>
  );

  /* getInventoryList */
  useEffect(() => {
    if (Object.keys(branch).length > 0 && Object.keys(company).length) {
      setIsLoading(true);
      getInventoryList(
        branch.id,
        company.coRegNo,
        0,
        1,
        branch.id === 0 ? "ALL" : ""
      )
        .then((res) => {
          const dataWithKey = res.map((item, index) => {
            return {
              key: item.id,
              ...item,
            };
          });
          setInitialDataSource(dataWithKey);
          setDataSource(dataWithKey);
          setIsLoading(false);
        })
        .catch((err) => {
          err && message.error(err);
        });
    }
  }, [branch, company]);

  /* getBranchList */
  useEffect(() => {
    // if (isModalVisible) {
    setIsBranchLoading(true);
    getBranchListByCoRegNo(company.coRegNo)
      .then((res) => {
        if (!res) throw res.message;
        setBranchDetails(res.branchDetails);
      })
      .catch((err) => {
        err && message.error(err);
      })
      .finally(() => setIsBranchLoading(false));
    // }
  }, []);
  // }, [isModalVisible, isEditVehicle]);

  return (
    <>
      <>
        <div className="sticky-card">
          {/* Pagination */}
          <Card>
            <Row justify="space-around" align="middle">
              <Col span={3} style={{ textAlign: "left" }}>
                <Pagination
                  current={currentPage}
                  pageSize={1}
                  total={totalPages}
                  onChange={(page) => {
                    setCurrentPage(page);
                    setSelectAll((prev) => ({ ...prev, [page]: false }));
                  }}
                  showSizeChanger={false}
                  style={{ marginBottom: "16px" }}
                />
                <CustomPagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </Col>
              <Col span={13} style={{ textAlign: "left" }}>
                <Row>
                  <h2
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      // marginBottom: 24,
                    }}
                  >
                    Inventory Listing
                  </h2>
                </Row>
                <Row>
                  <span>
                    Showing{" "}
                    <b>
                      {startItem} - {endItem}
                    </b>{" "}
                    of <b>{dataSource.length} vehicles</b>.
                  </span>
                </Row>
              </Col>
              <Col span={5}>
                {VehicleSearchForm(
                  searchForm,
                  searchTerm,
                  setSearchTerm,
                  handleSearch,
                  setIsEmptySearch,
                  isEmptySearch
                )}
              </Col>
              <Col span={3} style={{ textAlign: "right" }}>
                <Button
                  style={{
                    border: "1px solid #2B61C4",
                    color: "#2B61C4",
                    height: "45px",
                    // borderRadius: "4px",
                    // marginBottom: 10,
                    marginRight: 10,
                  }}
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                >
                  <>
                    <PlusOutlined />
                    <span className="img-btn-text">ADD VEHICLE</span>
                  </>
                </Button>
              </Col>
            </Row>
          </Card>

          {/* <Divider style={{ borderColor: "black", marginBottom: 1 }} /> */}

          {/* Selected & Submit to JPJ  */}
          <div
            style={{
              padding: 16,
              backgroundColor:
                getTotalSelectedKeys() !== 0 ? "#fffca4" : "white",
            }}
          >
            <Row justify="space-around" align="middle">
              {getTotalSelectedKeys() !== 0 ? (
                <>
                  <Col
                    span={getTotalSelectedKeys() === 0 ? 24 : 12}
                    style={{
                      textAlign: "left",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    &nbsp;
                    <ArrowElbowIcon /> Selected
                    <Button
                      type="link"
                      style={{ padding: 2 }}
                      onClick={onSelectedVehicle}
                    >
                      <span style={{ textDecoration: "underline" }}>
                        {getTotalSelectedKeys()} vehicles
                      </span>
                    </Button>{" "}
                    in the full inventory.
                    <Button
                      type="link"
                      onClick={handleDeselectThisPage}
                      style={{ padding: 2, marginLeft: 5 }}
                    >
                      <b>Deselect This Page</b>
                    </Button>
                    <Button
                      type="link"
                      style={{ padding: 2, marginLeft: 5 }}
                      onClick={handleClearAll}
                    >
                      <b>Clear All</b>
                    </Button>
                  </Col>
                </>
              ) : (
                <></>
              )}
              <Col
                span={getTotalSelectedKeys() === 0 ? 24 : 12}
                style={{ textAlign: "right" }}
              >
                <>
                  {getTotalSelectedKeys() === 0 && (
                    <>
                      Select up to a maximum 50 vehicles for JPJ
                      submission.&nbsp;&nbsp;&nbsp;&nbsp;
                    </>
                  )}
                  {submitToJPJButton()}
                </>
              </Col>
            </Row>
          </div>
        </div>

        {/* Inventory Listing */}
        <Table
          className="report-table"
          bordered={true}
          dataSource={paginatedData}
          columns={columns}
          loading={isLoading || isBranchLoading}
          pagination={false}
          sticky={{
            offsetHeader: offsetHeader, // Adjust based on the height of other sticky elements
            offsetScroll: 0,
            getContainer: () => window,
          }}
          scroll={{
            x: columns.length * 100,
          }}
          onChange={(pagination, filters, sorter) => {
            setSortOrder(sorter.order);
            handleSort(sorter.order);
          }}
          onRow={(record, rowIndex) => ({
            onClick: () => {},
          })}
        />

        {/* Modal pop out */}
        {addVehicleModal()}
        {selectedVehicleTableModal()}
      </>
      {/* Footer */}
      {/* <div className="section-bottom-button">{BackButton(props.prev)}</div> */}
      <style>
        {`
              //custom underline
              .custom-link-button-underline {
                text-decoration: underline;
              }
              .custom-link-button-underline:hover {
                text-decoration: underline;
              }

              //sticky table
              .ant-table-sticky-holder {
                z-index: 996;
              }
              /* Make the card sticky */
              .sticky-card {
                position: sticky;
                top: 0px;
                z-index: 999;
              }

              .site-page-header {
                padding-left: 0px;
                padding-bottom: 15px;
              }
              .search-header {
                font: italic normal normal 16px/22px Open Sans;
                color: white;
                margin-bottom: 7px;
              }
              .add-new-user-button {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 127px !important;
              }
              .add-new-user-button > span {
                font-size: 16px;
              }
              .export-report {
                padding-left: 30px;
                padding-right: 30px;
                box-shadow: none;
              }
              .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                height: 45px !important;
              }
              .ant-select-single .ant-select-selector {
                align-items: center;
                color: #00000080;
                font-weight: 600;
              }
              .ant-select-arrow {
                margin: 0px;
              }
              .ant-select-item-option-content {
                color: #2b61c4;
                font-weight: 600;
              }
              .section-card-left {
                text-align: left !important;
                color: black !important;
              }
              .ant-card-left {
                text-align: left !important;
              }
              .ant-pagination {
                display: none;
              }
              // .ant-table-selection {
              //   text-align: center;
              // }
              .red-asterisk {
                color: red;
                font-weight: bold;
              }
              .ant-form-item-label
                > label.ant-form-item-required:not(
                  .ant-form-item-required-mark-optional
                )::before {
                display: none !important;
              }
              // .ant-modal-content {
              //     width: max-content;
              // }
              .ant-card {
                border-radius: 8px 8px 0px 0px !important;
              }
              .report-table .ant-table {
                border-radius: 0 !important;
              }
              
              .report-table .ant-table-thead > tr > th {
                border-radius: 0 !important;
              }
              
              .report-table .ant-table-tbody > tr > td {
                border-radius: 0 !important;
              }

              .ant-select-selector {
                box-shadow: 0px 2px 4px #00000033;
                border: 1px solid #2B61C4 !important;
              }

              .ant-select-selection-item {
                color: black;
              }
          `}
      </style>
    </>
  );
}
