// @ts-nocheck
import {
    CheckCircleOutlined, CloseCircleOutlined,
} from '@ant-design/icons'
import {
    Button, Card,
    Col, message,
    Row, Skeleton,
    Space,
    Image,
    Modal,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { SessionContext } from '../../App'
import {
    IN_TRANSACTION_STATUSES,
    STATES,
    TRANSACTION_RESPONSE_CODES, TRANSFER_PAYMENT_STATUSES,
    APP_CONST
} from '../../constants'
import {
    getInTransferAmountByTransferId,
    getInTransferByTransferId, inUpdateVehicleDetailsToJPJ, processPaymentIn,
    saveInSeller,
    verifyApproverOTP,
    verifyApproverSmsOtp,
    InTransferInquiryWithJPJ
} from '../../services/api'

import {
    GetInTransferAmountRequest,
    GetInTransferDetailRequest,
    GetInTransferDetailResponse,
    GetInTransferAmountResponse,
    InSaveSellerRequest,
    InProcessPaymentRequest,
    InUpdateVehicleDetailsToJPJRequest,
} from '../../services/api/types'
import {
    breakdownIc,
    getDistrictName,
    parseAddress, parseCorrespondenceAddress, parseDate, parseTime,
    getDecryptedVerifyOtpResponse
} from '../../util'
import './index.less'
import PaymentAuthModal from './jpjSubmission_modal'
import { Params } from './types'
import {useShortcut, onApprovalSubmit, ModuleType} from '../../components/Utils'
import { getAuthorizedNric, 
    getPermissions,
} from "../../services/local";
import { processErrorMessage, validateRolePermission } from '../../components/Utils'
import failedIcon from "../../assets/icon-failed.svg";

type PaymentProps = {
    approverNRIC: string,
    seller: Object,
    dealer: Object,
    vehicle: Object,
    titleM2M: Function,
    onApproverNRICChange: Function,
    isReport: boolean,
    tabType: propTypes.string,
}

function Payment(props: PaymentProps) {

    // renaming isReport to avoid conflict with line 68. 
    // this variable is for checking if viewing from Transaction Report page == true
    const { isReport: comingFromReport } = props

    const history = useHistory() as any
    const { state: locationState } = useLocation<Record<string, any>>()
    const params = useParams<Params>()
    const { user, prepaid, branch, company } = useContext(SessionContext)

    const [isLoading, setIsLoading] = useState(false)
    const [isPaymentLoading, setIsPaymentLoading] = useState(false)
    const [transferDetails, setTransferDetails] = useState<GetInTransferDetailResponse | undefined>()
    const [transferAmount, setTransferAmount] = useState<GetInTransferAmountResponse | undefined>()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isM2M, setisM2M] = useState(false)
    const [isReport, setIsReport] = useState(false)
    const { formattedDob: sellerFormattedDob, isMale: sellerIsMale } = breakdownIc(transferDetails?.sellerInfo?.identificationNo)
    const transferId = Number(params.transferId)
    const [districtName, setDistrictName] = useState('')
    const [correspondenceDistrictName, setCorrespondenceDistrictName] = useState('')
    const [isIncorrectOtp, setIsIncorrectOtp] = useState(false)
    const [isApprovalRejected, setIsApprovalRejected] = useState(false)
    {useShortcut(setIsModalVisible)}
	const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        getPermissions().then(async (permissions) => {
          setPermissions(permissions);
        });
    }, [])

    useEffect(() => {

        if (locationState) {

            if (locationState?.isM2M === true) {

                setisM2M(true)
                props.titleM2M('Member to Member')

            }

            if (locationState.oldIsReport === true) {

                setIsReport(true)

            }

        }

        function getAndSetTransfer() {
            setIsLoading(true)

            const getInTransferAmountRequest: GetInTransferAmountRequest = {
                transferId
            }

            getInTransferAmountByTransferId(getInTransferAmountRequest)
                .then(res => {
                    if (res.id === undefined) throw res.message

                    setTransferAmount(res)
                })
                .catch(err => err && message.error(err))
                .finally(() => setIsLoading(false))


            const getInTransferDetailRequest: GetInTransferDetailRequest = {
                transferId, moduleType : "IN"
            }
            getInTransferByTransferId(getInTransferDetailRequest)
                .then(res => {
                    if (res.id === undefined) throw res.message
                    if(res.status === 1) {
                        message.warning("Redirect to printslip")
                        history.push(`/transfer/in/printslip/${res.id}`)
                    }

                    setTransferDetails(res)

                    getDistrictName(
                        res?.sellerInfo?.state,
                        res?.sellerInfo?.district
                    ).then((res) => {
                        setDistrictName(res)
                    })

                    getDistrictName(
                        res?.sellerInfo?.correspondentState,
                        res?.sellerInfo?.correspondentDistrict
                    ).then((res) => {
                        setCorrespondenceDistrictName(res)
                    })
                })
                .catch(err => err && message.error(err))
                .finally(() => setIsLoading(false))
        }

        if (transferId) {

            getAndSetTransfer()

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferId])

    function onSave() {
        setIsLoading(true)

        const inSaveSellerRequest: InSaveSellerRequest = {
            branchId: branch.branchId,
            buyerCorrespondentAddress1: transferDetails?.buyerInfo?.correspondentAddress1,
            buyerCorrespondentAddress2: transferDetails?.buyerInfo?.correspondentAddress2,
            buyerCorrespondentAddress3: transferDetails?.buyerInfo?.correspondentAddress3,
            buyerCorrespondentDistrict: transferDetails?.buyerInfo?.district,
            buyerCorrespondentEmail: transferDetails?.buyerInfo?.correspondentEmail,
            buyerCorrespondentMobileNo: transferDetails?.buyerInfo?.correspondentMobileNo,
            buyerCorrespondentPostcode: transferDetails?.buyerInfo?.correspondentPostcode,
            buyerCorrespondentState: transferDetails?.buyerInfo?.correspondentState,
            currentLoginUserName: user.name,
            dealerCompanyROC: company.coRegNo,
            sellerInfo: {
                address1: transferDetails?.sellerInfo?.address1,
                address2: transferDetails?.sellerInfo?.address2,
                address3: transferDetails?.sellerInfo?.address3,
                correspondentAddress1: transferDetails?.sellerInfo?.correspondentAddress1,
                correspondentAddress2: transferDetails?.sellerInfo?.correspondentAddress2,
                correspondentAddress3: transferDetails?.sellerInfo?.correspondentAddress3,
                correspondentDistrict: transferDetails?.sellerInfo?.correspondentDistrict,
                correspondentEmail: transferDetails?.sellerInfo?.correspondentEmail,
                correspondentMobileNo: transferDetails?.sellerInfo?.correspondentMobileNo,
                correspondentPostcode: transferDetails?.sellerInfo?.correspondentPostcode,
                correspondentState: transferDetails?.sellerInfo?.correspondentState,
                dateOfBirth: transferDetails?.sellerInfo?.dateOfBirth,
                district: transferDetails?.sellerInfo?.district,
                email: transferDetails?.sellerInfo?.email,
                gender: transferDetails?.sellerInfo?.gender,
                identificationNo: transferDetails?.sellerInfo?.identificationNo,
                name: transferDetails?.sellerInfo?.name,
                phoneNo: transferDetails?.sellerInfo?.phoneNo,
                postcode: transferDetails?.sellerInfo?.postcode,
                repIc: transferDetails?.sellerInfo?.repIc,
                repIc2: transferDetails?.sellerInfo?.repIc2,
                repName: transferDetails?.sellerInfo?.repName,
                sellerType: transferDetails?.sellerInfo?.sellerType,
                state: transferDetails?.sellerInfo?.state,
            },
            vehicleInfo: {
                // carMake: transferDetails?.make,
                // carModel: transferDetails?.model,
                chassisNo: transferDetails?.chassisNo,
                engineNo: transferDetails?.engineNo,
                isWhiteListVehicle: false,    // 30.3.2022 daniel.kwok hardcoded defaults
                lkmoption: 1, //30.3.2022 daniel.kwok hardcoded defaults
                // manufactureYear: transferDetails?.manufactureYear,
                ownerIdCat: transferDetails?.ownerCatagory,
                vehicleRegistrationNo: transferDetails?.carRegistrationNo,
                vehicleType: (transferDetails?.vehicleTypeId).toString(),
            }

        }
        saveInSeller(inSaveSellerRequest)
            .then(res => {
                /**
                 * TODO 30/3/2022 daniel.kwok
                 * might wanna revisit this and verify if it's success
                 */
                if (res.saveSellerStatus !== "success") throw res.message

                message.success('Saved transfer')
                history.goBack()
            })
            .catch(err => (err && message.error(err.toString())))
            .finally(() => setIsLoading(false))
    }

    function onProcessPayment() {
        onPay(getAuthorizedNric())
    }

    const onPay = async (approverIc) => {

        setIsPaymentLoading(true)

        const inProcessPaymentRequest: InProcessPaymentRequest = {
            dealerCompanyROC: company.coRegNo,
            transferId: (transferDetails?.id || "").toString()
        }

        const inUpdateVehicleDetailsToJPJRequest: InUpdateVehicleDetailsToJPJRequest = {
            approverNRIC: approverIc,
            transferId: transferDetails?.id || 0,
            branchId: branch?.id || 0
        }

        const inUpdateToJpj = async () => {

            await inUpdateVehicleDetailsToJPJ(inUpdateVehicleDetailsToJPJRequest)
                .then(res => {

                    if (res.statusCode !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.statusMSG

                    message.success(res.statusMSG)

                    history.push(
                        `/transfer/in/printslip/${params.transferId}`,
                        { isM2M: isM2M }
                    )

                })
                .catch(err => {

                    // err && message.error(err)
                    processErrorMessage(err)

                })

        }

        const inProcessPayment = async () => {

            let isPaymentSuccess = true
            await processPaymentIn(inProcessPaymentRequest)
                .then(res => {

                    if (!res.status || res.status === "false") throw res.message

                    message.success(res.message)
                    const updatedTransferDetails = {
                      ...transferDetails,
                      paymentStatus: TRANSFER_PAYMENT_STATUSES.SUCCESS, // Update the value here
                    };

                    setTransferDetails(updatedTransferDetails);

                })
                .catch(err => {
                    isPaymentSuccess = false
                    if(err === "Fail to process prepaid payment." || err.includes("Insufficient")) {
                        Modal.error({
                            content: <div>You have insufficient prepaid balance. Please <a href={process.env.REACT_APP_RELOAD_URL} target="_blank">click here</a> to top up, and the amount will be automatically credited to your prepaid account.</div>
                        })
                    }
                    else {
                        err && message.error(err)
                    }

                })
            return isPaymentSuccess
        }

        const InInquiryJpj = async () => {

            await InTransferInquiryWithJPJ(transferId)
                .then((res) => {

                    if (res.inquiryStatus !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.inquiryMSG

                    message.success(res.inquiryMSG)

                })
                .catch((err) => {

                    if (err == 'UNKNOWN ERROR') {

                        message.error('Unable to process JPJ submission')

                    } else {

                        err && message.error(err)

                    }
                    setIsPaymentLoading(false)
                    throw err

                })
        }

        let isPaymentSuccess = true;
        if (transferDetails?.status == IN_TRANSACTION_STATUSES.PENDING_APPROVAL) {

            if (transferDetails?.paymentStatus != TRANSFER_PAYMENT_STATUSES.SUCCESS) {
                await InInquiryJpj()
                isPaymentSuccess = await inProcessPayment()

            }

            if (isPaymentSuccess) await inUpdateToJpj()


        } else if (transferDetails?.status == IN_TRANSACTION_STATUSES.FAILED) {

            await InInquiryJpj()

            if (transferDetails?.paymentStatus != TRANSFER_PAYMENT_STATUSES.SUCCESS) {

                isPaymentSuccess = await inProcessPayment()

            }

            if (isPaymentSuccess) await inUpdateToJpj()

        }

        setIsPaymentLoading(false)

    }

    /**
     * If transfer store no longer loading,
     * and no transfer is found
     */
    if (
        !isLoading
        && !transferDetails
    ) {
        return (
            <Card
                style={{
                    height: '50%',
                    width: '100%'
                }}
            >
                <h1>Unknown transfer</h1>
                <a href='/'>Go back to home</a>
            </Card>
        )
    }

    return (

        <div
            style={{
                width: '100%',
            }}
        >
            {
                isLoading ? (
                    <Skeleton active />
                ) :
                    (
                        isApprovalRejected ? (
                            <Card
                                style={{
                                    height: '50%',
                                    textAlign: 'left',
                                    boxShadow: '0px 3px 6px #00000029',
                                    borderRadius: '4px',
                                    marginBottom: 16
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Image src={failedIcon} alt="failed icon" preview={false} style={{ transform: 'scale(0.6)' }} />
                                    <Space direction='vertical' size={0}>
                                        <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >Approval Unsuccessful</h2>
                                    </Space>
                                </div>
                            </Card>
                        ) : 
                        (transferDetails?.paymentStatus === TRANSFER_PAYMENT_STATUSES.PENDING || transferDetails?.paymentStatus == '' && !isReport) ? (
                            <Card className='section-card section-card-left'>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <CheckCircleOutlined
                                        style={{
                                            color: '#40e327a8',
                                            fontSize: 50,
                                            marginRight: 20,
                                        }}
                                    />
                                    <div style={{ display: 'flex', flex: 4, alignItems: 'center' }}>
                                        <h2 style={{ color: '#40e327a8' }} >JPJ checking completed</h2>
                                    </div>
                                </div>
                            </Card>
                        ) : transferDetails?.paymentStatus === TRANSFER_PAYMENT_STATUSES.SUCCESS && transferDetails?.status === IN_TRANSACTION_STATUSES.TRANSACTION_SUCCESS ? (
                            isReport
                                ? (
                                    <Card className='section-card section-card-left'>
                                        <h2>Transaction Status</h2>
                                        <Row>
                                            <Col span={8}>
                                                <p style={styles.label}>Transaction Date</p>
                                                <p style={styles.value}>{parseDate(transferDetails.createdDate)}</p>
                                            </Col>
                                            <Col span={8}>
                                                <p style={styles.label}>Transaction Type</p>
                                                <p style={styles.value}>{isM2M ? 'M2M' : 'In'}</p>
                                            </Col>
                                            <Col span={8}>
                                                <p style={styles.label}>Transaction Status</p>
                                                <p style={styles.value}>Done</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <p style={styles.label}>Reason Fail</p>
                                                <p style={styles.value}>-</p>
                                            </Col>
                                        </Row>
                                    </Card>
                                )
                                : (
                                    <Card className='section-card section-card-left'>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <CheckCircleOutlined
                                                style={{
                                                    color: '#40e327a8',
                                                    fontSize: 70,
                                                    marginRight: 50,
                                                }}
                                            />
                                            <div style={{ flex: 4 }}>
                                                <h2 style={{ color: '#40e327a8' }} >Payment Successful</h2>
                                                <p>Your remaining balance is {new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: 'MYR',
                                                }).format(prepaid?.balanceAmount || 0) || `Unavailable`}</p>
                                                <Button
                                                    shape={'round'}
                                                    type='primary'
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                    onClick={() => {
                                                        window.location.pathname = `/transfer/in/printslip/${params.transferId}`
                                                    }}
                                                >
                                                    Click here to printslip
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                )
                        ) : transferDetails?.paymentStatus === TRANSFER_PAYMENT_STATUSES.REFUNDED ? (
                            <Card
                                className='section-card'
                                style={{
                                    height: '50%',
                                    textAlign: 'left',

                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <CloseCircleOutlined
                                        style={{
                                            color: 'red',
                                            fontSize: 70,
                                            marginRight: 50,
                                        }}
                                    />
                                    <div style={{ flex: 4 }}>
                                        <h2 style={{ color: 'red', display: 'inline' }} >Payment Failed. </h2>
                                        <h2 onClick={onPay} style={{ color: 'red', display: 'inline', textDecoration: 'underline', cursor: 'pointer' }} >Try again?</h2>
                                        <p>{transferDetails.message || `Payment failed`}</p>
                                    </div>
                                </div>
                            </Card>
                        ) : <></>
                    )
            }
            <Card className='section-card section-card-left'>
                <h2>Payment Details</h2>
                {
                    isLoading ? (
                        <Skeleton active />
                    ) : (

                        <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                                <p style={styles.label}>JPJ Ownership Transfer Fee </p>
                                <p style={styles.value}>RM {transferAmount?.jpjAmount.toFixed(2)}</p>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <p style={styles.label}>MYEG Service Charge</p>
                                <p style={styles.value}>RM {transferAmount?.eserviceAmount}</p>
                            </Col>
                            <Col className="gutter-row" span={6}>
                                <p style={styles.label}>SST 8%</p>
                                <p style={styles.value}>RM {transferAmount?.sstAmount.toFixed(2)}</p>
                            </Col>
                            {transferAmount?.processingAmount && <Col className="gutter-row" span={6}>
                                <p style={styles.label}>Processing Fee</p>
                                <p style={styles.value}>RM {transferAmount?.processingAmount.toFixed(2)}</p>
                            </Col>}
                            <Col className="gutter-row" span={6}>
                                <p style={styles.label}>Total Payment</p>
                                <p style={styles.value}>RM {transferAmount?.totalAmount.toFixed(2)}</p>
                            </Col>
                        </Row>
                    )
                }
            </Card>
            <Card className='section-card section-card-left'>
                <h2>Vehicle Information</h2>
                {
                    isLoading ? (
                        <Skeleton active />

                    ) : (
                        <div>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={8}>
                                    <p style={styles.label}>Vehicle Number</p>
                                    <p style={styles.value}>{transferDetails?.carRegistrationNo}</p>
                                </Col>
                                {/* <Col className="gutter-row" span={8}>
                                    <p style={styles.label}>Make</p>
                                    <p style={styles.value}>{transferDetails?.make}</p>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <p style={styles.label}>Model</p>
                                    <p style={styles.value}>{transferDetails?.model}</p>
                                </Col> */}
                            {/* </Row>
                            <Row gutter={16}> */}
                                <Col className="gutter-row" span={8}>
                                    <p style={styles.label}>Engine Number</p>
                                    <p style={styles.value}>{transferDetails?.engineNo}</p>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <p style={styles.label}>Chassis Number</p>
                                    <p style={styles.value}>{transferDetails?.chassisNo}</p>
                                </Col>
                            </Row>
                        </div>
                    )
                }
            </Card>
            <Card className='section-card section-card-left'>
                <h2>JPJ Enquiry Status</h2>
                {
                    isLoading ? (
                        <Skeleton active />

                    ) : (
                        <div>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={8}>
                                    <p style={styles.label}>Blacklisted</p>
                                    <p style={styles.value}>No</p>

                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <p style={styles.label}>Ownership Claim</p>
                                    <p style={styles.value}>No</p>

                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <p style={styles.label}>Condition Code</p>
                                    <p style={styles.value}>No</p>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={8}>
                                    <p style={styles.label}>Valid as at</p>
                                    <p style={styles.value}> {parseTime(transferDetails?.updatedDate)}</p>
                                </Col>
                            </Row>
                        </div>
                    )
                }
            </Card>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 10,
                    marginTop: '-20px'
                }}
            >

                <>
                    <Card className='section-card section-card-left'>

                        <h2>{!isM2M ? `Owner's` : `Dealer's`} Details</h2>
                        {
                            isLoading ? (
                                <Skeleton active />
                            ) : (
                                <>
                                    <p style={styles.label}>{!isM2M ? `Name` : `Company Name`}</p>
                                    <p style={styles.value}>{transferDetails?.sellerInfo?.name}</p>
                                    <p style={styles.label}>{!isM2M ? `NRIC` : `Company ROC number`}</p>
                                    <p style={styles.value}>{transferDetails?.sellerInfo?.identificationNo}</p>
                                    {!isM2M ? (
                                        <>
                                            <p style={styles.label}>Date of Birth</p>
                                            <p style={styles.value}>{sellerFormattedDob}</p>
                                            <p style={styles.label}>Gender</p>
                                            <p style={styles.value}>{sellerIsMale ? 'Male' : 'Female'}</p>
                                        </>
                                    ) : null}
                                    <p style={styles.label}>{isM2M && `Company `}Address</p>
                                    <p style={styles.value}>{
                                        parseAddress({
                                            ...transferDetails?.sellerInfo,
                                            state: Object.values(STATES).find(s => s.code === transferDetails?.sellerInfo?.state)?.label,
                                            districtName: transferDetails?.sellerInfo?.districtName,
                                        })}
                                    </p>
                                    {/* <p style={styles.label}>Current Address</p>
                                    <p style={styles.value}>{
                                        parseCorrespondenceAddress({
                                            ...transferDetails?.sellerInfo,
                                            correspondentState: Object.values(STATES).find(s => s.code === transferDetails?.sellerInfo?.correspondentState)?.label,
                                            districtName: correspondenceDistrictName,
                                        })}
                                    </p> */}
                                    <p style={styles.label}>{isM2M && `Company `}Email</p>
                                    <p style={styles.value}>{transferDetails?.sellerInfo?.email}</p>
                                    <p style={styles.label}>{!isM2M ? `Mobile ` : `Company Phone `} Number</p>
                                    <p style={styles.value}>{transferDetails?.sellerInfo?.phoneNo}</p>
                                </>
                            )
                        }
                    </Card>
                    <Card className='section-card section-card-left'>
                        <h2>{!isM2M ? `Dealer's` : `Buyer's`} Details</h2>
                        {
                            isLoading ? (
                                <Skeleton active />

                            ) : (
                                <>
                                    <p style={styles.label}>Company Name</p>
                                    <p style={styles.value}>{transferDetails?.buyerInfo?.name}</p>
                                    <p style={styles.label}>Company ROC number</p>
                                    <p style={styles.value}>{transferDetails?.buyerInfo?.identificationNo}</p>
                                    <p style={styles.label}>Company Address</p>
                                    <p style={styles.value}>{parseAddress({ ...transferDetails?.buyerInfo, state: Object.values(STATES).find(s => s.code === transferDetails?.buyerInfo?.state)?.label, districtName: transferDetails?.buyerInfo?.districtName ? transferDetails?.buyerInfo?.districtName : transferDetails?.buyerInfo?.district ?? transferDetails?.buyerInfo?.correspondentDistrict })}</p>
                                    <p style={styles.label}>Company e-mail</p>
                                    <p style={styles.value}>{transferDetails?.buyerInfo?.correspondentEmail}</p>
                                    <p style={styles.label}>Company Phone Number</p>
                                    <p style={styles.value}>{transferDetails?.buyerInfo?.correspondentMobileNo}</p>
                                    <p style={styles.label}>Dealer Representative</p>
                                    <p style={styles.value}>{user.name || transferDetails?.buyerInfo.repName}</p>
                                    {/* <p style={styles.value}>{transferDetails?.buyerInfo?.repName}</p> */}
                                    <p style={styles.label}>Representative's NRIC</p>
                                    <p style={styles.value}>{user.nric || transferDetails?.buyerInfo.repIc}</p>
                                    {/* <p style={styles.value}>{transferDetails?.buyerInfo?.repIc}</p> */}
                                    <p style={styles.label}>Representative's Mobile Number</p>
                                    <p style={styles.value}>{user.mobileno || transferDetails?.buyerInfo.repPhoneNo}</p>
                                    {/* <p style={styles.value}>{transferDetails?.buyerInfo?.repPhoneNo}</p> */}
                                </>
                            )
                        }
                    </Card>
                </>
            </div>

            {/* {!isM2M && isReport ? (
                <Card className='section-card'>
                    <h2>Supporting Document</h2>
                    <Row>
                        <Col span={8}>
                            <p style={styles.label}>Company Registration Card (Form D, 9/13)</p>
                            <Button type='link' onClick={() => null}>VIEW FILE</Button>
                        </Col>
                        <Col span={8}>
                            <p style={styles.label}>Board Resolution</p>
                            <Button type='link' onClick={() => null}>VIEW FILE</Button>
                        </Col>
                        <Col span={8}>
                            <p style={styles.label}>Registration Card</p>
                            <Button type='link' onClick={() => null}>VIEW FILE</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '20px'}}>
                        <Col span={24}>
                            <p style={styles.label}>Authorization Letter</p>
                            <Button type='link' onClick={() => null}>VIEW FILE</Button>
                        </Col>
                    </Row>
                </Card>
            ) : null} */}

            {/* {transferDetails?.status === IN_TRANSACTION_STATUSES.PENDING_APPROVAL && (
                <Card className='section-card'>
                    <p style={styles.label}>Remark</p>
                    <Input placeholder='Remark' />
                </Card>
            )} */}


            <div className='section-bottom-button' style={{ marginBottom: '10px' }}>
                <Button
                    className='back-button buttons'
                    shape='round'
                    onClick={() => {
                        if (locationState?.oldIsReport) {
                            if (locationState?.fromPendingPage === true) {
                                // history.push('/reporting/transferreport/In')
                                history.push(`/reporting/transferreport/In`, {tabType: props.tabType})
                            } else {
                                history.push('/reporting/transferreport')
                            }
                        } else {
                            history.push('/')
                        }
                    }}
                >
                    {locationState?.oldIsReport ? "Back" : "Home"}
                </Button>

                {(transferDetails?.status != IN_TRANSACTION_STATUSES.TRANSACTION_SUCCESS) ? (
                    <>
                        {comingFromReport == false &&
                            (
                                !validateRolePermission("", "IN", true, permissions) ?   
                                <Button
                                    type='primary'
                                    className='rectangle-button to-the-right'
                                    onClick={() =>
                                        // setIsModalVisible(true)
                                        onApprovalSubmit(setIsModalVisible,
                                            setIsPaymentLoading,
                                            "",
                                            onProcessPayment,
                                            company,
                                            ModuleType.IN,
                                            permissions
                                        )
                                    }
                                    loading={isPaymentLoading}
                                >
                                    {isReport ? 'Approve' : 'Proceed Payment'}
                                </Button> :
                                <Button
                                    className='rectangle-button to-the-right'
                                    onClick={() => {
                                        history.push(`/reporting/transferreport/In`, {tabType: props.tabType})
                                    }}
                                    type='primary'
                                >
                                    OK
                                </Button>
                            )
                        }
                    </>
                ) : null}
            </div>

            <PaymentAuthModal
                isIncorrectOtp={isIncorrectOtp}
                ownerCatagory={transferDetails?.ownerCatagory}
                isModalVisible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={(approverIc, authCode, isPush, appApprovalResponse) => {//add new param as a push notif flag

                    if (isPush) {
                        props.onApproverNRICChange(approverIc)
                        setIsModalVisible(false)
                        if (appApprovalResponse.isRejected) {
                            setIsApprovalRejected(true)
                        }
                        else {
                            setIsApprovalRejected(false)
                            onPay(approverIc)
                        }
                        /* history.push(
                            `/transfer/in/printslip/${params.transferId}`,
                            { isM2M: isM2M }
                        ) */
                    } else if (APP_CONST.USE_OTP === true) {
                        verifyApproverSmsOtp(approverIc, authCode)
                            .then(res => {
                                if(!res.encryptedString) {
                                    let errorMsg = res.message ? res.message : "ERROR"
                                    throw errorMsg
                                }
                                const decryptedResponse = getDecryptedVerifyOtpResponse(res.encryptedString)
                                if (decryptedResponse.status == 'false') {
                                    setIsIncorrectOtp(true)
                                    throw decryptedResponse.message
                                }
                                setIsIncorrectOtp(false)
                                props.onApproverNRICChange(approverIc)
                                setIsModalVisible(false)
                                onPay(approverIc)
                            })
                            .catch(err => {
                                err && message.error(err)
                                setIsIncorrectOtp(true)
                            })
                            .finally(() => {
                                setIsIncorrectOtp(false)
                            })
                    } else {
                        verifyApproverOTP(approverIc, authCode)
                            .then(res => {
                                if (res.status !== true) {
                                    setIsIncorrectOtp(true)
                                    throw res.message
                                }
                                setIsIncorrectOtp(false)
                                props.onApproverNRICChange(approverIc)
                                setIsModalVisible(false)
                                onPay(approverIc)
                            })
                            .catch(err => {
                                err && message.error(err)
                                setIsIncorrectOtp(true)
                            })
                            .finally(() => {
                                setIsIncorrectOtp(false)
                            })
                    }
                }}
            />
        </div>
    )
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0, fontFamily: 'Open Sans', fontSize: '16px'
    },
    value: {
        fontWeight: 'bold', fontFamily: 'Open Sans', fontSize: '16px'
    },
}

export default Payment