import React, { useContext, useEffect, useState } from 'react';
import { Card, Row, Col, Button, Space, Alert, Modal, Spin, message } from 'antd';
import { WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import icon_save from "../../../assets/icon-save-20.png";
import icon_info from "../../../assets/info_fill.png";
import './index.less';
import { useHistory } from "react-router-dom";
import { roadTaxInquiry } from '../../../services/api';
import PaymentSubmissionModal from './paymentSubmission_modal';
import {

    onPaymentSubmitRtx,
} from "./function";
import { SessionContext } from "../../../App";

const stateNames = {
    "01": "Johor",
    "02": "Kedah",
    "03": "Kelantan",
    "04": "Malacca",
    "05": "Negeri Sembilan",
    "06": "Pahang",
    "07": "P. Pinang",
    "08": "Perak",
    "09": "Perlis",
    "10": "Selangor",
    "11": "Terengganu",
    "12": "Sabah",
    "13": "Sarawak",
    "14": "Kuala Lumpur",
    "15": "Labuan",
    "16": "Putrajaya"
};

const PaymentPage = ({ handleNext, vehicleNumber, ownerIdNumber, roadTaxData, apiResponse, validityPeriod, updatedRequestBody, setPaymentResult }) => {
    const selectedState = roadTaxData?.state ? stateNames[roadTaxData.state] : "Unknown";
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [isPendingModalVisible, setIsPendingModalVisible] = useState(false);
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
    const { company, branch, user } = useContext(SessionContext);



    useEffect(() => {
        console.log("📥 Updated API Response Received in Payment:", apiResponse);
    }, [apiResponse]); //  Ensures it updates when `apiResponse` changes

    const handleSubmit = async () => {
        setIsLoading(true);
        console.log("🚀 Submitting for approval...");

        try {
            const inqRequestId = apiResponse?.requestId;

            if (inqRequestId) {
                const finalPayload = {
                    ...updatedRequestBody,
                    inqRequestId,
                };

                console.log("📥 inqRequestId exists, calling roadTaxInquiry with:", finalPayload);

                //  Call API with inqRequestId
                const res = await roadTaxInquiry(finalPayload, true);

                // Proceed with showing modal or saving result
                setIsPaymentModalVisible(true);
            } else {
                message.warning(" Request ID not found in apiResponse, skipping inquiry.");
            }

        } catch (error) {
            console.error("Error calling roadTaxInquiry with inqRequestId:", error);
            message.error("Failed to re-check inquiry before approval.");
        } finally {
            setIsLoading(false);
        }
    };



    const amount = parseFloat(apiResponse?.amount) || 0;
    const deliveryCharge = parseFloat(apiResponse?.deliveryCharge) || 0;
    const laminationCharge = roadTaxData?.lamination_fee === "true" ? 1.00 : 0; //  Add only if lamination is selected

    const subtotal = amount + deliveryCharge + laminationCharge; //  Compute subtotal dynamically

    const handleSaveAndContinueLater = () => {
        setIsPendingModalVisible(true);
    };


    return (
        <Card className="payment-container" style={{ width: '1244px', padding: 0, paddingTop: 14, borderRadius: 8, border: 0, backgroundColor: 'transparent' }}>
            <Card className="payment-card" style={{ width: '1196px', border: 0 }}>
                <Card className='payment-inner-card' style={{ width: '760px', margin: '0 auto', backgroundColor: 'transparent', boxShadow: 'none', border: 'none', }}>
                    <p className="payment-title" style={{ margin: '24px 0 4px', fontWeight: 400, fontSize: '16px', lineHeight: '24px', letterSpacing: ' -0.8px' }}>Purchasing road tax for</p>
                    <h3 style={{ margin: '0px 0px 24px', fontWeight: 700, fontSize: '20px', lineHeight: '25.2px', letterSpacing: ' -0.1px' }}>{vehicleNumber}</h3>
                    <Row>
                        {/* Vehicle Owner */}
                        <Col span={12} className="owner-details" style={{ textAlign: 'start' }}>
                            <p className="detail-title">Vehicle Owner</p>
                            <p className="detail-title-2">{apiResponse?.name} <br /> ({ownerIdNumber})</p>
                        </Col>
                        {/* Recipient Contact */}
                        <Col span={12} className="recipient-details" style={{ textAlign: 'end' }}>
                            <p className="detail-title">Recipient's Contact</p>
                            <p className="detail-title-2">+6{roadTaxData?.mobileNumber}</p>
                        </Col>
                    </Row>

                    {/* Delivery Address */}
                    {roadTaxData?.roadTaxFormat === "physical_digital" && roadTaxData?.collectionMethod === "delivery" && (
                        <Card
                            className="delivery-card"
                            style={{
                                margin: '0 auto',
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                border: '0',
                                borderLeft: '4px solid #707070',
                                borderRadius: 0,
                                textAlign: 'start',
                                marginTop: 16,
                                paddingLeft: 8
                            }}
                        >
                            <p className="detail-title">Item will be delivered to</p>
                            <p className="detail-title-2" style={{ marginBottom: 0 }}>
                                {roadTaxData?.address1},
                            </p>
                            {roadTaxData?.address2 !== "-" && (
                                <p className="detail-title-2" style={{ marginBottom: 0 }}>
                                {roadTaxData?.address2}, 
                            </p>
                            )}
                            <p className="detail-title-2" style={{ marginBottom: 0 }}>
                                {roadTaxData?.postcode} {roadTaxData?.city}, {selectedState}
                            </p>
                        </Card>
                    )}

                </Card>


                <Card className='item-description-card' style={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', }}>
                    {/* Payment Breakdown */}
                    <Card className="payment-table">
                        <div className='payment-table-title-row'>
                            <div style={{ width: '760px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                <p className='payment-table-title'>Item Description</p>
                                <p className='payment-table-title'>Amount (RM)</p>
                            </div>

                        </div>
                        <div className='item-description-row'>
                            <div className='item-description'>
                                <p className='payment-table-p'><b>{roadTaxData?.roadTaxFormat === "physical_digital" ? "Physical & Digital"
                                    : roadTaxData?.roadTaxFormat === "44" ? "Digital Only"
                                        : ""} ({updatedRequestBody?.renewalPeriod} Months)</b><br /> <span>{validityPeriod?.start} - {validityPeriod?.end}</span></p>
                                <p className='payment-table-p'>{apiResponse?.amount}</p>
                            </div>
                            {roadTaxData?.roadTaxFormat === "physical_digital" && roadTaxData?.collectionMethod === "delivery" && (
                                <div className='item-description'>
                                    <p className='payment-table-p'>Standard Delivery<br /><span>Klang Valley</span></p>
                                    <p className='payment-table-p'>{apiResponse?.deliveryCharge}</p>
                                </div>
                            )}
                            {roadTaxData?.lamination_fee === true && (
                                <div className='item-description'>
                                    <p className='payment-table-p'>Lamination charge</p>
                                    <p className='payment-table-p'>1.00</p>
                                </div>
                            )}
                        </div>
                        <div className="subtotal-row">
                            <div className='item-subtotal'>
                                <p className='payment-table-p-2'>Subtotal</p>
                                <p className='payment-table-p'>{subtotal.toFixed(2)}</p>
                            </div>
                            <div className='item-subtotal'>
                                <p className='payment-table-p-2'>eService Charges</p>
                                <p className='payment-table-p'>{apiResponse?.serviceCharge}</p>
                            </div>
                            <div className='item-subtotal'>
                                <p className='payment-table-p-2'>SST @ 8% on delivery fee and charges</p>
                                <p className='payment-table-p'>{apiResponse?.sst}</p>
                            </div>
                        </div>
                        <div className="total-amount-row">
                            <div style={{ width: '760px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                                <p className='payment-table-p-2'>Total Amount</p>
                                <p className='payment-table-p'>RM {apiResponse?.totalAmount}</p>
                            </div>
                        </div>
                    </Card>
                </Card>



            </Card>

            {/* Approval Notification */}
            <Alert
                className="approval-notice"
                message={<span> <b>Request</b> a user with a <b>higher authority level</b> to complete payment for this transaction.</span>}
                type="warning"
                showIcon
                icon={<img src={icon_info} alt='info' tyle={{ width: 24, height: 24, objectFit: 'cover', marginRight: '8px' }} />}
                style={{ marginBottom: 24, backgroundColor: '#FFF9A2', padding: '0 24px', fontSize: '16px', fontFamily: 'Plus Jakarta Sans', lineHeight: '24px', borderRadius: 4, textAlign: 'start', marginTop: 24, height: 52 }}
            />
            {/* Buttons */}
            {/* Buttons */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 16 }}>
                <Button type="default" style={{ width: 288, height: 48, backgroundColor: 'transparent', borderRadius: '40px', border: '1px solid #2B61C4 ', color: '#2B61C4', fontSize: '16px', fontWeight: 600 }} onClick={() => handleSaveAndContinueLater()}>
                    <img src={icon_save} alt='save' style={{ width: 28, height: 28, objectFit: 'cover', marginRight: '8px' }} />
                    Save & Continue Later
                </Button>
                <Button
                    className="next-button"
                    type="primary"
                    htmlType="submit"
                    style={{ width: 345, height: 56 }}
                    onClick={handleSubmit}
                >
                    Request for approval
                </Button>
            </div>
            {/* Loading Modal */}
            <Modal
                className='roadtax-modal'
                open={isLoading}
                footer={null}
                closable={false}
                centered
                style={{ textAlign: 'center', padding: '30px', borderRadius: 8 }}
            >
                <Spin percent="auto" />
                <p style={{ fontSize: '16px', margin: '16px 0px 0px', lineHeight: '24px', fontWeight: 400, letterSpacing: '-0.08px' }}>Please wait...</p>
            </Modal>
            <Modal
                className='roadtax-modal'
                open={isPendingModalVisible}
                centered
                footer={null}
                closable={false} // Remove top-right X button
                maskClosable={false} // Disable clicking outside to close

            >
                <p style={{ fontSize: 16, marginBottom: 0, textAlign: 'center' }}>
                    This vehicle will be available under <br /> <b>'Pending list'</b> for 7 days.
                </p>
                <Button
                    type="primary"
                    style={{ marginTop: 24, width: 200, display: 'flex', justifySelf: 'center' }}
                    onClick={() => {
                        setIsPendingModalVisible(false);
                        history.push("/renewroadtax/list");
                    }}
                >
                    OK
                </Button>
            </Modal>
            <PaymentSubmissionModal
                isModalVisible={isPaymentModalVisible}
                apiResponse={apiResponse}
                onOk={(approverIc, authCode, isApp, res) => {
                    // Handle submit approval here
                    console.log("Approver IC:", approverIc);
                    console.log("OTP/Auth Code:", authCode);
                    console.log("Is using app:", isApp);

                    console.log("Payment success with approval:", approverIc);
                    setIsPaymentModalVisible(false);
                    handleNext();
                }}
                onCancel={() => setIsPaymentModalVisible(false)}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                company={company}
                user={user}
                vehicleNumber={vehicleNumber}
                ownerIdNumber={ownerIdNumber}
                setPaymentResult={setPaymentResult}
            />
        </Card>
    );
};

export default PaymentPage;
