import React, { useState } from "react";
import { Card, Button, Form, message } from "antd";
import { useHistory } from "react-router-dom";

import { postResendActivationEmail } from "../../services/api";

export default function InactiveAccountPage() {
    const history = useHistory();

    const { activationEmail, isTempAccount } = history?.location?.state || {};

    const [resendLoading, setResendLoading] = useState(false);

    const resendActivationEmail = async () => {
        setResendLoading(true);

        try {
            const response = await postResendActivationEmail(activationEmail, isTempAccount);
            if (response && response?.status) {
                message.info(response?.message);
            } else {
                throw new Error(response?.message);
            }
        } catch (error) {
            message.error(JSON.stringify(error));
        } finally {
            setResendLoading(false);
        }
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Card
                style={{
                    minWidth: 300,
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "8px",
                }}
            >
                <div
                    style={{
                        margin: "15px",
                    }}
                >
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <h2
                            style={{
                                font: "normal normal 600 32px/37px Raleway",
                            }}
                        >
                            Inactive Account
                        </h2>
                    </div>
                    <Form layout={"vertical"}>
                        <Form.Item
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{ marginBottom: "8px" }}
                        >
                            <Button
                                type="primary"
                                loading={resendLoading}
                                onClick={() => resendActivationEmail()}
                            >
                                Resend Activaton Email
                            </Button>
                        </Form.Item>
                        <Button
                            id={"forgot-password-link"}
                            style={{
                                width: "100%",
                                background: "white",
                                border: "none",
                                color: "#2B61C4",
                                textTransform: "uppercase",
                            }}
                            type="secondary"
                            onClick={() => history.push("/login")}
                        >
                            Back to login
                        </Button>
                    </Form>
                </div>
            </Card>
        </div>
    );
}
