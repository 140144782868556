import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  message,
  Skeleton,
  Table,
  Switch,
  Button,
  Image,
  Form,
  Input,
  Select,
  Tag,
} from "antd";
import { parseAddress, getDistrictName } from "../../util";
import { HQ_BRANCH } from "../../constants";
import { activateOrDeactivateBranchUser } from "../../services/api";
import "./index.less";
import { STEPS } from "./steps";
import arrow_back from "../../assets/arrow_back.svg";

const ViewPage = (props) => {
  const [districtName, setDistrictName] = useState();
  const [company, setCompany] = useState();
  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [initialDataSource, setInitialDataSource] = useState([]);
  const [branchs, setBranchs] = useState([]);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [searchForm] = Form.useForm();
  const [searchBy, setSearchBy] = useState("nric");

  const roleMapping = {
    VALUER: "VALUER (Level 1)",
    SALES: "SALES (Level 2)",
    ADMIN: "ADMIN (Level 3)",
    SALES_MANAGER: "SALES_MANAGER (Level 4)",
    GENERAL_MANAGER: "GENERAL_MANAGER (Level 5)",
  };

  useEffect(() => {
    setIsLoading(true);

    if (props?.company) {
      setCompany(props?.company);
      setIsLoading(false);
    }
  }, [props]);

  useEffect(() => {
    if (company?.companyAddress?.state && company?.companyAddress?.district) {
      setIsLoading(true);

      getDistrictName(
        company?.companyAddress?.state,
        company?.companyAddress?.district
      )
        .then((res) => {
          setDistrictName(res);
        })
        .catch((err) => {
          err && message.error(err);
        })
        .finally(() => setIsLoading(false));
    }
  }, [company]);

  useEffect(async () => {
    if (props?.company?.branchDetails) {
      setBranchs(props?.company?.branchDetails);
    } else {
      setBranchs([HQ_BRANCH]);
    }

    if (props?.company?.users) {
      setIsTableLoading(true);

      // let users = []

      // for (let user_ of props?.company?.users) {

      //     let isOwner = false

      //     let res = await getRolesByUsername(user_.nricNo)

      //     if (res.roles) {

      //         for (let role of res.roles) {
      //             if (role.name == 'OWNER') isOwner = true
      //         }

      //     }

      //     let user = {
      //         isOwner: isOwner,
      //         ...user_
      //     }

      //     users.push(user)
      // }

      // setUsers(users)
      setUsers(props?.company?.users);

      setIsTableLoading(false);
    }
  }, [props]);

  useEffect(() => {
    if (users && Object.keys(users).length > 0) {
      setDataSource(
        users.map((user) => {
          return {
            key: user.id,
            ...user,
          };
        })
      );
      setInitialDataSource(
        users.map((user) => {
          return {
            key: user.id,
            ...user,
          };
        })
      );
    }
  }, [users]);

  const columns = [
    {
      title: "No",
      width: "5%",
      render: (text, record, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Name",
      dataIndex: "personName",
      sorter: (a, b) => a.personName.localeCompare(b.personName),
      defaultSortOrder: "ascend",
    },
    {
      title: "Ic No",
      dataIndex: "nricNo",
      width: "12%",
      sorter: (a, b) => a.nricNo.localeCompare(b.nricNo),
    },
    {
      title: "Branch",
      dataIndex: "branchId",
      ellipsis: true,
      // width: "20%",
      sorter: (firstBranch, secondBranch) =>
        [...branchs, HQ_BRANCH]
          .find((a) => a.id === firstBranch.branchId)
          ?.branchName.localeCompare(
            [...branchs, HQ_BRANCH].find((b) => b.id === secondBranch.branchId)
              ?.branchName
          ),
      render: (branchId) => {
        return (
          [...branchs, HQ_BRANCH].find((b) => b.id === branchId)?.branchName ||
          `(No branch found)`
        );
      },
    },
    {
      title: "User role",
      dataIndex: "userRoles",
      render: (userRoles) => {
        return (
          <div>
            {userRoles.map((role, index) => (
              <div key={index}>{roleMapping[role] || role}</div>
            ))}
          </div>
        );
      },
      sorter: (a, b) => {
        const firstRoleA = a.userRoles?.[0]?.toLowerCase() || "";
        const firstRoleB = b.userRoles?.[0]?.toLowerCase() || "";
        return firstRoleA.localeCompare(firstRoleB);
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      // width: "12%",
      sorter: (a, b) => a.email.localeCompare(b.nricNo),
    },
    {
      title: "Phone number",
      dataIndex: "contactNumber",
      width: "10%",
      sorter: (a, b) => a.contactNumber.localeCompare(b.nricNo),
    },
    {
      title: "Action",
      width: "10%",
      render: (rowData) => {
        return (
          <Button
            style={{
              border: "1px solid #2B61C4",
              padding: "12px 24px",
              borderRadius: "4px",
              color: "#2B61C4",
              display: "flex",
              alignItems: "center",
              font: "normal normal 600 14px/19px Open Sans",
            }}
            onClick={() => {
              // setIsLoading(true)

              history.push("/adminCompany/edit/", {
                step: STEPS.AUTHORIZE_ADMIN_COMPANY_USER.label,
                subStep: STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.EDIT_STAFF,
                staffId: rowData?.id,
                tempAuthPersonnel: rowData?.tempAuthPersonnel,
                companyInfo: company,
              });

              // getUser(rowData?.id)
              //     .then(async res => {

              //         if (res.status !== '00') throw res.message

              //         const roles = await getRolesByUsername(res.userDetails.nricNo)
              //             .then(res => {
              //                 const roles = res.roles?.map(r => r.name) || []
              //                 return roles
              //             })
              //             .catch(err => err && message.error(err))

              //         const accountDetails = await getAccountDetails(res.userDetails.nricNo)
              //             .then(res => {
              //                 //if have message meaning user account details is empty thus, throw error
              //                 if (res.message) throw res.message + ' - ' + res.details[0]
              //                 return res
              //             })
              //             .catch(err => err && message.error(err))

              //         if (Object.keys(accountDetails).length > 0) {

              //             let staffInfo = {
              //                 id: res.userDetails.id,
              //                 name: res.userDetails.personName,
              //                 nric: accountDetails.nric,
              //                 gender: accountDetails.gender,
              //                 dob: accountDetails.dob,
              //                 username: accountDetails.username,
              //                 mobileNo: accountDetails.mobileno,
              //                 // phoneNo : accountDetails.phoneno,
              //                 branchId: res.userDetails.branchId,
              //                 roles: roles,
              //                 address : res.address
              //             }

              //             history.push("/adminCompany/edit/", {
              //                 step: STEPS.AUTHORIZE_ADMIN_COMPANY_USER.label,
              //                 subStep: STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.EDIT_STAFF,
              //                 staffInfo: staffInfo,
              //                 companyInfo: company
              //             })
              //         }

              //     })
              //     .catch(err => err && message.error(err))
              //     .finally(() => setIsLoading(false))
            }}
          >
            EDIT{" "}
          </Button>
        );
      },
    },
    {
      title: "Account Status",
      dataIndex: "accountStatus",
      render: (accountStatus) => {
        return (
          <>
            {accountStatus === "ACTIVE" ? (
              <Tag color={`green`}>Active</Tag>
            ) : (
              <Tag color={`red`}>Inactive</Tag>
            )}
          </>
        );
      },
      sorter: (a, b) => {
        const firstRoleA = a.accountStatus === "ACTIVE" ? "Active" : "Inactive";
        const firstRoleB = b.accountStatus === "ACTIVE" ? "Active" : "Inactive";
        return firstRoleA.localeCompare(firstRoleB);
      },
    },
    {
      // title: "Activate",
      fixed: "right",
      width: "6%",
      sorter: (a, b) => Number(a.is_active) - Number(b.is_active),
      render: (rowData) => {
        // if (rowData.isOwner == true) return
        return (
          <>
            <Switch
              checked={rowData.is_active}
              onChange={(e) => {
                const is_active = e;
                activateOrDeactivateBranchUser(
                  is_active,
                  rowData.id,
                  company?.coRegNo,
                  "",
                  rowData?.tempAuthPersonnel
                )
                  .then((res) => {
                    if (res.status !== true) throw res.message;
                    const newBranchUsers = users.map((b) => {
                      if (b.id === rowData.id) {
                        b.is_active = is_active;
                      }
                      return b;
                    });
                    message.success(res.message);
                    setUsers(newBranchUsers);
                  })
                  .catch((err) => {
                    message.error(err.toString());
                  });
              }}
            />
          </>
        );
      },
    },
  ];

  const staffDetailsTableTitle = () => {
    return (
      <div>
        <Row justify="space-between">
          <Col>
            <h1>Staff details</h1>
          </Col>
          <Col>
            <Button
              className="admin-add-new-user-button"
              type="primary"
              onClick={() =>
                history.push("/adminCompany/add/", {
                  step: STEPS.AUTHORIZE_ADMIN_COMPANY_USER.label,
                  subStep:
                    STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.ADD_STAFF,
                  companyInfo: company,
                })
              }
            >
              Add Staff
            </Button>
          </Col>
        </Row>
        <Row>
          <Row>
            <Col span={24}>
              <div
                style={{
                  font: "italic normal normal 16px/22px Open Sans",
                  color: "black",
                  marginBottom: "7px",
                }}
              >
                Search by
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "25px",
                }}
              >
                <Form
                  layout="inline"
                  className="select-search-wrapper"
                  style={{ minWidth: "65vw", borderRadius: 4 }}
                  initialValues={{
                    searchBy: "nric",
                  }}
                >
                  <Form.Item className="form-item" name={"searchBy"}>
                    <Select
                      optionFilterProp="children"
                      style={{ width: "100%", maxWidth: 200 }}
                      onChange={(value) => {
                        setSearchBy(value);
                      }}
                    >
                      <Select.Option value={"nric"}>Nric</Select.Option>
                      <Select.Option value={"name"}>Name</Select.Option>
                      <Select.Option value={"email"}>Email</Select.Option>
                      <Select.Option value={"phone"}>Phone</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item className="form-item" name={"searchTerm"}>
                    <Input.Search
                      style={{ maxWidth: "100%" }}
                      size="large"
                      placeholder={"Search"}
                      enterButton
                      onSearch={(value) => {
                        const result =
                          value !== "" && value !== null
                            ? initialDataSource.filter((item) => {
                                if (searchBy === "nric") {
                                  return item.nricNo
                                    .toLowerCase()
                                    .includes(value.trim().toLowerCase());
                                } else if (searchBy === "name") {
                                  return item.personName
                                    .toLowerCase()
                                    .includes(value.trim().toLowerCase());
                                } else if (searchBy === "email") {
                                  return item.email
                                    .toLowerCase()
                                    .includes(value.trim().toLowerCase());
                                } else if (searchBy === "phone") {
                                  return item.contactNumber
                                    .toLowerCase()
                                    .includes(value.trim().toLowerCase());
                                }
                              })
                            : initialDataSource;
                        setDataSource(result);
                      }}
                    />
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        </Row>
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Card
            style={{
              height: "50%",
              textAlign: "left",
              boxShadow: "0px 3px 6px #00000029",
              borderRadius: "4px",
              marginBottom: 16,
            }}
          >
            <div style={{ flex: 4 }}>
              <h2>Company Details</h2>
              <Row gutter={16}>
                <Col className="gutter-row" span={8}>
                  <p style={styles.label}>Company name</p>
                  <p style={styles.value}>{company?.coName}</p>
                </Col>
                <Col className="gutter-row" span={8}>
                  <p style={styles.label}>Company ROC number</p>
                  <p style={styles.value}>{company?.coRegNo}</p>
                </Col>
                <Col className="gutter-row" span={8}>
                  <p style={styles.label}>Company address</p>
                  <p style={styles.value}>
                    {parseAddress({
                      ...company?.companyAddress,
                      state: company?.companyAddress?.state,
                      districtName,
                    })}
                  </p>
                </Col>
              </Row>
            </div>
          </Card>
          <Table
            className="report-table"
            size="small"
            columns={columns}
            dataSource={dataSource}
            loading={isTableLoading}
            scroll={{
              x: columns.length * 150,
            }}
            // rowSelection={{
            //     type: "checkbox",
            //     onChange: (selectedRowKeys) => {
            //         setSelectedIds(selectedRowKeys);
            //     },
            // }}
            pagination={{
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            title={() => staffDetailsTableTitle()}
            footer={() => {}}
          />

          <Button
            className="back-button buttons"
            shape="round"
            onClick={() => {
              history.push("/adminCompany");
            }}
          >
            <Image src={arrow_back} preview={false} />
            BACK
          </Button>
        </>
      )}
    </div>
  );
};

const styles = {
  label: {
    color: "grey",
    fontStyle: "italic",
    marginBottom: 0,
    fontFamily: "Open Sans",
    fontSize: "16px",
  },
  value: {
    fontWeight: "bold",
    fontFamily: "Open Sans",
    fontSize: "16px",
  },
};

export default ViewPage;
