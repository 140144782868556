import React, {useContext, useState } from 'react';
import { Card, Form, Input, Alert, Row, Col, Button, Modal, Spin } from 'antd';
import { CloseCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import illus_jpj_notice from "../../../assets/illus-jpj-notice.png";
import renew_icon from "../../../assets/renew-icon.png"
import red_warning_icon from "../../../assets/icon-red-warning.png"
import successIcon from '../../../assets/icon-success-300.png';
import { roadTaxInquiry } from '../../../services/api';
import { SessionContext } from "../../../App";

import './index.less';

function VehicleInformation({ handleNext, handleVehicleSubmit, requiresFormFill, isFormFilled, setIsFormFilled, roadTaxData, setRenewalPrices, setApiResponse }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', message: '', type: 'error' });
    const [form] = Form.useForm(); // Ensure form instance is used
    const [spinProgress, setSpinProgress] = useState(0);
    const { company, branch } = useContext(SessionContext);


    const handleSubmit = async (values) => {
        console.log("🚀 Form values received:", values);
    
        setIsLoading(true);
        setSpinProgress(0);
    
        let progress = 0;
        const interval = setInterval(() => {
            progress += 10;
            setSpinProgress(progress);
            if (progress >= 100) clearInterval(interval);
        }, 200);
    
        const vehicleNumber = values.vehicleNumber.trim().toUpperCase();
        const ownerIdNumber = values.ownerIdNumber.trim();
    
        handleVehicleSubmit(vehicleNumber, ownerIdNumber);
    
        const baseRequestBody = {
            address1: roadTaxData?.address1 || "-",
            address2: roadTaxData?.address2 || "-",
            category: "1",
            contactNo: roadTaxData?.mobileNumber || "-",
            email:  "-",
            idNo: ownerIdNumber,
            name: "dummyName",
            postcode: roadTaxData?.postcode || "-",
            state: "00",
            vehicleNo: vehicleNumber,
            lamination_fee: roadTaxData?.lamination_fee || false,
            dealerCompanyRoc: company.coRegNo,
        };
    
        const updatedRequestBody = {
            ...baseRequestBody,
            state: String(roadTaxData?.state || roadTaxData?.roadTaxFormat || "00"),
        };
    
        console.log("📤 Final API Request Body:", JSON.stringify(updatedRequestBody, null, 2));
    
        try {
            console.log("🔄 Requesting road tax for 6 & 12 months simultaneously...");
    
            //  Run both requests in parallel using Promise.all
            const [response6, response12] = await Promise.all([
                roadTaxInquiry({ ...updatedRequestBody, renewalPeriod: "6" }),
                roadTaxInquiry({ ...updatedRequestBody, renewalPeriod: "12" }),
            ]);
    
            console.log("🚀 Response 6:", response6);
            console.log("🚀 Response 12:", response12);
    
            if (!response6 || response6.error || !response12 || response12.error) {
                console.error(" API ERROR - Responses:", { response6, response12 });
                setModalContent({
                    title: "Error",
                    message: response6?.errorMessage || response12?.errorMessage || "Invalid response from API",
                    type: "error",
                    buttonLabel: "Try Again",
                    buttonAction: handleModalClose,
                });
                setIsModalVisible(true);
                throw new Error(response6?.errorMessage || response12?.errorMessage || "Invalid API response");
            }
    
            // ✅ Handle status codes for response6 (primary response)
            if (response6.statusCode === "VEL000069E") {
                console.warn(" No Active Insurance - Showing Modal...");
                const handleInsuranceRedirect = () => {
                    const baseURL = "https://compare.myeg.com.my/";
                    // const queryParams = new URLSearchParams({
                    //     vehicle_reg_no: vehicleNumber,
                    //     id_no: ownerIdNumber,
                    // });
            
                    // window.open(`${baseURL}?${queryParams.toString()}`, "_blank");
                    window.open(`${baseURL}`, "_blank");
                };
                setModalContent({
                    title: "No active insurance found",
                    message: (
                        <>
                            Please renew insurance for <strong>{vehicleNumber}</strong> <br /> before renewing road tax.
                        </>
                    ),
                    type: "error",
                    buttonLabel: "Renew Insurance",
                    buttonAction: handleInsuranceRedirect,
                });
                setIsModalVisible(true);
            } else if (response6.statusCode === "MY0001") {
                console.log("🚀 StatusCode is MY0001 - Proceeding to next step...");
                setRenewalPrices({
                    "6": Number(response6.amount) || 0,
                    "12": Number(response12.amount) || 0,
                });
                setApiResponse(response6);
                handleNext(); //  Waited for both responses before proceeding
            } else if (response6.statusCode === "VEL000008E") {
                console.warn(" Owner Mismatch - Showing Modal...");
                setModalContent({
                    title: "Owner record do not match",
                    message: (
                        <>
                            Please check the vehicle number and owner <br /> details. And then try again.
                        </>
                    ),
                    type: "error",
                    buttonLabel: "Try Again",
                    buttonAction: handleModalClose,
                });
                setIsModalVisible(true);
            } else if (response6.statusCode === "MY0022") {
                console.warn(" Road Tax Active - Showing Modal...");
                setModalContent({
                    title: "Road tax is active",
                    message: (
                        <>
                            No renewal needed yet for <strong>{vehicleNumber}</strong>! <br /> <br /> The owner can renew up to
                            <strong> 2 months before <br /> expiry</strong>. Please come back later.
                        </>
                    ),
                    type: "success",
                    buttonLabel: "OK",
                    buttonAction: handleModalClose,
                });
                setIsModalVisible(true);
            }
    
            clearInterval(interval);
            setIsLoading(false);
            setSpinProgress(100);
    
            //  Store renewal prices
            setRenewalPrices({
                "6": Number(response6.amount) || 0,
                "12": Number(response12.amount) || 0,
            });
    
            setApiResponse(response6);
        } catch (error) {
            console.error("❌ API Call Failed:", error);
            clearInterval(interval);
            setIsLoading(false);
            setSpinProgress(100);
    
            setModalContent({
                title: "Error",
                message: error.message || "Failed to fetch road tax data. Please try again.",
                type: "error",
                buttonLabel: "Try Again",
                buttonAction: handleModalClose,
            });
            setIsModalVisible(true);
        }
    };
    






    const handleModalClose = () => {
        setIsModalVisible(false); // Hide modal
        setIsLoading(false); // Reset loading
        setSpinProgress(0); // ✅ Reset spin stroke progress to 0%
    };



    return (
        <Card style={{ width: '1244px', padding: 0, paddingTop: 14, borderRadius: 8, border: 0, backgroundColor: 'transparent' }}>
            {/* Banner Alert */}
            <Alert

                message={
                    <p style={{fontSize: '16px', fontWeight: 400, lineHeight: '24px', letterSpacing: "0%", margin: '0px' }}>
                        This service is exclusively available for <strong>vehicles registered</strong> as <strong>private vehicles</strong>. For natural gas vehicles (NGVs), please visit the JPJ counter for assistance.
                    </p>
                }
                type="warning"
                showIcon
                icon={<img src={illus_jpj_notice} alt="Notice" style={{ width: 120, height: 72, objectFit: 'cover', marginRight: '16px' }} />}
                style={{ marginBottom: 16, backgroundColor: '#FFF9A2', padding: '0 24px', fontSize: '16px', fontFamily: 'Plus Jakarta Sans', lineHeight: '24px', borderRadius: 4, textAlign: 'start' }}
            />

            <Card className='vehicle_information' style={{ height: '209px', margin: 'auto', padding: 24, borderRadius: 4 }}>
                <h2 style={{ textAlign: 'start', marginTop: 0, marginBottom: 0, lineHeight: '25.2px' }}>Renew road tax</h2>
                <p style={{ textAlign: 'start', marginTop: 5, marginBottom: 20 }}>
                    <span style={{ color: 'red' }}>*</span> indicates required field
                </p>

                {/* Form */}
                <Form
                    form={form} // Use form instance
                    layout="vertical"
                    onValuesChange={(_, allValues) => {
                        const filled = allValues.vehicleNumber && allValues.ownerIdNumber;
                        setIsFormFilled(filled);
                    }}
                    onFinish={handleSubmit} // Ensure it triggers modal
                    style={{ height: '184px', position: 'relative' }}
                >
                    <Row gutter={16}>
                        <Col style={{ width: 275 }}>
                            <Form.Item
                                label="Vehicle Number"
                                name="vehicleNumber"
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input placeholder="Enter vehicle number" style={{ boxShadow: '0px 4px 8px 0px #33333340' }} />
                            </Form.Item>
                        </Col>
                        <Col style={{ width: 275 }}>
                            <Form.Item
                                label="Owner's ID Number"
                                name="ownerIdNumber"
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input placeholder="Enter owner's ID number" style={{ boxShadow: '0px 4px 8px 0px #33333340' }} />
                            </Form.Item>
                        </Col>
                        <Col style={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ fontSize: 16, color: '#888' }}>Supported ID numbers are MyKad, MyKas, MyPR, Passport, Army, Police and <br/> ROC Number.</p>
                        </Col>
                    </Row>
                    <Button
                        className='next-button'
                        type="primary"
                        style={{ width: 345, height: 56, marginTop: 16, display: 'flex', justifySelf: 'end', position: 'absolute', bottom: 0, right: '-24px', zIndex: 2 }}
                        htmlType="submit"
                        disabled={requiresFormFill && !isFormFilled}

                    >
                        {isFormFilled ? 'Renew Now' : 'Enter details'}
                    </Button>
                </Form>
            </Card>

            {/* Loading Modal */}
            <Modal
                className='roadtax-modal'
                open={isLoading}
                footer={null}
                closable={false}
                centered
                style={{ textAlign: 'center', padding: '30px', borderRadius: 8 }}
            >
                {spinProgress > 0 && <Spin percent={spinProgress} />}
                <p style={{ fontSize: '16px', margin: '16px 0px 0px', lineHeight: '24px', fontWeight: 400, letterSpacing: '-0.08px' }}>Please wait...</p>
            </Modal>

            {/* Result Modal */}
            <Modal
                className='result-modal'
                open={isModalVisible}
                onCancel={handleModalClose} // ✅ Reset loading when user closes modal
                footer={[
                    <Button
                        type="primary"
                        key="confirm"
                        onClick={() => {
                            modalContent.buttonAction();
                            handleModalClose(); // ✅ Ensures loading is reset after action
                        }}
                        style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8.12px', fontSize: '16px', fontWeight: 700, lineHeight: '24px', letterSpacing: '-0.08px' }}
                    >
                        {modalContent.buttonLabel === "Renew Insurance" && (
                            <img src={renew_icon} alt="Renew Icon" style={{ width: "16.03px", height: "15.98px" }} />
                        )}
                        {modalContent.buttonLabel}
                    </Button>

                ]}
                centered
                style={{
                    minHeight: modalContent.type === "error" ? "362px" : "410px",  // ✅ Different heights for success & error
                }}
            >
                <div style={{ textAlign: 'center', padding: '0px' }}>
                    {modalContent.type === "error" ? (
                        <img src={red_warning_icon} style={{ width: 96, height: 96 }} />
                    ) : (
                        <img src={successIcon} style={{ width: 96, height: 96 }} />
                    )}
                    <h2 style={{ fontSize: '24px', fontWeight: 700, lineHeight: '30.24px', letterSpacing: '-0.12px', margin: '16px 0px 8px' }}>{modalContent.title}</h2>
                    <p style={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', letterSpacing: '-0.08px', margin: '8px 0px 12px ' }}>{modalContent.message}</p>
                </div>
            </Modal>
        </Card>
    );
}

export default VehicleInformation;
