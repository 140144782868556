import { Card, Button, Image, Breadcrumb } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { useHistory } from "react-router-dom";
import arrow_back from "../../assets/arrow_back.svg";
import React, { useContext, useMemo } from "react";
import {
    FingerPrintScanIcon,
    DigitalIdLogoIcon,
} from "../../components/CustomIcons";
import { SessionContext } from "../../App";

export default function VerificationMethodsPage(props) {
    const history = useHistory();
    const { user } = useContext(SessionContext);

    const { type, thumbPrintRedirect } = props.type
        ? {
              type: props.type,
              thumbPrintRedirect: props.thumbPrintRedirect,
          }
        : history?.location?.state;

    const isMobile = useMemo(() => {
        const screenWidth = window.innerWidth;
        return screenWidth < 768; // Mobile common screen size >> can adjust if needed
    }, [window.innerWidth]);

    return (
        <>
            <div style={{ padding: "0px 50px" }}>
                <Breadcrumb
                    separator=">"
                    style={{ marginTop: "60px" }}
                    items={[
                        {
                            title: type === "Dashboard" ? "Dashboard" : "Login",
                            href: "/",
                            onClick: (e) => {
                                e.preventDefault();
                                history.push(
                                    type === "Dashboard" ? "/" : "/login"
                                );
                            },
                        },
                        {
                            title:
                                type === "Dashboard"
                                    ? "Verify Authorised Person Identity"
                                    : "User Activation",
                        },
                    ]}
                ></Breadcrumb>
                <PageHeader
                    className="site-page-header"
                    style={{
                        color: "white",
                        paddingLeft: "0px",
                    }}
                    title={
                        type === "Dashboard"
                            ? "Verify Authorised Person Identity"
                            : "User Activation"
                    }
                />

                <Card
                    style={{
                        borderRadius: "8px",
                        boxShadow: "0px 3px 6px #00000029",
                    }}
                >
                    <div
                        className="verificationBody"
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            gap: 20,
                            justifyContent: "space-around",
                            alignItems: "center",
                            margin: isMobile ? "" : "88px 138px",
                        }}
                    >
                        {[
                            {
                                description: "Fingerprint Verification",
                                icon: <FingerPrintScanIcon />,
                                onClick: () => {
                                    if (type === "Dashboard") {
                                        const { route, params } =
                                            thumbPrintRedirect;
                                        history.push(route, {
                                            ...params,
                                        });
                                    } else if (
                                        type === "activate-remote-user"
                                    ) {
                                        const { pathname } = thumbPrintRedirect;
                                        history.push(pathname, {
                                            ...history.location.state,
                                            step: "REMOTE_USER_ACTIVATION",
                                            isMyDigitalId: false,
                                        });
                                    }
                                },
                            },
                            {
                                description: "MyDigital ID Verification",
                                icon: <DigitalIdLogoIcon />,
                                onClick: () => {
                                    history.push(
                                        user ? "/mydid" : "/mydid-unauth",
                                        {
                                            verifyType: type,
                                            thumbPrintRedirect:
                                                thumbPrintRedirect,
                                        }
                                    );
                                },
                            },
                        ].map((item, j) => {
                            return (
                                <Card
                                    className="ant-card-hover dashboard-card"
                                    key={j}
                                    onClick={item.onClick}
                                    style={styles.verificationCard}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div>{item.icon}</div>
                                        <p
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "16px",
                                                margin: "0px",
                                            }}
                                        >
                                            {item.description}
                                        </p>
                                    </div>
                                </Card>
                            );
                        })}
                    </div>
                </Card>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 40,
                    }}
                >
                    {type === "Dashboard" && (
                        <Button
                            style={{
                                border: "1px solid #2B61C4",
                                color: "#2B61C4",
                                padding: "15px",
                                height: "fit-content",
                                minWidth: "160px",
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "bold",
                                background: "transparent",
                            }}
                            shape="round"
                            onClick={() => {
                                history.push(
                                    type === "Dashboard" ? "/" : "/login"
                                );
                            }}
                        >
                            <Image src={arrow_back} preview={false} />
                            BACK
                        </Button>
                    )}
                </div>
            </div>
            <style>
                {`
                    .ant-page-header-heading-title{ 
                        font: normal normal 600 32px/37px Raleway;
                    }
					.verificationBody .ant-card-body{
						padding: 19px;
					}
                `}
            </style>
        </>
    );
}

const styles = {
    verificationCard: {
        width: "15%",
        minWidth: 100,
        height: "40%",
        minHeight: 200,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#EEEEEE 0% 0% no-repeat padding-box",
        flex: "0 0 calc(32% - 10px)",
    },
};
