import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DeviceReader from "../../components/DeviceReader";
import { breakdownIc, parseAddress, getState } from "../../util";
import { STEPS } from "./steps";
import {
    STATES,
    STATES_FULLNAME,
    // GENDER,
    RELIGION,
    RACE,
} from "../../constants";
import {
    isAccountExist,
    getAccountDetails,
    update1MIDAccount,
    getProfile,
    ActivateRemoteUser,
    getCityListForDealer,
} from "../../services/api";
import {
    setUnverifiedAccount,
    getCompanyId,
    removeRemoteToken,
} from "../../services/local";
import { LoadingOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Image,
    message,
    Space,
    Modal,
    Form,
    Input,
    Select,
    Spin,
} from "antd";
import PasswordComponent from "../../components/PasswordComponent";
import thumbprint from "../../assets/thumbprint.jpg";
import green_thumbprint from "../../assets/illus-thumb-success.svg";
import red_thumbprint from "../../assets/illus-thumb-fail.svg";
import arrow_back from "../../assets/arrow_back.svg";
import verify_user from "../../assets/icon-bio-device-160@2x.png";
import { decryptUrlVerify } from "../../components/Utils";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";

function AuthorizeRemoteUser(props) {
    
    const [step, setStep] = useState(STEPS.REMOTE_USER_ACTIVATION.label)
    const [subStep, setSubStep] = useState(STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC)
    const { formattedDob, isMale } = breakdownIc(props.remoteUser?.identificationNo)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const [isValidThumbprint, setIsValidThumbprint] = useState(props.isValidThumbprint)
    const [encryptPassword, setEncryptPassword] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isPasswordLoading, setIsPasswordLoading] = useState(false)
    const [cities, setCities] = useState([])

    const [digitalIdForm] = useForm();

    const COMPONENT_STEPS = {
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: "bolder",
                        }}
                    >
                        Verify new remote user identity
                    </h1>
                    <img src={verify_user} height={160} />
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERTING_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify new user identity
                    </h1>
                    <p>retrieving data...</p>
                    <LoadingOutlined />
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_BEFORE]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify new user identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img src={thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red' }}>
                        Please wait for the red light to disappear
                    </p>
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINTING]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify new user identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <LoadingOutlined
                        style={{ marginBottom: 20 }}
                    />
                    <p style={{ color: 'red' }}>
                        Please wait for the red light to disappear
                    </p>
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_SUCCESS]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify new user identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img src={green_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'green' }}>
                        Verified!
                    </p>
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_FAILED]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify new user identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img src={red_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red' }}>
                        Failed. Try again?
                    </p>
                </div>
            )
        },
        [STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY]: {
            component: (
                <div>
                    <Card
                        style={{
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px'
                        }}
                    >
                        <h2 style={{ textAlign: 'left', font: 'normal normal 600 24px Raleway' }}>Identity Card Details</h2>
                        {!props?.isMyDigitalId ? (
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%'
                                }}
                            >
                                <img src={`data:image/png;base64, ${props.remoteUser?.photo}`} height='200px' style={{ marginRight: '10%' }} />
                                <div style={{ flex: 1, textAlign: 'left' }}>
                                    <Space direction="vertical">
                                        <p style={styles.label}>Name</p>
                                        <p style={styles.label}>NRIC</p>
                                        <p style={styles.label}>Date of Birth</p>
                                        <p style={styles.label}>Gender</p>
                                        <p style={styles.label}>Address</p>
                                    </Space>
                                </div>
                                <div style={{ flex: 4, textAlign: 'left' }}>
                                    <Space direction='vertical'>
                                        <p style={styles.value}>{props.remoteUser?.name || props.remoteUser?.personName}</p>
                                        <p style={styles.value}>{props.remoteUser?.identificationNo}</p>
                                        <p style={styles.value}>{formattedDob}</p>
                                        <p style={styles.value}>{isMale ? 'MALE' : 'FEMALE'}</p>
                                        <p style={styles.value}>{parseAddress({ ...props.remoteUser, state: parseInt(props.remoteUser?.state) ? Object.values(STATES).find(s => s.code === props.remoteUser?.state)?.label : props.remoteUser?.state })}</p>
                                    </Space>
                                </div>
                            </div>
                        ) : (
                            <Spin spinning={isLoading} delay={500}>
                                <div>
                                    <Form
                                        name="digitalIdForm"
                                        labelAlign="left"
                                        form={digitalIdForm}
                                        layout="horizontal"
                                        labelCol={{
                                            span: 8,
                                        }}
                                        requiredMark={false}
                                        autoComplete="off"
                                        scrollToFirstError={{
                                            behavior: "instant",
                                            block: "end",
                                            focus: true,
                                        }}
                                    >
                                        <Form.Item
                                            label={
                                                <p style={styles.label}>Name</p>
                                            }
                                            name="DIname"
                                        >
                                            <p
                                                style={{
                                                    textAlign: "left",
                                                    marginBottom: 0,
                                                    ...styles.value,
                                                }}
                                            >
                                                {props.remoteUser?.name ||
                                                    props.remoteUser
                                                        ?.personName}
                                            </p>
                                        </Form.Item>
                                        <Form.Item
                                            label={
                                                <p style={styles.label}>
                                                    Mykad Number
                                                </p>
                                            }
                                            name="DInric"
                                        >
                                            <p
                                                style={{
                                                    textAlign: "left",
                                                    marginBottom: 0,
                                                    ...styles.value,
                                                }}
                                            >
                                                {
                                                    props.remoteUser
                                                        ?.identificationNo
                                                }
                                            </p>
                                        </Form.Item>
                                        <Form.Item
                                            label={
                                                <p style={styles.label}>
                                                    Date of Birth
                                                </p>
                                            }
                                            name="DIdob"
                                        >
                                            <p
                                                style={{
                                                    textAlign: "left",
                                                    marginBottom: 0,
                                                    ...styles.value,
                                                }}
                                            >
                                                {formattedDob}
                                            </p>
                                        </Form.Item>
                                        <Form.Item
                                            label={
                                                <p style={styles.label}>
                                                    Gender
                                                </p>
                                            }
                                            name="DIgender"
                                        >
                                            <p
                                                style={{
                                                    textAlign: "left",
                                                    marginBottom: 0,
                                                    ...styles.value,
                                                }}
                                            >
                                                {isMale ? "MALE" : "FEMALE"}
                                            </p>
                                        </Form.Item>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Address not valid`,
                                                    // pattern: new RegExp('^[a-zA-Z 0-9]{2,20}$'),
                                                    whitespace: true,
                                                },
                                            ]}
                                            name="DIaddress1"
                                            label={
                                                <p style={styles.label}>
                                                    Address 1
                                                </p>
                                            }
                                        >
                                            <Input
                                                className="form-input-field"
                                                style={{
                                                    width: "60%",
                                                    font: "normal normal 14px Open Sans",
                                                    float: "left",
                                                }}
                                                type="text"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            rules={[
                                                {
                                                    required: false,
                                                    message: `Address not valid`,
                                                    // pattern: new RegExp('^[a-zA-Z 0-9]{2,20}$'),
                                                },
                                            ]}
                                            name="DIaddress2"
                                            label={
                                                <p style={styles.label}>
                                                    Address 2
                                                </p>
                                            }
                                        >
                                            <Input
                                                className="form-input-field"
                                                style={{
                                                    width: "60%",
                                                    font: "normal normal 14px Open Sans",
                                                    float: "left",
                                                }}
                                                type="text"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            rules={[
                                                {
                                                    required: false,
                                                    // pattern: new RegExp('^[a-zA-Z 0-9]{2,20}$'),
                                                    message: `Address not valid`,
                                                },
                                            ]}
                                            name="DIaddress3"
                                            label={
                                                <p style={styles.label}>
                                                    Address 3
                                                </p>
                                            }
                                        >
                                            <Input
                                                className="form-input-field"
                                                style={{
                                                    width: "60%",
                                                    font: "normal normal 14px Open Sans",
                                                    float: "left",
                                                }}
                                                type="text"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Postcode is required`,
                                                },
                                                {
                                                    pattern: new RegExp(
                                                        "^[0-9]{5}$"
                                                    ),
                                                    message: `Postcode must be a 5 digit number`,
                                                },
                                            ]}
                                            name="DIpostcode"
                                            label={
                                                <p style={styles.label}>
                                                    Postcode
                                                </p>
                                            }
                                        >
                                            <Input
                                                className="textbox-form-style"
                                                style={{
                                                    width: "60%",
                                                    font: "normal normal  14px Open Sans",
                                                    float: "left",
                                                }}
                                                type="number"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="DIstate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please choose state from the dropdown",
                                                },
                                            ]}
                                            label={
                                                <p style={styles.label}>
                                                    State
                                                </p>
                                            }
                                        >
                                            <Select
                                                className={"custom-select"}
                                                showSearch
                                                style={{
                                                    width: "60%",
                                                    height: 48,
                                                    font: "normal normal bold 14px Open Sans",
                                                    float: "left",
                                                    textAlign: "left",
                                                }}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                onChange={(stateCode) => {
                                                    setIsLoading(true);
                                                    digitalIdForm.setFieldsValue(
                                                        { DIcity: "" }
                                                    );
                                                    getCityListForDealer(
                                                        stateCode
                                                    )
                                                        .then((res) => {
                                                            setCities(res);
                                                        })
                                                        .catch((err) => {
                                                            err &&
                                                                message.error(
                                                                    err?.toString()
                                                                );
                                                        })
                                                        .finally(() => {
                                                            setIsLoading(false);
                                                        });
                                                }}
                                            >
                                                {Object.values(STATES).map(
                                                    (state) => {
                                                        return (
                                                            <Select.Option
                                                                key={state.code}
                                                                value={
                                                                    state.code
                                                                }
                                                            >
                                                                {state.label}
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="DIcity"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        cities.length === 0
                                                            ? "Please select state first"
                                                            : "Please choose city from the dropdown",
                                                },
                                            ]}
                                            label={
                                                <p style={styles.label}>City</p>
                                            }
                                        >
                                            <Select
                                                className="custom-select"
                                                showSearch
                                                style={{
                                                    width: "60%",
                                                    height: 48,
                                                    font: "normal normal bold 14px Open Sans",
                                                    float: "left",
                                                    textAlign: "left",
                                                }}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                disabled={cities.length === 0}
                                            >
                                                {cities.length === 0 ? (
                                                    <Select.Option value="">
                                                        Select state first
                                                    </Select.Option>
                                                ) : (
                                                    cities.map((city) => {
                                                        return (
                                                            <Select.Option
                                                                key={
                                                                    city?.cityName
                                                                }
                                                                value={
                                                                    city?.cityCode
                                                                }
                                                            >
                                                                {city?.cityName}
                                                            </Select.Option>
                                                        );
                                                    })
                                                )}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Please enter place of birth`,
                                                    whitespace: true,
                                                },
                                            ]}
                                            name="DIplaceOfBirth"
                                            label={
                                                <p style={styles.label}>
                                                    Place of Birth
                                                </p>
                                            }
                                        >
                                            <Input
                                                className="form-input-field"
                                                style={{
                                                    width: "60%",
                                                    font: "normal normal 14px Open Sans",
                                                    float: "left",
                                                }}
                                                type="text"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name={"DIrace"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please choose a race from the dropdown",
                                                },
                                            ]}
                                            label={
                                                <p style={styles.label}>Race</p>
                                            }
                                        >
                                            <Select
                                                className={"custom-select"}
                                                showSearch
                                                style={{
                                                    width: "60%",
                                                    height: 48,
                                                    font: "normal normal bold 14px Open Sans",
                                                    float: "left",
                                                    textAlign: "left",
                                                }}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                            >
                                                {Object.values(RACE).map(
                                                    (race) => {
                                                        return (
                                                            <Select.Option
                                                                key={
                                                                    race?.label
                                                                }
                                                                value={
                                                                    race?.value
                                                                }
                                                            >
                                                                {race?.label}
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name={"DIreligion"}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please choose a religion from the dropdown",
                                                },
                                            ]}
                                            label={
                                                <p style={styles.label}>
                                                    Religion
                                                </p>
                                            }
                                        >
                                            <Select
                                                className={"custom-select"}
                                                showSearch
                                                style={{
                                                    width: "60%",
                                                    height: 48,
                                                    font: "normal normal bold 14px Open Sans",
                                                    float: "left",
                                                    textAlign: "left",
                                                }}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                            >
                                                {Object.values(RELIGION).map(
                                                    (religion) => {
                                                        return (
                                                            <Select.Option
                                                                key={
                                                                    religion?.label
                                                                }
                                                                value={
                                                                    religion?.value
                                                                }
                                                            >
                                                                {
                                                                    religion?.label
                                                                }
                                                            </Select.Option>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </Spin>
                        )}
                    </Card>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between', marginTop: 40
                        }}
                    >
                        <Button
                            shape='round'
                            className='back-button buttons'
                            style={{ backgroundColor: "#F0F2F5" }}
                            onClick={() => {
                                setEncryptPassword(null) //If back reset encrypt password
                                history.push(history.location.pathname, {
                                    ...history.location.state,
                                    step: STEPS.VERIFICATION_METHODS_PAGE.label,
                                    subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC
                                })
                            }}
                        >
                            <Image src={arrow_back} preview={false} />
                            Back
                        </Button>
                        <Button
                            loading={isLoading}
                            size='large'
                            style={{
                                width: 200,
                                background: " #2B61C4 0% 0% no-repeat padding-box",
                                boxShadow: "0px 4px 0px #1E4489",
                                borderRadius: "8px",
                                borderColor: 'transparent'
                            }}
                            onClick={() => {
                                setIsLoading(true)
                                handleRemoteUserVerification()
                            }}
                            type='primary'
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            )
        },
    }

    useEffect(async () => {

        if(encryptPassword !== null) {
            setIsModalVisible(false)
            setIsLoading(true)

            let {
                address1,
                address2,
                address3,
                dob,
                birthPlace, // birthplace for sagem, birthPlace for dermalog
                birthplace,
                citizenship,
                city, // city for sagem, district for dermalog
                district,
                personName, // personName for sagem, name for dermalog
                name,
                identificationNo,
                postcode,
                race,
                religion,
                state,
            } = props.remoteUser;

            if (props?.isMyDigitalId) {
                const {
                    DIaddress1,
                    DIaddress2,
                    DIaddress3,
                    DIpostcode,
                    DIstate,
                    DIcity,
                    DIplaceOfBirth,
                    DIrace,
                    DIreligion,
                } = digitalIdForm.getFieldValue();
                address1 = DIaddress1;
                address2 = DIaddress2;
                address3 = DIaddress3;
                birthplace = DIplaceOfBirth;
                postcode = DIpostcode;
                race = DIrace;
                religion = DIreligion;
                state = DIstate;
                city = DIcity;
                dob = dayjs(formattedDob).format("YYYY-MM-DD");
            }

            let stateCode = parseInt(state)
                ? state
                : Object.values(STATES).find(
                      (s) =>
                          s.label.replaceAll(" ", "").toLowerCase() ==
                          state.replaceAll(" ", "").toLowerCase()
                  )?.code;

            //activate account
            const request = {
                encryptedString: props.token,
                thumbprintNric: identificationNo,
                newPassword: encryptPassword,
                thumbprintData: {
                    name: personName ?? name,
                    address1: address1,
                    address2: address2,
                    address3: address3,
                    city: city ?? district,
                    postcode: postcode,
                    state: stateCode,
                    birthPlace: birthplace ?? birthPlace,
                    citizenship: citizenship,
                    race: race,
                    religion: religion,
                    gender: isMale ? "MALE" : "FEMALE",
                    birthDate: dob,
                },
                testing: props.isTesting ? true : false //TODO - remove
            }
            await ActivateRemoteUser(request).then(async res => {
                if(!res.status) {
                    throw res.message
                }
                message.success(res.message, 5)
                //redirect to login
                await removeRemoteToken()
                history.push('/login')
            })
            .catch((err => {
                setIsLoading(false)
                message.error(err)
            }))
        }
    }, [encryptPassword])

    const handleRemoteUserVerification = async () => {
        let {
            address1,
            address2,
            address3,
            dob,
            birthPlace, // birthplace for sagem, birthPlace for dermalog
            birthplace,
            citizenship,
            city, // city for sagem, district for dermalog
            district,
            personName, // personName for sagem, name for dermalog
            name,
            identificationNo,
            postcode,
            race,
            religion,
            state,
        } = props.remoteUser;

        // let remoteUserName = name ? name : personName
        // remoteUserName = String(remoteUserName).replace(/[^\w]/g, "")

        if (props?.isMyDigitalId) {
            try {
                await digitalIdForm.validateFields();

                const {
                    DIaddress1,
                    DIaddress2,
                    DIaddress3,
                    DIpostcode,
                    DIstate,
                    DIcity,
                    DIplaceOfBirth,
                    DIrace,
                    DIreligion,
                } = digitalIdForm.getFieldValue();
                address1 = DIaddress1;
                address2 = DIaddress2;
                address3 = DIaddress3;
                birthplace = DIplaceOfBirth;
                postcode = DIpostcode;
                race = DIrace;
                religion = DIreligion;
                state = DIstate;
                city = DIcity;
                dob = dayjs(formattedDob).format("YYYY-MM-DD");
            } catch (err) {
                setIsLoading(false);
                return message.error("Please Fill Required Fields");
            }
        }

        if (props.token) {
            //activate remote user logic
            setIsPasswordLoading(false);
            setEncryptPassword(null);
            if (!isValidThumbprint && !props.isValidThumbprint) {
                message.error(
                    "Mismatch NRIC with registered user NRIC. Please contact company's owner.",
                    5
                );
            } else {
                //show modal for new password
                setIsModalVisible(true);
            }
            setIsLoading(false);
            return;
        }
        getProfile()
            .then((res) => {
                if (res.nric != identificationNo) {
                    message.error(
                        "Mismatch NRIC with registered user NRIC. Please ensure the correct mykad inserted."
                    );
                    setIsLoading(false);
                    return;
                } else {
                    let stateCode = parseInt(state)
                        ? state
                        : Object.values(STATES).find(
                              (s) =>
                                  s.label.replaceAll(" ", "").toLowerCase() ==
                                  state.replaceAll(" ", "").toLowerCase()
                          )?.code;

                    isAccountExist(identificationNo)
                        .then((res) => {
                            if (!res.status) {
                                setIsLoading(false);
                                message.error(res.message);
                                return;
                            }

                            getAccountDetails(identificationNo)
                                .then((res) => {
                                    const {
                                        email,
                                        mobileno,
                                        phoneno,
                                        // name
                                    } = res;

                                    // let _name = String(name).replace(/[^\w]/g, "")

                                    // if (remoteUserName.localeCompare(_name) !== 0) {
                                    //     setIsLoading(false)
                                    //     message.error('The name entered is incorrect. For any further assistance, please call us at 03-7664 8181 or drop us an e-mail at twocar@myeg.com.my')
                                    //     return
                                    // }

                                    update1MIDAccount(
                                        address1,
                                        address2,
                                        address3,
                                        dob,
                                        birthplace ?? birthPlace,
                                        citizenship,
                                        city ?? district,
                                        email,
                                        email,
                                        isMale ? "MALE" : "FEMALE",
                                        mobileno,
                                        personName ?? name,
                                        identificationNo,
                                        true,
                                        phoneno,
                                        postcode,
                                        race,
                                        religion,
                                        stateCode
                                    ).then(res => {

                                        if (!res.status) {
                                            setIsLoading(false)
                                            message.error(res.message)
                                            return
                                        }

                                        history.push('/')
                                        message.success('Thumbprint verification success')
                                        setUnverifiedAccount(false)
                                        setIsLoading(false)


                                    }).catch(err => {
                                        setIsLoading(false)
                                        err && message.error(err?.toString())
                                    })

                                }).catch(err => {
                                    setIsLoading(false)
                                    err && message.error(err?.toString())
                                })

                        }).catch(err => {
                            setIsLoading(false)
                            err && message.error(err?.toString())
                        })

                }
            })
            .catch(err => {
                err && message.error(err)
            })

    }

    useEffect(() => {
        if (!history.location.state) return null
        if (!history.location.state.subStep) return null

        // on refresh, remoteUser details will disappear, therefore, go back to INSERT_IC substep
        setSubStep(props.remoteUser.identificationNo ? history.location.state.subStep : STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC)
    }, [history.location.state])

    const currentStep = COMPONENT_STEPS[subStep]

    return (<>
        <div
            className={
                subStep !== STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY ? 'form-background' : ""
            }
            style={{
                height: '50%',
                width: '100%',
                textAlign: subStep !== STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY ? "center" : "left",
                padding: subStep !== STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY ? "20px" : "0px",
                marginBottom: subStep !== STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY ? "10px" : "0px"
            }}
        >
            {
                currentStep && currentStep.component
            }
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >

                {
                    (
                        subStep !== STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY
                    ) ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <DeviceReader
                                token={props.token}
                                forKey={'remoteUser'}
                                nextStep={STEPS.REMOTE_USER_ACTIVATION.label}
                                nextSubStep={STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY}
                                onIdentityCardSuccess={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERTING_IC)
                                }}
                                onWaitingThumbprint={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_BEFORE)
                                }}
                                onThumbprintDetected={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINTING)
                                }}
                                onThumbprintFailed={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_FAILED)
                                }}
                                onThumbprintSuccess={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_SUCCESS)
                                }}
                                onTryAgain={() => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC)
                                }}
                                onFinish={async data => {

                                    let errorMessages = [];

                                    if (!data.name) {
                                        errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + data.name + "'. Please contact the eTukar Admin for more information.");
                                    }

                                    if (!data.district) {
                                        errorMessages.push("Failed to read the District from MyKad. The District read as '" + data.district + "'. Please contact the eTukar Admin for more information.");
                                    }

                                    if (!data.identificationNo || !/^[+]?\d+([.]\d+)?[*]?$/.test(data.identificationNo)) {
                                        errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + data.identificationNo + "'. Please contact the eTukar Admin for more information.");
                                    }

                                    if (errorMessages.length > 0) {
                                        for (let errorMessage of errorMessages) {
                                            message.error(errorMessage);
                                        }
                                        history.push(history.location.pathname, {
                                            ...history.location.state,
                                            step: STEPS.REMOTE_USER_ACTIVATION.label,
                                            subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC
                                        })
                                        return;
                                    }

                                    if (props.token) {
                                        const decryptedResponse = await decryptUrlVerify(data,
                                            data.encryptString,
                                            data.forkey,
                                            getCompanyId(),
                                            "DERMALOG",
                                            data.identificationNo.replace(/[\s*]/g, ""),
                                            true,
                                            props.isTesting)
                                        if(decryptedResponse !== false) {
                                            setIsValidThumbprint(true)
                                            /* history.push(history.location.pathname, {
                                                ...history.location.state,
                                                step: STEPS.REMOTE_USER_ACTIVATION.label,
                                                subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC
                                            })
                                            return; */
                                        }
                                    }

                                    const formattedState = data?.state?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                                    let state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                                    if (!state) state = getState(data.identificationNo)

                                    props.onChange({
                                        ...data,
                                        state: state?.code,
                                        name: data.name.replace(/\*/g, ''),
                                        district: data.district.replace(/[^a-zA-Z\s]/g, '')
                                    })

                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY)
                                }}
                                onDataChanged={data => { }}
                                onError={data => {
                                    setStep(STEPS.REMOTE_USER_ACTIVATION.label)
                                    setSubStep(STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC)
                                }}
                            />
                        </div>
                    ) : null
                }
            </div>
        </div>

        {STEPS.REMOTE_USER_ACTIVATION.subSteps[subStep] === STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC && (
            <div
                style={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Button
                    style={{
                        border: "1px solid #2B61C4",
                        color: "#2B61C4",
                        padding: "15px",
                        height: "fit-content",
                        minWidth: "160px",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: "bold",
                        background: "transparent",
                    }}
                    shape="round"
                    onClick={(e) => {
                        e.preventDefault();
                        history.push(history.location.pathname, {
                            ...history.location.state,
                            step: STEPS.VERIFICATION_METHODS_PAGE.label,
                            subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC
                        })
                    }}
                >
                    <Image src={arrow_back} preview={false} />
                    BACK
                </Button>
            </div>
        )}
        
        <Modal
            open={isModalVisible}
            footer={false}
            onCancel={() => {
                setIsModalVisible(false);
            }}
            centered
            okButtonProps={{ style: { display: "none" } }}
        >
            <PasswordComponent 
                setEncryptPassword={setEncryptPassword}
                setIsLoading={setIsPasswordLoading}
                isLoading={isPasswordLoading}
            />

        </Modal>
        </>)

}

const styles = {
    label: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',
    },
    value: {
        color: '#333333',
        font: 'normal normal 600 16px Open Sans'
    },

    disclaimer: {
        color: 'grey', fontStyle: 'italic', marginBottom: '15px', width: "60%"
    }
}

export default AuthorizeRemoteUser