import React, { useContext, useState, useEffect } from 'react';
import { Card, Button, Image, Space } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import successIcon from '../../../assets/icon-success-300.png';
import downloadIcon from '../../../assets/icon-download.png';
import moment from 'moment';
import './index.less';
import { useHistory } from 'react-router-dom';
import { getPrepaidBalance } from '../../../services/api';
import { SessionContext } from "../../../App";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const PrintSlip = ({ vehicleNumber, ownerIdNumber, roadTaxData, apiResponse, validityPeriod, updatedRequestBody, paymentResult }) => {
    // Hardcoded values for now
    const roadTaxFormat = 'physical_digital'; // Change this to 'digital_only' to see the difference
    const collectionMethod = 'delivery'; // Change this to 'self_collect' for self-collection
    const history = useHistory();
    const [remainingBalance, setRemainingBalance] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [paymentMessage, setPaymentMessage] = useState('');
    const [additionalDetails, setAdditionalDetails] = useState(null);
    const [collectionDate, setCollectionDate] = useState('');
    const { company, branch } = useContext(SessionContext);
    console.log("🧾 paymentResult in PrintSlip:", paymentResult);

    // Function to calculate 3 working days from today (skipping weekends)
    const getWorkingDayAfter = (days) => {
        let date = moment(); // Start from today

        let addedDays = 0;
        while (addedDays < days) {
            date = date.add(1, 'days'); // Move to the next day
            if (date.isoWeekday() < 6) {
                // Only count if it's a weekday (Monday-Friday)
                addedDays++;
            }
        }

        return date.format('DD MMM YYYY'); // Format the date
    };






    useEffect(() => {
        if (roadTaxFormat === roadTaxData?.state) {
            setTotalAmount(`RM ${apiResponse?.totalAmount}`);
            setPaymentMessage(
                <span>
                    The vehicle owner can view their digital road tax on <br /> the <b>MyJPJ app</b> within 24 hours.
                </span>
            );
            setAdditionalDetails(null);
        } else if (roadTaxFormat === 'physical_digital') {
            if (collectionMethod === 'delivery') {
                setTotalAmount(`RM ${apiResponse?.totalAmount}`);
                setPaymentMessage(
                    <span>
                        The vehicle owner can view their digital road tax on the <b>MyJPJ app</b> within 24 hours.
                    </span>
                );
                setAdditionalDetails(
                    <p>
                        The vehicle owner will be contacted at <b>+6{roadTaxData?.mobileNumber}</b> <br /> once the road tax is ready for delivery.
                    </p>
                );
            } else if (collectionMethod === roadTaxData?.state) {
                const dynamicDate = getWorkingDayAfter(3); // Calculate the dynamic collection date
                setCollectionDate(dynamicDate);

                setTotalAmount(`RM ${apiResponse?.totalAmount}`);
                setPaymentMessage(
                    <span>
                        The vehicle owner can view their digital road tax on the <b>MyJPJ app</b> within 24 hours.
                    </span>
                );
                setAdditionalDetails(
                    <p>
                        The vehicle owner can collect the road tax after <br /> 3 working days (<b>{dynamicDate}</b>) at <b>MYEG Customer Service Center</b>.
                    </p>
                );
            }
        }
    }, [roadTaxFormat, collectionMethod]);

    useEffect(() => {
        const fetchPrepaidBalance = async () => {
            try {
                const res = await getPrepaidBalance(company?.coRegNo);
                if (res?.balanceAmount !== undefined) {
                    setRemainingBalance(`RM ${Number(res.balanceAmount).toFixed(2)}`);
                }
            } catch (err) {
                console.error("Failed to fetch prepaid balance:", err);
            }
        };

        if (company?.coRegNo) {
            fetchPrepaidBalance();
        }
    }, [company?.coRegNo]);


    return (
        <Card className='print-slip-container' style={{ width: '1196px', padding: 0, marginTop: 40, borderRadius: 4, border: 0, textAlign: 'center', boxShadow: '0px 4px 8px 0px #33333340' }}>
            <div className='success-message'>
                <Image src={successIcon} alt='Success' preview={false} />
                <h2>{totalAmount} road tax paid for <b>{vehicleNumber}</b></h2>
                <p>Your remaining account balance is <b>{remainingBalance}</b>.</p>
                {additionalDetails}
                <p>{paymentMessage}</p>
                <p style={{ margin: '16px 0px 0px' }}>Transaction ID {apiResponse?.requestId}</p>
            </div>
            <Space direction='vertical' className='button-group' style={{ gap: 0 }}>
                <Button
                    type='default'
                    style={{ width: 314, height: 48, backgroundColor: 'transparent', borderRadius: '40px', border: '1px solid #2B61C4 ', color: '#2B61C4', fontSize: '16px', fontWeight: 600, margin: '24px 0px 27px' }}
                    icon={<img src={downloadIcon} alt='download' style={{ width: 28, height: 28 }}
                    />}
                    shape='round'
                    onClick={() => {
                        if (paymentResult?.receiptUrl) {
                          window.open(paymentResult.receiptUrl, '_blank');
                          console.log("🔍 paymentResult.receiptUrl:", paymentResult?.receiptUrl);
                        } else {
                          console.warn("⚠️ No receipt URL found in paymentResult");
                        }
                      }}
                >
                    Download transaction receipt
                </Button>
                <Button type='link' onClick={() => history.push("/")} style={{ fontSize: '14px', lineHeight: '17.64px', letterSpacing: '-0.07px', color: '#2B61C4', padding: '0px 0px 15px', fontWeight: 700, border: 0 }}>RETURN TO HOMEPAGE</Button>
            </Space>

        </Card>
    );
};

export default PrintSlip;
