import { useEffect, useState } from "react";

const useMobileDetect = () => {
    const [device, setDevice] = useState("Unknown");

    useEffect(() => {
        const userAgent = navigator.userAgent;

        if (/android/i.test(userAgent)) {
        setDevice("Android");
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        setDevice("iOS");
        } else {
        setDevice("Other");
        }
    }, []);

    return device;
};

export default useMobileDetect;