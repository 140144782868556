import React, { useState, useRef, useEffect, useMemo } from "react";

import {
    Carousel,
    Row,
    Col,
    Progress,
    Image,
    Timeline,
    Card,
    Modal,
    Button,
    Collapse,
    // Form,
    // Input,
} from "antd";
import { useHistory } from "react-router-dom";

import Icon, { LeftOutlined, RightOutlined } from "@ant-design/icons";
import PinchZoomWrapper from "../../components/component/Function/PinchZoomWrapper";
import "./index.css";

import landing_page_hero from "../../assets/landing-page-hero.svg";
import speedIcon from "../../assets/icon-feature-speed.png";
import simpleIcon from "../../assets/icon-feature-simple.png";
import securityIcon from "../../assets/icon-feature-security.png";

export default function LandingPage() {
    const carouselRef = useRef(null);
    // const [form] = Form.useForm();
    const history = useHistory();

    const [showDetail, setShowDetail] = useState(false);
    const [showSupportedDevice, setShowSupportedDevice] = useState(false);
    const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

    const [countdown, setCountdown] = useState(7);
    const [isRunning, setIsRunning] = useState(true);

    const [currentOpenTab, setCurrentOpenTab] = useState("enterprise");

    const isMobile = useMemo(() => {
        const screenWidth = window.innerWidth;
        return screenWidth < 768; // Mobile common screen size >> can adjust if needed
    }, [window.innerWidth]);

    useEffect(() => {
        let intervalId;
        if (isRunning && !isMobile) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => {
                    const newCountdown =
                        prevCountdown > 0 ? prevCountdown - 1 : 7;
                    if (newCountdown === 0) {
                        setCurrentCarouselIndex((prevCurrentCarouselIndex) =>
                            prevCurrentCarouselIndex < carouselLists.length - 1
                                ? prevCurrentCarouselIndex + 1
                                : 0
                        );
                        carouselRef.current.next();
                        setCountdown(7);
                    }
                    return newCountdown;
                });
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [isRunning]);

    const numberIcon = (number) => {
        return (
            <span
                style={{
                    backgroundColor: "#442673",
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#FFFFFF",
                    fontSize: "24px",
                    padding: "8px",
                    marginLeft: "0px",
                    boxShadow: "0 0 0 12px #442673",
                }}
            >
                {number}
            </span>
        );
    };

    const accountIcon = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.77"
            height="28.81 "
            fill="none"
            viewBox="0 0 26 30"
        >
            <path
                fill="#212121"
                d="M19.8 23.422v-3.79a.758.758 0 1 1 1.516 0v3.791h3.79a.758.758 0 0 1 0 1.516h-3.79v3.79a.758.758 0 1 1-1.516 0v-3.791h-3.79a.758.758 0 1 1 0-1.516zm-4.486-8.24a7.6 7.6 0 0 1-6.188 0l-6.184 2.816a2.27 2.27 0 0 0-1.336 2.072v1.08a2.274 2.274 0 0 0 2.273 2.273h7.58a.758.758 0 0 1 0 1.516H3.883a3.79 3.79 0 0 1-3.79-3.79v-1.08a3.79 3.79 0 0 1 2.221-3.446l5.24-2.386a7.58 7.58 0 1 1 9.355-.02l3.946 1.685a.763.763 0 1 1-.597 1.395zm-3.098-.859a6.06 6.06 0 1 0-.007-12.12 6.06 6.06 0 0 0 .005 12.12z"
            ></path>
        </svg>
    );

    const walletIcon = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.77"
            height="28.81 "
            fill="none"
            viewBox="0 0 27 25"
        >
            <path
                fill="#333"
                d="M25.326 7.837a3.3 3.3 0 0 0-1.988-.662h-.73a3.32 3.32 0 0 0-3.245-2.652H7.428a3.32 3.32 0 0 0-3.245 2.652h-.732a1.99 1.99 0 0 1-1.988-1.99V3.86A1.99 1.99 0 0 1 3.45 1.87h19.887a1.99 1.99 0 0 1 1.988 1.989zM5.553 7.174a1.99 1.99 0 0 1 1.875-1.325H19.36a1.99 1.99 0 0 1 1.872 1.325zm19.773 5.303h-4.64a3.314 3.314 0 0 0 0 6.629h4.64v1.988a1.99 1.99 0 0 1-1.988 1.988H3.45a1.99 1.99 0 0 1-1.988-1.988V7.837A3.3 3.3 0 0 0 3.45 8.5h19.887a1.99 1.99 0 0 1 1.988 1.988zm0 5.304h-4.64a1.99 1.99 0 0 1 0-3.978h4.64zM23.338.545H3.45A3.314 3.314 0 0 0 .137 3.86v17.235a3.314 3.314 0 0 0 3.314 3.314h19.887a3.315 3.315 0 0 0 3.314-3.314V3.86A3.314 3.314 0 0 0 23.338.545"
            ></path>
        </svg>
    );

    const biometricIcon = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.77"
            height="28.81 "
            fill="none"
            viewBox="0 0 34 33"
        >
            <path
                stroke="#333"
                d="M29.365 1.25H4.091A3.34 3.34 0 0 0 .75 4.59v24.018a3.34 3.34 0 0 0 3.34 3.341h25.275a3.34 3.34 0 0 0 3.341-3.34V4.59a3.34 3.34 0 0 0-3.34-3.341Z"
            ></path>
            <path
                fill="#333"
                d="M23.982 7.59a.65.65 0 0 1-.279-.067 14.19 14.19 0 0 0-13.523-.006.61.61 0 0 1-.582-1.068 15.42 15.42 0 0 1 14.664-.006.607.607 0 0 1-.279 1.146M6.61 13.962a.604.604 0 0 1-.498-.953 12.64 12.64 0 0 1 4.558-3.975 14 14 0 0 1 12.518-.013 12.7 12.7 0 0 1 4.552 3.945.6.6 0 0 1-.14.844.61.61 0 0 1-.849-.139 11.4 11.4 0 0 0-4.115-3.57 12.77 12.77 0 0 0-11.41.013 11.4 11.4 0 0 0-4.121 3.6.62.62 0 0 1-.497.25zm7.594 14.646a.6.6 0 0 1-.43-.183 12.7 12.7 0 0 1-2.44-3.198 10.8 10.8 0 0 1-1.281-5.275 6.724 6.724 0 0 1 6.872-6.543 6.72 6.72 0 0 1 6.873 6.543.607.607 0 1 1-1.214 0 5.51 5.51 0 0 0-5.664-5.33 5.513 5.513 0 0 0-5.664 5.33 9.6 9.6 0 0 0 1.134 4.685 11.5 11.5 0 0 0 2.24 2.938.6.6 0 0 1-.006.859.54.54 0 0 1-.42.175zm8.698-2.245a6.6 6.6 0 0 1-3.763-1.074 6.47 6.47 0 0 1-2.883-5.33.607.607 0 1 1 1.214 0 5.25 5.25 0 0 0 5.432 5.19q.64-.008 1.268-.116a.61.61 0 0 1 .705.491.61.61 0 0 1-.492.705 8.7 8.7 0 0 1-1.481.134m-2.446 2.506a.5.5 0 0 1-.157-.024 9.27 9.27 0 0 1-4.51-2.55 8.89 8.89 0 0 1-2.624-6.336 3.743 3.743 0 0 1 7.478 0 2.53 2.53 0 0 0 5.05 0 8.565 8.565 0 0 0-8.795-8.296 8.86 8.86 0 0 0-8.028 4.892 7.8 7.8 0 0 0-.71 3.404c-.004 1.496.269 2.98.805 4.376a.611.611 0 1 1-1.145.425 13.5 13.5 0 0 1-.886-4.801 9 9 0 0 1 .831-3.927 10.08 10.08 0 0 1 9.123-5.578 9.78 9.78 0 0 1 10.008 9.511 3.743 3.743 0 0 1-7.477 0 2.53 2.53 0 0 0-5.05 0 7.68 7.68 0 0 0 2.27 5.475 8.2 8.2 0 0 0 3.972 2.24.605.605 0 0 1-.158 1.19z"
            ></path>
        </svg>
    );

    const contentStyle = {
        height: "599px",
        lineHeight: "160px",
        textAlign: "left",
    };

    const carouselLists = [
        {
            id: 1,
            src: "https://compare-cms-uat.myeg.com.my/uploads/Myeg_9b24f95ee4.jpg",
        },
        {
            id: 2,
            src: "https://compare-cms-uat.myeg.com.my/uploads/Myeg_9b24f95ee4.jpg",
        },
        {
            id: 3,
            src: "https://compare-cms-uat.myeg.com.my/uploads/Myeg_9b24f95ee4.jpg",
        },
        {
            id: 4,
            src: "https://compare-cms-uat.myeg.com.my/uploads/Myeg_9b24f95ee4.jpg",
        },
    ];

    const iconLandTransfer = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <mask
                id="a"
                width="68"
                height="68"
                x="6"
                y="6"
                maskUnits="userSpaceOnUse"
                style={{ maskType: "luminance" }}
            >
                <path fill="#fff" d="M73.333 6.66H6.666v66.666h66.667z"></path>
            </mask>
            <g mask="url(#a)">
                <mask
                    id="b"
                    width="56"
                    height="57"
                    x="12"
                    y="12"
                    maskUnits="userSpaceOnUse"
                    style={{ maskType: "luminance" }}
                >
                    <path
                        fill="#fff"
                        d="M67.175 12.215H12.22v56.086h54.954z"
                    ></path>
                </mask>
                <g
                    fill="#16467D"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    mask="url(#b)"
                >
                    <path d="M17.848 62.456c0-.767.622-1.39 1.389-1.39h7.015c.767 0 1.389.623 1.389 1.39v4.676c0 .767-.622 1.39-1.39 1.39h-7.015a1.39 1.39 0 0 1-1.388-1.39zm2.777 1.389v1.898h4.238v-1.898zM50.586 62.456c0-.767.622-1.39 1.389-1.39h7.015c.767 0 1.389.623 1.389 1.39v4.676c0 .767-.622 1.39-1.389 1.39h-7.015a1.39 1.39 0 0 1-1.39-1.39zm2.778 1.389v1.898H57.6v-1.898z"></path>
                </g>
                <path
                    fill="#16467D"
                    fillRule="evenodd"
                    d="M20.591 47.443c.26-.26.614-.407.982-.407h35.08c.368 0 .72.146.981.407l4.677 4.676c.26.26.407.614.407.982v9.355c0 .767-.622 1.388-1.39 1.388H16.898a1.39 1.39 0 0 1-1.39-1.388V53.1c0-.368.147-.721.408-.982zm1.557 2.37-3.862 3.864v7.39H59.94v-7.39l-3.863-3.863z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#16467D"
                    fillRule="evenodd"
                    d="M24.961 36.216a1.39 1.39 0 0 1 1.29-.873h25.723c.568 0 1.079.346 1.29.873l4.678 11.689a1.39 1.39 0 0 1-1.29 1.905H21.574a1.39 1.39 0 0 1-1.29-1.905zm2.23 1.905-3.565 8.911H54.6l-3.566-8.911zM15.508 57.779c0-.767.622-1.389 1.389-1.389H27.42a1.389 1.389 0 0 1 0 2.778H16.897a1.39 1.39 0 0 1-1.39-1.389M49.416 57.779c0-.767.622-1.389 1.389-1.389h10.523a1.389 1.389 0 0 1 0 2.778H50.805a1.39 1.39 0 0 1-1.389-1.389"
                    clipRule="evenodd"
                ></path>
                <mask
                    id="c"
                    width="56"
                    height="57"
                    x="12"
                    y="12"
                    maskUnits="userSpaceOnUse"
                    style={{ maskType: "luminance" }}
                >
                    <path
                        fill="#fff"
                        d="M67.175 12.215H12.22v56.086h54.954z"
                    ></path>
                </mask>
                <g
                    fill="#16467D"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    mask="url(#c)"
                >
                    <path d="M22.833 20.678a2.149 2.149 0 1 0 0 4.297 2.149 2.149 0 0 0 0-4.297m-4.927 2.148a4.926 4.926 0 1 1 9.853 0 4.926 4.926 0 0 1-9.853 0"></path>
                    <path d="M27.64 16.407a7.9 7.9 0 0 0-4.882-1.635h-.014a7.961 7.961 0 1 0 7.712 9.925 1.39 1.39 0 0 1 1.346-1.047h13.159c.767 0 1.388.622 1.388 1.389v3.287h1.903V25.04c0-.767.622-1.389 1.389-1.389h4.674c.767 0 1.389.622 1.389 1.389v3.286h1.898v-3.287c0-.768.622-1.39 1.389-1.39h4.657a.95.95 0 0 0 0-1.898h-31.83c-.634 0-1.188-.43-1.345-1.045a7.9 7.9 0 0 0-2.833-4.298m-4.901-4.412a10.67 10.67 0 0 1 10.1 6.977h30.864a3.728 3.728 0 0 1 0 7.454h-3.324v3.288c0 .767-.62 1.389-1.388 1.389h-4.676a1.39 1.39 0 0 1-1.39-1.389v-3.286H51.03v3.287c0 .767-.622 1.39-1.389 1.39h-4.68a1.39 1.39 0 0 1-1.39-1.39v-3.287H32.825A10.739 10.739 0 1 1 22.74 11.995"></path>
                </g>
            </g>
        </svg>
    );

    const iconCheckMark = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#a)">
                <path
                    stroke="#00622A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                    d="m18.3 8.093-9 8.4-3-3"
                ></path>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0-.007h24v24H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );

    const icoonLandRoad = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <mask
                id="a"
                width="68"
                height="68"
                x="6"
                y="6"
                maskUnits="userSpaceOnUse"
                style={{ maskType: "luminance" }}
            >
                <path fill="#fff" d="M73.333 6.66H6.666v66.666h66.667z"></path>
            </mask>
            <g fill="#16467D" mask="url(#a)">
                <path
                    fillRule="evenodd"
                    d="M6.389 16.106H73.61v49.166H6.389zm3.333 3.333v42.5h60.556v-42.5z"
                    clipRule="evenodd"
                ></path>
                <path d="M60.833 56.66V30.273H46.775v12.587H19.166v13.802zm2.778 2.779H16.389V40.082h27.608V27.494h19.614z"></path>
                <path
                    fillRule="evenodd"
                    d="M59.437 37.49h-11.11v-3.334h11.11zM27.5 29.161H16.389v-3.333h11.11zM27.5 33.328H16.389v-3.334h11.11zM27.5 37.495H16.389V34.16h11.11zM34.445 27.772a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5m-5.834 2.5a5.833 5.833 0 1 1 11.667 0 5.833 5.833 0 0 1-11.667 0"
                    clipRule="evenodd"
                ></path>
            </g>
        </svg>
    );

    const iconLandInsurance = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <path
                fill="#16467D"
                fillRule="evenodd"
                d="m40.366 8.912.744.523a27.94 27.94 0 0 0 16.249 5.085 34.5 34.5 0 0 0 10.94-1.87l1.657-.57.056 1.752c.211 6.557-.119 18.152-3.956 29.299C62.211 54.3 54.43 65.266 40.413 69.993c-14.019-4.728-21.9-15.688-25.745-26.858-3.837-11.147-4.168-22.742-3.957-29.3l.056-1.75 1.657.57a34.5 34.5 0 0 0 10.94 1.87 27.94 27.94 0 0 0 16.258-5.09zm-.008 58.372c12.75-4.46 19.61-14.414 23.252-24.995 3.38-9.82 3.944-20.073 3.856-26.66a37 37 0 0 1-10.089 1.478 30.53 30.53 0 0 1-17.01-5.048 30.53 30.53 0 0 1-17.02 5.052 37 37 0 0 1-10.09-1.479c-.087 6.588.477 16.842 3.857 26.66 3.642 10.579 10.5 20.53 23.244 24.992"
                clipRule="evenodd"
            ></path>
            <path
                fill="#16467D"
                fillRule="evenodd"
                d="M26.838 45.044c0-.714.579-1.293 1.293-1.293h4.393c.715 0 1.294.579 1.294 1.293v2.928c0 .715-.58 1.294-1.294 1.294h-4.393c-.714 0-1.293-.58-1.293-1.294zm2.587 1.294v.341h1.806v-.342zM47.332 45.044c0-.714.58-1.293 1.294-1.293h4.392c.715 0 1.294.579 1.294 1.293v2.928c0 .715-.58 1.294-1.294 1.294h-4.392c-.715 0-1.294-.58-1.294-1.294zm2.587 1.294v.341h1.806v-.342z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#16467D"
                fillRule="evenodd"
                d="M28.68 35.345c.243-.242.572-.379.915-.379h21.96c.343 0 .673.137.915.38l2.922 2.929c.242.242.378.57.378.913v5.856c0 .714-.579 1.293-1.293 1.293h-27.81c-.715 0-1.294-.579-1.294-1.293v-5.856c0-.343.136-.672.379-.914zm1.45 2.208-2.17 2.171v4.027h25.223v-4.028l-2.165-2.17z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#16467D"
                fillRule="evenodd"
                d="M31.322 28.46a1.29 1.29 0 0 1 1.2-.814h16.105c.529 0 1.004.322 1.2.813l2.93 7.32a1.293 1.293 0 0 1-1.202 1.774h-21.96a1.293 1.293 0 0 1-1.202-1.774zm2.076 1.773-1.893 4.733h18.14l-1.894-4.733zM25.373 42.117c0-.715.58-1.294 1.293-1.294h6.588a1.294 1.294 0 0 1 0 2.587h-6.587c-.715 0-1.294-.579-1.294-1.293M46.6 42.117c0-.715.579-1.294 1.293-1.294h6.588a1.294 1.294 0 0 1 0 2.587h-6.588c-.714 0-1.293-.579-1.293-1.293"
                clipRule="evenodd"
            ></path>
        </svg>
    );

    const enterpriseCard = () => (
        <ul>
            <li>
                2 photos of your{" "}
                <span>showroom with the signboard visible</span>.
            </li>
            <li>
                A copy of the company <span>director/owner's MyKad</span> (front
                and back).
            </li>
            <li>
                ⁠If your company is a{" "}
                <span>Sole Proprietorship/Partnerships</span>:
                <div>
                    <Card
                        style={{
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "8px",
                            background: "#F0E9F9",
                            textAlign: "left",
                            marginTop: "12px",
                            maxWidth: "fit-content",
                            paddingBottom: "12px",
                            paddingTop: "12px",
                        }}
                    >
                        <ul>
                            <li>
                                Certificate of registration (form D) / Form E
                            </li>
                            <li>Business Information</li>
                            <li>Latest Business Owner Information</li>
                        </ul>
                    </Card>
                </div>
            </li>

            <li>
                If your company is a{" "}
                <span>Limited Liability Partnerships (LLP)</span>:
                <div>
                    <Card
                        style={{
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "8px",
                            background: "#DEEDFF",
                            textAlign: "left",
                            marginTop: "12px",
                            maxWidth: "fit-content",
                            paddingBottom: "12px",
                            paddingTop: "12px",
                        }}
                    >
                        <ul>
                            <li>SSM Confirmation</li>
                            <li>
                                Certificate of Registration of Limited Liability
                                Partnership ( ACT 743 )
                            </li>
                            <li>Form A if any</li>
                            <li>
                                Certificate of registration (form D) / Form E if
                                any
                            </li>
                        </ul>
                    </Card>
                </div>
            </li>

            <li>
                <span> At least one</span> of the following{" "}
                <span>documents</span>:
                <div>
                    <Card
                        style={{
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "8px",
                            background: "#F0E9F9",
                            textAlign: "left",
                            marginTop: "12px",
                            maxWidth: "fit-content",
                            paddingBottom: "12px",
                            paddingTop: "12px",
                        }}
                    >
                        <ul>
                            <li>
                                Valid <span>Business License</span> issued by
                                Majlis Perbandaran
                            </li>
                            <li>
                                <span>Membership Certificate</span> of a
                                Malaysian, Regional Car/Motorcycle Association
                            </li>
                            <li>
                                Latest <span>Confirmation of Registration</span>{" "}
                                printout from CARSOME/CARRO
                            </li>
                        </ul>
                    </Card>
                </div>
            </li>
        </ul>
    );

    const sdnbhdCard = () => (
        <ul>
            <li>
                2 photos of your{" "}
                <span>showroom with the signboard visible</span>.
            </li>
            <li>
                A copy of the company <span>director/owner's MyKad</span> (front
                and back).
            </li>
            <li>
                Additional <span>documents</span>:
                <div>
                    <Card
                        style={{
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "8px",
                            background: "#DEEDFF",
                            textAlign: "left",
                            marginTop: "12px",
                            maxWidth: "fit-content",
                            paddingBottom: "12px",
                            paddingTop: "12px",
                        }}
                    >
                        <ul>
                            <li>SSM Company Profile (ROC)</li>
                            <li>Certificate of Incorporation (section 17)</li>
                            <li>
                                SSM Change of Company Name (Section 28), if
                                applicable, is required only when a company
                                changes its name.
                            </li>
                        </ul>
                    </Card>
                </div>
            </li>

            <li>
                <span>At least one</span> of the following{" "}
                <span>documents</span>:
                <div>
                    <Card
                        style={{
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "8px",
                            background: "#F0E9F9",
                            textAlign: "left",
                            marginTop: "12px",
                            maxWidth: "fit-content",
                            paddingBottom: "12px",
                            paddingTop: "12px",
                        }}
                    >
                        <ul>
                            <li>
                                Valid <span>Business License</span> issued by
                                Majlis Perbandaran
                            </li>
                            <li>
                                Certificate of Registration of Limited Liability
                                Partnership ( ACT 743 )
                            </li>
                            <li>
                                <span>Membership Certificate</span> of a
                                Malaysian, Regional Car/Motorcycle Association
                            </li>
                            <li>
                                Latest <span>Confirmation of Registration</span>{" "}
                                printout from CARSOME/CARRO
                            </li>
                        </ul>
                    </Card>
                </div>
            </li>
        </ul>
    );

    const sabahsarawakCard = () => (
        <ul>
            <li>
                2 photos of your{" "}
                <span>showroom with the signboard visible</span>.
            </li>
            <li>
                A copy of the company <span>director/owner's MyKad</span> (front
                and back).
            </li>
            <li>
                Additional <span>documents</span>:
                <div>
                    <Card
                        style={{
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "8px",
                            background: "#DEEDFF",
                            textAlign: "left",
                            marginTop: "12px",
                            maxWidth: "fit-content",
                            paddingBottom: "12px",
                            paddingTop: "12px",
                        }}
                    >
                        <ul>
                            <li>Business Names Ordinance (chapter 64)</li>
                            <li>Form 1 (sections 5, 23, and 24(2))</li>
                            <li>Business Names Registration Extract</li>
                            <li>Additional or supporting documents if any</li>
                        </ul>
                    </Card>
                </div>
            </li>

            <li>
                <span>At least one</span> of the following{" "}
                <span>documents</span>:
                <div>
                    <Card
                        style={{
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "8px",
                            background: "#F0E9F9",
                            textAlign: "left",
                            marginTop: "12px",
                            maxWidth: "fit-content",
                            paddingBottom: "12px",
                            paddingTop: "12px",
                        }}
                    >
                        <ul>
                            <li>
                                Valid <span>Business License</span> issued by
                                Majlis Perbandaran
                            </li>
                            <li>
                                <span>Membership Certificate</span> of a
                                Malaysian, Regional Car/Motorcycle Association
                            </li>

                            <li>
                                Latest <span>Confirmation of Registration</span>{" "}
                                printout from CARSOME/CARRO
                            </li>
                        </ul>
                    </Card>
                </div>
            </li>
        </ul>
    );

    const getItems = (
        panelStyle = {
            marginBottom: 8,
            border: "none",
            background: "#FFFFFF",
            boxShadow: "0px 4px 8px 0px #33333340",
            borderRadius: "8px",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
        }
    ) => [
        {
            key: "enterprise",
            label: "Enterprise",
            children: enterpriseCard(),
            style: panelStyle,
        },
        {
            key: "sdnbhd",
            label: "Sdn Bhd/Bhd",
            children: sdnbhdCard(),
            style: panelStyle,
        },
        {
            key: "sabahsarawak",
            label: "Sabah/Sarawak companies",
            children: sabahsarawakCard(),
            style: panelStyle,
        },
    ];

    return (
        <>
            {/* Carousel */}
            <div
                style={{
                    backgroundColor: "#FFFFFF",
                    marginTop: "20px",
                }}
            >
                <div>
                    <Carousel
                        ref={carouselRef}
                        arrows={false}
                        dots={false}
                        draggable={false}
                        afterChange={(next) => {
                            if (isMobile) {
                                setCurrentCarouselIndex(next);
                            }
                        }}
                    >
                        {carouselLists.map((item, index) => (
                            <div
                                key={index}
                                style={{
                                    ...contentStyle,
                                }}
                                onMouseEnter={() => {
                                    setIsRunning(false);
                                }}
                                onMouseLeave={() => {
                                    setIsRunning(
                                        (prevIsRunning) => !prevIsRunning
                                    );
                                }}
                                onTouchStart={() => {
                                    setIsRunning(false);
                                }}
                                onTouchEnd={() => {
                                    setIsRunning(
                                        (prevIsRunning) => !prevIsRunning
                                    );
                                }}
                            >
                                <div
                                    style={{
                                        height: "100%",
                                    }}
                                >
                                    <Row gutter={[0, 20]}>
                                        <Col span={24}>
                                            <PinchZoomWrapper>
                                                <img
                                                    src={item?.src}
                                                    alt={item?.id}
                                                    style={{
                                                        width: "100%",
                                                        height: "599px",
                                                        // borderRadius: "8px",
                                                    }}
                                                />
                                            </PinchZoomWrapper>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </Carousel>

                    <Row style={{ paddingTop: "24px", paddingBottom: "24px" }}>
                        <Col span={24}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <LeftOutlined
                                    style={{
                                        fontSize: "22.33px",
                                        color: "#333333",
                                        marginRight: "24px",
                                        userSelect: "none",
                                    }}
                                    onClick={() => {
                                        setCurrentCarouselIndex(
                                            (prevCurrentCarouselIndex) =>
                                                prevCurrentCarouselIndex > 0
                                                    ? prevCurrentCarouselIndex -
                                                      1
                                                    : carouselLists.length - 1
                                        );
                                        carouselRef.current.prev();
                                        setCountdown(7);
                                    }}
                                />
                                {carouselLists.map((item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            marginLeft: "5px",
                                            marginRight: "5px",
                                        }}
                                    >
                                        {index === currentCarouselIndex ? (
                                            <Progress
                                                type="circle"
                                                percent={
                                                    isMobile
                                                        ? 99.99
                                                        : countdown === 0
                                                        ? 0
                                                        : ((7 - countdown) /
                                                              7) *
                                                          100
                                                }
                                                size={32}
                                                showInfo={false}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    width: "12px",
                                                    height: "12px",
                                                    backgroundColor: "#333333",
                                                    borderRadius: "50%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setCurrentCarouselIndex(
                                                        index
                                                    );
                                                    carouselRef.current.goTo(
                                                        index
                                                    );
                                                    setCountdown(7);
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}

                                <RightOutlined
                                    style={{
                                        fontSize: "22.33px",
                                        color: "#333333",
                                        marginLeft: "24px",
                                        userSelect: "none",
                                    }}
                                    onClick={() => {
                                        setCurrentCarouselIndex(
                                            (prevCurrentCarouselIndex) =>
                                                prevCurrentCarouselIndex <
                                                carouselLists.length - 1
                                                    ? prevCurrentCarouselIndex +
                                                      1
                                                    : 0
                                        );
                                        carouselRef.current.next();
                                        setCountdown(7);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            {/* what is twocar */}
            <div
                style={{
                    background: "#4D719A",
                    padding: isMobile ? "24px 16px" : "42px 85px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: "24px",
                        margin: "0",
                    }}
                >
                    <h2
                        style={{
                            color: "#FFFFFF",
                            fontSize: "40px",
                            fontWeight: "700",
                            lineHeight: "50px",
                            textAlign: "center",
                            margin: "0",
                        }}
                    >
                        What is Twocar?
                    </h2>
                    <p
                        style={{
                            color: "#FFFFFF",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            textAlign: "center",
                        }}
                    >
                        MyEG Twocar simplifies vehicle ownership transfers for
                        used car and motorcycle dealers. With secure digital
                        processing and on-site thumbprint verification, you can
                        complete transfers quickly—without visiting JPJ
                        counters. Enjoy a fast, hassle-free, and compliant
                        transfer process, all in one platform!
                    </p>
                </div>

                <Row gutter={[16, 0]}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        style={{
                            display: "flex",
                            paddingRight: "8px",
                        }}
                        className="custom-landing-card"
                    >
                        <Card>
                            <div style={{ textAlign: "left" }}>
                                <Icon component={iconLandTransfer} />
                                <h3 style={{ color: "#16467D" }}>
                                    Vehicle ownership transfer
                                </h3>
                                <p>
                                    Wide range of secure & efficient ownership
                                    transfer services.
                                </p>
                            </div>
                            <div
                                style={{
                                    paddingTop: "16px",
                                    textAlign: "left",
                                }}
                            >
                                <p
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Icon component={iconCheckMark} />
                                    <span style={{ marginLeft: "8px" }}>
                                        Temporary ownership transfer from owner
                                        to dealer
                                    </span>
                                </p>
                                <p
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Icon component={iconCheckMark} />
                                    <span style={{ marginLeft: "8px" }}>
                                        Temporary ownership transfer from dealer
                                        to dealer
                                    </span>
                                </p>
                                <p
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Icon component={iconCheckMark} />
                                    <span style={{ marginLeft: "8px" }}>
                                        Permanent ownership transfer from dealer
                                        to individual Malaysian
                                    </span>
                                </p>
                                <p
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Icon component={iconCheckMark} />
                                    <span style={{ marginLeft: "8px" }}>
                                        Permanent ownership transfer from
                                        individual to individual (dealer as an
                                        intermediary)
                                    </span>
                                </p>
                            </div>
                        </Card>
                    </Col>

                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            paddingLeft: "8px",
                        }}
                        className="custom-landing-card custom-right-card"
                    >
                        <Card>
                            <div style={{ textAlign: "left", height: "50%" }}>
                                <Icon component={icoonLandRoad} />
                                <h3 style={{ color: "#16467D" }}>
                                    Road tax renewal
                                </h3>
                                <p>Renew road tax online, hassle-free.</p>
                            </div>
                        </Card>

                        <Card>
                            <div
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                <Icon component={iconLandInsurance} />
                                <h3 style={{ color: "#16467D" }}>
                                    Insurance renewal
                                </h3>
                                <p>
                                    Get competitive insurance plans instantly.
                                </p>
                            </div>
                        </Card>
                    </Col>
                </Row>

                <div style={{ textAlign: "center" }}>
                    <Button
                        color="default"
                        ghost
                        variant="outlined"
                        shape="round"
                        size="large"
                        style={{
                            marginTop: "24px",
                        }}
                        onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                            history.push("/reginterest");
                        }}
                    >
                        Register now
                    </Button>
                </div>
            </div>

            {/* why choose twocar? */}
            <div
                style={{
                    background: "#F1F1F1",
                    padding: isMobile ? "24px 16px" : "42px 85px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: "40px",
                        margin: "0",
                    }}
                >
                    <h1
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "16px",
                            color: "#333333",
                            margin: "0",
                            fontSize: "32px",
                        }}
                    >
                        Why choose Twocar?
                    </h1>
                </div>

                <Row gutter={[16, 0]}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 8 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingRight: "8px",
                        }}
                        className="custom-landing-card"
                    >
                        <div
                            style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                color: "#333333",
                                maxWidth: "80%",
                            }}
                        >
                            <Image
                                src={speedIcon}
                                preview={false}
                                width={"288px"}
                            />
                            <h3 style={{ paddingTop: "16px" }}>Speed</h3>
                            <p>
                                Real time transferring within second. No time
                                wasting.
                            </p>
                        </div>
                    </Col>

                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 8 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingRight: "8px",
                        }}
                        className="custom-landing-card"
                    >
                        <div
                            style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                color: "#333333",
                                maxWidth: "80%",
                            }}
                        >
                            <Image
                                src={simpleIcon}
                                preview={false}
                                width={"288px"}
                            />
                            <h3 style={{ paddingTop: "16px" }}>Simple</h3>
                            <p>
                                Hassle-free digital ownership transferring
                                process. Minimal documentation and traveling
                                required to transfer ownership.
                            </p>
                        </div>
                    </Col>

                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 8 }}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingRight: "8px",
                        }}
                        className="custom-landing-card"
                    >
                        <div
                            style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                color: "#333333",
                                maxWidth: "80%",
                            }}
                        >
                            <Image
                                src={securityIcon}
                                preview={false}
                                width={"288px"}
                            />
                            <h3 style={{ paddingTop: "16px" }}>Security</h3>
                            <p>
                                We use advanced encryption technology and
                                security measures to ensure that the process is
                                protected.
                            </p>
                        </div>
                    </Col>
                </Row>

                <Modal
                    title={
                        <p
                            style={{
                                fontWeight: 600,
                                fontSize: "20.25px",
                                lineHeight: "32px",
                                letterSpacing: "0%",
                                margin: 0,
                            }}
                        >
                            Supported biometric devices
                        </p>
                    }
                    open={showSupportedDevice}
                    onCancel={() => setShowSupportedDevice(false)}
                    centered
                    footer={null}
                >
                    <div className="custom-landing-modal">
                        <ul style={{ listStyleType: "disc" }}>
                            <li>Dermalog</li>
                            <li>Morpho</li>
                            <li>Sagem</li>
                            <li>Idemia</li>
                        </ul>

                        <p>
                            These devices are supported on both{" "}
                            <span>Android</span> and <span>desktop</span>.
                        </p>
                    </div>
                </Modal>
            </div>

            {/* how to register */}
            <div
                style={{
                    backgroundColor: "#FFFFFF",
                    padding: isMobile ? "24px 16px" : "42px 85px",
                }}
            >
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "24px",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <h1
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingBottom: "8px",
                                margin: "0",
                                fontSize: "32px",
                            }}
                        >
                            How to register with Twocar?
                        </h1>

                        <p>
                            Used car dealers can register and start using MyEG
                            Twocar in just a few steps
                        </p>
                    </div>
                    <Row gutter={[16, 0]}>
                        <Col
                            xs={{ span: 0 }}
                            sm={{ span: 0 }}
                            md={{ span: 0 }}
                            lg={{ span: 12 }}
                            style={{
                                justifyContent: "flex-end",
                                paddingRight: "24px",
                                textAlign: "right",
                                objectFit: "contain",
                            }}
                        >
                            <Image
                                src={landing_page_hero}
                                preview={false}
                                height={"446px"}
                            />
                        </Col>

                        <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 12 }}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            className="custom-landing-timeline"
                        >
                            <Timeline
                                items={[
                                    {
                                        dot: numberIcon(1),
                                        children: (
                                            <div
                                                style={{
                                                    paddingLeft: "8px",
                                                    position: "relative",
                                                    top: "-10px",
                                                }}
                                            >
                                                <h3
                                                    style={{
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                    }}
                                                >
                                                    Register interest and log in
                                                </h3>
                                                <p style={{ margin: 0 }}>
                                                    Fill out and submit the
                                                    register interest form, then
                                                    log in to your MyEG Twocar
                                                    account using the
                                                    credentials sent via email.
                                                </p>
                                            </div>
                                        ),
                                    },
                                    {
                                        dot: numberIcon(2),
                                        children: (
                                            <div
                                                style={{
                                                    paddingLeft: "8px",
                                                    position: "relative",
                                                    top: "-10px",
                                                }}
                                            >
                                                <h3
                                                    style={{
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                    }}
                                                >
                                                    Complete account setup
                                                </h3>
                                                <p style={{ margin: 0 }}>
                                                    Fill out and submit the
                                                    register interest form, then
                                                    log in to your MyEG Twocar
                                                    account using the
                                                    credentials sent via email.
                                                </p>
                                            </div>
                                        ),
                                    },
                                    {
                                        dot: numberIcon(3),
                                        children: (
                                            <div
                                                style={{
                                                    paddingLeft: "8px",

                                                    position: "relative",
                                                    top: "-10px",
                                                }}
                                            >
                                                <h3
                                                    style={{
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                    }}
                                                >
                                                    Choose a plan and make
                                                    payment
                                                </h3>
                                                <p style={{ margin: 0 }}>
                                                    Once approved, select a
                                                    plan, complete payment, and
                                                    start using MyEG Twocar.
                                                </p>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </div>
            </div>

            {/* Eligibility and required documents */}
            <div
                style={{
                    background: "#F1F1F1",
                    padding: isMobile ? "24px 16px" : "42px 85px",
                }}
            >
                <Row gutter={[16, 0]}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}
                        style={{
                            display: "flex",
                            paddingRight: "8px",
                        }}
                        className="custom-landing-card"
                    >
                        <Card>
                            <div style={{ textAlign: "left" }}>
                                <h3 style={{ color: "#333333" }}>
                                    Eligibility
                                </h3>

                                <ul
                                    style={{
                                        listStyleType: "disc",
                                        paddingLeft: "20px",
                                    }}
                                >
                                    <li>
                                        Your business is{" "}
                                        <span>registered in Malaysia</span>.
                                    </li>
                                    <li>
                                        Nature of the business is{" "}
                                        <span>Used Car/Motorbike Dealer</span>.
                                    </li>
                                    <li>
                                        You are the{" "}
                                        <span>
                                            company director/shareholder
                                        </span>
                                        , and a <span>Malaysian citizen</span>.
                                    </li>
                                </ul>
                            </div>
                        </Card>
                    </Col>

                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 16 }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: "8px",
                        }}
                        className="custom-landing-card"
                    >
                        <div
                            style={{ textAlign: "left", marginBottom: "16px" }}
                        >
                            <h3 style={{ color: "#333333" }}>
                                Required company documents upon registration
                            </h3>
                            <div
                                style={{
                                    fontSize: "16px",
                                    marginTop: "8px",
                                    fontWeight: "400",
                                }}
                            >
                                Ensure all documents are clear and up to date
                                for fast approval.
                            </div>
                        </div>

                        {isMobile ? (
                            <div className="custom-required-documents">
                                <Collapse
                                    bordered={false}
                                    // defaultActiveKey={["enterprise"]}
                                    expandIconPosition="end"
                                    expandIcon={({ isActive }) => (
                                        <RightOutlined
                                            style={{
                                                color: "#333333",
                                                fontSize: "16px",
                                            }}
                                            rotate={isActive ? 90 : -90}
                                        />
                                    )}
                                    style={{
                                        background: "transparent",
                                    }}
                                    items={getItems()}
                                />
                            </div>
                        ) : (
                            <div className="custom-required-documents">
                                <Card>
                                    <div className="tab-header">
                                        <div
                                            className={`tab-item ${
                                                currentOpenTab === "enterprise"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            style={{
                                                borderTopLeftRadius: "8px",
                                            }}
                                            onClick={() =>
                                                setCurrentOpenTab("enterprise")
                                            }
                                        >
                                            Enterprise
                                        </div>
                                        <div
                                            className={`tab-item ${
                                                currentOpenTab === "sdnbhd"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                setCurrentOpenTab("sdnbhd")
                                            }
                                        >
                                            Sdn Bhd / Bhd
                                        </div>
                                        <div
                                            className={`tab-item ${
                                                currentOpenTab ===
                                                "sabahsarawak"
                                                    ? "active"
                                                    : ""
                                            }`}
                                            style={{
                                                borderTopRightRadius: "8px",
                                            }}
                                            onClick={() =>
                                                setCurrentOpenTab(
                                                    "sabahsarawak"
                                                )
                                            }
                                        >
                                            Sabah / Sarawak companies
                                        </div>
                                    </div>
                                    <div className="tab-item-lists">
                                        {currentOpenTab === "enterprise" &&
                                            enterpriseCard()}
                                        {currentOpenTab === "sdnbhd" &&
                                            sdnbhdCard()}
                                        {currentOpenTab === "sabahsarawak" &&
                                            sabahsarawakCard()}
                                    </div>
                                </Card>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>

            {/* Affordable & transparent pricing */}
            <div
                style={{
                    background:
                        "linear-gradient(90deg, #4A9D9B 0%, #442373 100%)",
                    padding: isMobile ? "24px 16px" : "42px 85px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: "24px",
                        margin: "0",
                        textAlign: "center",
                    }}
                >
                    <h1
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "8px",
                            color: "#FFFFFF",
                            margin: "0",
                            fontSize: "32px",
                        }}
                    >
                        Affordable & transparent pricing
                    </h1>
                    <p
                        style={{
                            color: "#FFFFFF",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            textAlign: "center",
                            margin: "0",
                        }}
                    >
                        Get a plan that fits your dealership’s needs.
                    </p>
                </div>

                <Row gutter={[16, 0]}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "8px",
                        }}
                        className="custom-landing-card"
                    >
                        <Card
                            title={
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: "700",
                                            lineHeight: "100%",
                                        }}
                                    >
                                        Basic
                                    </div>
                                    <div
                                        style={{
                                            fontSize: "24px",
                                            fontWeight: "700",
                                            lineHeight: "100%",
                                        }}
                                    >
                                        RM 2,000
                                    </div>
                                </div>
                            }
                            variant="borderless"
                            style={{
                                width: "100%",
                            }}
                        >
                            <div style={{ textAlign: "left" }}>
                                <h4>What’s included</h4>
                                <p>
                                    <Icon
                                        component={accountIcon}
                                        style={{ marginRight: "9px" }}
                                    />
                                    Account registration
                                </p>
                                <p>
                                    <Icon
                                        component={walletIcon}
                                        style={{ marginRight: "9px" }}
                                    />
                                    Prepaid account credit with RM 1,730
                                </p>
                            </div>
                            <div style={{ textAlign: "left" }}>
                                <h4 style={{ paddingTop: "16px" }}>
                                    Not included
                                </h4>
                                <p>
                                    <Icon
                                        component={biometricIcon}
                                        style={{ marginRight: "9px" }}
                                    />
                                    Biometric device. View&nbsp;
                                    <a
                                        onClick={() =>
                                            setShowSupportedDevice(
                                                !showSupportedDevice
                                            )
                                        }
                                    >
                                        supported devices.
                                    </a>
                                </p>
                                <a onClick={() => setShowDetail(!showDetail)}>
                                    {showDetail ? "Hide" : "View"} price
                                    breakdown
                                </a>
                            </div>

                            {showDetail && (
                                <div
                                    style={{
                                        marginTop: "16px",
                                        border: "1px solid #CCCCCC",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: "bold",
                                            padding: "8px 16px 8px 16px",
                                            marginbottom: "6px",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            lineHeight: "100% ",
                                            color: "#757575",
                                        }}
                                    >
                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            Items
                                        </span>
                                        <span>Amount (RM)</span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "12px 16px 12px 16px",
                                            background: "#FAFAFA",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            lineHeight: "100% ",
                                            color: "#333333",
                                        }}
                                    >
                                        <span>
                                            Prepaid account credit RM 1,730
                                        </span>
                                        <span
                                            style={{
                                                flexGrow: 1,
                                                borderBottom: "1px dashed #ddd",
                                                margin: "0 8px",
                                            }}
                                        ></span>
                                        <span>1,730.00</span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "12px 16px 12px 16px",
                                            background: "#FAFAFA",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            lineHeight: "100% ",
                                            color: "#333333",
                                        }}
                                    >
                                        <span>Processing fee</span>
                                        <span
                                            style={{
                                                flexGrow: 1,
                                                borderBottom: "1px dashed #ddd",
                                                margin: "0 8px",
                                            }}
                                        ></span>
                                        <span>250.00</span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "12px 16px 12px 16px",
                                            background: "#FAFAFA",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            lineHeight: "100% ",
                                            color: "#333333",
                                        }}
                                    >
                                        <span>
                                            SST @ 8% on biometric device and fee
                                        </span>
                                        <span
                                            style={{
                                                flexGrow: 1,
                                                borderBottom: "1px dashed #ddd",
                                                margin: "0 8px",
                                            }}
                                        ></span>
                                        <span>20.00</span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: "bold",
                                            padding: "18px 16px 12px 16px",
                                            background: "#EFEFEF",
                                            fontWeight: "400",
                                            fontSize: "20px",
                                            lineHeight: "100% ",
                                            color: "#333333",
                                            borderBottomRightRadius: "8px",
                                            borderBottomLeftRadius: "8px",
                                        }}
                                    >
                                        <span>TOTAL</span>
                                        <span
                                            style={{
                                                fontWeight: "700",
                                            }}
                                        >
                                            RM 2,000.00
                                        </span>
                                    </div>
                                </div>
                            )}
                        </Card>
                    </Col>

                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            paddingLeft: "8px",
                        }}
                        className="custom-landing-card"
                    >
                        <Card
                            title={
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: "700",
                                            lineHeight: "100%",
                                            maxWidth: "70%",
                                            overflow: "visible",
                                            textOverflow: "clip",
                                            whiteSpace: "normal",
                                            textAlign: "left",
                                        }}
                                    >
                                        Plus with biometric device
                                    </div>
                                    <div
                                        style={{
                                            fontSize: "24px",
                                            fontWeight: "700",
                                            lineHeight: "100%",
                                        }}
                                    >
                                        RM 2,000
                                    </div>
                                </div>
                            }
                            variant="borderless"
                            style={{
                                width: "100%",
                            }}
                        >
                            <div style={{ textAlign: "left" }}>
                                <h4>What’s included</h4>
                                <p>
                                    <Icon
                                        component={accountIcon}
                                        style={{ marginRight: "9px" }}
                                    />
                                    Account registration
                                </p>
                                <p>
                                    <Icon
                                        component={walletIcon}
                                        style={{ marginRight: "9px" }}
                                    />
                                    Prepaid account credit with RM 867
                                </p>
                                <p>
                                    <Icon
                                        component={biometricIcon}
                                        style={{ marginRight: "9px" }}
                                    />
                                    Dermalog biometric device
                                </p>
                                <a onClick={() => setShowDetail(!showDetail)}>
                                    {showDetail ? "Hide" : "View"} price
                                    breakdown
                                </a>
                            </div>

                            {showDetail && (
                                <div
                                    style={{
                                        marginTop: "16px",
                                        border: "1px solid #CCCCCC",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: "bold",
                                            padding: "8px 16px 8px 16px",
                                            marginbottom: "6px",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            lineHeight: "100% ",
                                            color: "#757575",
                                        }}
                                    >
                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            Items
                                        </span>
                                        <span>Amount (RM)</span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "12px 16px 12px 16px",
                                            background: "#FAFAFA",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            lineHeight: "100% ",
                                            color: "#333333",
                                        }}
                                    >
                                        <span>
                                            Prepaid account credit RM 867.08
                                        </span>
                                        <span
                                            style={{
                                                flexGrow: 1,
                                                borderBottom: "1px dashed #ddd",
                                                margin: "0 8px",
                                            }}
                                        ></span>
                                        <span>867.08</span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "12px 16px 12px 16px",
                                            background: "#FAFAFA",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            lineHeight: "100% ",
                                            color: "#333333",
                                        }}
                                    >
                                        <span>Processing fee</span>
                                        <span
                                            style={{
                                                flexGrow: 1,
                                                borderBottom: "1px dashed #ddd",
                                                margin: "0 8px",
                                            }}
                                        ></span>
                                        <span>250.00</span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "12px 16px 12px 16px",
                                            background: "#FAFAFA",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            lineHeight: "100% ",
                                            color: "#333333",
                                        }}
                                    >
                                        <span>Dermalog biometric device</span>
                                        <span
                                            style={{
                                                flexGrow: 1,
                                                borderBottom: "1px dashed #ddd",
                                                margin: "0 8px",
                                            }}
                                        ></span>
                                        <span>799.00</span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            padding: "12px 16px 12px 16px",
                                            background: "#FAFAFA",
                                            fontWeight: "400",
                                            fontSize: "18px",
                                            lineHeight: "100% ",
                                            color: "#333333",
                                        }}
                                    >
                                        <span>
                                            SST @ 8% on biometric device and fee
                                        </span>
                                        <span
                                            style={{
                                                flexGrow: 1,
                                                borderBottom: "1px dashed #ddd",
                                                margin: "0 8px",
                                            }}
                                        ></span>
                                        <span>83.92</span>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontWeight: "bold",
                                            padding: "18px 16px 12px 16px",
                                            background: "#EFEFEF",
                                            fontWeight: "400",
                                            fontSize: "20px",
                                            lineHeight: "100% ",
                                            color: "#333333",
                                            borderBottomRightRadius: "8px",
                                            borderBottomLeftRadius: "8px",
                                        }}
                                    >
                                        <span>TOTAL</span>
                                        <span
                                            style={{
                                                fontWeight: "700",
                                            }}
                                        >
                                            RM 2,000.00
                                        </span>
                                    </div>
                                </div>
                            )}
                        </Card>
                    </Col>
                </Row>

                <Modal
                    title={
                        <p
                            style={{
                                fontWeight: 600,
                                fontSize: "20.25px",
                                lineHeight: "32px",
                                letterSpacing: "0%",
                                margin: 0,
                            }}
                        >
                            Supported biometric devices
                        </p>
                    }
                    open={showSupportedDevice}
                    onCancel={() => setShowSupportedDevice(false)}
                    centered
                    footer={null}
                >
                    <div className="custom-landing-modal">
                        <ul style={{ listStyleType: "disc" }}>
                            <li>Dermalog</li>
                            <li>Morpho</li>
                            <li>Sagem</li>
                            <li>Idemia</li>
                        </ul>

                        <p>
                            These devices are supported on both{" "}
                            <span>Android</span> and <span>desktop</span>.
                        </p>
                    </div>
                </Modal>
            </div>

            {/* Register with TwoCar */}
            {/* <div
                id="footer"
                style={{
                    background: "#F1F1F1",
                    padding: isMobile ? "24px 16px" : "42px 85px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: "24px",
                        margin: "0",
                    }}
                >
                    <h2
                        style={{
                            color: "#333333",

                            fontSize: "32px",
                            fontWeight: "700",
                            textAlign: "center",
                            margin: "0",
                        }}
                    >
                        Register with Twocar
                    </h2>
                    <p
                        style={{
                            color: "#333333",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "24px",
                            textAlign: "center",
                        }}
                    >
                        To get started, please fill in the form and submit. Our
                        team will provide the next steps via email.
                    </p>
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    className="custom-register-card"
                >
                    <Card>
                        <div style={{ textAlign: "left" }}>
                            <h3 style={{ color: "#333333" }}>
                                Owner/director’s details
                            </h3>
                            <p>
                                Fields marked
                                <span style={{ color: "#E31616" }}>
                                    &nbsp; * &nbsp;
                                </span>
                                are required.
                            </p>
                        </div>
                        <div
                            style={{
                                paddingTop: "16px",
                            }}
                        >
                            <Form
                                form={form}
                                name="registration-form"
                                style={{}}
                                onFinish={() => {}}
                            >
                                <Row vgutters={16}>
                                    <Col
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 12 }}
                                    >
                                        <Form.Item
                                            layout="vertical"
                                            name="fullName"
                                            label="Full name (as per MyKad)"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Input something!",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 12 }}
                                    >
                                        <Form.Item
                                            layout="vertical"
                                            name="mykadNo"
                                            label="MyKad number"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Input something!",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 12 }}
                                    >
                                        <Form.Item
                                            layout="vertical"
                                            name="emailAdress"
                                            label="Email address"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Input something!",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={{ span: 24 }}
                                        sm={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 12 }}
                                    >
                                        <Form.Item
                                            layout="vertical"
                                            name="mobileNo"
                                            label="Mobile number"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Input something!",
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Card>
                </div>
            </div> */}
        </>
    );
}
