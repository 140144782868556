import { LoadingOutlined, PlusOutlined, DownOutlined, RightOutlined, LeftOutlined, EyeInvisibleOutlined, EyeOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Alert, message, Row, Col, Space, Card, Image, Tooltip, Button, Divider, DatePicker } from "antd";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { SessionContext } from "../../App";
import i2i_image from "../../assets/icon-i2i.svg";
import in_image from "../../assets/icon-in.svg";
import out_image from "../../assets/icon-out.svg";
import reporting_image from "../../assets/icon-report.svg";
import user_management from "../../assets/icon-user-management.svg";
import branch_management from "../../assets/icon-branch-management.svg";
import inter_branch_management from "../../assets/inter-branch transfer.svg";
import user_guide from "../../assets/icon-user-guide.svg";
import renew_road_tax from "../../assets/illus-jpj-renew-road-tax-button@2x.png";
import renew_insurance from "../../assets/insurance.png";
// import vehicle_transfer from "../../assets/illus-vehicle-transfer.png";
import info_icon from "../../assets/icon/Icon-info-24.svg";
import { OTHER_TRANSACTIONS_LINK, WALLET_MINIMUM_BALANCE, CARSOME_COMPANY_ID } from "../../constants";
import "./index.less";
import { getUserRole, getAuthorizedNric, setAnnouncementShown, getAnnouncementShown, getPermissions } from "../../services/local";
import {
	// getMysikapLink,
	getAnnouncement,
	// getCmsNotification,
	getMonthlyCarTransactions
} from "../../services/api";
import { config } from "../../config";
import { LoadingSpinner } from "../../components/component/UI/Custom"
// import Icon from '@ant-design/icons';
import dayjs from "dayjs";
import AnnouncementModalComponent from "../../components/component/Layout/AnnouncementModalComponent";

export default function DashboardPage() {

	const { prepaid, branch, company } = useContext(SessionContext);
	const history = useHistory();

	const [isPrepaidLoading, setIsPrepaidLoading] = useState(false);
	const [isBranchUserLoading, setIsBranchUserLoading] = useState(false);
	const [role, setRole] = useState(null);
	const [permissions, setPermissions] = useState([]);
	// const [mySikapLink, setMySikapLink] = useState("");
	const [isLowBalance, setisLowBalance] = useState(false);
	const [announcementAlert, setAnnouncementAlert] = useState([]);
	const [announcementTooltip, setAnnouncementTooltip] = useState([]);
	const [showTransactioncard, setShowTransactioncard] = useState(false);
	const [viewTransactionsBannerDetails, setViewTransactionsBannerDetails] = useState(false);
	const [monthlyCarTransactions, setMonthlyCarTransactions] = useState({});
	const [selectedTransactionMonthYear, setSelectedTransactionMonthYear] = useState(dayjs());
	const [openDatePicker, setOpenDatePicker] = useState(false);

	useEffect(async () => {

		if (company.id) {

			await getUserRole().then(async (role) => {
				setRole(role);

				if (CARSOME_COMPANY_ID[process.env.REACT_APP_ENVIRONMENT].includes(company?.id) && (role === 'GENERAL_MANAGER' || role === 'OWNER')) {
					try {
						const monthlyCarTransactionsResponse = await getMonthlyCarTransactions(company?.id);

						if (monthlyCarTransactionsResponse && monthlyCarTransactionsResponse?.status == '00') {
							setMonthlyCarTransactions(monthlyCarTransactionsResponse);
							setShowTransactioncard(true);
						}

					} catch (error) {
						message.error(error?.message?.toString());
					}
				}

			});
			await getPermissions().then(async (permissions) => {
				setPermissions(permissions);
			});
			{/* commented to not show mysikap label */}
			/* await getMysikapLink(company.id).then(async (link) => {
				setMySikapLink(link.url);
			}); */

			//As of 5/2 only carsome company id and level 5 and 6 will show this component


		}
	}, [company]);

	const currentTxnData = useMemo(() => {
		if (monthlyCarTransactions && monthlyCarTransactions?.data) {
			return monthlyCarTransactions?.data?.find((txn) => txn?.monthYear === dayjs(selectedTransactionMonthYear)?.format('YYYY-MM'));
		}
		return {}
	})

	const isMobile = useMemo(() => {
        const screenWidth = window.innerWidth;
        return screenWidth < 768; // Mobile common screen size >> can adjust if needed
    }, [window.innerWidth]);

	useEffect(() => {
		if (prepaid) {
			if (prepaid.balanceAmount < WALLET_MINIMUM_BALANCE) {
				setisLowBalance(true)
			}
		}
	}, [prepaid])

	function getBanner() {
		/**prepaid banner */
		if (isLowBalance) {
			return (
				<Alert
					closable
					onClose={() => setisLowBalance(false)}
					showIcon={false}
					className={"warning-alert"}
					message={
						<p style={{ color: "white" }}>
							Your balance is running low. Kindly top up your prepaid account balance to avoid payment
							interruption. &nbsp;
							<a
								href={config.reloadUrl}
								target={"_blank"}
								rel="noreferrer"
								style={{
									color: "white",
									textDecoration: "underline",
								}}
							>
								Reload account
							</a>
						</p>
					}
					banner
				/>
			);
		}
	}

	useEffect(() => {
		getAnnouncement().then((res) => {
			res.map(item => {
				if(item.componentType === "MODAL") {
					// setAnnouncementModal(item)
				}
				else if(item.componentType === "ALERT") {
					setAnnouncementAlert(item)
				}
				else if(item.componentType === "TOOLTIP") {
					setAnnouncementTooltip(item)
				}
			})
		}).catch((err) => {
			err && message.error(err?.toString())
		})
	}, []);

	const carsomeTransactionsBanner = () =>  {
		return (
			<div style={{
				height: "100%",
				padding: "0px 80px",
				display: "flex",
				justifyContent: "space-between",
			}}>
				<Card
					style={{
						marginTop: viewTransactionsBannerDetails ? "-4.5em" : "-1.5em",
						width: "100%",
						borderRadius: "8px",
						transition: "all 0.8s",
					}}
					className="custom-dashboard-card"
				>
					{
						!viewTransactionsBannerDetails ? (
							<Row  style={{ height: '100%' }}>
								<Col span={24}>
									<div style={{ textAlign: 'left', display: 'flex', flexDirection: 'row', height: '100%', alignItems: 'center', fontSize: '16px', fontWeight: '600', lineHeight: '24px'}}>

										{currentTxnData?.totalAllCarTxn}&nbsp;<span style={{fontWeight: '400'}}> transactions  in </span>&nbsp;{dayjs(selectedTransactionMonthYear).format('MMM YYYY')}

										<div onClick={() => { setViewTransactionsBannerDetails(true) }} style={{ fontSize: '14px', fontWeight: '700', lineHeight: '17.64px', cursor: 'pointer', color: '#2B61C4', display: 'flex', gap: '8px'}}><EyeOutlined style={{ color: '#0E69DC', fontSize : '16.71px', cursor: 'pointer', paddingLeft: '16px'}}/> VIEW DETAILS</div>
									</div>
								</Col>
							</Row>
						) : (
							<Row  style={{ height: '100%', paddingTop: '8px', paddingBottom: '8px' }}>
								<Col span={18}>
									<div style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "space-between",
										gap: '10px',
										height: "100%",
									}}>
										<div style={{
											textAlign: "left",
											fontWeight: "400",
											fontSize: "16px",
											lineHeight: "24px",
										}}>
											<span style={{
												fontSize: "24px",
												fontWeight: "700",
												lineHeight: "30.24px",
											}}>{ currentTxnData?.totalAllCarTxn }</span> transactions in <DatePicker
												open={openDatePicker}
												onOpenChange={(open) => setOpenDatePicker(open)}
												placeholder=""
												picker="month"
												variant="borderless"
												size="small"
												minDate={dayjs(monthlyCarTransactions?.to)}
												maxDate={dayjs(monthlyCarTransactions?.from)}
												suffixIcon={<DownOutlined style={{ color: '#2B61C4', height: '28px', width: '28px', left: '-10px', position: 'relative'}}/>}
												superNextIcon={<RightOutlined style={{ color: '#333333'}}/>}
												superPrevIcon={<LeftOutlined style={{ color: '#333333' }}/>}
												allowClear={false}
												defaultValue={dayjs()}
												format="MMMM YYYY"
												value={selectedTransactionMonthYear}
												onChange={(date) => setSelectedTransactionMonthYear(date)}
												className="custom-date-picker"
												renderExtraFooter={(mode) => (
													<div onClick={() => {

														setSelectedTransactionMonthYear(dayjs())

														setTimeout(() => {
															setOpenDatePicker(false)
														}, 200);

													}} style={{ color: '#2B61C4', fontSize: '14px', fontWeight: '700', cursor: 'pointer' }}>This {mode?.charAt(0).toUpperCase() + mode?.slice(1)}</div>
												)}
											/>
										</div>

										<div style={{
											flexGrow: 1,
											textAlign: "left",
										}}>
											<Row>
												{
													currentTxnData?.data2?.map((item, index) => (
														<Col span={8} key={index}>
															<span style={{ display: 'flex', height: '90%' }}>
																{index != 0 && <Divider type="vertical" style={{ height: '100%', paddingRight: '24px' }} />}
																<div>
																	<div
																		style={{
																			width: '100%',
																			fontSize: "16px",
																			fontWeight: "400",
																			lineHeight: "24px",
																		}}
																	>
																		{item?.companyName}
																	</div>
																	<div
																		style={{
																			fontSize: "20px",
																			fontWeight: "700",
																			lineHeight: "25.2px",
																		}}
																	>
																		{item?.totalCompCarTxn}
																	</div>
																</div>
															</span>
														</Col>
												))}

											</Row>
										</div>
									</div>
								</Col>
								<Col span={6}>
									<div style={{
										textAlign: "left",
										display: 'flex',
										flexDirection: 'row',
										height: '100%',
										alignItems: 'center'
									}}>
										<Divider type="vertical" style={{ height: '90%', paddingRight: '24px' }} />
										<div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'space-between'}}>
											<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
												<div><span style={{  fontWeight: '600'}}>Last 12 Month</span> transactions</div>

												<div>
													<div style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px'}}>{dayjs(monthlyCarTransactions?.to).format('MMM YYYY')} - {dayjs(monthlyCarTransactions?.from).format('MMM YYYY')}</div>
													<div style={{fontSize: '20px', fontWeight: '700', lineHeight: '25.2px' }}>{monthlyCarTransactions?.totalYearCarTxn}</div>
												</div>
											</div>
											<div onClick={() => { setViewTransactionsBannerDetails(false) }}><EyeInvisibleOutlined style={{ color: '#0E69DC', fontSize : '16.71px', cursor: 'pointer'}}/></div>
										</div>
									</div>
								</Col>
							</Row>
						)
					}
				</Card>
			</div>
		)
	}

	function getNotificationBanner() {
		/**prepaid banner */
		// if (isLowBalance) {
		/* return (
			<Alert
				// closable
				// onClose={() => setisLowBalance(false)}
				showIcon={false}
				className={"default-alert"}
				message={
					<p style={{ color: "black", marginLeft: '30px' }}>
						<b>
							All ownership transfer transactions must be completed with payment within a period of 7 days (including Saturdays and Sundays) - In accordance with the Transport Act.
							<br />
							<br />
							Semua transaksi tukar milik mesti diselesaikan dengan bayaran dalam tempoh 7 hari (termasuk hari Sabtu dan Ahad) - Mengikut Akta Pengangkutan.
						</b>
					</p>
				}
				banner
			/>
		); */
		// }
		return (
			<>
			{announcementAlert.length !== 0 && !announcementAlert.remove &&
				<Alert
					// closable
					// onClose={() => setisLowBalance(false)}
					showIcon={false}
					className={"default-alert"}
					message={<p dangerouslySetInnerHTML={{ __html: announcementAlert.message }}></p>}
					banner
				/>
			}
			{!getAnnouncementShown() &&
				<AnnouncementModalComponent/>
			}
			</>
		);
	}

	function loadingScreen() {
		if (!company.id) {
			return <LoadingSpinner />
		}
	}

	return (
		<>
			{getBanner()}
			<Row justify="space-between" style={{ padding: "32px 80px" }}>
				<Col className="dashboard-header">
					<h1>
						{company.coName}{" "}
						{/* <span
							style={{
							fontFamily:
								"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
							}}
						>
							{company.ucdCode ? "(" + company.ucdCode + ")" : ""}
						</span> */}
					</h1>
					<h2>{branch.branchName}</h2>
				</Col>
				{/* {role !== "VALUER" ? ( */}
				{permissions.viewBalance || permissions.topupBalance ? (
					<Col className="dashboard-header">
						<Space size={2}>
							{/* {process.env.REACT_APP_ENVIRONMENT == "uat" ? <></> : */}
							{permissions.viewBalance &&
							<div style={{
								color: "#FAFAFA",
								fontSize: "16px",
								lineHeight: "24px",
								textAlign: "right",
								flex: 2,
								paddingRight: "16px",
							}}>
								<h3>
									Remaining <span style={{
										fontWeight: 400,
										fontSize: "14px",
									}}>balance</span>
								</h3>

								<Tooltip
									title="Low balance"
									placement="bottomLeft"
									open={isLowBalance}
									color={"#FF3939"}
								>
									<p style={{ color: "white", margin: 0, flex: 2 }}>
										{" "}
										{isBranchUserLoading || isPrepaidLoading ? (
											<LoadingOutlined />
										) : prepaid?.balanceAmount >= 0 ? (
											<span style={{lineHeight: "30.24px", textAlign: "right"}}>
												<span style={{ fontSize: '16px', verticalAlign: "top" }}>RM</span>
												<span style={{ fontSize: '24px', fontWeight: 700, }}>{new Intl.NumberFormat("en-US", {
													style: "currency",
													currency: "MYR",
												}).format(prepaid.balanceAmount).replace('MYR', '')}</span>
											</span>
										) : (
											`unavailable`
										)}
									</p>
								</Tooltip>
							</div>}
							{/* } */}
							{permissions.topupBalance && <Button
								target={"_blank"}
								href={config.reloadUrl}
								id={"reload-button"}
								style={{
									flex: 2,
									marginLeft: 5,
									height: 45,
									paddingBottom: 5,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								icon={<PlusOutlined />}
							>
								Reload
							</Button>}
						</Space>
					</Col>
				) : null}
			</Row>

			<div style={{
				background: "#F1F1F1",
				width: "100vw",
				marginTop: viewTransactionsBannerDetails ? "4.5em" : "0"
			}}>

			{showTransactioncard && carsomeTransactionsBanner()}

			{getNotificationBanner()}
			{loadingScreen()}
				<Row
					style={{
						height: "100%",
						paddingTop: "32px",
						paddingLeft: isMobile ? "0px" : "100px",
						paddingRight:  isMobile ? "0px" : "100px",
						display: "flex",
						justifyContent: "space-between",
					}}
				>

					{
						// role && role === 'SALES_MANAGER' &&
						role && role === 'MYEG_ADMIN' &&
						<Row gutter={[50, 30]} style={{ marginRight: 25 }}>
							<Col>
								<Space direction="vertical">
									<h1 className="dashboard-column-header">Account Management</h1>
									<Card
										className="dashboard-card-style"
										style={{ width: isMobile ? "100vw" : "380px", height: '70px' }}
										onClick={() => history.push("/adminCompany")}
									>
										<Row align="space-between">
											<h1 style={{ padding: 20 }}>Manage Company Account</h1>
										</Row>
									</Card>
									<Card
										className="dashboard-card-style"
										style={{width: isMobile ? "100vw" : "380px", height: '70px' }}
										onClick={() => history.push("/adminSearchVehicleInfo")}
									>
										<Row align="space-between">
											<h1 style={{ padding: 20 }}>Search Vehicle Information</h1>
										</Row>
									</Card>
								</Space>
							</Col>
						</Row>
					}
					{
						// role && role !== 'SALES_MANAGER' &&
						role && role !== 'MYEG_ADMIN' &&
						<Row gutter={[50, 30]} style={{ marginRight: 25 }}>
							<Col>
								<Space direction="vertical">
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<h1 className="dashboard-column-header">Transfer Vehicle Ownership&nbsp;</h1>
										{announcementTooltip.length !== 0 && !announcementTooltip.remove &&
											<Tooltip overlayInnerStyle={{ color: 'black', marginBottom: "10px", width: "225%" }} color="white" placement="right"
												title={<div dangerouslySetInnerHTML={{ __html: announcementTooltip.message }} />}>
												<Image src={info_icon} preview={false}/>
											</Tooltip>
										}
									</div>
									{/* IN MODULE */}
									{
										// role !== 'MYEG_OWNER_RUNNER' && role !== 'MYEG_RUNNER_2' && role !== 'MYEG_RUNNER_1' &&
										permissions.inTransaction &&
										<Card
											className="dashboard-card-style"
											style={{ width: isMobile ? "100vw" : "380px" }}
											onClick={() => {
												if (getAuthorizedNric()) {
													history.push(
														"/transfer/in/create",
														{
															step: "OWNERSHIP_TYPE",
															subStep: "OWNERSHIP_TYPE",
														},
													);
												} else {
													history.push(
														"/verification/methods",
														{
															type: "Dashboard",
															thumbPrintRedirect:
																{
																	route: "/transfer/in/create",
																	params: {
																		step: "AUTHORIZE_DEALER",
																		subStep:
																			"D_INSERT_IC",
																	},
																	authorizedParams: {
																		step: "OWNERSHIP_TYPE",
																		subStep: "OWNERSHIP_TYPE",
																	}
																},
														}
													);
												}
											}}
										>
											<Row align="space-between">
												<Col style={{paddingTop:"16px"}}>
													<h1>Owner to Dealer</h1>
													<p style={{textAlign: "left", color: "white", paddingLeft: "16px"}}>(STMS)</p>
												</Col>
												<Image width={135} height={70} src={in_image} preview={false} />
											</Row>
										</Card>
									}

									{/* OUT MODULE */}
									{/* {role !== "SALES" && role !== "VALUER" && role !== "ADMIN" && role !== 'MYEG_OWNER_RUNNER' && role !== 'MYEG_RUNNER_2' && role !== 'MYEG_RUNNER_1' ? ( */}
									{
									// role !== "VALUER" && role !== 'MYEG_OWNER_RUNNER' && role !== 'MYEG_RUNNER_2' && role !== 'MYEG_RUNNER_1' &&
									(permissions.outTransaction || permissions.m2mTransaction) &&
									(
										<Card
											// hoverable={false}
											// className="dashboard-card-style-disable"
											className="dashboard-card-style"
											style={{ width: isMobile ? "100vw" : "380px" }}
											onClick={() => {
												if (getAuthorizedNric()) {
                                                    history.push(
														"/transfer/out/create",
														{
															step: "TRANSACTION_TYPE",
															subStep: "TRANSACTION_TYPE",
														},
													);
												} else {
                                                    history.push(
                                                        "/verification/methods",
                                                        {
                                                            type: "Dashboard",
                                                            thumbPrintRedirect: {
                                                                route: "/transfer/out/create",
                                                                params: {
                                                                    step: 'AUTHORIZE_DEALER',
                                                                    subStep: 'D_INSERT_IC',
																authorizedParams: {
																	step: "TRANSACTION_TYPE",
																	subStep: "TRANSACTION_TYPE",
																},                                                            }
                                                            }
                                                        }
                                                    );
												}
											}}
										>
											<Row align="space-between">
												<Col style={{paddingTop:"16px"}}>
													<h1 style={{textAlign: "left", color: "white", paddingLeft: "16px"}}>Dealer to Buyer</h1>
													<p style={{textAlign: "left", color: "white", paddingLeft: "16px"}}>
														(e-Serahan, Interstate)
													</p>
												</Col>
												<Image width={135} height={70} src={out_image} preview={false} />
											</Row>
										</Card>
									)}

									{/* I2I MODULE */}
									{
									// role !== "VALUER" && 
									permissions.i2iTransaction &&
									(
										<Card
											className="dashboard-card-style"
											style={{ width: isMobile ? "100vw" : "380px" }}
											onClick={() => {
												if (getAuthorizedNric()) {
                                                    history.push(
														"/transfer/i2i/create",
														{
															step: "TRANSACTION_TYPE",
															subStep: "TRANSACTION_TYPE",
														},
													);
												} else {
                                                    history.push(
                                                        "/verification/methods",
                                                        {
                                                            type: "Dashboard",
                                                            thumbPrintRedirect: {
                                                                route: "/transfer/i2i/create",
                                                                params: {
                                                                    step: 'AUTHORIZE_DEALER',
                                                                    subStep: 'D_INSERT_IC',
																authorizedParams: {
																	step: "TRANSACTION_TYPE",
																	subStep: "TRANSACTION_TYPE",
																}                                                       }
                                                            }
                                                        }
                                                    );
												}
											}}
										>
											<Row align="space-between">
												<Col style={{paddingTop:"16px"}}>
													<h1>Owner to Buyer</h1>
													<p style={{textAlign: "left", color: "white", paddingLeft: "16px"}}>
														(STMSR)
													</p>
												</Col>
												<Image width={135} height={70} src={i2i_image} preview={false} />
											</Row>
										</Card>
									)}
								</Space>
							</Col>
							<Col>
								<Space direction="vertical">
									<h1 className="dashboard-column-header">Other Transactions</h1>
									{/* commented to not show mysikap label */}
									{/* {mySikapLink ? (
										<Card
											className="dashboard-card-style"
											style={{ width: "300px" }}
											onClick={() => {
												window.open(mySikapLink, "_blank");
											}}
										>
											<Row align="space-between">
												<div style={{ alignSelf: "center", textAlign: "justify" }}>
													<h2>mySikap</h2>
													<h1>Permanent</h1>
													<h1>Vehicle Transfer</h1>
												</div>
												<Image width={150} height={70} src={vehicle_transfer} preview={false} />
											</Row>
										</Card>
									) : null} */}
									<Card
										className="dashboard-card-style"
										style={{ width: "300px" }}
										onClick={() => window.open(OTHER_TRANSACTIONS_LINK.INSURANCE, "_blank")}
									>
										<Row align="space-between">
											<div style={{ alignSelf: "center", textAlign: "justify" }}>
												<h2>Renew</h2>
												<h1>Insurance</h1>
											</div>
											<Image width={150} height={70} src={renew_insurance} preview={false} />
										</Row>
									</Card>
									<Card
										className="dashboard-card-style"
										style={{ width: "300px" }}
										onClick={() => window.open(OTHER_TRANSACTIONS_LINK.ROADTAX, "_blank")}
									>
										<Row align="space-between">
											<div style={{ alignSelf: "center", textAlign: "justify" }}>
												<h2>Renew</h2>
												<h1>Road Tax</h1>
											</div>
											<Image width={150} height={70} src={renew_road_tax} preview={false} />
										</Row>
									</Card>
									<Card
										className="dashboard-card-style"
										style={{ width: "300px" }}
										onClick={() => history.push("/renewroadtax/list")} // Navigate to the route
									>
										<Row align="space-between">
											<div style={{ alignSelf: "center", textAlign: "justify" }}>
												<h2>Renew</h2>
												<h1>Road Tax Test</h1>
											</div>
											<Image width={150} height={70} src={renew_road_tax} preview={false} />
										</Row>
									</Card>
								</Space>
							</Col>
						</Row>

					}

					{
						// role && role !== 'SALES_MANAGER' &&
						role && role !== 'MYEG_ADMIN' &&
						<Col style={{ paddingLeft: 0 }} span={6}>
							<Space direction="vertical" className="dashboard-system-service">
								<h1 className="dashboard-column-header">System Services</h1>
								{
								(permissions.paymentReport || permissions.interBranchReport || permissions.auditReport || permissions.vehicleMovementReport ||
									permissions.transactionReport || permissions.inventoryReport || permissions.aptReport) &&
								<Space onClick={() => history.push("/reporting")} style={{ cursor: "pointer" }}>
									<Image src={reporting_image} width={40} preview={false} />
									<h3>Reporting</h3>
								</Space>}
								{
								(permissions.addNewUser || permissions.editUserDetails) &&
								// role !== "ADMIN" && role !== "VALUER" && role !== "SALES" && role !== 'MYEG_RUNNER_1' && role !== 'MYEG_RUNNER_2' &&
								(
									<Space onClick={() => history.push("/usermanagement")} style={{ cursor: "pointer" }}>
										<Image src={user_management} width={40} preview={false} />
										<h3>User Management</h3>
									</Space>
								)}

								{
								(permissions.addBranch || permissions.editBranch) &&
								// role == "OWNER" || role == "GENERAL_MANAGER" && 
								(
									<Space onClick={() => history.push("/branchmanagement")} style={{ cursor: "pointer" }}>
										<Image src={branch_management} width={40} preview={false} />
										<h3>Branch Management</h3>
									</Space>
								)}

								{
								permissions.interBranchTransfer &&
								// role !== "SALES" && role !== "VALUER" && role !== 'MYEG_OWNER_RUNNER' && role !== 'MYEG_RUNNER_2' && role !== 'MYEG_RUNNER_1' && 
								(
									<Space
										onClick={() => history.push("/interbranchtransfer")}
										style={{ cursor: "pointer" }}
									>
										<Image src={inter_branch_management} width={40} preview={false} />
										<h3>Inter-Branch Transfer</h3>
									</Space>
								)}
								<Space onClick={() => history.push("/userguide")} style={{ cursor: "pointer" }}>
									<Image src={user_guide} width={40} preview={false} />
									<h3>User Guide</h3>
								</Space>
								<Space onClick={() => history.push("/download")} style={{ cursor: "pointer" }}>
									<Image src={reporting_image} width={40} preview={false} />
									<h3>Download</h3>
								</Space>
							</Space>
						</Col>
					}

					{
						// role && role === 'SALES_MANAGER' &&
						role && role === 'MYEG_ADMIN' &&
						<Col style={{ paddingLeft: 0 }} span={6}>
							<Space direction="vertical" className="dashboard-system-service">
								<h1 className="dashboard-column-header">System Services</h1>
								{/* <Space onClick={() => history.push("/reporting")} style={{ cursor: "pointer" }}>
									<Image src={reporting_image} width={40} preview={false} />
									<h3>Reporting</h3>
								</Space> */}
								<Space onClick={() => history.push("/userguide")} style={{ cursor: "pointer" }}>
									<Image src={user_guide} width={40} preview={false} />
									<h3>User Guide</h3>
								</Space>
								<Space onClick={() => history.push("/download")} style={{ cursor: "pointer" }}>
									<Image src={reporting_image} width={40} preview={false} />
									<h3>Download</h3>
								</Space>
							</Space>
						</Col>
					}
				</Row>
			</div>
		</>
	);
}
