import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import DashboardPage from "../../pages/DashboardPage";
import ProfilePage from "../../pages/ProfilePage";
import EditProfilePage from "../../pages/EditProfilePage";
import NotFoundPage from "../../pages/NotFoundPage";
import ForgotPasswordPage from "../../pages/ForgotPasswordPage";
import CreateInTransferPage from "../../pages/CreateInTransferPage/index.tsx";
import CreateOutTransferPage from "../../pages/CreateOutTransferPage";
import CreateI2ITransferPage from "../../pages/CreateI2ITransferPage";
import BranchUsersPage from "../../pages/BranchUsersPage";
import BranchUserFormPage from "../../pages/BranchUserFormPage";
import BranchPage from "../../pages/BranchPage";
import BranchFormPage from "../../pages/BranchFormPage";
import ReportingPage from "../../pages/ReportingPage";
import EditCompanyPage from "../../pages/EditCompanyPage";
import Setup2fa from "../../pages/Setup2FAPage";
import SetupOtp from "../../pages/SetupOtpPage";
import TransferReportPage from "../../pages/ReportingPage/TransferReportPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage";
import ActivateAccountPage from "../../pages/ActivateAccountPage";
import Member2MemberPage from "../../pages/Member2MemberPage";
import Member2MemberPageV2 from "../../pages/Member2MemberPageV2";
import SelectBranchPage from "../../pages/SelectBranchPage/index.tsx";
import PostLoginPage from "../../pages/PostLoginPage";
import AuditTrailLogPage from "../../pages/ReportingPage/AuditTrailLog";
import APTReportPage from "../../pages/ReportingPage/APTReport";
import InventoryListingPage from "../../pages/ReportingPage/InventoryListingPage";
import VehicleMovementReportPage from "../../pages/ReportingPage/VehicleMovementReport";
import PaymentReportPage from "../../pages/ReportingPage/PaymentReport";
import InterBranchTransferPage from "../../pages/InterBranchTransferPage";
import BranchVehiclesPage from "../../pages/InterBranchTransferPage/BranchVehicles";
import RemoteUserActivationPage from "../../pages/RemoteUserActivationPage";
import AdminCompanyPage from "../../pages/AdminCompanyPage";
import AdminCompanyFormPage from "../../pages/AdminCompanyFormPage";
import AdminSearchVehicleInfoPage from "../../pages/AdminSearchVehicleInfoPage";
import DownloadForms from "../../pages/DownloadForms";
import UserForms from "../../pages/UserForms";
import TransferReportBulkPage from "../../pages/ReportingPage/TransferReportBulkPage";
import TransferReportBulkPageV2 from "../../pages/ReportingPage/TransferReportBulkPageV2";
import CreateOutTransferPageV2 from "../../pages/CreateOutTransferPageV2";
import ContactUsPage from "../../pages/ContactUsPage";
import PendingListPage from "../../pages/ReportingPage/PendingListReport";
import VerificationMethodsPage from "../../pages/VerificationMethodsPage";
import MydIdPage from "../../pages/MydIdPage";
import RoadTaxTransactionPage from "../../pages/ReportingPage/RoadTaxTransactionPage";
import RenewRoadTaxPage from "../../pages/RenewRoadTaxPage/RenewRoadTaxList"
import RenewRoadTaxForm from "../../pages/RenewRoadTaxPage/RenewRoadTaxForm"

export default function Routes() {
    return (
        <Switch>
            {/* <Route
                exact
                path="/transfer/m2m"
                component={Member2MemberPage}
            /> */}

            <Route
                exact
                // path="/transfer/m2mV2"
                path="/transfer/m2m"
                // component={Member2MemberPage}
                component={Member2MemberPageV2} //Change accordingly for release
            />

            <Route exact path="/postlogin" component={PostLoginPage} />

            <Route exact path="/selectbranch" component={SelectBranchPage} />

            <Route
                exact
                path="/transfer/in/:mode?/:transferId?" //mode = create | edit | payment | printslip
                component={CreateInTransferPage}
            />

            <Route
                exact
                // component={CreateOutTransferPage}
                component={CreateOutTransferPageV2}
                path="/transfer/out/:mode?/:transferId?" //mode = create | edit | payment | printslip
            />

            <Route
                exact
                component={CreateI2ITransferPage}
                path="/transfer/i2i/:mode?/:transferId?" //mode = create | continue | edit | payment
            />

            <Route
                exact
                path="/branchmanagement/create"
                component={BranchFormPage}
            />
            <Route
                exact
                path="/branchmanagement/edit/:branchId?"
                component={BranchFormPage}
            />

            <Route
                exact
                path="/usermanagement/create"
                component={BranchUserFormPage}
            />

            <Route
                exact
                path="/usermanagement/edit"
                component={BranchUserFormPage}
            />

            <Route exact path="/download" component={DownloadForms} />

            <Route exact path="/userguide" component={UserForms} />

            <Route exact path="/profile/edit" component={EditProfilePage} />

            <Route exact path="/profile" component={EditProfilePage} />
            {/* example token
            vj661zeWU8ok7cJBm6NgFj/iWe+AGK+1SEGMDLQzNuf1C/3KwTYT8a9OPHDdE/AMjGdnfwo/0ds9//PYwQGSyw==
            
            dev parameter is to force token to be valid. Should only be used locally
            */}
            <Route
                exact
                path="/forgot-password/:token/:dev?"
                component={ResetPasswordPage}
            />
            <Route
                exact
                path="/activate-account/:token/:dev?"
                component={ActivateAccountPage}
            />
            <Route exact path="/usermanagement" component={BranchUsersPage} />
            <Route exact path="/branchmanagement" component={BranchPage} />

            <Route exact path="/reporting" component={ReportingPage} />
            {/* <Route
                exact
                path="/reporting/transferreport/:pendingType/:isbulk?"
                component={TransferReportBulkPage}
            /> */}
            {/* <Route
                exact
                path="/reporting/transferreport/m2m/bulk"
                component={TransferReportBulkPage}
            /> */}
            <Route
                exact
                path="/reporting/transferreport/:pendingType/bulk"
                component={TransferReportBulkPage}
            />

            <Route
                exact
                path="/reporting/transferreport"
                component={TransferReportPage}
            />

            {/* <Route
                exact
                path="/reporting/transferreport/In"
                // component={TransferReportPage}
                component={TransferReportBulkPageV2}
            />
            <Route
                exact //RELEASE_CHANGE
                path="/reporting/transferreport/Out"
                // component={TransferReportPage}
                component={TransferReportBulkPageV2}
            />
            <Route
                exact //RELEASE_CHANGE
                path="/reporting/transferreport/i2i"
                // component={TransferReportPage}
                component={TransferReportBulkPageV2}
            /> */}
            <Route
                exact
                path="/reporting/transferreport/:pendingType/:tab"
                // component={TransferReportPage}
                component={TransferReportBulkPageV2}
            />
            <Route
                exact
                path="/reporting/transferreport/:pendingType"
                // component={TransferReportPage}
                component={TransferReportBulkPageV2}
            />

            {/* <Route
                exact
                path="/reporting/transferreport/:pendingType/:isbulk?"
                component={TransferReportBulkPage}
            /> */}
            <Route
                exact
                path="/reporting/audittraillog"
                component={AuditTrailLogPage}
            />
            <Route
                exact
                path="/reporting/aptreport"
                component={APTReportPage}
            />
            <Route
                exact
                path="/reporting/pendinglist"
                component={PendingListPage}
            />
            <Route
                exact
                path="/reporting/audittraillog/:serviceId/:transferId"
                component={AuditTrailLogPage}
            />
            <Route
                exact
                path="/reporting/inventorylisting"
                component={InventoryListingPage}
            />
            <Route
                exact
                path="/reporting/vehiclemovementreport"
                component={VehicleMovementReportPage}
            />
            <Route
                exact
                path="/reporting/paymentreport"
                component={PaymentReportPage}
            />
            <Route
                exact
                path="/reporting/roadTaxTransactions"
                component={RoadTaxTransactionPage}
            />
            <Route exact path="/company" component={EditCompanyPage} />
            <Route exact path="/2fa/:step?" component={Setup2fa} />
            <Route exact path="/otp/:step?" component={SetupOtp} />
            <Route
                exact
                path="/interbranchtransfer/:companyId/:branchId"
                component={BranchVehiclesPage}
            />
            <Route
                exact
                path="/interbranchtransfer"
                component={InterBranchTransferPage}
            />
            <Route
                exact
                path="/remoteUserActivation"
                component={RemoteUserActivationPage}
            />
            <Route exact path="/adminCompany" component={AdminCompanyPage} />
            <Route
                exact
                path="/adminCompany/:mode?"
                component={AdminCompanyFormPage}
            />
            <Route
                exact
                path="/adminSearchVehicleInfo"
                component={AdminSearchVehicleInfoPage}
            />
            <Route
                exact
                path="/contact-us"
                component={ContactUsPage}
            />
            <Route
                exact
                component={RenewRoadTaxPage}
                path="/renewroadtax/list" //mode = create | continue | edit | payment
            />
            <Route
                exact
                component={RenewRoadTaxForm}
                path="/renewroadtax/form" //mode = create | continue | edit | payment
            />
            {/* Any additional routes should be placed above this line. */}
            {/* Otherwise, the route will not work */}
            <Route
                exact
                path="/verification/methods"
                component={VerificationMethodsPage}
            />
            <Route exact path="/mydid" component={MydIdPage} />

            {/* Any additional routes should be placed above this line. */}
            {/* Otherwise, the route will not work */}
            <Route exact path="/" component={DashboardPage} />
            <Route path="*" component={NotFoundPage} />
        </Switch>
    );
}
