import React, { useEffect, useContext, useState } from 'react'
import {
    Card,
    Col,
    Row,
    Button,
    message,
    Modal,
    Skeleton,
    Image,
    Space
} from 'antd'
import './index.less'
import successIcon from "../../assets/icon-success.svg";

import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
    CloseCircleOutlined
} from '@ant-design/icons'
import {
    breakdownIc,
    parseAddress,
    parseTime,
    parseCorrespondenceAddress,
    getDistrictName,
    getDecryptedVerifyOtpResponse
} from '../../util'
import propTypes from 'prop-types'
import {
    verifyApproverOTP,
    getOutTransferAmountByTransferId,
    getOutTransferByTransferId,
    processPaymentOut,
    outUpdateVehicleDetailsToJPJ,
    verifyApproverSmsOtp,
    saveOutSellerBuyerInquiry,
} from '../../services/api'
import {
    TRANSACTION_RESPONSE_CODES,
    STATES,
    APP_CONST,
    TRANSACTION_OUT_RESPONSE_CODES,
} from '../../constants'
import { SessionContext } from '../../App'
import JPJSubmissionModal from './jpjSubmission_modal'
import { ReactComponent as WarningIcon } from '../../assets/icon-warning-red-28.svg';
import {useShortcut, onApprovalSubmit, ModuleType} from '../../components/Utils'
import failedIcon from "../../assets/icon-failed.svg";
import {
    getCompanyId,
    getPermissions,
} from '../../services/local'
import {
    validateRolePermission,
} from '../../components/Utils'

const TRANSFER_PAYMENT_STATUSES = {
    PENDING: 'P', //TODO daniel.k change to 'P' instead of empty string. Set as such to work around BE bug at time
    SUCCESS: 'S',
    REFUNDED: 'R',
}

function Payment(props) {

    const { isReport } = props

    const history = useHistory()
    const params = useParams()
    const { company, user  } = useContext(SessionContext)
    const { state: locationState } = useLocation()

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isPaymentLoading, setIsPaymentLoading] = useState(false)
    const [transfer, setTransfer] = useState(null)
    const [payments, setPayments] = useState([])
    const transferId = Number(params.transferId)

    const [sellerDistrictName, setSellerDistrictName] = useState('')
    const [sellerCorrespondenceDistrictName, setSellerCorrespondenceDistrictName] = useState('')
    const [buyerDistrictName, setBuyerDistrictName] = useState('')
    const [buyerCorrespondenceDistrictName, setBuyerCorrespondenceDistrictName] = useState('')
    const [isIncorrectOtp, setIsIncorrectOtp] = useState(false)
    const [isApprovalRejected, setIsApprovalRejected] = useState(false)
    {useShortcut(setIsModalVisible)}
	const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        getPermissions().then(async (permissions) => {
          setPermissions(permissions);
        });
    }, [])

    useEffect(() => {
        getAndSetTransfer()
    }, [transferId])

    function getAndSetTransfer() {
        setIsLoading(true)

        Promise.all([
            getOutTransferAmountByTransferId(transferId),
            getOutTransferByTransferId(transferId, 'I2I', getCompanyId()),
            // i2iSaveOutSellerBuyerInquiry(transferId)
        ]).then(res => {
            if (!res) throw `Something went wrong`
            const [resAmount, resTransfer] = res
            if (resTransfer.id === undefined) throw res.message
            if(resTransfer.status === 4) {
                message.warning("Redirect to printslip")
                history.push(`/transfer/i2i/printslip/${transferId}`)
            }

            setTransfer({
                id: resTransfer.id,
                status: resTransfer.status,
                paymentStatus: resTransfer.paymentStatus,
                buyerNRIC: resTransfer.buyerNRIC,
                buyerName: resTransfer.buyerName,
                sellerNRIC: resTransfer.sellerNRIC,
                sellerName: resTransfer.sellerName,
                transferDate: resTransfer.transferDate,
                updatedDate: resTransfer.updatedDate,
                createdDate: resTransfer.createdDate,
                transactionType: resTransfer.transactionType,

                carRegistrationNo: resTransfer.carRegistrationNo,
                chassisNo: resTransfer.chassisNo,
                engineNo: resTransfer.engineNo,
                // carMake: resTransfer.make,
                // carModel: resTransfer.model,
                // manufactureYear: resTransfer.manufactureYear,

                ownerCatagory: resTransfer.ownerCatagory,
                vehicleTypeId: resTransfer.vehicleTypeId,
                sellerInfo: resTransfer.sellerInfo,
                buyerInfo: resTransfer.buyerInfo,
                // inquiry: resEnquiry,
                fisStatus: resTransfer.fisStatus,

                "eserviceAmount": resAmount.eserviceAmount,
                "fisAmount": resAmount.fisAmount,
                "fisEserviceAmount": resAmount.fisEserviceAmount,
                "jpjAmount": resAmount.jpjAmount,
                "name": resAmount.name,
                "totalAmount": resAmount.totalAmount,
                "sstAmount": resAmount.sstAmount,
                "processingAmount": resAmount.processingAmount

            })

            if (resTransfer.buyerInfo?.state && resTransfer.buyerInfo?.district) {

                getDistrictName(
                    resTransfer.buyerInfo?.state,
                    resTransfer.buyerInfo?.district
                ).then((res) => {
                    setBuyerDistrictName(res)
                })

            }

            if (resTransfer.buyerInfo?.correspondentState && resTransfer.buyerInfo?.correspondentDistrict) {

                getDistrictName(
                    resTransfer.buyerInfo?.correspondentState,
                    resTransfer.buyerInfo?.correspondentDistrict
                ).then((res) => {
                    setBuyerCorrespondenceDistrictName(res)
                })

            }

            if (resTransfer.sellerInfo?.state && resTransfer.sellerInfo?.district) {

                getDistrictName(
                    resTransfer.sellerInfo?.state,
                    resTransfer.sellerInfo?.district
                ).then((res) => {
                    setSellerDistrictName(res)
                })

            }

            if (resTransfer.sellerInfo?.correspondentState && resTransfer.sellerInfo?.correspondentDistrict) {

                getDistrictName(
                    resTransfer.sellerInfo?.correspondentState,
                    resTransfer.sellerInfo?.correspondentDistrict
                ).then((res) => {
                    setSellerCorrespondenceDistrictName(res)
                })

            }

        })
            .catch(err => err && message.error(err))
            .finally(() => setIsLoading(false))
    }

    function onPay() {

        const processPayment = () => {
            message.loading('Proceeding payment')
            processPaymentOut(company.coRegNo, transfer.id)
                .then(res => {
                    if (res.status !== "true") throw res.message
                    const updatedTransferDetails = {
                        ...transfer,
                        paymentStatus: TRANSFER_PAYMENT_STATUSES.SUCCESS,
                        status: 7,
                    };
    
                    setTransfer(updatedTransferDetails);
                    message.success(`Payment success`)

                    getAndSetTransfer()

                    return outUpdateVehicleDetailsToJPJ(transfer.id)
                })
                .then(res => {
                    if (res.statusCode !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.statusMSG
                    message.success(`Successfully updated to JPJ`)
                    history.push(`/transfer/i2i/printslip/${transfer.id}`)
                })
                .catch(err => {
                    if(err === "Fail to process prepaid payment." || err.includes("Insufficient")) {
                        Modal.error({
                            content: <div>You have insufficient prepaid balance. Please <a href={process.env.REACT_APP_RELOAD_URL} target="_blank">click here</a> to top up, and the amount will be automatically credited to your prepaid account.</div>
                        })
                    }
                    else {
                        Modal.error({
                            content: err?.toString()
                        })
                    }
                })
                .finally(() => setIsPaymentLoading(false))
        }

        const inquiryResponse = async () => {
            saveOutSellerBuyerInquiry(user.nric, transfer.id).then(async res => {

                if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) {
                    throw res.statusMSG
                }
                await processPayment()
            }).catch(err => {
                if (err) {
                    Modal.error({
                        content: err?.toString()
                    })
                }
                setIsPaymentLoading(false)
            }).finally(() => {})
        };

        inquiryResponse()
    }

    const updateToJPJ = () => {
        setIsPaymentLoading(true)

        const processUpdateJpj = () => {
            message.loading('Proceeding update JPJ')

            outUpdateVehicleDetailsToJPJ(transfer.id).then(res => {

                if (res.statusCode !== TRANSACTION_RESPONSE_CODES.SUCCESS) throw res.statusMSG

                message.success(`Successfully updated to JPJ`)
                history.push(`/transfer/i2i/printslip/${transfer.id}`)

            }).catch(err => {
                err && message.error(err?.toString())
                getAndSetTransfer()
            }).finally(() => setIsPaymentLoading(false))
        }

        const inquiryResponse = async () => {
            saveOutSellerBuyerInquiry(user.nric, transfer.id).then(async res => {

                if (res.statusCode !== TRANSACTION_OUT_RESPONSE_CODES.SUCCESS) {
                    throw res.statusMSG
                }
                await processUpdateJpj()
            }).catch(err => {
                if (err) {
                    Modal.error({
                        content: err?.toString()
                    })
                }
                setIsPaymentLoading(false)
            }).finally(() => {})
        };

        inquiryResponse()
    }

    /**
     * If transfer store no longer loading,
     * and no transfer is found
     */
    if (
        !isLoading
        && !transfer
    ) {
        return <></>
        /* return (
            <Card
                style={{
                    height: '50%',
                    width: '100%'
                }}
            >
                <h1>Unknown transfer</h1>
                <a
                    onClick={() => history.push('/')}
                >Go back to home</a>
            </Card>
        ) */
    }

    const { formattedDob: buyerFormattedDob, isMale: buyerIsMale } = breakdownIc(transfer?.buyerInfo?.identificationNo)
    const { formattedDob: sellerFormattedDob, isMale: sellerIsMale } = breakdownIc(transfer?.sellerInfo?.identificationNo)

    const buttonToShow = (message) => {
        return (!validateRolePermission("", "I2I", true, permissions) ?
        <Button
            type='primary'
            className='rectangle-button'
            onClick={() => {
                // setIsModalVisible(true)
                onApprovalSubmit(setIsModalVisible,
                    setIsPaymentLoading,
                    transfer?.transactionType,
                    onPay,
                    company,
                    ModuleType.I2I,
                    permissions
                )
            }}
            loading={isPaymentLoading}
        >
            {message}
        </Button> : 
        <Button
            className='rectangle-button to-the-right'
            onClick={() => {
                // setIsModalVisible(true)
                history.push(`/reporting/transferreport/i2i`, {tabType: props.tabType})
            }}
            type='primary'
        >
            OK
        </Button>
        )
    }
    return (

        <div
            style={{
                width: '100%',
            }}
        >
            <JPJSubmissionModal
                isIncorrectOtp={isIncorrectOtp}
                transactionType={transfer?.transactionType}
                isModalVisible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={(approverIc, authCode, isPush, appApprovalResponse) => {

                    if (isPush) {
                        setIsModalVisible(false)
                        if (appApprovalResponse.isRejected) {
                            setIsApprovalRejected(true)
                        }
                        else {
                            onPay()
                        };
                        // history.push(`/transfer/i2i/printslip/${transferId}`)
                    } else if (APP_CONST.USE_OTP === true) {
                        setIsPaymentLoading(true)
                        verifyApproverSmsOtp(approverIc, authCode)
                            .then(res => {
                                if(!res.encryptedString) {
                                    let errorMsg = res.message ? res.message : "ERROR"
                                    throw errorMsg
                                }
                                const decryptedResponse = getDecryptedVerifyOtpResponse(res.encryptedString)
                                if (decryptedResponse.status == 'false') {
                                    setIsIncorrectOtp(true)
                                    throw decryptedResponse.message
                                }
                                setIsIncorrectOtp(false)
                                setIsModalVisible(false)
                                onPay();
                            })
                            .catch(err => {
                                setIsPaymentLoading(false)
                                setIsIncorrectOtp(true)
                                err && message.error(err)
                            })
                            .finally(() => {
                                setIsIncorrectOtp(false)
                            })
                    } else {
                        setIsPaymentLoading(true)
                        verifyApproverOTP(approverIc, authCode)
                            .then(res => {
                                if (res.status !== true) {
                                    setIsIncorrectOtp(true)
                                    throw res.message
                                }
                                setIsIncorrectOtp(true)
                                setIsModalVisible(false)
                                onPay();
                            })
                            .catch(err => {
                                setIsPaymentLoading(false)
                                setIsIncorrectOtp(true)
                                err && message.error(err)
                            })
                            .finally(() => {
                                setIsIncorrectOtp(false)
                            })
                    }
                }
                }
                paymentLoading={isPaymentLoading}
            />
            {
                isApprovalRejected ? (
                    <Card
                        style={{
                            height: '50%',
                            textAlign: 'left',
                            boxShadow: '0px 3px 6px #00000029',
                            borderRadius: '4px',
                            marginBottom: 16
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Image src={failedIcon} alt="failed icon" preview={false} style={{ transform: 'scale(0.6)' }} />
                            <Space direction='vertical' size={0}>
                                <h2 style={{ color: '#E53B34', font: 'normal normal bold 23px/31px Open Sans' }} >Approval Unsuccessful</h2>
                            </Space>
                        </div>
                    </Card>
                ) : 
                transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.REFUNDED ? (
                    <div>
                        <Card
                            style={{
                                height: '50%',
                                textAlign: 'left',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: '4px'

                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <CloseCircleOutlined
                                    style={{
                                        color: 'red',
                                        fontSize: 70,
                                        marginRight: 50,
                                    }}
                                />
                                <div style={{ flex: 4 }}>
                                    <h2 style={{ color: 'red', display: 'inline' }} >Payment Failed. </h2>
                                    {!validateRolePermission("", "I2I", true, permissions) ? <h2 onClick={() => {
                                        // setIsModalVisible(true)
                                        onApprovalSubmit(setIsModalVisible,
                                            setIsPaymentLoading,
                                            transfer?.transactionType,
                                            onPay,
                                            company,
                                            ModuleType.I2I,
                                            permissions
                                        )
                                        }} style={{ color: 'red', display: 'inline', textDecoration: 'underline', cursor: 'pointer' }} >Try again?</h2>
                                        :
                                        <Button
                                            className='rectangle-button to-the-right'
                                            onClick={() => {
                                                // setIsModalVisible(true)
                                                history.push(`/reporting/transferreport/i2i`, {tabType: props.tabType})
                                            }}
                                            type='primary'
                                        >
                                            OK
                                        </Button>
                                        }
                                    <p>{payments.message || `Payment failed`}</p>
                                </div>
                            </div>
                        </Card>
                    </div>
                ) : transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.PENDING || transfer?.paymentStatus == '' ? (
                    <div>
                        <Card
                            style={{
                                height: '50%',
                                textAlign: 'left',
                                boxShadow: '0px 3px 6px #00000029',
                                borderRadius: '4px'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <Image src={successIcon} alt="Success icon" preview={false} style={{ paddingRight: '20px' }} />
                                <div style={{ display: 'flex', flex: 4, alignItems: 'center' }}>
                                    <h2 style={{ color: '#40e327a8', font: 'normal normal bold 23px/31px Open Sans' }} >JPJ checking completed</h2>
                                </div>
                            </div>
                        </Card>
                    </div>
                ) : null
            }
            <div>
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        marginTop: 10,
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '4px'
                    }}
                >
                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 40 }}>Payment Details</h2>

                    {
                        isLoading ? (
                            <Skeleton active />
                        ) : (

                            <Row gutter={16}>
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>JPJ Ownership Transfer Fee</p>
                                    <p style={styles.value}>RM {transfer && transfer.jpjAmount.toFixed(2)}</p>
                                </Col>
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>MYEG Service Charge</p>
                                    <p style={styles.value}>RM {transfer?.fisStatus == true ? (transfer && (transfer.eserviceAmount + transfer.fisEserviceAmount)) : (transfer && transfer.eserviceAmount)}</p>
                                </Col>
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>SST 8%</p>
                                    <p style={styles.value}>RM {transfer && transfer.sstAmount.toFixed(2)}</p>
                                </Col>
                                {
                                    transfer?.fisStatus == true &&
                                    <Col className="gutter-row" span={5}>
                                        <p style={styles.label}>Ownership Claimed Fee</p>
                                        <p style={styles.value}>RM {transfer && transfer.fisAmount.toFixed(2)}</p>
                                    </Col>
                                }
                                {transfer?.processingAmount && <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>Processing Fee</p>
                                    <p style={styles.value}>RM {transfer && transfer?.processingAmount.toFixed(2)}</p>
                                </Col>}
                                <Col className="gutter-row" span={transfer?.fisStatus == true ? 5 : 6}>
                                    <p style={styles.label}>Total Payment</p>
                                    <p style={styles.value}>RM {transfer && transfer.totalAmount.toFixed(2)}</p>
                                </Col>
                            </Row>
                        )
                    }
                </Card>
            </div>
            <div>
                <Card
                    style={{
                        height: '50%',
                        textAlign: 'left',
                        marginTop: 10,
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '4px'
                    }}
                >
                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 40 }}>Vehicle Information</h2>
                    {
                        isLoading ? (
                            <Skeleton active />

                        ) : (
                            <div>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Vehicle Number</p>
                                        <p style={styles.value}>{transfer?.carRegistrationNo}</p>
                                    </Col>
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Make</p>
                                        <p style={styles.value}>{transfer?.carMake}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Model</p>
                                        <p style={styles.value}>{transfer?.carModel}</p>
                                    </Col> */}
                                {/* </Row>
                                <Row gutter={16}> */}
                                    {/* <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Manufacture Year</p>
                                        <p style={styles.value}>{transfer?.manufactureYear}</p>
                                    </Col> */}
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Engine Number</p>
                                        <p style={styles.value}>{transfer?.engineNo}</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Chassis Number</p>
                                        <p style={styles.value}>{transfer?.chassisNo}</p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Card>
            </div>
            <div>
                <Card style={{
                    height: '50%',
                    textAlign: 'left',
                    marginTop: 10,
                    boxShadow: '0px 3px 6px #00000029',
                    borderRadius: '4px'
                }}>

                    <h2 style={{ font: 'normal normal 600 24px Raleway', marginBottom: 40 }}>JPJ Enquiry Status</h2>
                    {
                        isLoading ? (<Skeleton active />) : (
                            <div>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Blacklisted</p>
                                        {/* <p style={styles.value}>{transfer?.inquiry?.statusCode === 'BLACKLISTED' ? transfer?.inquiry?.statusMSG : 'No'}</p> */}
                                        <p style={styles.value}>No</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Ownership Claim</p>
                                        {/* <p style={styles.value}>{transfer?.inquiry?.statusCode === 'OWNERSHIP_CLAIM' ? transfer?.inquiry?.statusMSG : 'No'}</p> */}
                                        <p style={styles.value}>No</p>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Condition Code</p>
                                        {/* <p style={styles.value}>{transfer?.inquiry?.statusCode === 'CONDITION' ? transfer?.inquiry?.statusMSG : 'No'}</p> */}
                                        <p style={styles.value}>No</p>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={8}>
                                        <p style={styles.label}>Valid as at</p>
                                        <p style={styles.value}> {parseTime(transfer?.updatedDate)}</p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Card>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 10,
                }}
            >
                <Card
                    style={{
                        flex: 3,
                        textAlign: 'left',
                        marginTop: 10,
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '4px'
                    }}
                >
                    {isLoading ? (
                        <Skeleton active />
                    ) : (transfer.transactionType === 1 || transfer.transactionType === 4 ? (
                        <>
                            <h2 className='owner-detail-header'>Owner's Details</h2>
                            <p style={styles.label}>Name</p>
                            <p style={styles.value}>{transfer?.sellerInfo?.name}</p>
                            <p style={styles.label}>NRIC</p>
                            <p style={styles.value}>{transfer?.sellerInfo?.identificationNo}</p>
                            <p style={styles.label}>Date of Birth</p>
                            <p style={styles.value}>{transfer?.sellerInfo?.dob || sellerFormattedDob}</p>
                            <p style={styles.label}>Gender</p>
                            <p style={styles.value}>{sellerIsMale ? 'Male' : 'Female'}</p>
                            <p style={styles.label}>Address</p>
                            <p style={styles.value}>{parseAddress({ ...transfer?.sellerInfo, state: Object.values(STATES).find(s => s.code === transfer?.sellerInfo?.state)?.label, districtName: sellerDistrictName })}</p>
                            {/* <p style={styles.label}>Current Address</p>
                            <p style={styles.value}>
                                {
                                    transfer?.sellerInfo.correspondentAddress1
                                        ? parseCorrespondenceAddress({ ...transfer?.sellerInfo, correspondentState: Object.values(STATES).find(s => s.code === transfer?.sellerInfo.correspondentState)?.label, districtName: sellerCorrespondenceDistrictName })
                                        : parseAddress({ ...transfer?.sellerInfo, state: Object.values(STATES).find(s => s.code === transfer?.sellerInfo.state)?.label, districtName: sellerDistrictName })
                                }
                            </p> */}
                            <p style={styles.label}>Email</p>
                            <p style={styles.value}>{transfer?.sellerInfo?.email}</p>
                            <p style={styles.label}>Mobile Number</p>
                            <p style={styles.value}>{transfer?.sellerInfo?.phoneNo}</p>
                        </>
                    ) : (
                        <>
                            <h2 className='owner-detail-header'>Seller's Company Details</h2>
                            {/* <p style={styles.label}>Company Name</p>
                                <p style={styles.value}>{company.coName}</p>
                                <p style={styles.label}>Company ROC</p>
                                <p style={styles.value}>{company.coRegNo}</p>
                                <p style={styles.label}>Company Address</p>
                                <p style={styles.value}>{parseCompanyAddress({ ...company.companyAddress })}</p>
                                <p style={styles.label}>Representative Name</p>
                                <p style={styles.value}>{user.name}</p>
                                <p style={styles.label}>Representative NRIC</p>
                                <p style={styles.value}>{user.nric}</p>
                                <p style={styles.label}>Representative Email</p>
                                <p style={styles.value}>{user.email}</p>
                                <p style={styles.label}>Representative Mobile Number</p>
                                <p style={styles.value}>{user.mobileno}</p> */}
                            <p style={styles.label}>Company Name</p>
                            <p style={styles.value}>-</p>
                            <p style={styles.label}>Company ROC</p>
                            <p style={styles.value}>-</p>
                            <p style={styles.label}>Company Address</p>
                            <p style={styles.value}>-</p>
                            <p style={styles.label}>Representative Name</p>
                            <p style={styles.value}>-</p>
                            <p style={styles.label}>Representative NRIC</p>
                            <p style={styles.value}>-</p>
                            <p style={styles.label}>Representative Email</p>
                            <p>-</p>
                            <p style={styles.label}>Representative Mobile Number</p>
                            <p style={styles.value}>-</p>
                        </>
                    )
                    )}
                </Card>

                <Card
                    style={{
                        flex: 3,
                        textAlign: 'left',
                        marginTop: 10,
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '4px'
                    }}
                >
                    {isLoading ? (
                        <Skeleton active />
                    ) : (transfer.transactionType === 1 || transfer.transactionType === 2 ? (
                        <>
                            <h2 className='owner-detail-header'>Buyer's Details</h2>
                            <p style={styles.label}>Name</p>
                            <p style={styles.value}>{transfer?.buyerInfo?.name}</p>
                            <p style={styles.label}>NRIC</p>
                            <p style={styles.value}>{transfer?.buyerInfo?.identificationNo}</p>
                            <p style={styles.label}>Date of Birth</p>
                            <p style={styles.value}>{transfer?.buyerInfo?.dob || buyerFormattedDob}</p>
                            <p style={styles.label}>Gender</p>
                            <p style={styles.value}>{buyerIsMale ? 'Male' : 'Female'}</p>
                            <p style={styles.label}>Address</p>
                            <p style={styles.value}>{parseAddress({ ...transfer?.buyerInfo, state: Object.values(STATES).find(s => s.code === transfer?.buyerInfo?.state)?.label, districtName: buyerDistrictName })}</p>
                            {/* <p style={styles.label}>Current Address</p>
                            <p style={styles.value}>{parseCorrespondenceAddress({ ...transfer?.buyerInfo, correspondentState: Object.values(STATES).find(s => s.code === transfer?.buyerInfo?.correspondentState)?.label, districtName: buyerCorrespondenceDistrictName })}</p> */}
                            <p style={styles.label}>Email</p>
                            <p style={styles.value}>{transfer?.buyerInfo?.email}</p>
                            <p style={styles.label}>Mobile Number</p>
                            <p style={styles.value}>{transfer?.buyerInfo?.phoneNo}</p>
                        </>
                    ) : (
                        <>
                            <h2 className='owner-detail-header'>Buyer's Company Details</h2>
                            <p style={styles.label}>Company Name</p>
                            <p style={styles.value}>{transfer.buyerInfo.name}</p>
                            <p style={styles.label}>Company ROC</p>
                            <p style={styles.value}>{transfer.buyerInfo.identificationNo}</p>
                            <p style={styles.label}>Company Address</p>
                            <p style={styles.value}>{parseAddress({ ...transfer.buyerInfo, state: Object.values(STATES).find(s => s.code === transfer?.buyerInfo?.state)?.label, districtName: buyerCorrespondenceDistrictName })}</p>
                            <p style={styles.label}>Representative Name</p>
                            <p style={styles.value}>{transfer.buyerInfo.repName}</p>
                            <p style={styles.label}>Representative NRIC</p>
                            <p style={styles.value}>{transfer.buyerInfo.repIc}</p>
                            <p style={styles.label}>Representative Email</p>
                            <p style={styles.value}>{transfer.buyerInfo.correspondentEmail}</p>
                            <p style={styles.label}>Representative Mobile Number</p>
                            <p style={styles.value}>{transfer.buyerInfo.correspondentMobileNo}</p>
                        </>
                    )
                    )}
                </Card>
            </div>
            <Card
                style={{
                    backgroundColor: '#FFFFBB', // Set the background color
                    boxShadow: '0px 3px 6px #00000029',
                    borderRadius: 8,
                    marginTop: 16,
                    textAlign: 'inherit',
                }}
            >
                <Space>
                    <WarningIcon style={{ color: '#2B61C4' }} width={24} />
                    <span style={{ font: 'normal normal normal 16px Open Sans', marginLeft: 8 }}>
                        <b>Road Tax will be cancelled.</b> Please be reminded to apply for a new insurance policy and road tax as soon as possible.
                    </span>
                </Space>
            </Card>
            {
                transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.REFUNDED ? (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 20
                        }}
                    >
                        <Button
                            className='back-button buttons'
                            shape='round'
                            onClick={() => {
                                if (locationState?.oldIsReport) {
                                    if (locationState?.fromPendingPage === true) {
                                        // history.push('/reporting/transferreport/i2i')
                                        history.push(`/reporting/transferreport/i2i`, {tabType: props.tabType})
                                    } else {
                                        history.push('/reporting/transferreport')
                                    }
                                } else {
                                    history.push('/')
                                }
                            }}
                        >
                            {locationState?.oldIsReport ? "Back" : "Home"}
                        </Button>
                        {buttonToShow("Try again")}
                    </div>
                ) : transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.PENDING ? (
                    <div>

                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 20,
                            }}
                        >
                            <Button
                                className='back-button buttons'
                                shape='round'
                                onClick={() => {
                                    if (locationState?.oldIsReport) {
                                        if (locationState?.fromPendingPage === true) {
                                            // history.push('/reporting/transferreport/i2i')
                                            history.push(`/reporting/transferreport/i2i`, {tabType: props.tabType})
                                        } else {
                                            history.push('/reporting/transferreport')
                                        }
                                    } else {
                                        history.push('/')
                                    }
                                }}
                            >
                                {locationState?.oldIsReport ? "Back" : "Home"}
                            </Button>
                            {isReport == false &&
                                buttonToShow("Proceed to Payment") 
                            }
                        </div>
                    </div>
                ) : transfer?.paymentStatus === TRANSFER_PAYMENT_STATUSES.SUCCESS ? (
                    <div>

                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 20,
                            }}
                        >
                            <Button
                                className='back-button buttons'
                                shape='round'
                                onClick={() => {
                                    if (locationState?.oldIsReport) {
                                        if (locationState?.fromPendingPage === true) {
                                            // history.push('/reporting/transferreport/i2i')
                                            history.push(`/reporting/transferreport/i2i`, {tabType: props.tabType})
                                        } else {
                                            history.push('/reporting/transferreport')
                                        }
                                    } else {
                                        history.push('/')
                                    }
                                }}
                            >
                                {locationState?.oldIsReport ? "Back" : "Home"}
                            </Button>
                            {isReport === false ? transfer?.status === 7 ?
                                <Button
                                    type='primary'
                                    className='rectangle-button'
                                    onClick={() => { updateToJPJ() }}
                                    loading={isPaymentLoading}
                                >
                                    Update JPJ
                                </Button> :
                                buttonToShow("Proceed to Payment") : <></>
                            }
                        </div>
                    </div>
                ) : (
                    <div>

                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: 20,
                            }}
                        >
                            <Button
                                className='back-button buttons'
                                shape='round'
                                onClick={() => {
                                    if (locationState?.oldIsReport) {
                                        if (locationState?.fromPendingPage === true) {
                                            // history.push('/reporting/transferreport/i2i')
                                        history.push(`/reporting/transferreport/i2i`, {tabType: props.tabType})
                                        } else {
                                            history.push('/reporting/transferreport')
                                        }
                                    } else {
                                        history.push('/')
                                    }
                                }}
                            >
                                {locationState?.oldIsReport ? "Back" : "Home"}
                            </Button>
                            {isReport === false && transfer?.status === 3 ?
                                buttonToShow("Proceed to Payment")
                                :
                                <></>
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

Payment.propTypes = {
    seller: propTypes.object,
    dealer: propTypes.object,
    vehicle: propTypes.object,
    approverNric: propTypes.string,
    tabType: propTypes.string,
}

const styles = {
    label: {
        font: 'italic normal normal 16px Open Sans',
        color: 'grey', marginBottom: 8
    },
    value: {
        font: 'normal normal 600 16px Open Sans'
    },
}

export default Payment