import { Space, Steps, Card, Button, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import VehicleInformation from './vehicleInformation';
import RoadTaxPurchase from './roadtaxPurchase';
import Payment from './payment';
import PrintSlip from './printSlip';
import './index.less';

export default function RenewRoadTaxPage() {
    const [currentStep, setCurrentStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState(false); //  Standardized Form State
    const [vehicleNumber, setVehicleNumber] = useState(null); //  Store vehicleNumber in state
    const [ownerIdNumber, setownerIdNumber] = useState(null); //  Store ID number in state
    const [roadTaxData, setRoadTaxData] = useState(null); //  Store Road Tax form data
    const [renewalPrices, setRenewalPrices] = useState({ "6": null, "12": null });
    const [apiResponse, setApiResponse] = useState(null); //  Define apiResponse state
    const [validityPeriod, setValidityPeriod] = useState(null); //  New state
    const [updatedRequestBody, setUpdatedRequestBody] = useState(null);
    const [paymentResult, setPaymentResult] = useState(null);


    


    function handleVehicleSubmit(number, id) {
        setVehicleNumber(number); //  Store vehicle number in state when form is submitted
        setownerIdNumber(id); //  Save ID number in state
    }

    function handleRoadTaxSubmit(data, validity, updatedRequestBody, apiResponseData) {
        setRoadTaxData(data); //  Includes lamination_fee (true/false)
        setValidityPeriod(validity); //  Store validityPeriod

        setApiResponse(
        apiResponseData,  //  Store responseData separately
        );
        setUpdatedRequestBody(
            updatedRequestBody
        )
        console.log(" New API Response Set in State:",
           
            apiResponseData
        );
    }

    function handleNext() {
        if (!isFormFilled) return; // Global check before proceeding
    
        const nextStep = Math.min(currentStep + 1, COMPONENTS.length - 1);
    
        // Prevent jumping from RoadTaxPurchase directly to PrintSlip
        if (nextStep === 3 && currentStep !== 2) return;
    
        setIsLoading(true);
    
        // Simulate delay (e.g. for API or transition)
        setTimeout(() => {
            setIsLoading(false);
            setCurrentStep(nextStep);
        }, 0);
    }
    
    



    const COMPONENTS = [
        {
            title: 'Vehicle & Owner Information',
            component: <VehicleInformation
                handleNext={handleNext}
                currentStep={currentStep}
                requiresFormFill={true}
                isFormFilled={isFormFilled}
                setIsFormFilled={setIsFormFilled}
                handleVehicleSubmit={handleVehicleSubmit} //  Pass function to update vehicle number
                roadTaxData={roadTaxData}
                setRenewalPrices={setRenewalPrices} //  Pass setter function
                setApiResponse={setApiResponse}
            />,
            requiresFormFill: true
        },
        {
            title: 'Road Tax Purchase',
            component: <RoadTaxPurchase
                handleNext={handleNext}
                currentStep={currentStep}
                requiresFormFill={true}
                isFormFilled={isFormFilled}
                setIsFormFilled={setIsFormFilled}
                vehicleNumber={vehicleNumber} //  Pass vehicle number to RoadTaxPurchase
                ownerIdNumber={ownerIdNumber}
                handleRoadTaxSubmit={handleRoadTaxSubmit}
                renewalPrices={renewalPrices} //  Pass prices
            />,
            requiresFormFill: true
        },
        {
            title: 'Payment Summary & Payment',
            component: <Payment
                handleNext={handleNext}
                currentStep={currentStep}
                requiresFormFill={false}
                vehicleNumber={vehicleNumber} //  Pass vehicle number to Payment
                ownerIdNumber={ownerIdNumber}
                roadTaxData={roadTaxData}
                apiResponse={apiResponse}
                validityPeriod={validityPeriod}
                updatedRequestBody={updatedRequestBody}
                setPaymentResult={setPaymentResult}
            />,
            requiresFormFill: false
        },
        {
            title: 'Payment Slip',
            component: <PrintSlip
                vehicleNumber={vehicleNumber} //  Pass vehicle number to PrintSlip
                ownerIdNumber={ownerIdNumber}
                roadTaxData={roadTaxData}
                apiResponse={apiResponse}
                validityPeriod={validityPeriod}
                updatedRequestBody={updatedRequestBody}
                paymentResult={paymentResult} 
            />,
            requiresFormFill: false
        }
    ];

    return (
        <div className='roadtax' style={{ margin: '40px 80px' }}>
            <div style={{ textAlign: 'center' }}>
                <Space direction='horizontal'>
                    <h2 className='transaction-type-i2i'>Renew Road Tax</h2>
                </Space>
            </div>

            <div className='roadtaxItem' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '1244px', margin: 'auto' }}>
                <div style={{ marginBottom: 40, marginTop: 20, display: 'block', margin: 'auto' }}>
                    <Steps labelPlacement='vertical' style={{ width: '60vw' }} size="default" current={currentStep}>
                        {COMPONENTS.map((step, index) => (
                            <Steps.Step key={step.title} title={<span>{step.title}</span>} />
                        ))}
                    </Steps>
                </div>
                {COMPONENTS[currentStep].component}
            </div>

            {/* <div style={{ marginTop: 24, display: 'flex', justifySelf: 'center', justifyContent: 'space-between', width: '1196px' }}>
                <Button disabled={currentStep === 0} onClick={() => setCurrentStep(prev => prev - 1)}>Back</Button>
                <Button style={{ width: 345, height: 56 }} type="primary" onClick={handleNext}>
                    {currentStep === COMPONENTS.length - 1 ? 'Finish' : 'Next'}
                </Button>
            </div> */}


        </div>
    );
}
