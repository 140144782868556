export const config = {
    s3: {
        jobBucket: "hire-with-api-job-posting-dev",
        resumeBucket: "hire-with-api-job-resume-dev",
        resumeBucketUrl:
            "https://hire-with-api-job-resume-dev.s3.ap-southeast-1.amazonaws.com",
        miscBucket: "hire-with-api-misc-dev",
        miscBucketUrl:
            "https://hire-with-api-misc-dev.s3.ap-southeast-1.amazonaws.com",
    },
    api: process.env.REACT_APP_API,
    version: process.env.REACT_APP_VERSION,
    env: process.env.REACT_APP_ENVIRONMENT,
    sagemUrl: process.env.REACT_APP_SAGEM_URL,
    legacyMyegUrl: process.env.REACT_APP_SSM_URL,
    reloadUrl: process.env.REACT_APP_RELOAD_URL,
    cmsApi: process.env.REACT_APP_CMS_API,
    digitalIdWsUrl: process.env.REACT_APP_MYDIGITALID_WEBSOCKET_URL,
};

export const links = {
    privacyPolicy: "",
    termsAndCondition: "https://www.myeg.com.my/terms-conditions",
    googlePlayStore: "https://play.google.com/",
    appStore: "https://www.apple.com/app-store/",
    huaweiAppGallery: "https://appgallery.huawei.com/",
};
