import {
    LoadingOutlined
} from '@ant-design/icons'
import { Alert, Button, Card, Progress, message } from 'antd'
import propTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { config } from '../../config'
import { setCachedState, setSagemUrlForKey } from '../../services/local'
import {skipAuth, skipAuthKey, getEncryptedString} from '../../components/Utils'
import {
    encryptUrl,
    encryptUrlForRemote,
  } from "../../services/api";
const WSS_CONFIG = {
    wss: "wss://localhost:7878/IDCard"
}
/**Constants to make my life easier to prevent typo */
const TO_DEVICE_MESSAGES = {
    READCARD: 'READCARD',
    INIT: 'INIT',
    LIVESCAN: 'LIVESCAN'
}
const FROM_DEVICE_MESSAGE = {
    // errors
    ERROR_DEVICE: 'ErrorDevice',
    ERROR_READ_CARD: 'ErrorReadCard',
    ERROR_NO_CHIP: 'ErrorNoChip',
    NEED_TO_PRESS_HARDER: `Need to press harder`,
    NOT_IDENTICAL: 'Not Identical',
    IDENTICAL: 'Identical',

    // useful messages
    DEVICE_NUMBER: 'Device Number',
    SMART_CARD_READY: 'Smart Card Ready',
    CARD_INSERTED: 'Card Inserted',
    READ_PHOTO_TIME_TAKEN: 'Read Photo Time taken',
    SCANNER_START: 'Scanner Start',
    FINGERPRINT: 'Fingerprint',
    MATCHED_POSITION: 'Matched Position',
    READ_CHIP_END: 'Read Chip End'
}
const SUCCESSES = {
    DEVICE_READY: 'DeviceReady'
}
const OTHERS = {
    LOADING: 'Loading',
    WSS_CONNECTION_FAILED: 'WSS_CONNECTION_FAILED'
}
/**Constants to make my life easier to prevent typo */

/**
 * Mapping of [device's keys] : [api's keys]
 */
const KEYS = {
    'Address1': 'address1',
    'Address2': 'address2',
    'Address3': 'address3',
    'Postcode': 'postcode',
    'POB':'birthPlace',
    'Citizenship':'citizenship',
    'State': 'state',
    'City': 'district',
    'NRIC': 'identificationNo',
    // 'OriginalName': 'name',
    'GMPCName': 'name',
    'Gender': 'gender',
    'DOB': 'dob',
    'Photo':'photo',
    'Race':'race',
    'Religion':'religion',
    'Card Type':'cardType'
}

/**
 * For self reference
 * Keys from Dermalog:
 * [
    "Device Number",
    "Smart Card Ready",
    "Fingerprint",
    "Card Type",
    "Card_Version",
    "OriginalName",
    "GMPCName",
    "KPTName",
    "NRIC",
    "OldIC",
    "Scanner Start",
    "Template Quality",
    "Matching Result",
    "Matched Position",
    "Gender",
    "DOB",
    "POB",
    "DateIssue",
    "Citizenship",
    "Race",
    "Religion",
    "Category",
    "OtherID",
    "Address1",
    "Address2",
    "Address3",
    "Postcode",
    "City",
    "State",
    "LeftHandCode",
    "RightHandCode",
    "Restricted_Resident",
    "Criminal_Record",
    "East_Malaysia_Origin"
    ]
 */

// Expected number of keys from device.
// Used to measure progress of device reading info
const MAX_NUMBER_OF_KEYS_FROM_DEVICE = 36

let webSocket
class DeviceReader extends Component {
    state = {
        // stores raw data from device
        data: {},

        // for user to know what's going on
        status: null,
        isButtonDisabled: false,
        encryptString: null,
    }

    openSocket = async () => {
        const res = this.props.token && this.props.token != null && this.props.token != undefined ? 
            await encryptUrlForRemote(this.props.token) : await encryptUrl();
        if (res.status !== true) {
            message.error(res.message)
            return new URL(`${window.location.origin}${window.location.pathname}`).href
        }
        this.setState({ encryptString: res.encryptString })
        this.setState({ status: OTHERS.LOADING })
        await this.wait(1 * 1000)
        try {
            console.log('opening socket...')
            // Ensures only one connection is open at a time
            if (webSocket !== undefined && webSocket.readyState !== WebSocket.CLOSED) {
                console.log(('...socket already opened. No action taken. End.'))

                this.setState({ status: SUCCESSES.DEVICE_READY })
                return;
            }

            webSocket = new WebSocket(WSS_CONFIG.wss);

            webSocket.onopen = async event => {
                console.log(`...socket opened.`)
                this.setState({ status: SUCCESSES.DEVICE_READY })
                webSocket && webSocket.send(TO_DEVICE_MESSAGES.INIT)

                await this.wait()
                webSocket && webSocket.send(TO_DEVICE_MESSAGES.READCARD)

                if (event.data === undefined) return
            };

            webSocket.onmessage = event => {
                var json = JSON.parse(event.data);
                this.handleDeviceMessage(json.DataTitle, json);
            };

            webSocket.onclose = e => {
                console.log(`...socket closed.`)
            };

            webSocket.onerror = e => {
                this.setState({ status: OTHERS.WSS_CONNECTION_FAILED })
            }
        }
        catch (err) {
            console.log(`...socket failed.`, err)
        }
    }
    /**
     * 
     * @param {String} keyIn 
     * @param {Object{DataTitle: String, Message: String}} text 
     * @returns 
     */
    handleDeviceMessage = async (keyIn, text) => {

        const data = JSON.parse(JSON.stringify(this.state.data))

        /**
         * TODO 
         * Remove in production
         * Put in for debug at QA stage
         */
        window.eTukar_deviceData = data

        if (!data.hasOwnProperty(keyIn)) {

            /**
             * 13/1/2022 daniel.kwok
             * Massage data from device reader
             */
            switch (keyIn) {
                case 'NRIC':
                    text.Message = text?.Message?.replaceAll('-', '')
                    break
                default:
                    break
            }

            data[keyIn] = text || ''
        }

        this.setState({
            status: keyIn
        })

        switch (data[keyIn].Message) {
            case FROM_DEVICE_MESSAGE.NOT_IDENTICAL:
                this.setState({
                    status: FROM_DEVICE_MESSAGE.NOT_IDENTICAL
                })
                this.props.onThumbprintFailed()
        }

        switch (keyIn) {
            case FROM_DEVICE_MESSAGE.ERROR_NO_CHIP:
            case FROM_DEVICE_MESSAGE.SMART_CARD_READY:
                this.props.onError()
                break

            case FROM_DEVICE_MESSAGE.CARD_INSERTED:
                this.readCard()
                break

            case FROM_DEVICE_MESSAGE.FINGERPRINT:
            case FROM_DEVICE_MESSAGE.SCANNER_START:
                this.props.onWaitingThumbprint()
                break

            case FROM_DEVICE_MESSAGE.IDENTICAL:
                // this.render()
                this.props.onThumbprintSuccess()
                break

            case FROM_DEVICE_MESSAGE.READ_PHOTO_TIME_TAKEN:
                this.rescan()
                break

            case FROM_DEVICE_MESSAGE.READ_CHIP_END:
                /**
                 * Sometimes this message pops up, before card has finished reading all info.
                 * This check is here to make sure at least 'Photo' data has arrived, 
                 * To verify it'd actually finished reading all info.
                 */
                if (!this.state.data['Photo']) return null
                this.closeCard()
                this.props.onFinish(this.convertDeviceData(data))
        }

        this.setState({ data })
        this.props.onDataChanged(this.convertDeviceData(data))
    }

    closeCard = () => {
        if (
            webSocket !== undefined
            && webSocket.readyState !== WebSocket.CLOSED
            && webSocket.readyState !== WebSocket.CLOSING
        ) {
            console.log(('...socket already opened. No action taken. End.'))
            webSocket.close()
            return;
        }
    }

    readCard = () => {
        console.log(`Sending signal to read card...`)
        webSocket && webSocket.send(TO_DEVICE_MESSAGES.READCARD)
    }
    rescan = async () => {
        console.log(`Rescan...`)
        this.openSocket()

        await this.wait(5 * 1000)
        webSocket && webSocket.send(TO_DEVICE_MESSAGES.LIVESCAN)
    }

    shouldComponentUpdate = () => {
        return true
    }

    wait = (duration) => {
        return new Promise((res) => setTimeout(() => res(), duration || 3 * 1000))
    }

    tryAgain = () => {
        this.setState({ data: {} })
        this.closeCard()
        this.wait()
        this.openSocket()
    }

    // To redirect to sagem static website. The logic for retrieving mykad data when using sagem device
    // is in E-Tukar Hardware project.
    getSagemUrl = () => {

        const state = window.history.state?.state || {}
        /**set next step & substep */
        state.step = this.props.nextStep
        state.subStep = this.props.nextSubStep

        let redirectUrl = `${window.location.origin}${window.location.pathname}`
        if (this.props.token && this.props.token != null && this.props.token != undefined) {
            redirectUrl = `${window.location.origin}${"/activate-remote-user/true"}`
        }
        const forKey = this.props.forKey //must be one of seller, buyer, or dealer
        setSagemUrlForKey(forKey)

        const json = JSON.stringify(state)

        // Save location state into local storage before redirecting, so that when when we were
        // redirected back from sagem to etukar website, we can get back previous location state.
        // Keep in mind that location state will be gone when page redirect/reloaded.
        setCachedState(json)

        const sagemUrl = config.sagemUrl
        const url = new URL(sagemUrl)

        // url.searchParams.set('cached', encoded)
        url.searchParams.set('redirectUrl', redirectUrl)
        url.searchParams.set('forKey', forKey)

        //call api get encrypted string
        url.searchParams.set('tk', this.state.encryptString)

        return url.href
    }
    // 
    getSagemTestUrl = () => {

        const state = window.history.state?.state || {}
        /**set next step & substep */
        state.step = this.props.nextStep
        state.subStep = this.props.nextSubStep

        const redirectUrl = `${window.location.origin}${window.location.pathname}`
        
        const forKey = this.props.forKey //must be one of seller, buyer, or dealer
        setSagemUrlForKey(forKey)

        const json = JSON.stringify(state)

        // Save location state into local storage before redirecting, so that when when we were
        // redirected back from sagem to etukar website, we can get back previous location state.
        // Keep in mind that location state will be gone when page redirect/reloaded.
        setCachedState(json)

        const sagemUrl = config.sagemUrl
        const url = new URL(sagemUrl)

        // url.searchParams.set('cached', encoded)
        url.searchParams.set('redirectUrl', redirectUrl)
        url.searchParams.set('forKey', forKey)
        
        return skipAuth(true, redirectUrl, forKey, null)
    }

    getUserMessageComponent = () => {
        const env = process.env.REACT_APP_ENVIRONMENT;
        switch (this.state.status) {
            case null:
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'left',
                            marginBottom: 10
                        }}
                    >
                        <ol>
                            <li>We support Google Chrome, Microsoft Edge and Firefox</li>
                            <li>Make sure device is connected and online</li>
                            <li>Insert identity card and click "Start"</li>
                        </ol>
                        <Button
                            type='primary'
                            onClick={() => {
                                this.openSocket()
                                this.setState({
                                    isButtonDisabled: true,
                                    data: {},
                                })
                            }}
                            disabled={this.state.isButtonDisabled}
                        >
                            Start
                        </Button>
                    </div>
                )
            case FROM_DEVICE_MESSAGE.ERROR_DEVICE:
            case FROM_DEVICE_MESSAGE.ERROR_READ_CARD:
            case OTHERS.WSS_CONNECTION_FAILED:
                return (
                    <>
                        <Alert 
                            banner 
                            type='error' 
                            message = {
                                <> 
                                    Device not found.
                                    <a onClick={this.tryAgain}> Try again?</a>
                                    <br />
                                    <a href={`${this.getSagemUrl()}`}>
                                        Click here if you are using SAGEM
                                    </a>
                                    {env === "uat" ? <>
                                    <br />
                                    <a href={`${this.getSagemTestUrl()}`}>Click here if you want skip SAGEM</a></>:<></>}

                                    <br /> <br />
                                    <span
                                        style={{
                                            marginLeft: 10,
                                            color: "#1677ff",
                                            cursor: "pointer",
                                            marginTop: 10,
                                        }}
                                        onClick={() => 
                                            this.props.history.push("/contact-us")
                                        }
                                    >
                                        (Report Biometric Device Issue) 
                                    </span>
                                </>
                            } 
                        />
                    </>
                )
            case FROM_DEVICE_MESSAGE.ERROR_NO_CHIP:
                return <Alert banner type='error' message={<>No card found. Make sure card is inserted into device. <a onClick={this.tryAgain}>Try again</a></>} />
            case FROM_DEVICE_MESSAGE.NOT_IDENTICAL:
                return <Alert banner type='error' message={<>Fingerprint does not match. <a onClick={this.tryAgain}>Try again</a></>} />

            case FROM_DEVICE_MESSAGE.SCANNER_START:
            case FROM_DEVICE_MESSAGE.FINGERPRINT:
                return <Alert banner type='info' message={<>Waiting for fingerprint <LoadingOutlined /></>} />
            case OTHERS.LOADING:
                return <Alert banner type='info' message={<>Waiting for device to connect <LoadingOutlined /></>} />

            case SUCCESSES.DEVICE_READY:
                return <Alert banner type='success' message={`Device connected`} />
            case FROM_DEVICE_MESSAGE.READ_CHIP_END:
                return <Alert banner type='success' message={<>Finished. <a onClick={this.tryAgain}>Start again</a></>} />

            case '':
            case undefined:
                return null

            default:
                return <Alert banner type='info' message={<>Reading info from device <LoadingOutlined /></>} />
        }
    }

    convertDeviceData = (deviceData) => {
        if (typeof deviceData !== 'object') return {}

        let convertedData = {}
        Object.keys(deviceData).map(key => {
            const convertedKey = KEYS[key]
            if (convertedKey) {
                convertedData[convertedKey] = deviceData[key].Message
            }
        })

        return {...convertedData, "encryptString" : this.state.encryptString, "forkey": this.props.forKey}
    }

    render() {

        return (
            <Card
                style={this.props.style || {
                    width: '60%'
                }}
            >
                {this.getUserMessageComponent()}
                <Progress percent={Object.keys(this.state.data).length / MAX_NUMBER_OF_KEYS_FROM_DEVICE * 100} showInfo={false} />
            </Card>
        )
    }
}

DeviceReader.propTypes = {
    onWaitingThumbprint: propTypes.func.isRequired,
    onIdentityCardSuccess: propTypes.func.isRequired,
    onThumbprintDetected: propTypes.func.isRequired,
    onThumbprintFailed: propTypes.func.isRequired,
    onThumbprintSuccess: propTypes.func.isRequired,
    onTryAgain: propTypes.func.isRequired,
    onFinish: propTypes.func.isRequired,
    onDataChanged: propTypes.func,
    onError: propTypes.func,
    style: propTypes.object,
    forKey: propTypes.oneOf(['seller', 'buyer', 'dealer', 'branchUser', 'remoteUser', 'ownerUser']).isRequired,
    nextStep: propTypes.string.isRequired,
    nextSubStep: propTypes.string.isRequired
}

export default withRouter(DeviceReader)