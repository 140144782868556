import { Form, Table, Pagination, Card, Button, Input, Radio, message, Modal, Spin, Row, Col, Select, Alert, Checkbox, } from "antd";
import { PageHeader } from '@ant-design/pro-components';
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SessionContext } from "../../../App";
import "./index.less";
import { VehicleSearchForm } from "../../../components/component/Layout/FormComponent";
import { useForm } from "antd/lib/form/Form";
import plusIcon from "../../../assets/icon-plus.png";
import editIcon from "../../../assets/edit-icon.png";
import illusEmpty from "../../../assets/illus-empty-state.png";
import arrowBack from "../../../assets/back-button.png";
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import { ReactComponent as ArrowElbowIcon } from "../../../assets/icon/Icon-arrow-elbow.svg";
import { searchRtxTransactions, getRtxTransactions, roadTaxInquiry } from '../../../services/api';
import { CalendarOutlined, PlusCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import PaymentSubmissionModal from './paymentSubmission_modal';


const stateNames = {
    "01": "Johor",
    "02": "Kedah",
    "03": "Kelantan",
    "04": "Malacca",
    "05": "Negeri Sembilan",
    "06": "Pahang",
    "07": "P. Pinang",
    "08": "Perak",
    "09": "Perlis",
    "10": "Selangor",
    "11": "Terengganu",
    "12": "Sabah",
    "13": "Sarawak",
    "14": "Kuala Lumpur",
    "15": "Labuan",
    "16": "Putrajaya"
};
export default function RenewRoadTaxList() {
    const history = useHistory();
    const { company, branch, branchs, user } = useContext(SessionContext);
    const [selectedState, setSelectedState] = useState("Unknown");

    const [searchForm] = useForm();
    const [tabType, setTabType] = useState("1"); // Default to Pending Transactions
    const [pendingCount, setPendingCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceCount, setDataSourceCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isScrolled, setIsScrolled] = useState(false);
    const [spinProgress, setSpinProgress] = useState(0);
    const [showRenewModal, setShowRenewModal] = useState(false);
    const [isEmptySearch, setIsEmptySearch] = useState(false);
    const [maxSelectSize, setMaxSelectSize] = useState(30);
    const [editingTransaction, setEditingTransaction] = useState(null);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isEditLoading, setIsEditLoading] = useState(false);
    const [editMode, setEditMode] = useState(null); // 'view' | 'delivery' | 'contact' etc.
    const [renewalPrices, setRenewalPrices] = useState({});
    const [selectedFormat, setSelectedFormat] = useState(editingTransaction?.stateCode === "44" ? "44" : "physical_digital");
    const [collectionMethod, setCollectionMethod] = useState(editingTransaction?.stateCode === "00" ? "00" : "delivery");
    const [renewalPeriod, setRenewalPeriod] = useState(editingTransaction?.renewalPeriod);
    const [isChecked, setIsChecked] = useState(true);
    const [addressLines, setAddressLines] = useState(['address1']); // Initial address line
    const currentRadioValue = editingTransaction?.stateCode === "00" ? "00" : "delivery";
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);


    const maxPageSize = 30;

    const handleRowSelectionChange = (selectedKeys) => {
        if (selectedKeys.length <= maxSelectSize) {
            setSelectedRowKeys(selectedKeys);
        }
    };

    const columns = [
        {
            title: "Created Date",
            key: "createdDate",
            render: (_, record) => (
                <div>
                    <div style={{ fontWeight: 500 }}>{record.createdDateDisplay.date}</div>
                    <div style={{ color: "gray", fontSize: 12 }}>
                        Available for {record.createdDateDisplay.countdown} more day{record.createdDateDisplay.countdown !== 1 ? "s" : ""}
                    </div>
                </div>
            )
        },
        {
            title: "Vehicle Number",
            dataIndex: "vehicleNumber",
            key: "vehicleNumber",
        },
        {
            title: "Owner",
            dataIndex: "owner",
            key: "owner",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_, record) => {
                const { paymentStatusCode, prepaidPaymentStatus } = record;

                if (!paymentStatusCode && !prepaidPaymentStatus) {
                    return "Awaiting Payment";
                }

                if (paymentStatusCode !== "MY0001" && prepaidPaymentStatus === "Y") {
                    return "Update payment to JPJ failed";
                }

                if (!paymentStatusCode && prepaidPaymentStatus === "N") {
                    return "Prepaid Payment Failed";
                }

                return paymentStatusCode || "-";
            },
        },
        {
            title: "Amount (RM)",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => (
                <Button
                    type="link"
                    // icon={<EditOutlined />}
                    onClick={async () => {
                        try {
                            setIsEditLoading(true);
                            const res = await getRtxTransactions(record.transactionId);
                            setEditingTransaction(res);


                            // const basePayload = {
                            //     address1: res.address1 || "-",
                            //     address2: res.address2 || "-",
                            //     category: "1",
                            //     contactNo: res.customerContactNo || "-",
                            //     email: "-",
                            //     idNo: res.idNo,
                            //     name: "dummyName",
                            //     postcode: res.postcode || "-",
                            //     state: res.stateCode === "00" ? "00" : res.stateCode,
                            //     vehicleNo: res.vehicleNo,
                            //     lamination_fee: res.laminationFee || false,
                            //     dealerCompanyRoc: company.coRegNo,
                            // };

                            // // Make 2 requests in parallel
                            // const [res6, res12] = await Promise.all([
                            //     roadTaxInquiry({ ...basePayload, renewalPeriod: 6 }, false),
                            //     roadTaxInquiry({ ...basePayload, renewalPeriod: 12 }, false),
                            // ]);

                            // // Update renewalPrices state
                            // setRenewalPrices({
                            //     "6": parseFloat(res6?.amount) || 0,
                            //     "12": parseFloat(res12?.amount) || 0,
                            // });

                            setEditMode("view");
                            setIsEditModalVisible(true);
                        } catch (error) {
                            console.error("❌ Error fetching transaction:", error);
                            message.error("Failed to load transaction details.");
                        } finally {
                            setIsEditLoading(false);
                        }
                    }}

                >
                    View
                </Button>
            ),
        }
    ];

    const columnsWithoutAction = [
        {
            title: "No",
            key: "no",
            render: (_text, _record, index) => index + 1, // simple index-based numbering
        },
        ...columns.filter((col) => col.key !== "action"),
    ];

    const selectedTransactions = dataSource.filter((item) =>
        selectedRowKeys.includes(item.key)
    );



    const formatDate = (isoDate) => {
        if (!isoDate) return "-";
        const date = new Date(isoDate);
        return date.toLocaleDateString("en-MY", {
            day: "2-digit", month: "short", year: "numeric"
        });
    };

    useEffect(() => {
        console.log("📦 editingTransaction updated:", editingTransaction);
    }, [editingTransaction]);



    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 380);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Tab Navigation
    const CustomRadio = () => {
        return (
            <div className={`list-radio ${isScrolled ? "scrolled" : ""}`} style={{ width: "100%", position: "sticky", top: 0, zIndex: 2 }}>
                <Radio.Group
                    className="full-width-radio-group"
                    defaultValue={tabType}
                    onChange={(e) => {
                        setTabType(e.target.value);
                        setCurrentPage(1);
                        setDataSource([]);
                    }}
                >
                    <Radio.Button value="1" style={{ background: tabType === "1" ? "white" : "#f0f4fc" }}>
                        <b>Pending Transactions</b>
                        <Input className="custom-input" value={pendingCount} readOnly />
                    </Radio.Button>
                    {/* <Radio.Button value="2" style={{ background: tabType === "2" ? "white" : "#f0f4fc" }}>
                        <b>Completed Transactions</b>
                        <Input className="custom-input" value={completedCount} readOnly />
                    </Radio.Button> */}
                </Radio.Group>
            </div>
        );
    };



    const addAddressLine = () => {
        setAddressLines((prev) => [...prev, `address${prev.length + 1}`]);
    };

    const handleCollectionMethodChange = (e) => {
        const selected = e.target.value;

        setEditingTransaction((prev) => ({
            ...prev,
            stateCode: selected === "00" ? "00" : "-", // Reset to "-" if delivery
        }));
    };



    const handleFormatChange = (e) => {
        const format = e.target.value;
        setSelectedFormat(format);

        setEditingTransaction((prev) => ({
            ...prev,
            stateCode: format === "44" ? "44" : "physical_digital",

        }));
    };

    const handleRenewalPeriodChange = (e) => {

        const selected = e.target.value;
        setRenewalPeriod(selected); // Update the renewal period based on user selection

        setEditingTransaction((prev) => ({
            ...prev,
            renewalPeriod: selected,

        }));
    };

    const handleCheckboxChange = (e) => {
        const check = e.target.checked
        setIsChecked(check);

        setEditingTransaction((prev) => ({
            ...prev,
            laminationFee: check,
        }))
    };

    const calculateValidityPeriod = () => {
        const startDate = moment(); // Current date
        const endDate = moment().add(renewalPeriod, 'months'); // Add selected months
        return {
            start: startDate.format('DD MMM YYYY'),
            end: endDate.format('DD MMM YYYY'),
        };
    };

    const validityPeriod = calculateValidityPeriod();

    const handleRenewClick = () => {
        setShowRenewModal(true);
        setSpinProgress(0);

        let progress = 0;
        const interval = setInterval(() => {
            progress += 20;
            setSpinProgress(progress);

            if (progress >= 100) {
                clearInterval(interval);
                setTimeout(() => {
                    setShowRenewModal(false);
                    history.push("/renewroadtax/form");
                }, 300);
            }
        }, 200);
    };

    const rowSelection = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: (selectedKeys) => {
            setSelectedRowKeys(selectedKeys.length > 1 ? [selectedKeys[selectedKeys.length - 1]] : selectedKeys);
        },
        columnTitle: () => null, // 👈 Removes the header checkbox label
        renderCell: (checked, record, index, originNode) => {
            return originNode; // 👈 Keep row-level checkboxes
        },
    };



    // Handle Search
    const handleSearch = () => {
        const { searchTerm } = searchForm.getFieldsValue();
        setCurrentPage(1);
        setSearchTerm(searchTerm.trim().toUpperCase());
    };

    useEffect(() => {
        if (company?.coRegNo && branch?.id) {
            fetchTransactions();
        }


    }, [branch, company, currentPage, searchTerm, tabType]);



    const fetchTransactions = async () => {
        setIsLoading(true);
        try {
            const res = await searchRtxTransactions(
                branch.id || "",                     // branchId
                company.coRegNo || "",               // dealerCompanyROC
                maxPageSize,                          // pageLimit
                currentPage,                          // pageNumber
                searchTerm || "",                     // search
                "vehicleNumber",                      // searchParam (change if necessary)
                "1D",                                 // sort (add logic if needed)
                tabType === "1" ? "Pending" : "Done", // status
            );

            const data = res?.data || [];

            const formattedData = data.map((item, index) => {
                const inquiryDate = new Date(item?.inquiryCreatedDate);

                // Expiry = 7 days after inquiryCreatedDate
                const expiryDate = new Date(inquiryDate);
                expiryDate.setDate(expiryDate.getDate() + 7);

                // Days remaining from today
                const now = new Date();
                const diffTime = expiryDate.getTime() - now.getTime();
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                return {
                    ...item,
                    key: item?.id || index,
                    transactionId: item?.id,
                    createdDateDisplay: {
                        date: inquiryDate.toLocaleDateString("en-MY", {
                            year: "numeric", month: "short", day: "numeric"
                        }),
                        countdown: diffDays
                    },
                    vehicleNumber: item?.vehicleNo || "-",
                    owner: item?.customerName || "-",
                    status: item?.inqStatusMessage || "-",
                    amount: item?.totalAmount?.toFixed(2) || "0.00",
                };
            });


            setDataSource(formattedData);
            setDataSourceCount(res?.count || data.length);
            setPendingCount(res?.count || data.length); // use same for now
            // setMaxSelectSize(data.length);
            setMaxSelectSize(1);
        } catch (error) {
            console.error("Failed to fetch transactions:", error);
            message.error("Failed to fetch transactions");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (editingTransaction?.stateCode) {
            const code = editingTransaction.stateCode.toString().padStart(2, "0");
            const name = stateNames[code] || "Unknown";
            setSelectedState(name);
    
            console.log("✅ Translated State Code:", code, "→", name);
        }
    }, [editingTransaction]);

    return (
        <>
            <div className="page-renewroadtax-list">
                <PageHeader
                    style={{ paddingLeft: "50px", paddingBottom: '24px', marginTop: "32px" }}
                    className="site-page-header"
                    title="Renew Road Tax"
                />
                <div className="page-content">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "24px 0" }}>
                        {VehicleSearchForm(
                            searchForm,
                            searchTerm,
                            setSearchTerm,
                            handleSearch,
                            setIsEmptySearch,
                            isEmptySearch,
                            dataSource.length === 0
                        )}
                        <div className="right-wrap" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 12 }}>
                            <p>Renew road tax for <b>non-inventory vehicle</b></p>
                            <Button
                                className="renew-right-button"
                                onClick={handleRenewClick}>
                                <img className="plusIcon" src={plusIcon} style={{ width: 24, height: 24 }} />
                                RENEW ROAD TAX
                            </Button>
                        </div>
                    </div>

                    <div className="body-section">
                        <CustomRadio />

                        {dataSource.length === 0 ? (
                            <div className="body-empty">
                                <div className="illus-wrap">
                                    <img className="illus-icon" src={illusEmpty} alt="No Data" />
                                    <h2 className="empty-text">
                                        {tabType === "1" ? "No pending road tax renewal transactions" : "No completed road tax renewal transactions"}
                                    </h2>
                                </div>
                                <Button type="primary" className="renew-button" onClick={() => window.location.href = "/renewroadtax/form"}>
                                    Renew Road Tax
                                </Button>
                            </div>
                        ) : (
                            <div className="body-fill">
                                {/* <div className="filter-section" style={{ position: 'sticky', top: 55, zIndex: 1000 }}>
                                    <Button className="filter-button" icon={<CheckOutlined style={{ color: "#00622A" }} />}>
                                        Awaiting payment
                                        <span className="side-value">{pendingCount}</span>
                                    </Button>
                                </div> */}
                                <div style={{ backgroundColor: '#fff', position: 'sticky', top: 55, zIndex: 2 }}>

                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 16 }}>
                                        <Pagination
                                            current={currentPage}
                                            pageSize={maxPageSize}
                                            total={dataSourceCount}
                                            onChange={setCurrentPage}
                                            showSizeChanger={false}
                                        />
                                        <div>
                                            Showing {(currentPage - 1) * maxPageSize + 1} - {Math.min(currentPage * maxPageSize, dataSourceCount)} of {dataSourceCount} transactions.
                                        </div>

                                    </div>

                                    <div className="transition-container" style={{ background: selectedRowKeys.length > 0 ? "#fffca4" : "#fff" }}>
                                        <div
                                            className="transition-content"
                                            style={{
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div
                                                className={selectedRowKeys.length === maxSelectSize ? "" : "slide-to-end"}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: 8,
                                                    fontSize: 16,
                                                    fontWeight: 400,
                                                    lineHeight: "24px",
                                                    color: "#000",
                                                }}
                                            >

                                                {selectedRowKeys.length === 1 ? (
                                                    <>
                                                        <ArrowElbowIcon />
                                                        You've selected{" "}
                                                        <a
                                                            style={{
                                                                textDecoration: "underline",
                                                                color: "#2b61c4",
                                                            }}
                                                        >
                                                            1 transaction
                                                        </a>

                                                    </>
                                                ) : (
                                                    <>
                                                        Select 1 transaction at a time for payment.
                                                    </>
                                                )}
                                                {/* {selectedRowKeys.length === maxSelectSize ? (
                                                    <>
                                                        <ArrowElbowIcon />
                                                        <span>
                                                            Selected the maximum of{" "}
                                                            <a
                                                                style={{
                                                                    textDecoration: "underline",
                                                                    color: "#2b61c4",
                                                                }}
                                                            >
                                                                {selectedRowKeys.length} transactions
                                                            </a>{" "}
                                                            to proceed to payment.
                                                        </span>
                                                        <a
                                                            onClick={() => setSelectedRowKeys([])}
                                                            style={{
                                                                marginLeft: 16,
                                                                color: "#2b61c4",
                                                                fontWeight: 500,
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            Clear all
                                                        </a>
                                                    </>
                                                ) : selectedRowKeys.length === 1 ? (
                                                    <>
                                                        You’ve selected{" "}
                                                        <a
                                                            style={{
                                                                textDecoration: "underline",
                                                                color: "#2b61c4",
                                                            }}
                                                        >
                                                            1 transaction
                                                        </a>
                                                        . You can select up to {maxSelectSize}.
                                                    </>
                                                ) : selectedRowKeys.length > 1 ? (
                                                    <>
                                                        You’ve selected{" "}
                                                        <a
                                                            style={{
                                                                textDecoration: "underline",
                                                                color: "#2b61c4",
                                                            }}
                                                        >
                                                            {selectedRowKeys.length} transactions
                                                        </a>
                                                        . You can select up to {maxSelectSize}.
                                                    </>
                                                ) : (
                                                    <>
                                                        Select up to a maximum of {maxSelectSize} transaction
                                                        {maxSelectSize !== 1 ? "s" : ""} for payment.
                                                    </>
                                                )} */}

                                            </div>

                                            <Button
                                                style={{ width: 287 }}
                                                type="primary"
                                                disabled={selectedRowKeys.length === 0}
                                                onClick={() => setShowPaymentModal(true)}
                                            >
                                                {selectedRowKeys.length > 0 ? "Make Payment" : "Select Transaction"}
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <Table
                                        // rowSelection={{
                                        //     selectedRowKeys,
                                        //     onChange: handleRowSelectionChange,
                                        //     preserveSelectedRowKeys: true,
                                        // }}
                                        rowSelection={rowSelection}
                                        columns={columns}
                                        dataSource={dataSource}
                                        loading={isLoading}
                                        pagination={false}
                                        rowKey="key"
                                        className="custom-list-table"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <Modal
                    className="transaction-modal"
                    title={`Selected ${selectedRowKeys.length} transactions to pay`}
                    open={showPaymentModal}
                    onCancel={() => setShowPaymentModal(false)}
                    footer={[
                        <p style={{ marginTop: 12, textAlign: "end", marginRight: 24, fontSize: 16 }}>
                            Approval is required for this payment to complete.
                        </p>,
                        <Button key="submit" type="primary" style={{ width: 287 }} onClick={() => {
                            setIsPaymentModalVisible(true);
                            setShowPaymentModal(false)
                        }}>
                            Confirm & Pay RM {selectedTransactions
                                .reduce((total, item) => total + parseFloat(item.amount), 0)
                                .toFixed(2)}
                        </Button>,
                    ]}
                    width={1196}
                >
                    <Table
                        columns={columnsWithoutAction}
                        dataSource={selectedTransactions}
                        pagination={false}
                        rowKey="key"
                    />

                </Modal>

                <PaymentSubmissionModal
                    isModalVisible={isPaymentModalVisible}
                    selectedTransactions={selectedTransactions}
                    onOk={(approverIc, authCode, isApp, res) => {
                        // Handle submit approval here
                        console.log("Approver IC:", approverIc);
                        console.log("OTP/Auth Code:", authCode);
                        console.log("Is using app:", isApp);

                        console.log("Payment success with approval:", approverIc);
                        setIsPaymentModalVisible(false);
                        // handleNext();
                    }}
                    onCancel={() => setIsPaymentModalVisible(false)}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    company={company}
                    user={user}
                    editingTransaction={selectedTransactions[0]}
                />


                <Modal
                    className='roadtax-modal'
                    open={showRenewModal}
                    footer={null}
                    closable={false}
                    centered
                    style={{ textAlign: 'center', padding: '30px', borderRadius: 8 }}
                >
                    {spinProgress > 0 && <Spin percent={spinProgress} />}
                    <p style={{ fontSize: '16px', margin: '16px 0px 0px', lineHeight: '24px', fontWeight: 400, letterSpacing: '-0.08px' }}>Please wait...</p>
                </Modal>
                <Modal
                    open={isEditModalVisible}
                    onCancel={() => setIsEditModalVisible(false)}
                    footer={null}
                    width="100%"
                    style={{ top: 0, padding: 0, }}
                    bodyStyle={{ height: '100vh', overflow: 'auto', padding: '24px 8px' }}
                    className="fullscreen-modal"
                >
                    {isEditLoading ? (
                        <Spin tip="Loading transaction details..." />
                    ) : (
                        <>
                            {editMode === 'view' && (
                                <>
                                    <div style={{ maxWidth: '100%', margin: '0 auto' }}>
                                        <h2 style={{ fontSize: 20, fontWeight: 700, position: 'fixed', top: '17.5px', left: 16, margin: 0, borderBottom: '1px solid #e0e0e0', width: '94.65%', paddingLeft: 32, paddingBottom: '17.5px', backgroundColor: "#FFFFFF", zIndex: 1, lineHeight: '24px' }}>
                                            {editingTransaction?.vehicleNo}
                                            <br />
                                            <span style={{ fontSize: 16, fontWeight: 400, color: '#333333' }}>{editingTransaction?.customerName} ({editingTransaction?.idNo})</span>
                                        </h2>

                                        <div className="section-block" style={{ marginTop: 80 }}>
                                            <div className="section-header" >
                                                <span style={{ fontSize: 18, fontWeight: 600 }}>Road tax details</span>
                                                {/* <Button type="link" onClick={() => setEditMode('roadtax')} style={{ padding: 0, marginLeft: 24, color: 'rgba(43, 97, 196, 1)' }}>
                                                    <img className="editIcon" src={editIcon} style={{ width: 24, height: 24 }} />
                                                    EDIT
                                                </Button> */}
                                            </div>
                                            <div className="section-content">
                                                <div className="field-row">
                                                    <div className="field-label">Renewal period</div>
                                                    {/* <div className="field-value">
                                                        {editingTransaction?.renewalPeriod} months ({formatDate(editingTransaction?.lkmStartDate)} - {formatDate(editingTransaction?.lkmEndDate)})
                                                    </div> */}
                                                    <div className="field-value">
                                                        {editingTransaction?.renewalPeriod} months ({validityPeriod.start} - {validityPeriod.end})
                                                    </div>
                                                </div>
                                                <div className="field-row">
                                                    <div className="field-label">Road tax format</div>
                                                    <div className="field-value">
                                                        {editingTransaction?.stateCode !== "44" ? 'Physical road tax slip  ' : 'Digital only '}{editingTransaction?.laminationFee === true ? 'with lamination & digital' : 'without lamination & digital'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {editingTransaction?.stateCode !== '44' && (
                                            <div className="section-block">
                                                <div className="section-header">
                                                    <span style={{ fontSize: 18, fontWeight: 600 }}>Owner's delivery details</span>
                                                    {/* <Button
                                                        type="link"
                                                        onClick={() => setEditMode('delivery')}
                                                        style={{ padding: 0, marginLeft: 24, color: 'rgba(43, 97, 196, 1)' }}
                                                    >
                                                        <img className="editIcon" src={editIcon} style={{ width: 24, height: 24 }} />
                                                        EDIT
                                                    </Button> */}
                                                </div>

                                                <div className="section-content-2">
                                                    <div className="field-row">
                                                        <div className="field-label">Collection method</div>
                                                        <div className="field-value">
                                                            {editingTransaction?.stateCode === '00'
                                                                ? 'Self-collect at MYEG Tower'
                                                                : 'Delivery'}
                                                        </div>
                                                    </div>

                                                    {editingTransaction?.stateCode === '00' ? (
                                                        <div className="field-row">
                                                            <div className="field-label">Collection address</div>
                                                            <div className="field-value">
                                                                <b>MyEG Customer Service Center</b><br />
                                                                Level UG, MYEG Tower, Empire City, No 8, Jalan Damansara, PJU 8, 47820 Petaling Jaya, Selangor.
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="field-row">
                                                            <div className="field-label">Delivery address</div>
                                                            <div className="field-value">
                                                                {[editingTransaction?.address1, editingTransaction?.address2]
                                                                    .filter(val => val && val !== '-')
                                                                    .join(', ')}
                                                                {editingTransaction?.postcode ? `, ${editingTransaction.postcode}` : ''}
                                                                {editingTransaction.stateCode ? `, ${selectedState}` : ''}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                        )}

                                        <div className="section-block" style={{ border: 0 }}>
                                            <div className="section-header">
                                                <span style={{ fontSize: 18, fontWeight: 600 }}>Recipient's contact details</span>
                                                {/* <Button type="link" onClick={() => setEditMode('contact')} style={{ padding: 0, marginLeft: 24, color: 'rgba(43, 97, 196, 1)' }}><img className="editIcon" src={editIcon} style={{ width: 24, height: 24 }} />
                                                    EDIT</Button> */}
                                            </div>
                                            <div className="section-content">
                                                <div className="field-row">
                                                    <div className="field-label">Mobile number</div>
                                                    <div className="field-value">{editingTransaction?.customerContactNo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            )}

                            {editMode === 'roadtax' && (
                                <>
                                    <div style={{ position: 'fixed', top: '17.5px', left: 8, margin: 0, borderBottom: '1px solid #e0e0e0', width: '95.85%', paddingLeft: 32, paddingBottom: '17.5px', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', zIndex: 1, backgroundColor: '#FFFFFF' }}>
                                        <Button onClick={() => setEditMode('view')} style={{ width: 44, border: 0, boxShadow: '0' }}><img src={arrowBack} /></Button>
                                        <h2 style={{ fontSize: 20, fontWeight: 700, margin: 0, lineHeight: '24px' }}>
                                            {editingTransaction?.vehicleNo}
                                            <br />
                                            <span style={{ fontSize: 16, fontWeight: 400, color: '#333333' }}>{editingTransaction?.customerName} ({editingTransaction?.idNo})</span>
                                        </h2>
                                    </div>

                                    <Card className="roadtaxDetails" style={{ marginTop: 80 }}>
                                        <h2 className='cardTitle' >
                                            Road Tax Details
                                        </h2>
                                        <p className='indicatorText'>
                                            <span style={{ color: 'red' }}>*</span> indicates required field
                                        </p>

                                        <Col >
                                            <Form.Item
                                                name="renewalPeriod"
                                                label="Renewal Period"
                                                rules={[{ required: true, message: '' }]}
                                                className="renewalPeriod"
                                            >
                                                <Row style={{ flexDirection: "row" }}>
                                                    <Col style={{ display: "flex", width: '566px', flexDirection: "column" }}>
                                                        <Radio.Group
                                                            defaultValue={editingTransaction?.renewalPeriod}
                                                            className="radio-group-custom"
                                                            onChange={handleRenewalPeriodChange}
                                                            style={{
                                                                position: 'relative',
                                                                zIndex: 1,
                                                                boxShadow: '0px 4px 8px 0px #33333340'

                                                            }}
                                                        >
                                                            <Radio value={6}>
                                                                <p
                                                                    style={{
                                                                        width: '219px',
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <span>6 months</span>
                                                                    <span>RM {renewalPrices["6"] ? renewalPrices["6"].toFixed(2) : "Loading..."}</span>
                                                                </p>
                                                            </Radio>
                                                            <Radio value={12}>
                                                                <p
                                                                    style={{
                                                                        width: '219px',
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <span>12 months</span>
                                                                    <span>RM {renewalPrices["12"] ? renewalPrices["12"].toFixed(2) : "Loading..."}</span>
                                                                </p>
                                                            </Radio>
                                                        </Radio.Group>
                                                        <Alert
                                                            message={
                                                                <span>
                                                                    Your road tax will be <b>valid from{' '}
                                                                        {validityPeriod.start}</b> to <b>{validityPeriod.end}</b>.
                                                                </span>
                                                            }
                                                            type="warning"
                                                            showIcon
                                                            icon={
                                                                <CalendarOutlined style={{ color: 'black', fontSize: '24px', marginRight: '12.67px' }} />
                                                            }
                                                            style={{
                                                                marginBottom: 16,
                                                                backgroundColor: '#FFF9A2',
                                                                padding: '0 16.67px',
                                                                fontSize: '16px',
                                                                fontFamily: 'Plus Jakarta Sans',
                                                                lineHeight: '24px',
                                                                borderRadius: 4,
                                                                textAlign: 'start',
                                                                width: '566px',
                                                                height: '52px',
                                                                borderTopLeftRadius: 0,
                                                                borderTopRightRadius: 0,
                                                                position: 'relative',
                                                                top: '-1px',
                                                                zIndex: 0
                                                            }}
                                                        />
                                                    </Col>

                                                    <Col style={{ width: '50%' }}>
                                                        <p style={{ color: '#737373', fontSize: '16px', lineHeight: '24px', letterSpacing: '-0.5%', marginTop: '12px', marginLeft: '16px', textAlign: 'start' }}>
                                                            The prices displayed do not include processing fees.
                                                        </p>
                                                    </Col>

                                                </Row>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item
                                                name="roadTaxFormat"
                                                label="Road Tax Format"
                                                rules={[{ required: true, message: '' }]}

                                            >
                                                <Row>
                                                    <Col style={{ width: '566px' }}>
                                                        <Radio.Group
                                                            defaultValue={editingTransaction?.stateCode === "44" ? "44" : "physical_digital"}
                                                            className="radio-group-custom"
                                                            style={{ boxShadow: '0px 4px 8px 0px #33333340' }}
                                                            onChange={handleFormatChange}
                                                        >
                                                            <Radio value="physical_digital">Physical & Digital</Radio>
                                                            <Radio value="44">Digital Only</Radio>
                                                        </Radio.Group>

                                                        {selectedFormat === 'physical_digital' && (
                                                            <Checkbox
                                                                onChange={handleCheckboxChange}
                                                                checked={isChecked}
                                                                disabled={selectedFormat === "44"}
                                                                style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', lineHeight: '24px' }}
                                                            >
                                                                Protect roadtax slip with lamination for RM 1.08 (including 8% SST).
                                                            </Checkbox>
                                                        )}
                                                    </Col>
                                                    <Col style={{ width: '50%' }}>
                                                        <p style={{ color: '#737373', fontSize: '16px', lineHeight: '24px', letterSpacing: '-0.5%', marginTop: 0, marginLeft: '16px', textAlign: 'start' }}>
                                                            Upon renewal, the vehicle owner can access the digital road tax within 24 hours through the MyJPJ app, available on Google Play Store, Apple App Store, or Huawei App Gallery.
                                                        </p>
                                                    </Col>
                                                </Row>

                                            </Form.Item>
                                        </Col>

                                    </Card>

                                    <div style={{ marginTop: 24 }}>
                                        <Button type="primary" onClick={() => setEditMode('view')} style={{ width: 287 }}>Save</Button>
                                    </div>
                                </>
                            )}

                            {editMode === 'delivery' && (
                                <>
                                    <div style={{ position: 'fixed', top: '17.5px', left: 8, margin: 0, borderBottom: '1px solid #e0e0e0', width: '95.85%', paddingLeft: 32, paddingBottom: '17.5px', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', zIndex: 1, backgroundColor: '#FFFFFF' }}>
                                        <Button onClick={() => setEditMode('view')} style={{ width: 44, border: 0, boxShadow: '0' }}><img src={arrowBack} /></Button>
                                        <h2 style={{ fontSize: 20, fontWeight: 700, margin: 0, lineHeight: '24px' }}>
                                            {editingTransaction?.vehicleNo}
                                            <br />
                                            <span style={{ fontSize: 16, fontWeight: 400, color: '#333333' }}>{editingTransaction?.customerName} ({editingTransaction?.idNo})</span>
                                        </h2>
                                    </div>

                                    <Card className="deliveryDetails">
                                        <h2 className='cardTitle'>Owner's Delivery Details</h2>
                                        <p className='indicatorText'>
                                            <span style={{ color: 'red' }}>*</span> indicates required field
                                        </p>

                                        <Row style={{ alignItems: 'center' }}>
                                            <Col style={{ width: '566px', height: '80px' }}>
                                                <Form.Item
                                                    name="collectionMethod"
                                                    label="Collection Method"
                                                    rules={[{ required: true, message: '' }]}

                                                >
                                                    <Radio.Group
                                                        className="radio-group-custom"
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            setEditingTransaction((prev) => ({
                                                                ...prev,
                                                                stateCode: value === "00" ? "00" : "-", // ✅ delivery resets to "-"
                                                            }));
                                                        }}
                                                        style={{ boxShadow: '0px 4px 8px 0px #33333340' }}
                                                        defaultValue={currentRadioValue}
                                                    >
                                                        <Radio value="delivery">Delivery</Radio>
                                                        <Radio value="00">Self-collect at MYEG Tower</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            {/* Display Self-collect Form */}
                                            {editingTransaction?.stateCode === "00" && (
                                                <>
                                                    <Col style={{ width: '574px', height: '80px' }}>
                                                        <p style={{ fontSize: "16px", lineHeight: "24px", letterSpacing: '-0.5%', fontWeight: 400, paddingLeft: '16px', marginTop: '32px', textAlign: 'start' }}>The road tax will be ready for collection <b>three working days after</b> the <b>payment</b> is successfuly made.</p>
                                                    </Col>
                                                    <Row>
                                                        <Col span={24}>
                                                            <div style={{ marginTop: "16px", display: "flex", gap: "16px" }}>
                                                                {/* Map */}
                                                                <div style={{ flex: "1", minWidth: "566px", minHeight: '256.28px' }}>
                                                                    <iframe
                                                                        title="MYEG Tower Map"
                                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15919.455545124553!2d101.6296012689517!3d3.1569360967081357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc49b8ec8bfc81%3A0x6a3b72e8b383569!2sMYEG%20Tower!5e0!3m2!1sen!2smy!4v1670836797468!5m2!1sen!2smy"
                                                                        width="100%"
                                                                        height="100%"
                                                                        style={{ border: "0" }}
                                                                        allowFullScreen=""
                                                                        loading="lazy"
                                                                    ></iframe>
                                                                </div>
                                                                {/* Text Details */}
                                                                <div style={{ flex: "2" }}>
                                                                    <p style={{ fontSize: "16px", lineHeight: "24px", marginTop: 0, textAlign: 'start' }}>
                                                                        Collection address: <br />
                                                                        <b>MyEG Customer Service Center</b> <br />
                                                                        Level UG, MYEG Tower, Empire City, No 8, Jalan Damansara,
                                                                        PJU 8, 47820 Petaling Jaya, Selangor.
                                                                    </p>
                                                                    <p style={{ fontSize: "16px", lineHeight: "24px", textAlign: 'start' }}>
                                                                        <b>Monday – Friday: 9am – 5pm</b> (closed on public
                                                                        holidays)
                                                                    </p>
                                                                    <p style={{ fontSize: "16px", lineHeight: "24px", textAlign: 'start' }}>
                                                                        Vehicle owners can collect their road tax with their
                                                                        MyKad or appoint <br /> someone to collect it on their behalf.
                                                                        The <b>appointed person</b> must bring the <br /> <b>photocopy of the
                                                                            owner's MyKad</b> and <b>road tax receipt.</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row></>
                                            )}
                                        </Row>

                                        {/* Display Delivery Form */}
                                        {editingTransaction?.stateCode !== "00" && (
                                            <>
                                                <Col style={{ width: '566px' }}>
                                                    <p
                                                        style={{
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            letterSpacing: "0.5%",
                                                            fontWeight: "400",
                                                            marginBottom: 0,
                                                            textAlign: 'start'
                                                        }}
                                                    >
                                                        Country
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: "16px",
                                                            lineHeight: "24px",
                                                            letterSpacing: "0.5%",
                                                            fontWeight: "600",
                                                            marginTop: "8px",
                                                            textAlign: 'start'
                                                        }}
                                                    >
                                                        Malaysia
                                                    </p>
                                                </Col>
                                                <Row style={{ width: '566px', gap: "16px" }}>
                                                    <Col>
                                                        <Form.Item
                                                            name="postcode"
                                                            label="Postcode"
                                                            rules={[{ required: true, message: '' }]}
                                                            style={{ width: "90px", height: "48px" }}
                                                        >
                                                            <Input style={{ boxShadow: '0px 4px 8px 0px #33333340', borderRadius: '4px' }}
                                                                defaultValue={editingTransaction?.postcode}
                                                                onChange={(e) => setEditingTransaction({ ...editingTransaction, postcode: e.target.value })} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item
                                                            name="city"
                                                            label="City"
                                                            rules={[{ required: true, message: '' }]}
                                                            style={{ width: "222px", height: "48px" }}
                                                        >
                                                            <Input style={{ width: "222px", boxShadow: '0px 4px 8px 0px #33333340', borderRadius: '4px' }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item
                                                            name="state"
                                                            label="State"
                                                            rules={[{ required: true, message: '' }]}
                                                        >
                                                            <Select
                                                                style={{
                                                                    width: "222px",
                                                                    height: "48px",
                                                                    boxShadow: "0px 4px 8px 0px #33333340",
                                                                    borderRadius: "4px",
                                                                }}
                                                                placeholder="Select State"
                                                                defaultValue={editingTransaction?.stateCode}
                                                                onChange={(e) => setEditingTransaction({ ...editingTransaction, stateCode: e.target.value })}
                                                            >
                                                                <Select.Option value="01">Johor</Select.Option>
                                                                <Select.Option value="02">Kedah</Select.Option>
                                                                <Select.Option value="03">Kelantan</Select.Option>
                                                                <Select.Option value="04">Malacca</Select.Option>
                                                                <Select.Option value="05">Negeri Sembilan</Select.Option>
                                                                <Select.Option value="06">Pahang</Select.Option>
                                                                <Select.Option value="07">P. Pinang</Select.Option>
                                                                <Select.Option value="08">Perak</Select.Option>
                                                                <Select.Option value="09">Perlis</Select.Option>
                                                                <Select.Option value="10">Selangor</Select.Option>
                                                                <Select.Option value="11">Terengganu</Select.Option>
                                                                <Select.Option value="12">Sabah</Select.Option>
                                                                <Select.Option value="13">Sarawak</Select.Option>
                                                                <Select.Option value="14">Kuala Lumpur</Select.Option>
                                                                <Select.Option value="15">Labuan</Select.Option>
                                                                <Select.Option value="16">Putrajaya</Select.Option>
                                                            </Select>

                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                {addressLines.map((line, index) => (
                                                    <Form.Item
                                                        key={line}
                                                        name={line}
                                                        label={index === 0 ? "Address" : undefined}
                                                        rules={[{ required: index === 0 }]}
                                                        style={{ marginBottom: "17px" }}
                                                    >
                                                        <Input.TextArea
                                                            autoSize={false}
                                                            style={{ width: "566px", height: "48px", boxShadow: '0px 4px 8px 0px #33333340', borderRadius: '4px', resize: 'none' }}
                                                            defaultValue={editingTransaction?.[line]}
                                                            onChange={(e) =>
                                                                setEditingTransaction((prev) => ({
                                                                    ...prev,
                                                                    [line]: e.target.value,
                                                                }))
                                                            }
                                                        />
                                                    </Form.Item>
                                                ))}
                                                <Button
                                                    type="link"
                                                    icon={<PlusCircleFilled style={{ color: "#2B61C4" }} />}
                                                    style={{
                                                        padding: 0,
                                                        fontSize: "14px",
                                                        fontWeight: 700,
                                                        lineHeight: "17.64px",
                                                        letterSpacing: "-0.5%",
                                                        color: "#2B61C4",
                                                        alignSelf: 'flex-start'
                                                    }}
                                                    onClick={addAddressLine}
                                                >
                                                    ADD ADDRESS LINE {addressLines.length + 1}
                                                </Button>
                                            </>
                                        )}



                                    </Card>

                                    <div style={{ marginTop: 24 }}>
                                        <Button type="primary" onClick={() => setEditMode('view')} style={{ width: 287 }}>Save</Button>
                                    </div>
                                </>
                            )}

                            {editMode === 'contact' && (
                                <>
                                    <div style={{ position: 'fixed', top: '17.5px', left: 8, margin: 0, borderBottom: '1px solid #e0e0e0', width: '95.85%', paddingLeft: 32, paddingBottom: '17.5px', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center', zIndex: 1, backgroundColor: '#FFFFFF' }}>
                                        <Button onClick={() => setEditMode('view')} style={{ width: 44, border: 0, boxShadow: '0' }}><img src={arrowBack} /></Button>
                                        <h2 style={{ fontSize: 20, fontWeight: 700, margin: 0, lineHeight: '24px' }}>
                                            {editingTransaction?.vehicleNo}
                                            <br />
                                            <span style={{ fontSize: 16, fontWeight: 400, color: '#333333' }}>{editingTransaction?.customerName} ({editingTransaction?.idNo})</span>
                                        </h2>
                                    </div>

                                    <Card className="contactDetails">
                                        <h2 className='cardTitle'>Recipient's Contact Details</h2>
                                        <p className='indicatorText'>
                                            <span style={{ color: 'red' }}>*</span> indicates required field
                                        </p>
                                        <Row style={{ gap: '16px', height: '100%' }}>
                                            <Col>
                                                <Form.Item
                                                    name="mobileNumber"
                                                    label="Mobile number"
                                                    rules={[
                                                        { required: true, message: '' },
                                                        { pattern: /^\d{9,11}$/, message: '' },
                                                    ]}
                                                    style={{ width: '275px', height: '80px', marginBottom: 0 }}
                                                >
                                                    <Input placeholder="Enter mobile number" style={{ boxShadow: '0px 4px 8px 0px #33333340', borderRadius: '4px', height: '48px', width: 287 }}
                                                        defaultValue={editingTransaction?.customerContactNo}
                                                        onChange={(e) => setEditingTransaction({ ...editingTransaction, customerContactNo: e.target.value })} />
                                                </Form.Item>
                                                <p style={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', letterSpacing: '-0.5%', marginTop: '8px', marginBottom: 0, color: '#737373', textAlign: 'start' }}>MYEG delivery personnel will contact <br /> this number</p>
                                            </Col>
                                            {/* <Col>
                                                                <Form.Item
                                                                    name="email"
                                                                    label="Email address"
                                                                    rules={[
                                                                        { required: true, message: '' },
                                                                        { type: 'email', message: '' },
                                                                    ]}
                                                                    style={{ width: '275px', height: '80px', marginBottom: 0 }}
                                                                >
                                                                    <Input placeholder="Enter email address" style={{ boxShadow: '0px 4px 8px 0px #33333340', borderRadius: '4px', height: '48px' }} />
                                                                </Form.Item>
                                                            </Col> */}
                                        </Row>

                                    </Card>
                                    <div style={{ marginTop: 24 }}>
                                        <Button type="primary" onClick={() => setEditMode('view')} style={{ width: 287 }}>Save</Button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Modal>


            </div>

            <style>
                {`
            .full-width-radio-group {
                display: flex;
                width: 25%;
            }

            .pointer {
                width: 38px;
                height: 36px;
                border-top: 1px solid black;
                border-right: 1px solid black;
                background-color: white;
                position: absolute;
                right: -20px;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                z-index: 100;
            }

            .ant-radio-button-wrapper {
                flex: 1;
                text-align: left;
                position: relative;
                border-top: 1px solid #2B61C4;
                border-right: none;
                border-radius: 0;
                padding: 10px 10px 10px 20px;
                height: fit-content;
            }

            .custom-input {
                border-radius: 10px;
                height: 30px;
                width: 60px;
                margin-left: 10px;
            }
            `}
            </style>
        </>
    );
}
