import React, { useRef, useEffect, useState } from "react";

const PinchZoomWrapper = ({ children }) => {
    const wrapperRef = useRef(null);
    const imgRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [lastDistance, setLastDistance] = useState(0);

    useEffect(() => {
        const wrapper = wrapperRef.current;
        const img = imgRef.current;

        if (!wrapper || !img) return;

        const handleTouchStart = (e) => {
            if (e.touches.length === 2) {
                setLastDistance(getDistance(e.touches));
            }
        };

        const handleTouchMove = (e) => {
            if (e.touches.length === 2) {
                const currentDistance = getDistance(e.touches);
                if (lastDistance) {
                    const newScale = scale * (currentDistance / lastDistance);
                    setScale(Math.max(1, Math.min(5, newScale))); // Limit zoom to 1x-5x
                    setLastDistance(currentDistance);
                }
            }
        };

        const getDistance = (touches) => {
            const x1 = touches[0].clientX;
            const y1 = touches[0].clientY;
            const x2 = touches[1].clientX;
            const y2 = touches[1].clientY;
            return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        };

        wrapper.addEventListener("touchstart", handleTouchStart);
        wrapper.addEventListener("touchmove", handleTouchMove);

        return () => {
            wrapper.removeEventListener("touchstart", handleTouchStart);
            wrapper.removeEventListener("touchmove", handleTouchMove);
        };
    }, [scale, lastDistance]);

    return (
        <div ref={wrapperRef} style={{ height: "100%" }}>
            {React.cloneElement(children, {
                ref: imgRef,
                style: {
                    ...children.props.style,
                    transform: `scale(${scale})`,
                },
            })}
        </div>
    );
};

export default PinchZoomWrapper;
