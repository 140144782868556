import { Input, Radio, message } from "antd";
import { PageHeader } from '@ant-design/pro-components';
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { SessionContext } from "../../../App";
import {
  searchInTransactions,
  searchI2iTransactions,
  searchOutTransactions,
  searchM2mTransactions,
} from "../../../services/api";
import "./index.less";
import CustomPaginationTable, {
  REPORT_TYPE_LABELS,
  TAB_TYPE,
  STATUS_TYPE,
  SEARCH,
  TAB_TYPE_MESSAGE,
} from "../../../components/CustomPagination/TransactionTable";
import { getPermissions } from "../../../services/local";
import { VehicleSearchForm } from "../../../components/component/Layout/FormComponent";
import { useForm } from "antd/lib/form/Form";

export const ROUTE_PENDING_TYPE = {
  IN: "In",
  OUT: "Out",
  I2I: "i2i",
};

export default function TransferReportBulkPageV2() {
  const location = useLocation();
  const history = useHistory();
  // const { pendingType } = useParams();
  const params = useParams();
  const { pendingType , tab } = params;
  const transferReportUrl = "/reporting/transferreport/";
  // const pendingType =
  //   location.pathname === transferReportUrl + ROUTE_PENDING_TYPE.IN
  //     ? REPORT_TYPE_LABELS.IN
  //     : location.pathname === transferReportUrl + ROUTE_PENDING_TYPE.OUT
  //     ? REPORT_TYPE_LABELS.OUT
  //     : REPORT_TYPE_LABELS.I2I;
  const { company, branch, branchs, user } = useContext(SessionContext);

  const [searchForm] = useForm();

  const historyTabType = tab ?? history.location.state?.tabType ?? "1";

  const [tabType, setTabType] = useState(historyTabType);
  const [jpjSUbmissionCount, setJpjSubmissionCount] = useState(0);
  const [paymentCount, setPaymentCount] = useState(0);
  const [m2mCount, setM2mCount] = useState(0);

  const [permissions, setPermissions] = useState([]);
  // const [reportType, setReportType] = useState(REPORT_TYPE_LABELS.IN);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmptySearch, setIsEmptySearch] = useState(false);
  const [sortBy, setSortBy] = useState("1D");
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceCount, setDataSourceCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const ReceiptType = {
    vehicleDealerCopy: "1",
    confirmationSlip: "2",
    dealerCopy: "3",
  };

  const isI2I = pendingType === REPORT_TYPE_LABELS.I2I;
  const isOUT = pendingType === REPORT_TYPE_LABELS.OUT;
  const isOutTransaction = permissions.outTransaction;
  const isM2mTransaction = permissions.m2mTransaction;
  const isHideM2m = isI2I || (isOUT && isOutTransaction && !isM2mTransaction);
  const isHideOut = isOUT && !isOutTransaction && isM2mTransaction;

  const tabTypeCountMap = isHideM2m
    ? [TAB_TYPE.JPJ_SUBMISSION, TAB_TYPE.PAYMENT]
    : isHideOut
    ? [TAB_TYPE.M2M]
    : [TAB_TYPE.JPJ_SUBMISSION, TAB_TYPE.PAYMENT, TAB_TYPE.M2M];

  // isHideOut && setTabType(TAB_TYPE.M2M)
  // const tabTypeCountMap = !isI2I
  //   ? [TAB_TYPE.JPJ_SUBMISSION, TAB_TYPE.PAYMENT, TAB_TYPE.M2M]
  //   : [TAB_TYPE.JPJ_SUBMISSION, TAB_TYPE.PAYMENT];

  const jpjSubmitTabStyle = tabType === "1" ? "#f0f4fc" : "white";
  const paymentTabStyle = tabType === "2" ? "#f0f4fc" : "white";
  const m2mTabStyle = tabType === "3" ? "#f0f4fc" : "white";
  const CustomRadio = () => {
    return (
      <>
        <div
          style={{ width: "100%", position: "sticky", top: 0, zIndex: 1000 }}
        >
          <Radio.Group
            className="full-width-radio-group"
            defaultValue={tabType}
            onChange={(e) => {
              setTabType(e.target.value);
            }}
          >
            {!isHideOut && (
              <>
                <Radio.Button
                  value="1"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "0px",
                    background: jpjSubmitTabStyle,
                  }}
                >
                  <b>JPJ submission</b>{" "}
                  <Input
                    style={{ background: jpjSubmitTabStyle }}
                    className="custom-input"
                    value={jpjSUbmissionCount}
                    readOnly
                  />
                  {!isHideOut && (
                    <div
                      className="pointer"
                      style={{ background: jpjSubmitTabStyle }}
                    ></div>
                  )}
                </Radio.Button>
                <Radio.Button
                  value="2"
                  style={{
                    background: paymentTabStyle,
                    borderTopRightRadius: isHideM2m ? "10px" : "0px",
                    borderBottomRightRadius: "0px",
                  }}
                >
                  <b style={{ marginLeft: "20px" }}>Payment</b>{" "}
                  <Input
                    style={{ background: paymentTabStyle }}
                    className="custom-input"
                    value={paymentCount}
                    readOnly
                  />
                  {!isHideM2m && !isHideOut && (
                    <div
                      className="pointer"
                      style={{ background: paymentTabStyle }}
                    ></div>
                  )}
                </Radio.Button>
              </>
            )}
            {!isHideM2m && (
              <Radio.Button
                value="3"
                style={{
                  borderTopRightRadius: "10px",
                  background: m2mTabStyle,
                  borderBottomRightRadius: "0px",
                }}
              >
                <b style={{ marginLeft: "20px" }}>M2M</b>{" "}
                <Input
                  style={{ background: m2mTabStyle }}
                  className="custom-input"
                  value={m2mCount}
                  readOnly
                />
              </Radio.Button>
            )}
          </Radio.Group>
        </div>
      </>
    );
  };

  const maxPageSize = 30;
  const REPORT_TYPES = {
    [REPORT_TYPE_LABELS.IN]: {
      [TAB_TYPE.JPJ_SUBMISSION]: {
        api: async (
          currentPage = 1, // deafult initial value 1
          isSearch = false, // deafult initial value false
          sort = "1D", // deafult initial value 1D
          searchValue,
          isEmptySearch = false
        ) => {
          let searchParam = "";
          if (searchTerm !== "") {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          try {
            const res = await searchInTransactions(
              branch.id,
              company.coRegNo,
              maxPageSize,
              currentPage,
              isInitialLoad ? "" : SEARCH,
              isEmptySearch ? "" : searchParam,
              sort ?? sortBy,
              STATUS_TYPE.JPJ_SUBMISSION,
              false
            )

            const dataWithKey = res.data.map((item, index) => {
              return {
                key: item.id,
                ...item,
              };
            });
            if (isSearch && res.count === 0) {
              setDataSource([]);
              setDataSourceCount(0);
              setJpjSubmissionCount(0);
            } else {
              tabType === "1" && setDataSource(dataWithKey);
              tabType === "1" && setDataSourceCount(res.count);
              setJpjSubmissionCount(res.count);
            }
            return res;
          } catch (error) {
            error && message.error(error);
          } finally {
            setIsLoading(false);
          };
        },
      },
      [TAB_TYPE.PAYMENT]: {
        api: async (
          currentPage = 1, // deafult initial value 1
          isSearch = false, // deafult initial value false
          sort = "1D", // deafult initial value 1D
          searchValue,
          isEmptySearch = false
        ) => {
          // const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";

          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          try {
            const res = await searchInTransactions(
              branch.id,
              company.coRegNo,
              maxPageSize,
              currentPage,
              isInitialLoad ? "" : SEARCH,
              isEmptySearch ? "" : searchParam,
              sort ?? sortBy,
              STATUS_TYPE.PAYMENT,
              false
            )
            const dataWithKey = res.data.map((item, index) => {
              return {
                key: item.id,
                ...item,
              };
            });
            if (isSearch && res.count === 0) {
              setDataSource([]);
              setDataSourceCount(0);
              setPaymentCount(0);
            } else {
              tabType === "2" && setDataSource(dataWithKey);
              tabType === "2" && setDataSourceCount(res.count);
              setPaymentCount(res.count);
            }

            return res;
          } catch (error) {
            error && message.error(error);
          } finally {
            setIsLoading(false);
          };
        },
      },
      [TAB_TYPE.M2M]: {
        api: async (
          currentPage = 1, // deafult initial value 1
          isSearch = false, // deafult initial value false
          sort = "1D", // deafult initial value 1D
          searchValue,
          isEmptySearch = false
        ) => {
          // const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          try {
            const res = await searchInTransactions(
              branch.id,
              company.coRegNo,
              maxPageSize,
              currentPage,
              isInitialLoad ? "" : SEARCH,
              isEmptySearch ? "" : searchParam,
              sort ?? sortBy,
              STATUS_TYPE.M2M,
              false
            )
            const dataWithKey = res.data.map((item, index) => {
              return {
                key: item.id,
                ...item,
              };
            });
            if (isSearch && res.count === 0) {
              setDataSource([]);
              setDataSourceCount(0);
              setM2mCount(0);
            } else {
              tabType === "3" && setDataSource(dataWithKey);
              tabType === "3" && setDataSourceCount(res.count);
              setM2mCount(res.count);
            }
            return res;
          } catch (error) {
            error && message.error(error);
          } finally {
            setIsLoading(false);
          };
        },
      },
    },
    [REPORT_TYPE_LABELS.OUT]: {
      [TAB_TYPE.JPJ_SUBMISSION]: {
        api: async (
          currentPage = 1, // deafult initial value 1
          isSearch = false, // deafult initial value false
          sort = "1D", // deafult initial value 1D
          searchValue,
          isEmptySearch = false
        ) => {
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          try {
            const res = await searchOutTransactions(
              branch.id,
              company.coRegNo,
              maxPageSize,
              currentPage,
              isInitialLoad ? "" : SEARCH,
              isEmptySearch ? "" : searchParam,
              sort ?? sortBy,
              STATUS_TYPE.JPJ_SUBMISSION,
              false
            )
            const dataWithKey = res.data.map((item, index) => {
              return {
                key: item.id,
                ...item,
              };
            });
            if (isSearch && res.count === 0) {
              setDataSource([]);
              setDataSourceCount(0);
              setJpjSubmissionCount(0);
            } else {
              tabType === "1" && setDataSource(dataWithKey);
              tabType === "1" && setDataSourceCount(res.count);
              setJpjSubmissionCount(res.count);
            }
            return res;
            
          } catch (error) {
            error && message.error(error);
          } finally {
            setIsLoading(false);
          };
        },
      },
      [TAB_TYPE.PAYMENT]: {
        api: async (
          currentPage = 1, // deafult initial value 1
          isSearch = false, // deafult initial value false
          sort = "1D", // deafult initial value 1D
          searchValue,
          isEmptySearch = false
        ) => {
          // const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }
          try {
            const res = await searchOutTransactions(
              branch.id,
              company.coRegNo,
              maxPageSize,
              currentPage,
              isInitialLoad ? "" : SEARCH,
              isEmptySearch ? "" : searchParam,
              sort ?? sortBy,
              STATUS_TYPE.PAYMENT,
              false
            )

            const dataWithKey = res.data.map((item, index) => {
              return {
                key: item.id,
                ...item,
              };
            });
            if (isSearch && res.count === 0) {
              setDataSource([]);
              setDataSourceCount(0);
              setPaymentCount(0);
            } else {
              tabType === "2" && setDataSource(dataWithKey);
              tabType === "2" && setDataSourceCount(res.count);
              setPaymentCount(res.count);
            }
            return res;
          } catch (error) {
            error && message.error(error);
          } finally {
            setIsLoading(false);
          };
        },
      },
      [TAB_TYPE.M2M]: {
        api: async (
          currentPage = 1, // deafult initial value 1
          isSearch = false, // deafult initial value false
          sort = "1D", // deafult initial value 1D
          searchValue,
          isEmptySearch = false
        ) => {
          // const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          try {
            const res = await searchM2mTransactions(
              branch.id,
              company.coRegNo,
              maxPageSize,
              currentPage,
              isInitialLoad ? "" : SEARCH,
              isEmptySearch ? "" : searchParam,
              sort ?? sortBy,
              STATUS_TYPE.M2M,
              false
            )

            const dataWithKey = res.data.map((item, index) => {
              return {
                key: item.id,
                ...item,
              };
            });
            if (isSearch && res.count === 0) {
              setDataSource([]);
              setDataSourceCount(0);
              setM2mCount(0);
            } else {
              tabType === "3" && setDataSource(dataWithKey);
              tabType === "3" && setDataSourceCount(res.count);
              setM2mCount(res.count);
            }
            
            return res;
          } catch (error) {
            error && message.error(error);
          } finally {
            setIsLoading(false);
          };
        },
      },
    },
    [REPORT_TYPE_LABELS.I2I]: {
      [TAB_TYPE.JPJ_SUBMISSION]: {
        api: async (
          currentPage = 1, // deafult initial value 1
          isSearch = false, // deafult initial value false
          sort = "1D", // deafult initial value 1D
          searchValue,
          isEmptySearch = false
        ) => {
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          try {
            const res = await searchI2iTransactions(
              branch.id,
              company.coRegNo,
              maxPageSize,
              currentPage,
              isInitialLoad ? "" : SEARCH,
              isEmptySearch ? "" : searchParam,
              sort ?? sortBy,
              STATUS_TYPE.JPJ_SUBMISSION,
              false
            )

            const dataWithKey = res.data.map((item, index) => {
              return {
                key: item.id,
                ...item,
              };
            });
            if (isSearch && res.count === 0) {
              setDataSource([]);
              setDataSourceCount(0);
              setJpjSubmissionCount(0);
            } else {
              tabType === "1" && setDataSource(dataWithKey);
              tabType === "1" && setDataSourceCount(res.count);
              setJpjSubmissionCount(res.count);
            }
            
            return res
          } catch (error) {
            error && message.error(error);
          } finally {
            setIsLoading(false);
          };
        },
      },
      [TAB_TYPE.PAYMENT]: {
        api: async (
          currentPage = 1, // deafult initial value 1
          isSearch = false, // deafult initial value false
          sort = "1D", // deafult initial value 1D
          searchValue,
          isEmptySearch = false
        ) => {
          // const status = tabType === TAB_TYPE.JPJ_SUBMISSION ? "2" : "0";
          let searchParam = "";
          if (searchTerm !== "" || searchValue) {
            // setIsLoading(true);
            searchParam = searchValue ? searchValue : searchTerm;
          }

          try {
            const res = await searchI2iTransactions(
              branch.id,
              company.coRegNo,
              maxPageSize,
              currentPage,
              isInitialLoad ? "" : SEARCH,
              isEmptySearch ? "" : searchParam,
              sort ?? sortBy,
              STATUS_TYPE.PAYMENT,
              false
            )
  
            const dataWithKey = res.data.map((item, index) => {
              return {
                key: item.id,
                ...item,
              };
            });
            if (isSearch && res.count === 0) {
              setDataSource([]);
              setDataSourceCount(0);
              setPaymentCount(0);
            } else {
              tabType === "2" && setDataSource(dataWithKey);
              tabType === "2" && setDataSourceCount(res.count);
              setPaymentCount(res.count);
            }
            return res
          } catch (error) {
            error && message.error(error);
          } finally {
            setIsLoading(false);
          };
        },
      },
    },
  };

  const report = REPORT_TYPES[pendingType][tabType];

  const [timer, setTimer] = useState(null);
  const debounceSearch = () => {
    // Clear the previous timer if there's any
    if (timer) {
        clearTimeout(timer);
    }

    // Set a new timer to call the handleSearch function after 1000ms
    const newTimer = setTimeout(() => {
      getPendingLists();
    }, 1000);

    // Update the timer state to clear it later
    setTimer(newTimer);
  };

  const getPendingLists = async () => {
    const apiArray = tabTypeCountMap.map((tab) => {
      return REPORT_TYPES[pendingType][tab].api;
    });

    const responses = await Promise.all(apiArray.map((api) => api()));
    setIsInitialLoad(false);

    if (responses && responses.length) {
      const responseWithData = responses.findIndex(data => data?.count != 0);

      //If got response && current tab dont have result count >> change tab to tab that have result count
      if (responseWithData !== -1 && responses[Number(tabType) - 1]?.count == 0) {
        setTabType(`${responseWithData + 1}`);        

        message.info(`Tab Changed To ${TAB_TYPE_MESSAGE[responseWithData + 1]}`)
      };
    }
  }

  useEffect(() => {
    if (company.coRegNo && branch.id !== null) {
      setIsLoading(true);

      // debounce endpoint call to avoid too many call issue
      debounceSearch();
    }
  }, [company, branch, searchTerm]);

  useEffect(async () => {
    if (company.id) {
      await getPermissions().then(async (permissions) => {
        setPermissions(permissions);
        if (
          permissions &&
          pendingType === REPORT_TYPE_LABELS.OUT &&
          !permissions.outTransaction
        ) {
          setTabType(TAB_TYPE.M2M);
        }
      });
    }
  }, [company]);

  useEffect(() => {
    if (dataSource && dataSource.length === 0 && searchTerm !== "") {
      setIsEmptySearch(true);
    } else {
      setIsEmptySearch(false);
    }
  }, [dataSource]);
  
  const handleSearch = async (isEmptySearch) => {
    const { searchTerm } = searchForm.getFieldsValue();
    setCurrentPage(1);

    // setIsLoading(true);
    let trimmedSearchQuery = ""; // Assign empty string if searchTerm is undefined
    if (typeof searchTerm !== "undefined" || searchTerm !== undefined) {
      // trimmedSearchQuery = searchTerm.replace(/\s/g, "");
      trimmedSearchQuery = searchTerm.trim().toUpperCase();
    }

    setSearchTerm(trimmedSearchQuery);
    // Api call in searchTerm's useEffect()
    // await report.api(1, true, sortBy, trimmedSearchQuery, isEmptySearch);
  };

  const getData = async (currentPage, sort) => {
    if (isInitialLoad) {
      return;
    } 

    setIsLoading(true);
    await report.api(currentPage, false, sort);
  };

  return (
    <>
      <div className="page-content">
        <>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>

            <PageHeader
              style={{ paddingLeft: "0px", marginTop: "40px" }}
              className="site-page-header"
              title={
                pendingType === REPORT_TYPE_LABELS.I2I
                  ? "I2I Pending Transaction"
                  : pendingType === REPORT_TYPE_LABELS.OUT
                  ? "OUT Pending Transaction"
                  : "IN Pending Transaction"
              }
            />
            
            {VehicleSearchForm(
                searchForm,
                searchTerm,
                setSearchTerm,
                handleSearch,
                setIsEmptySearch,
                isEmptySearch
              )}
                  
            </div>
                    
          <CustomRadio />
          <CustomPaginationTable
            pendingType={pendingType}
            tabType={tabType}
            setTabType={setTabType}
            searchTerm={searchTerm}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setSortBy={setSortBy}
            dataSource={dataSource}
            dataSourceCount={dataSourceCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            getData={getData}
          />
          {/* <Card></Card>
          <Card></Card>
          <Table></Table> */}
        </>
      </div>

      <style>
        {`
            .full-width-radio-group {
              display: flex;
              width: 100%;
              // position: relative;
            }

            .pointer {
              width: 38px;
              height: 36px;
              border-top: 1px solid black;
              border-right: 1px solid black;
              background-color: white;
              position: absolute;
              right: -20px;
              top: 50%;
              transform: translateY(-50%) rotate(45deg);
              z-index: 100;
          }

            .ant-radio-button-wrapper {
              flex: 1;
              text-align: left;
              position: relative;
              border: 1px solid black;
              border-right: none;
              border-radius: 0;
              padding: 10px 10px 10px 20px;
              height: fit-content;
              /* align-items: center; */
          }

          .custom-input {
            border-radius: 10px;
            height: 30px;
            width: 60px;
            margin-left: 10px;
        }

            
        `}
      </style>
    </>
  );
}
