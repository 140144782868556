import React, { useState, useContext, useEffect, useMemo } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { SessionContext } from "../../App";
import {
    getRemoteToken,
    removeRemoteToken,
} from "../../services/local";
import {
    contactUsRedmineGetTrackers,
    contactUsRedmineCreateIssue,
    contactUsRedmineGetUserInfo,
    contactUsRedmineSendOtpByEmail,
    contactUsRedmineVerifyOtpByEmail,
    getRemoteUserInfo,
    postVerifyRecaptcha,
} from "../../services/api";

import { PageHeader } from "@ant-design/pro-components";
import {
    Breadcrumb,
    Col,
    Row,
    Card,
    Flex,
    Button,
    Form,
    Select,
    Modal,
    message,
    Spin,
    Input,
    InputNumber,
} from "antd";
import {
    PhoneOutlined,
    EnvironmentOutlined,
    MailOutlined,
    CheckCircleOutlined,
    LoadingOutlined,
    WhatsAppOutlined,
    InfoCircleOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";

export default function ContactUsPage() {
    const { company, user } = useContext(SessionContext);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showEmailValidationOtp, setShowEmailValidationOtp] = useState(false);
    const [ticketResponse, setTicketResponse] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [selectedUser, setSelectedUser] = useState({
        nric: user?.nric || "",
        name: user?.name || "",
        mobileNo: user?.mobileno ? user.mobileno.replace(/^0/, "") : "",
        coName: company?.coName || "",
        coRegNo: company?.coRegNo || "",
    });
    const [loading, setLoading] = useState(true);
    const [trackersList, setTrackersList] = useState([]);
    const [shortCompanyList, setShortCompanyList] = useState([]);

    const [reportIssueForm] = useForm();
    const [emailValidationForm] = useForm();
    const { TextArea } = Input;

    const isMobile = useMemo(() => {
        const screenWidth = window.innerWidth;
        return screenWidth < 768; // Mobile common screen size >> can adjust if needed
    }, [window.innerWidth]);

    useEffect(async () => {
        setLoading(true);

        try {
            const { trackers } = await contactUsRedmineGetTrackers();
            setTrackersList(trackers);
        } catch (error) {
            error && error?.message && message.error(error?.message);
        } finally {
            const autoPopulate = ({
                coName,
                coRegNo,
                shortBranchList,
                nric,
                contactNo,
                name,
                userName,
            }) => {
                setShortCompanyList([
                    {
                        id: 1,
                        coName,
                        coRegNo,
                        shortBranchList,
                        nric,
                        contactNo,
                        name,
                    },
                ]);

                setCurrentUser({
                    coRegNo,
                    coName,
                    name,
                    contactNo,
                    nric,
                    userName,
                });

                setShowForm(true);
            };

            // Prioritize auto populate user information on logged in user
            if (company && company?.coName && company?.coRegNo) {
                autoPopulate({
                    coName: company?.coName,
                    coRegNo: company?.coRegNo,
                    shortBranchList: null,
                    nric: user?.nric,
                    contactNo: user?.mobileno
                        ? user?.mobileno.replace(/^0/, "")
                        : "",
                    name: user?.name,
                    userName: "",
                });

                //Consider logged in if have company / branch information
                setIsLoggedIn(true);
            }

            //Auto populate for activation scenario
            else if (getRemoteToken() && getRemoteToken() !== "") {
                try {
                    const remoteUserResponse = await getRemoteUserInfo({
                        encryptedString: getRemoteToken(),
                    });

                    autoPopulate({
                        coName: remoteUserResponse?.coName,
                        coRegNo: remoteUserResponse?.coRegNo,
                        shortBranchList: null,
                        nric: remoteUserResponse?.nric,
                        contactNo: remoteUserResponse?.contactNo
                            ? remoteUserResponse?.contactNo.replace(/^0/, "")
                            : "",
                        name: remoteUserResponse?.name,
                        userName: remoteUserResponse?.username,
                    });

                    //Removed the remote activation token on success
                    removeRemoteToken();
                } catch (error) {
                    error &&
                        error?.message &&
                        message.error(error?.message.toString());
                }
            }

            //For the sake of useContext update
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    }, [company, company?.coName, company?.coRegNo]);

    const capitalizedStr = (str = "") => {
        return str
            ? str
                  .toLowerCase()
                  .split(" ")
                  .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
                  .join(" ")
            : "";
    };

    const verifyCaptcha = async (response) => {
        try {
            if (!response) throw new Error("No Capcha Response");

            const capchaResponse = await postVerifyRecaptcha({
                secretKey: process.env.REACT_APP_RECAPTCHA_SECRET_KEY,
                response,
            });

            if (capchaResponse && capchaResponse?.success) {
                setCaptchaValue(true);
                reportIssueForm.setFieldsValue({
                    capcha: response,
                });
            } else {
                throw new Error(JSON.stringify(capchaResponse));
            }
        } catch (error) {
            setCaptchaValue(false);

            message.error(error.toString());
        }
    };

    const setCurrentUser = ({
        coRegNo,
        coName,
        name,
        contactNo,
        nric,
        userName,
    }) => {
        message.loading("User information found, Populating...", 1);

        reportIssueForm.setFieldsValue({
            nric: nric,
            companyName: coName,
            contactNo: contactNo,
            name: capitalizedStr(name),
        });

        setSelectedUser({
            ...selectedUser,
            name: name,
            mobileNo: contactNo,
            coName: coName,
            coRegNo: coRegNo,
            userName: userName,
        });
    };

    const onFinish = async (values) => {
        setLoading(true);

        const { nric } = values;
        // if showForm is false (no user info), then we need to populate user information
        if (!showForm) {
            try {
                //Populate user information
                const userInfoResponse = await contactUsRedmineGetUserInfo(
                    nric
                );

                if (userInfoResponse && userInfoResponse?.status) {
                    setShortCompanyList(
                        userInfoResponse?.shortCompanyList.map((company) => ({
                            ...company,
                            nric: nric,
                            contactNo: userInfoResponse?.phoneNo?.replace(
                                /^0/,
                                ""
                            ),
                            name: userInfoResponse?.userFullName,
                            userName: userInfoResponse?.loginName,
                        }))
                    );

                    if (
                        userInfoResponse?.shortCompanyList &&
                        userInfoResponse?.shortCompanyList.length === 1
                    ) {
                        setCurrentUser({
                            coRegNo:
                                userInfoResponse?.shortCompanyList[0]?.coRegNo,
                            coName: userInfoResponse?.shortCompanyList[0]
                                ?.coName,
                            name: userInfoResponse?.userFullName,
                            contactNo: userInfoResponse?.phoneNo
                                ? userInfoResponse?.phoneNo?.replace(/^0/, "")
                                : "",
                            nric: nric,
                            userName: userInfoResponse?.loginName,
                        });
                        setShowForm(true);
                    }
                } else {
                    throw new Error(userInfoResponse?.message);
                }
            } catch (error) {
                message.error(error.toString());
            } finally {
                setLoading(false);
            }
        } else if (!isLoggedIn) {
            // sendEmailOtp
            try {
                const response = await contactUsRedmineSendOtpByEmail({
                    userName: selectedUser?.userName,
                    nric: nric,
                });

                if (response && response?.status) {
                    message.success(response?.message);
                    setShowEmailValidationOtp(true);
                } else {
                    throw new Error(response?.message);
                }
            } catch (error) {
                message.error(error.toString());
            } finally {
                setLoading(false);
            }
        } else {
            createRedmineTicket();
        }
    };

    const createRedmineTicket = async () => {
        try {
            //as of now (15/1/2025) only cater for biometric category
            const { id, name } = trackersList.find((tracker) =>
                tracker.name.toLowerCase().includes("biometric")
            );

            if (id && name) {
                const {
                    contactNo,
                    description,
                    // subCategory,
                    numberOfDevice,
                    typeOfDevice,
                } = reportIssueForm.getFieldsValue();

                const response = await contactUsRedmineCreateIssue({
                    trackerId: id,
                    subject: name,
                    subCategory: "Installation/Troubleshooting", //hardcoded since only 1 option as of 28/1/2025
                    description,
                    phoneNo: `0${contactNo}`, // add back 0
                    rocNumber: selectedUser?.coRegNo,
                    loginName: selectedUser?.name,
                    numberOfDevice: numberOfDevice,
                    deviceType: typeOfDevice,
                });

                if (response && response?.status) {
                    setTicketResponse({
                        message: response?.message,
                        ticketNumber: response?.ticketNumber,
                    });
                    setShowSuccessModal(true);
                } else {
                    setShowSuccessModal(false);
                    throw new Error(response?.message);
                }
            } else {
                throw new Error("Redmine Tracker not found");
            }
        } catch (error) {
            message.error(error);
        }
    };

    return (
        <>
            <div className="page-content">
                <Breadcrumb
                    separator=">"
                    style={{ marginTop: "60px" }}
                    items={[
                        {
                            title: isLoggedIn ? "Dashboard" : "Login",
                            href: isLoggedIn ? "/" : "/login",
                            onClick: (e) => {
                                e.preventDefault();
                                if (isLoggedIn) {
                                    window.location.href = "/";
                                } else {
                                    window.location.href = "/login";
                                }
                            },
                        },
                        {
                            title: "Contact Us",
                        },
                    ]}
                ></Breadcrumb>
                <PageHeader
                    className="site-page-header"
                    style={{ paddingLeft: "0px" }}
                    title="Report Biometric Device Issue"
                />

                {/* Page Content */}
                <>
                    <Row gutter={[16, 16]}>
                        <Col sm={24} md={18}>
                            <Spin spinning={loading}>
                                <Card
                                    style={{
                                        textAlign: "justify",
                                        minHeight: "25vh",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: isMobile
                                                ? "column"
                                                : "row",
                                        }}
                                    >
                                        <div style={{ minWidth: "75%" }}>
                                            <Form
                                                name="reportIssueForm"
                                                labelAlign="left"
                                                form={reportIssueForm}
                                                layout={
                                                    showForm
                                                        ? "horizontal"
                                                        : "vertical"
                                                }
                                                labelCol={{
                                                    span: showForm ? 8 : 24,
                                                }}
                                                wrapperCol={{
                                                    span: showForm ? 16 : 24,
                                                }}
                                                style={{
                                                    maxWidth: 800,
                                                    display: showForm
                                                        ? "inline"
                                                        : "block",
                                                    textAlignLast: showForm
                                                        ? ""
                                                        : "center",
                                                }}
                                                onFinish={onFinish}
                                                // onFinishFailed={onFinishFailed}
                                                requiredMark={false}
                                                autoComplete="off"
                                                // initialValues={{
                                                //     subCategory: "Installation",
                                                // }}
                                            >
                                                <Form.Item
                                                    label="Mykad Number"
                                                    name="nric"
                                                    style={{
                                                        font: "italic normal normal 16px/22px Open Sans",
                                                        color: "#333333",
                                                    }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Please search using your NRIC",
                                                        },
                                                        {
                                                            required: true,
                                                            pattern: new RegExp(
                                                                "^[0-9]{12}$"
                                                            ),
                                                            message:
                                                                "Accepts only numbers (length must be 12)",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="input-width"
                                                        placeholder={
                                                            "Enter mykad number"
                                                        }
                                                        style={{
                                                            border: "1px solid #2B61C4",
                                                            borderRadius: "6px",
                                                            font: "normal normal 600 15px/20px Open Sans",
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (
                                                                !/[0-9]/.test(
                                                                    e.key
                                                                ) &&
                                                                e.key !==
                                                                    "Backspace" &&
                                                                e.key !==
                                                                    "Delete" &&
                                                                !(
                                                                    (e.ctrlKey ||
                                                                        e.metaKey) &&
                                                                    (e.key ===
                                                                        "c" ||
                                                                        e.key ===
                                                                            "v")
                                                                ) // enable copy and paste
                                                            ) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={12}
                                                        disabled={showForm}
                                                    />
                                                </Form.Item>

                                                {shortCompanyList &&
                                                    shortCompanyList.length !==
                                                        0 && (
                                                        <>
                                                            <Form.Item
                                                                label="Company Name"
                                                                name="companyName"
                                                                style={{
                                                                    font: "italic normal normal 16px/22px Open Sans",
                                                                    color: "#333333",
                                                                }}
                                                            >
                                                                <Select
                                                                    className="input-width"
                                                                    placeholder="Select a company"
                                                                    style={{
                                                                        border: "1px solid #2B61C4",
                                                                        borderRadius:
                                                                            "6px",
                                                                        font: "normal normal 600 15px/20px Open Sans",
                                                                    }}
                                                                    onChange={(
                                                                        value
                                                                    ) => {
                                                                        const selectedCompay =
                                                                            shortCompanyList.find(
                                                                                (
                                                                                    company
                                                                                ) =>
                                                                                    company.coRegNo ===
                                                                                    value
                                                                            );

                                                                        setCurrentUser(
                                                                            {
                                                                                coRegNo:
                                                                                    selectedCompay.coRegNo,
                                                                                coName: selectedCompay?.coName,
                                                                                name: selectedCompay?.name,
                                                                                contactNo:
                                                                                    selectedCompay?.contactNo,
                                                                                nric: selectedCompay?.nric,
                                                                                userName:
                                                                                    selectedCompay?.userName,
                                                                            }
                                                                        );
                                                                        setShowForm(
                                                                            true
                                                                        );
                                                                    }}
                                                                    options={shortCompanyList.map(
                                                                        (
                                                                            company
                                                                        ) => ({
                                                                            value: company.coRegNo,
                                                                            label: company.coName,
                                                                        })
                                                                    )}
                                                                    disabled={
                                                                        shortCompanyList.length ===
                                                                        1
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </>
                                                    )}

                                                {showForm && (
                                                    <>
                                                        {/* <Form.Item
                                                            label="Company SSM Number"
                                                            style={{
                                                                font: "italic normal normal 16px/22px Open Sans",
                                                                color: "#333333",
                                                            }}
                                                        >
                                                            {selectedUser?.coRegNo ? (
                                                                capitalizedStr(
                                                                    selectedUser?.coRegNo
                                                                )
                                                            ) : (
                                                                <Spin
                                                                    indicator={
                                                                        <LoadingOutlined
                                                                            spin
                                                                        />
                                                                    }
                                                                    size="small"
                                                                    style={{
                                                                        paddingLeft:
                                                                            "20px",
                                                                    }}
                                                                />
                                                            )}
                                                        </Form.Item> */}

                                                        <Form.Item
                                                            label="Name"
                                                            name="name"
                                                            style={{
                                                                font: "italic normal normal 16px/22px Open Sans",
                                                                color: "#333333",
                                                            }}
                                                        >
                                                            <Input
                                                                className="input-width"
                                                                placeholder={
                                                                    "Enter user Name"
                                                                }
                                                                style={{
                                                                    border: "1px solid #2B61C4",
                                                                    borderRadius:
                                                                        "6px",
                                                                    font: "normal normal 600 15px/20px Open Sans",
                                                                }}
                                                                disabled={
                                                                    showForm
                                                                }
                                                            />
                                                        </Form.Item>

                                                        <Form.Item
                                                            label="Contact No"
                                                            name="contactNo"
                                                            style={{
                                                                font: "italic normal normal 16px/22px Open Sans",
                                                                color: "#333333",
                                                            }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        "Please input your contact number",
                                                                },
                                                                {
                                                                    required: true,
                                                                    pattern:
                                                                        new RegExp(
                                                                            "^\\d{9,11}$"
                                                                        ),
                                                                    message:
                                                                        "Accepts only numbers (length must be between 9 - 11)",
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                className="input-width"
                                                                placeholder="Mobile No"
                                                                style={{
                                                                    height: "100%",
                                                                    border: "1px solid #2B61C4",
                                                                    borderRadius:
                                                                        "6px",
                                                                    font: "normal normal 600 15px/20px Open Sans",
                                                                }}
                                                                addonBefore={
                                                                    <PhoneOutlined />
                                                                }
                                                                prefix="+60"
                                                                controls={false}
                                                            />
                                                        </Form.Item>

                                                        {/* <Form.Item
                                                            label="Category"
                                                            style={{
                                                                font: "italic normal normal 16px/22px Open Sans",
                                                                color: "#333333",
                                                            }}
                                                        >
                                                            Biometric
                                                        </Form.Item> */}

                                                        {/* <Form.Item
                                                            label="Sub-Category"
                                                            name="subCategory"
                                                            style={{
                                                                font: "italic normal normal 16px/22px Open Sans",
                                                                color: "#333333",
                                                            }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        "Please select a sub-category",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                className="input-width"
                                                                placeholder="Select a sub-category"
                                                                style={{
                                                                    border: "1px solid #2B61C4",
                                                                    borderRadius: "6px",
                                                                    font: "normal normal 600 15px/20px Open Sans",
                                                                }}
                                                                onChange={(value) => {
                                                                    reportIssueForm.setFieldsValue(
                                                                        {
                                                                            subcategory:
                                                                                value,
                                                                        }
                                                                    );
                                                                }}
                                                                options={[
                                                                    {
                                                                        value: "Installation",
                                                                        label: "Installation",
                                                                    },
                                                                    {
                                                                        value: "Troubleshooting",
                                                                        label: "Troubleshooting",
                                                                    },
                                                                    {
                                                                        value: "Logic",
                                                                        label: "Logic",
                                                                    },
                                                                ]}
                                                                disabled
                                                            />
                                                        </Form.Item> */}

                                                        <Form.Item
                                                            label="Type of Device"
                                                            name="typeOfDevice"
                                                            style={{
                                                                font: "italic normal normal 16px/22px Open Sans",
                                                                color: "#333333",
                                                            }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        "Please select type of device",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                className="input-width"
                                                                placeholder="Select type of device"
                                                                style={{
                                                                    border: "1px solid #2B61C4",
                                                                    borderRadius:
                                                                        "6px",
                                                                    font: "normal normal 600 15px/20px Open Sans",
                                                                }}
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    reportIssueForm.setFieldsValue(
                                                                        {
                                                                            typeOfDevice:
                                                                                value,
                                                                        }
                                                                    );
                                                                }}
                                                                options={[
                                                                    {
                                                                        value: "Dermalog",
                                                                        label: "Dermalog device",
                                                                    },
                                                                    {
                                                                        value: "Sagem",
                                                                        label: "Sagem device",
                                                                    },
                                                                ]}
                                                            />
                                                        </Form.Item>

                                                        <Form.Item
                                                            label="Number of Device"
                                                            name="numberOfDevice"
                                                            style={{
                                                                font: "italic normal normal 16px/22px Open Sans",
                                                                color: "#333333",
                                                            }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        "Please select number of device",
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                className="input-width"
                                                                placeholder="Select number of device"
                                                                style={{
                                                                    border: "1px solid #2B61C4",
                                                                    borderRadius:
                                                                        "6px",
                                                                    font: "normal normal 600 15px/20px Open Sans",
                                                                }}
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    reportIssueForm.setFieldsValue(
                                                                        {
                                                                            numberOfDevice:
                                                                                value,
                                                                        }
                                                                    );
                                                                }}
                                                                options={[
                                                                    {
                                                                        value: "1",
                                                                        label: "1",
                                                                    },
                                                                    {
                                                                        value: "2",
                                                                        label: "2",
                                                                    },
                                                                    {
                                                                        value: "3",
                                                                        label: "3",
                                                                    },
                                                                ]}
                                                            />
                                                        </Form.Item>

                                                        <Form.Item
                                                            label="Description"
                                                            name="description"
                                                            style={{
                                                                font: "italic normal normal 16px/22px Open Sans",
                                                                color: "#333333",
                                                            }}
                                                        >
                                                            <TextArea
                                                                rows={6}
                                                                className="input-width"
                                                                style={{
                                                                    border: "1px solid #2B61C4",
                                                                    borderRadius:
                                                                        "6px",
                                                                    font: "normal normal 600 15px/20px Open Sans",
                                                                }}
                                                                maxLength={255}
                                                                placeholder="Description of the biometric device installation. 
                                                        For all other enquiries, please call our Helpline at 03-7664 8181 or email us at twocar@myeg.com.my"
                                                            />
                                                        </Form.Item>

                                                        <Form.Item
                                                            label={null}
                                                            name="capcha"
                                                            rules={[
                                                                !captchaValue && {
                                                                    required: true,
                                                                    message:
                                                                        "Please verify reCaptcha",
                                                                },
                                                            ]}
                                                            wrapperCol={{
                                                                span: 24,
                                                            }} // Full width for reCAPTCHA
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                overflowX:
                                                                    "scroll",
                                                                overflowY:
                                                                    "hidden",
                                                                scrollbarWidth:
                                                                    "none",
                                                                msOverflowStyle:
                                                                    "none",
                                                                paddingLeft:
                                                                    isMobile
                                                                        ? "0px"
                                                                        : "10vw",
                                                            }}
                                                        >
                                                            <ReCAPTCHA
                                                                sitekey={
                                                                    process.env
                                                                        .REACT_APP_RECAPTCHA_SITE_KEY
                                                                }
                                                                onChange={
                                                                    verifyCaptcha
                                                                }
                                                                // theme="dark"
                                                            />
                                                        </Form.Item>
                                                    </>
                                                )}

                                                <Form.Item
                                                    label={null}
                                                    style={{
                                                        paddingLeft: isMobile
                                                            ? "0px"
                                                            : "10vw",
                                                        paddingRight: !isMobile
                                                            ? showForm
                                                                ? "0px"
                                                                : "10vw"
                                                            : "0px",
                                                    }}
                                                >
                                                    <Button
                                                        style={{
                                                            width: "50%",
                                                        }}
                                                        type="primary"
                                                        htmlType="submit"
                                                        // disabled={submitDisabled}
                                                    >
                                                        {showForm
                                                            ? "Submit"
                                                            : "Search"}
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "start",
                                                justifyContent: "end",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    fontSize: "12px",
                                                    color: "#2196F3",
                                                    fontWeight: "bold",
                                                    marginTop: "0px",
                                                    marginBottom: "10px",
                                                    padding: "10px",
                                                    backgroundColor: "#E5E5EA",
                                                    border: "1px solid #DDD",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <InfoCircleOutlined /> Important
                                                Notice: This form is temporarily
                                                only applicable for
                                                troubleshooting or installation
                                                of biometric devices. Please
                                                contact our helpline if you need
                                                other assistance.
                                            </p>
                                        </div>
                                    </div>
                                </Card>
                            </Spin>
                        </Col>
                        <Col sm={24} md={6}>
                            <Card style={{ textAlign: "left" }}>
                                <Flex gap="middle" vertical>
                                    <Row>
                                        <Col flex="35px">
                                            <PhoneOutlined
                                                style={{
                                                    fontSize: "18px",
                                                    color: "#2B61C4",
                                                }}
                                            />
                                        </Col>
                                        <Col flex="100">
                                            <div>
                                                Level UG, MYEG Tower, Empire
                                                City, No 8, Jalan Damansara,
                                                Jalan PJU 8/3a, 47820 Petaling
                                                Jaya, Selangor
                                            </div>
                                            <div>
                                                <a
                                                    href={`https://www.google.com/maps/place/@3.1696965210421832,101.61550899620703,20z`}
                                                    target="_blank"
                                                >
                                                    Get directions
                                                </a>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col flex="35px">
                                            <EnvironmentOutlined
                                                style={{
                                                    fontSize: "18px",
                                                    color: "#2B61C4",
                                                }}
                                            />
                                        </Col>
                                        <Col flex="100">
                                            <a
                                                href={`tel:0376648181`}
                                                target="_blank"
                                            >
                                                03-7664-8181
                                            </a>
                                            <div>
                                                <strong>9 AM - 5 PM</strong>{" "}
                                                Monday to Friday
                                            </div>
                                            <div>
                                                Closed on national public
                                                holidays
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col flex="35px">
                                            <MailOutlined
                                                style={{
                                                    fontSize: "18px",
                                                    color: "#2B61C4",
                                                }}
                                            />
                                        </Col>
                                        <Col flex="100">
                                            <a
                                                href={`mailto:twocar@myeg.com.my`}
                                                target="_blank"
                                            >
                                                twocar@myeg.com.my
                                            </a>
                                        </Col>
                                    </Row>
                                </Flex>
                            </Card>
                        </Col>
                    </Row>
                </>

                {/* Modal */}
                <Modal
                    open={showEmailValidationOtp}
                    footer={null}
                    onCancel={() => {
                        setLoading(false);
                        setShowEmailValidationOtp(false);
                        emailValidationForm.resetFields(["emailOtp"]);
                    }}
                >
                    <h2
                        style={{
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Email OTP Verification
                    </h2>
                    <Form
                        layout="vertical"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                        requiredMark={false}
                        form={emailValidationForm}
                        onFinish={async (value) => {
                            try {
                                const { emailOtp } = value;

                                const otpResponse =
                                    await contactUsRedmineVerifyOtpByEmail({
                                        userName: selectedUser?.userName,
                                        emailOtp,
                                    });

                                if (otpResponse && otpResponse?.status) {
                                    message.success(otpResponse?.message);
                                    createRedmineTicket();
                                    emailValidationForm.resetFields([
                                        "emailOtp",
                                    ]);
                                    setShowEmailValidationOtp(false);
                                } else {
                                    throw new Error(otpResponse?.message);
                                }
                            } catch (error) {
                                message.error(error.toString());
                            }
                        }}
                    >
                        <Form.Item
                            style={{ color: "grey", fontStyle: "italic" }}
                            name="emailOtp"
                            requiredMark={"optional"}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter valid OTP",
                                },
                                {
                                    pattern: new RegExp("^[0-9]{4}$"),
                                    message: "OTP must be 4 digits",
                                },
                            ]}
                        >
                            <Input.OTP
                                autoFocus
                                length={4}
                                onKeyDown={(e) => {
                                    if (/[0-9]/.test(e.key)) {
                                    } else if (
                                        e.key === "Backspace" ||
                                        e.key === "Delete"
                                    ) {
                                        let otp =
                                            emailValidationForm.getFieldValue(
                                                "emailOtp"
                                            );
                                        emailValidationForm.setFieldsValue({
                                            emailOtp: otp.slice(0, -1),
                                        });
                                    } else {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                style={{ width: "20vw" }}
                                htmlType="submit"
                            >
                                Confirm
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title=""
                    centered
                    open={showSuccessModal}
                    onCancel={() => {
                        reportIssueForm.resetFields([
                            "typeOfDevice",
                            "numberOfDevice",
                            "description",
                            "capcha",
                        ]);

                        setShowSuccessModal(false);
                    }}
                    footer={null}
                    maskClosable={false}
                    afterClose={() => setLoading(false)}
                >
                    <div style={{ textAlign: "center" }}>
                        <CheckCircleOutlined
                            style={{
                                fontSize: "100px",
                                color: "#25D366",
                            }}
                        />
                        <br />
                        <br />
                        {ticketResponse?.message}
                        <br />
                        <br />
                        <a
                            href={`https://wa.me/60376648181?text=Hi, my ticket number is ${encodeURIComponent(
                                ticketResponse?.ticketNumber
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Button
                                type="primary"
                                size="middle"
                                style={{
                                    width: "35%",
                                    marginBottom: "10px",
                                    backgroundColor: "#25D366",
                                    borderColor: "#25D366",
                                    boxShadow: "0px 3px 6px #00000029",
                                }}
                                icon={
                                    <WhatsAppOutlined
                                        style={{ fontSize: "22px" }}
                                    />
                                }
                            >
                                Chat With Us
                            </Button>
                        </a>
                    </div>
                </Modal>
            </div>

            <style>
                {`
                    .input-width {
                        width: ${showForm ? "95%" : "50%"} !important;
                        height: 35px;
                    }

                    @media (max-width: 768px) {
                        .input-width {
                            width: 100% !important;
                        }
                    }
                `}
            </style>
        </>
    );
}
