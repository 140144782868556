import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useParams, useLocation } from "react-router-dom"
import { message, Spin } from 'antd'
import { getCachedStates, removeCachedStates,
  getCompanyId,
  // setToken,
  setRemoteToken,
  getRemoteToken,
} from '../../services/local'
import AuthorizeRemoteUser from './authorizeRemoteUser'
import moment from 'moment'
import { STEPS } from "./steps"
import { STATES, STATES_FULLNAME } from '../../constants'
// import { getAccountDetails, isAccountExist } from '../../services/api'
import {
  getState
} from '../../util'
import {skipAuth, decodeForSagem, decryptUrlVerify} from '../../components/Utils'
import { SessionContext } from "../../App";
import VerificationMethodsPage from "../VerificationMethodsPage"

function RemoteUserActivationPage(props) {

  const { user, isLoading } = useContext(SessionContext);
  const location = useLocation()
  const history = useHistory()
  const params = useParams()
  const { token, isTesting } = params
  const [isValidThumbprint, setIsValidThumbprint] = useState(false)
  const [ activateToken, setActivateToken] = useState(token);

  const [remoteUser, setRemoteUser] = useState({
    address1: '',
    address2: '',
    address3: '',
    dob: '',
    birthplace: '',
    citizenship: '',
    city: "",
    confirmEmail: "",
    email: '',
    gender: '',
    id: 0,
    mobileNo: '',
    personName: '',
    nricNo: '',
    identificationNo: '',
    phoneNo: '',
    postcode: '',
    race: '',
    religion: '',
    state: '',
    roles: [],
  })

  /**
       * Explanation:
       * useMemo essentially memorises the state of whatever that is in return {} bracket. 
       * [] denotes the dependency for WHEN a new object should be built and memorised. (just like dependency for useEffect)
       * In this case: any update to mobileNo/email/identificationNo should return a new remoteUser object and memorise it
       */
  // const branchUserMemo = useMemo(() => {
  //   return { ...remoteUser };
  // }, [remoteUser.mobileNo, remoteUser.email, remoteUser.identificationNo]);
  const COMPONENTS = [
    {
      label: STEPS.VERIFICATION_METHODS_PAGE.label,
      component: <VerificationMethodsPage
        type={"activate-remote-user"}
        thumbPrintRedirect={{activateToken : activateToken, isTesting: isTesting, pathname: history.location.pathname}}
      />
    },
    {
      title: 'Authorize Remote User',
      label: STEPS.REMOTE_USER_ACTIVATION.label,
      component: <AuthorizeRemoteUser
        remoteUser={history.location?.state?.remoteUser ?? remoteUser}
        token={history.location?.state?.activateToken ?? activateToken}
        isTesting={history.location?.state?.isTesting ?? isTesting}
        isValidThumbprint={history.location?.state?.isValidThumbprint || isValidThumbprint}
        isMyDigitalId={history.location?.state?.isMyDigitalId ? true : false}
        onChange={_remoteUser => {
          history.push(history.location.pathname, {
            ...history.location.state,
            remoteUser: _remoteUser,
          })
          if(activateToken !== undefined && activateToken !== "" && remoteUser.identificationNo != "") {
            history.push(history.location.pathname, {
              ...history.location.state,
              step: STEPS.REMOTE_USER_ACTIVATION.label,
              subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY,
              remoteUser: _remoteUser,
              isValidThumbprint: isValidThumbprint
            })
          }
          setRemoteUser(_remoteUser)
        }}
      />
    },
  ]

  useEffect(()=> {
    if(activateToken === "true" || activateToken === true) {
      if(getRemoteToken() === undefined || getRemoteToken() === null) {
        message.error("Invalid token, redirect to login...")
        history.push('/login')
        return;
      }
      setActivateToken(getRemoteToken())
    }
    else {
      if(activateToken !== undefined && activateToken !== getRemoteToken()) {
        history.push(history.location.pathname, {
          step: STEPS.VERIFICATION_METHODS_PAGE.label,
        })
      }
      setRemoteToken(activateToken)
    }
  }, [])

  useEffect(async () => {
    const urlParams = new URLSearchParams(location.search);
    // const cached = JSON.parse(getCachedStates())
    // removeCachedStates()


    // const decodedValues = decodeURIComponent(urlParams.get('values') || '{}')
    // const values = JSON.parse(decodedValues)
    // const decodedForKey = decodeURIComponent(urlParams.get('forKey') || '{}')
    if (urlParams.get('forKey') != null && urlParams.get('forKey') != 'undefined') {

      let values;
      let decodedValues;
      const decodedForKey = decodeURIComponent(urlParams.get('forKey'))
      const cached = JSON.parse(getCachedStates())
      const isSkipAuth = skipAuth(false, null, null, urlParams, user?.nric ?? "960930085679")
      if (isSkipAuth) {
          values = isSkipAuth
          removeCachedStates()
          if(activateToken) {
            await decryptUrlVerify(values,
                "",
                decodedForKey,
                getCompanyId(),
                "SAGEM",
                values['Ic No.'].replace(/[\s*]/g, ""),
                true,
                isTesting,
                );
              if(isTesting) setIsValidThumbprint(true)
          }
      }
      else {
          if (urlParams.get('tk') != null && urlParams.get('dt') != null) {
              decodedValues = await decodeForSagem(
                  decodeURIComponent(urlParams.get('tk')), 
                  decodeURIComponent(urlParams.get('dt')),
                  urlParams.get('forKey'),
                  history,
                  STEPS,
                  "REMOTESTAFF")
              if (decodedValues === undefined) {
                  message.error("Please try again.")
                  history.push(history.location.pathname, {
                    ...history.location.state,
                    step: STEPS.REMOTE_USER_ACTIVATION.label,
                    subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC
                  })
                  return
              }
          }
          else {
              message.error("Please try again.")
              history.push(history.location.pathname, {
                ...history.location.state,
                step: STEPS.REMOTE_USER_ACTIVATION.label,
                subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC
              })
              return
          }

          removeCachedStates()

          // const decodedValues = decodeURIComponent(urlParams.get('values') || '{}')
          values = JSON.parse(decodedValues)
          if(activateToken) {
            const decryptResponse = await decryptUrlVerify(values,
                urlParams.get('tk'),
                decodedForKey,
                getCompanyId(),
                "SAGEM",
                values['Ic No.'].replace(/[\s*]/g, ""),
                true,
                isTesting,
                );
            if(decryptResponse !== false) {
              setIsValidThumbprint(true)
              /* history.push(history.location.pathname, {
                ...history.location.state,
                step: STEPS.REMOTE_USER_ACTIVATION.label,
                subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC
              })
                return */
            }
          }
      }
      await new Promise(resolve => setTimeout(resolve, 2000));

      if (values['ThumbSucess'] === 'true') {

        let errorMessages = [];

        if (!values['Name']) {
          errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + values['Name'] + "'. Please contact the eTukar Admin for more information.");
        }

        if (!values['City']) {
          errorMessages.push("Failed to read the District from MyKad. The District read as '" + values['City'] + "'. Please contact the eTukar Admin for more information.");
        }

        if (!values['Ic No.'] || !/^[+]?\d+([.]\d+)?[*]?$/.test(values['Ic No.'])) {
          errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + values['Ic No.'] + "'. Please contact the eTukar Admin for more information.");
        }

        if (errorMessages.length > 0) {
          for (let errorMessage of errorMessages) {
            message.error(errorMessage);
          }
          history.push(history.location.pathname, {
            ...history.location.state,
            step: STEPS.REMOTE_USER_ACTIVATION.label,
            subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC
          })
          return;
        }

        let stateKey, state, formatted, dob, photo, formattedState
        switch (decodedForKey) {
          case 'remoteUser':

            formattedState = values['State']?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
            state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

            if (!state) state = getState(values['Ic No.'])

            if (moment(values['Date of Birth'], 'DD/M/YYYY HH').isValid()) {

              dob = moment(values['Date of Birth'], 'DD/M/YYYY HH').format('YYYY-MM-DD')

            } else {

              let ic = values['Ic No.']

              let Year = ic.substring(0, 2)
              let Month = ic.substring(2, 4)
              let Day = ic.substring(4, 6)

              let cutoff = (new Date()).getFullYear() - 2000

              let fulldob = Day + '/' + Month + '/' + (Year > cutoff ? '19' : '20') + Year

              if (moment(fulldob, 'DD/M/YYYY HH').isValid()) {
                dob = moment(fulldob, 'DD/M/YYYY HH').format('YYYY-MM-DD')
              } else {
                dob = ''
              }
            }

            if (dob == 'Invalid date') dob = ''

            formatted = {
              address1: values['Address 1'],
              address2: values['Address 2'],
              address3: values['Address 3'],
              birthplace: values['Place of Birth'],
              citizenship: values['Warganegara'],
              city: values['City'].replace(/[^a-zA-Z\s]/g, ''),
              gender: values['Gender'],
              mobileNo: '',
              personName: values['Name'].replace(/\*/g, ''),
              // 27/3/2022 daniel.kwok the model for user is really confusing, especially for nric.
              // multiple keys for "Ic No." is used here as fall back values
              nricNo: values['Ic No.'],
              identificationNo: values['Ic No.'],
              phoneNo: '',
              postcode: values['PostCode'],
              race: values['Race'],
              religion: values['Religion'],
              state: state?.code,
              dob: dob,
              email: '',
              photo: values['Photo'],
            }

            setRemoteUser(formatted)

            if(activateToken !== undefined && activateToken !== null) {
              history.push(history.location.pathname, {
                ...cached,
                remoteUser: formatted,
                token : activateToken,
                isValidThumbprint : isValidThumbprint,
                isTesting : isTesting,
                step: STEPS.REMOTE_USER_ACTIVATION.label,
                subStep: formatted.identificationNo != "" ? 
                    STEPS.REMOTE_USER_ACTIVATION.subSteps.SUMMARY : STEPS.REMOTE_USER_ACTIVATION.subSteps.INSERT_IC
              })
            }
            else {
              history.push(history.location.pathname, {
                ...cached,
                remoteUser: formatted,
                // token : activateToken,
                // isValidThumbprint : isValidThumbprint,
                // isTesting : isTesting
              })
            }

            break
          default:
            break
        }

      } else if (values['ThumbSucess'] !== 'true' && decodedForKey != '{}') {
        message.error('Thumbprint is not sucess')
        history.push(history.location.pathname, {
          step: STEPS.REMOTE_USER_ACTIVATION.label,
          subStep: STEPS.REMOTE_USER_ACTIVATION.subSteps.THUMBPRINT_FAILED
        })
        return
      }
    }
  }, [])

  const component = COMPONENTS.find(step => step.label === (history.location.state && history.location.state.step)) || COMPONENTS[0]

  /**
       * Get email and phone number, when provided identificationNo
       * only triggers when there are update to branchUserMemo
       */
  // useEffect(() => {
  //   if (remoteUser.identificationNo) {
  //     isAccountExist(remoteUser.identificationNo)
  //       .then((res) => {
  //         if (res.status == true) {
  //           getAccountDetails(remoteUser.identificationNo)
  //             .then((res) => {
  //               setRemoteUser({
  //                 ...remoteUser,
  //                 mobileNo: res.mobileno,
  //                 email: res.email
  //               })
  //             })
  //             .catch((err) => message.error(err));
  //         }
  //       })
  //       .catch((err) => message.error(err));
  //   }
  // }, [branchUserMemo]);

    return (
        <>
            <Spin spinning={isLoading}>
                <div className="page-content" style={{ paddingTop: "3%" }}>
                    {component.label ===
                    STEPS.VERIFICATION_METHODS_PAGE.label ? (
                        <div>{component?.component}</div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            {component?.component}
                        </div>
                    )}
                </div>
            </Spin>
        </>
    );
}

export default RemoteUserActivationPage;
