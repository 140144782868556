import React from "react";
import { Helmet } from "react-helmet";

const mainTitle = `Twocar`;
const mainDescription =
  "Simplify vehicle transfers with the MyEG Twocar system. Now you can transfer vehicles online from anywhere, hassle-free.";

const keywords = [
  "Vehicle ownership transfer process",
  "Transfer vehicle title",
  "Vehicle title transfer requirements",
  "How to transfer vehicle ownership",
  "Transfer vehicle registration",
  "Vehicle ownership transfer form",
  "Transfer of vehicle ownership procedure",
  "Vehicle ownership transfer fees",
  "Vehicle ownership transfer documents",
  "Transfer car ownership checklist",
  "Selling a car: ownership transfer",
  "Vehicle transfer of ownership guidelines",
  "Vehicle ownership transfer laws",
  "Transfer vehicle ownership online",
  "Steps to transfer vehicle ownership",
  "car ownership transfer Malaysia",
  "motorbike ownership transfer Malaysia",
  "vehicle ownership transfer process Malaysia",
  "JPJ vehicle transfer Malaysia",
  "transfer vehicle ownership Malaysia cost",
  "online vehicle transfer Malaysia",
  "used car ownership transfer Malaysia",
  "new car ownership transfer Malaysia",
  "inheritance vehicle transfer Malaysia",
  "gift vehicle transfer Malaysia",
  "loan on vehicle ownership transfer Malaysia",
  "how to transfer car ownership in Malaysia",
  "documents needed for vehicle transfer Malaysia",
  "steps for motorbike ownership transfer JPJ Malaysia",
  "cost of transferring car ownership to family member Malaysia",
  "online vehicle transfer portal Malaysia",
"car transfer",
]

export default function MetaTagsManager() {
  return (
    <>
      <Helmet>
        <title translate="no">{mainTitle}</title>
        <meta name="description" content={mainDescription} />
        <meta name="keywords" content={keywords} />
        {process.env.REACT_APP_ENVIRONMENT === 'prd' && (
          <meta name="robots" content="index, follow" />
        )}
      </Helmet>
    </>
  );
}
