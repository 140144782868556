import React, { useEffect, useState, useRef, useMemo, useContext } from "react";
import { Card, message, Row, Col, Breadcrumb, Button, Image } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import arrow_back from "../../assets/arrow_back.svg";
import { useHistory } from "react-router-dom";

import {
    getWebWebQr,
    postLoginSession,
    check2FA,
    checkOtpStatus,
    getRemoteUserInfo,
    getVerifySession,
} from "../../services/api";
import { config, links } from "../../config";
import { APP_CONST, ERROR_MESSAGE_LOGIN_NOT_ACTIVATED } from "../../constants";
import {
    setToken,
    setUnverifiedAccount,
    setIsAuthorizeToAccessUserManagement,
    getAuthorizedNric,
    removeAuthorizedNric,
    setAuthUid,
    setLoggedInTime,
    setAuthorizedNric,
} from "../../services/local";

import { SyncOutlined, LoadingOutlined, DownOutlined } from "@ant-design/icons";
import { SessionContext } from "../../App";

import dayjs from "dayjs";

export default function MydIdPage() {
    const wsRef = useRef(null);
    const { user } = useContext(SessionContext);
    const history = useHistory();

    const { verifyType, thumbPrintRedirect } = history?.location?.state || {
        verifyType: null,
    };

    const [isLoading, setIsLoading] = useState(true);
    const [showDetail, setShowDetail] = useState(false);
    const [qrBase64, setQrBase64] = useState("");
    const [mobileRedirectUrl, setMobileRedirectUrl] = useState("");
    const [countdown, setCountdown] = useState(120);

    const isMobile = useMemo(() => {
        const screenWidth = window.innerWidth;
        return screenWidth < 768; // Mobile common screen size >> can adjust if needed
    }, [window.innerWidth]);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        } else {
            setIsLoading(true);
            if (wsRef.current?.readyState === WebSocket.OPEN) {
                wsRef.current.close();
            }
            init();
        }

        // Cleanup timer before unmount
        return () => clearInterval(timer);
    }, [countdown]);

    const getQr = async () => {
        try {
            const res = await getWebWebQr();
            if (res.status) {
                return res;
            } else {
                throw res.message;
            }
        } catch (err) {
            message.error(JSON.stringify(err));
        }
    };

    const verifySessionStatus = async (data) => {
        if (data?.action === "SUBMITCERT_EXECUTE_RESPONSE") {
            if (data?.status === "2000") {
                processSession(data);
            } else {
                if (data?.status === "2025") {
                    message.error(
                        "Incorrect password entered. Authentication failed.",
                        7
                    );
                } else if (data?.payload) {
                    message.error(data?.payload, 10);
                }

                init();
            }
        }
    };

    const tryReVerifySession = async (sessionId, payload) => {
        try {
            const sessionResponse = await getVerifySession(sessionId);

            verifySessionStatus({
                session: sessionResponse?.sessionId,
                status: sessionResponse?.status,
                action: "SUBMITCERT_EXECUTE_RESPONSE",
                payload: payload,
                IDNumber: sessionResponse?.idNumber,
                fullName: sessionResponse?.fullName,
            });
        } catch (error) {
            message.error("Session verification failed. Please try again.");

            init();
        }
    };

    const initWebSocket = async (qrInfo) => {
        wsRef.current = new WebSocket(config?.digitalIdWsUrl);

        // On connection open
        wsRef.current.onopen = () => {
            if (wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send(
                    JSON.stringify({
                        version: "2",
                        action: "BIND_SESSION_BROWSER",
                        nonce: qrInfo?.nonce,
                    })
                );

                console.log("WebSocket connected ✅");

                setQrBase64(qrInfo?.qrCodeBase64);

                setMobileRedirectUrl(
                    `misignet://mydigitalid?qr=${qrInfo?.hyperlinkBase64}` //Same URL for all environments
                );

                setIsLoading(false);
            }
        };

        // On receiving messages
        wsRef.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log("WebSocket message received:", JSON.stringify(data));

            wsRef.current.currentSessionId = data?.session;
            wsRef.current.payload = data?.payload;
            verifySessionStatus(data);
        };

        // On connection close
        wsRef.current.onclose = () => {
            console.log("WebSocket disconnected ❌");
        };

        // On error
        wsRef.current.onerror = (error) => {
            setIsLoading(true);
            tryReVerifySession(
                wsRef.current.currentSessionId,
                wsRef.current.payload
            );

            console.error("WebSocket error:", JSON.stringify(error));
        };
    };

    const processSession = async (data) => {
        const { session, IDNumber, fullName } = data;

        if (verifyType === "Login" && session) {
            setIsLoading(true);

            try {
                const res = await postLoginSession(session);
                if (res.status === "00") {
                    await setToken(res.access_token);
                    await setUnverifiedAccount(res.unverifiedAccount);
                    await setIsAuthorizeToAccessUserManagement(true); //set to true to disable otp
                    await setAuthUid(res.uid);

                    let authorizedNric = getAuthorizedNric();

                    if (authorizedNric) {
                        await removeAuthorizedNric();
                    }

                    if (APP_CONST.USE_OTP === true) {
                        await checkOtpStatus().then((res) => {
                            if (res.status === true) {
                                window.location.pathname = "/postlogin";
                            } else {
                                history.push("/otp");
                                window.location.reload();
                            }
                        });
                    } else {
                        await check2FA().then((res) => {
                            if (res.status === true) {
                                window.location.pathname = "/postlogin";
                            } else {
                                history.push("/2fa");
                                window.location.reload();
                            }
                        });
                    }
                } else {
                    throw res;
                }
            } catch (err) {
                if (err && err?.message === "Account not activated.") {
                    message.error(
                        <div
                            style={{ textAlign: "center", paddingLeft: "10px" }}
                            dangerouslySetInnerHTML={{
                                __html: ERROR_MESSAGE_LOGIN_NOT_ACTIVATED(
                                    err?.loginName || ""
                                ),
                            }}
                        />,
                        7
                    );

                    err?.loginName &&
                        history.push("/inactive", {
                            activationEmail: err?.loginName,
                            isTempAccount: err?.isTempAccount,
                        });
                } else {
                    err &&
                        message.error(
                            err.error_description ||
                                err.message ||
                                `Error Prosessing Session ID`,
                            7
                        );
                }

                init();

                setIsLoading(false);
            } finally {
                setLoggedInTime(dayjs().format("D MMM YYYY h:mm A"));

                if (
                    wsRef.current &&
                    wsRef.current.readyState === WebSocket.OPEN
                ) {
                    console.log("🔒 Closing WebSocket before unmount...");
                    wsRef.current.close();
                }
            }
        } else if (verifyType === "Dashboard" && IDNumber) {
            setIsLoading(true);
            // For QR scan from dashboard scenario
            if (user?.nric === IDNumber) {
                setAuthorizedNric(IDNumber);

                const { route, authorizedParams } = thumbPrintRedirect;
                history.push(route, {
                    ...authorizedParams,
                });
            } else {
                message.error(
                    "Mismatch NRIC with registered user NRIC. Please ensure you scan using your own MyDigitalID."
                );

                init();
            }
        } else if (
            verifyType === "activate-remote-user" &&
            IDNumber &&
            fullName
        ) {
            const { activateToken, isTesting, pathname } = thumbPrintRedirect;

            //get User Info and compare with nric / IDNumber
            try {
                let activationEmail;
                let activationMobileNo;
                let activationNricNo;
                let activationErrorMessage;
                let activationStatus = true;

                if (user) {
                    const { email, mobileno, nric } = user;
                    activationEmail = email;
                    activationMobileNo = mobileno;
                    activationNricNo = nric;
                } else {
                    const remoteUserResponse = await getRemoteUserInfo({
                        encryptedString: activateToken,
                    });

                    const { status, nric, username, contactNo, message } =
                        remoteUserResponse;
                    activationEmail = username;
                    activationMobileNo = contactNo;
                    activationNricNo = nric;
                    activationErrorMessage = message;
                    activationStatus = status;
                }

                if (
                    activationStatus &&
                    (activationNricNo === IDNumber || isTesting)
                ) {
                    const remoteUser = {
                        address1: "",
                        address2: "",
                        address3: "",
                        dob: "", //use nric
                        birthplace: "",
                        citizenship: "",
                        city: "",
                        confirmEmail: "",
                        email: activationEmail,
                        gender: "", //use nric
                        id: 0,
                        mobileNo: activationMobileNo,
                        personName: fullName,
                        nricNo: isTesting ? activationNricNo : IDNumber,
                        identificationNo: isTesting
                            ? activationNricNo
                            : IDNumber,
                        phoneNo: "",
                        postcode: "",
                        race: "",
                        religion: "",
                        state: "",
                        roles: [],
                    };

                    history.push(pathname, {
                        remoteUserData: remoteUser,
                        remoteUser: remoteUser,
                        activateToken: activateToken,
                        isValidThumbprint: isTesting
                            ? true
                            : activationNricNo === IDNumber,
                        isTesting: isTesting,
                        step: "REMOTE_USER_ACTIVATION",
                        subStep: "SUMMARY",
                        isMyDigitalId: true, //to allow edit address
                    });
                } else {
                    // If mismatch IC custom message
                    if (activationNricNo !== IDNumber) {
                        throw new Error(
                            "Mismatch NRIC with registered user NRIC. Please ensure you scan using your own MyDigitalID."
                        );
                        // throw endpoint message
                    } else {
                        throw new Error(`${activationErrorMessage}`);
                    }
                }
            } catch (error) {
                error &&
                    error?.message &&
                    message.error(error?.message.toString(), 7);

                init();
            }
        }
    };

    const init = async () => {
        setIsLoading(true);
        setCountdown(120); // 2 minutes countdown

        const qrInfo = await getQr();

        if (qrInfo) {
            return initWebSocket(qrInfo);
        }

        setIsLoading(false);
    };

    const goBack = () => {
        switch (verifyType) {
            case "Login":
                history.push("/login");
                break;
            case "activate-remote-user":
                history.push(thumbPrintRedirect?.pathname);
                break;
            default:
                history.push("/");
                break;
        }
    };

    useEffect(() => {
        // If the user refresh digitalId page
        if (verifyType) {
            init();
        } else {
            goBack();
        }

        // Cleanup: Close WebSocket before unmount
        return () => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                console.log("🔒 Closing WebSocket before unmount...");
                wsRef.current.close();
            }
        };
    }, []);

    return (
        <div
            style={{
                margin: verifyType === "Login" ? "0px" : "24px 50px",
            }}
        >
            <Breadcrumb
                separator=">"
                items={[
                    {
                        title: verifyType,
                        href: verifyType === "Login" ? "/login" : "/",
                        onClick: (e) => {
                            e.preventDefault();
                            goBack();
                        },
                    },
                    {
                        title: "MyDigital ID",
                    },
                ]}
            ></Breadcrumb>
            <PageHeader
                className="site-page-header"
                style={{ paddingLeft: "0px" }}
                title="MyDigital ID"
            />
            <Card
                variant="borderless"
                style={{
                    padding: "4vw",
                    background: "rgba(255, 255, 255, 0.8)",
                }}
            >
                <Row>
                    <Col
                        xs={{
                            span: 24,
                            order: 2,
                        }}
                        lg={{
                            span: 12,
                            order: 1,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "16px",
                                textAlign: "left",
                            }}
                        >
                            <h1
                                style={{
                                    fontSize: "41px",
                                    fontWeight: "400",
                                    margin: "0px",
                                }}
                            >
                                {verifyType === "Login"
                                    ? "Scan QR"
                                    : "Verify your identity"}{" "}
                                with <br /> MyDigital ID app
                            </h1>
                            <p
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    margin: "0px",
                                }}
                            >
                                Use your MyDigital ID app to scan the QR code,
                                and then follow the instructions on your app.
                            </p>
                            <p
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    margin: "0px",
                                }}
                            >
                                By signing in, you agree to MyEG's{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={links.termsAndCondition}
                                >
                                    Terms of Services
                                </a>
                            </p>
                            <p
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    margin: "0px",
                                    cursor: "pointer",
                                    color: "#1677ff",
                                    userSelect: "none",
                                }}
                                onClick={() => {
                                    showDetail
                                        ? setShowDetail(false)
                                        : setShowDetail(true);
                                }}
                            >
                                What is MyDigital ID?
                                <DownOutlined
                                    style={{
                                        color: "#1677ff",
                                        marginLeft: "10px",
                                    }}
                                    rotate={showDetail ? 180 : 0}
                                />
                            </p>
                            {showDetail && (
                                <span>
                                    <p
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            margin: "0px",
                                        }}
                                    >
                                        MyDigital ID is a government-initiated
                                        app that allows Malaysians to verify
                                        their identity in multiple applications.
                                    </p>
                                    <p
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            margin: "0px",
                                            marginTop: "20px",
                                        }}
                                    >
                                        You can download MyDigital ID from{" "}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={links.googlePlayStore}
                                        >
                                            Google Play Store
                                        </a>
                                        ,{" "}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={links.appStore}
                                        >
                                            App Store
                                        </a>
                                        , and{" "}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={links.huaweiAppGallery}
                                        >
                                            Huawei AppGallery
                                        </a>
                                        .
                                    </p>
                                </span>
                            )}
                        </div>
                    </Col>
                    <Col
                        xs={{
                            span: 24,
                            order: 1,
                        }}
                        lg={{
                            span: 12,
                            order: 2,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }}
                            >
                                {!isLoading && qrBase64 ? (
                                    <img
                                        src={`data:image/png;base64,${qrBase64}`}
                                        alt="MyDigital ID QR"
                                        style={{
                                            width: "300px",
                                            borderRadius: "10px",
                                            cursor: isMobile ? "pointer" : "",
                                        }}
                                        onClick={() => {
                                            // only in mobile
                                            if (isMobile) {
                                                window.location.href =
                                                    mobileRedirectUrl;
                                            }
                                        }}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            fontSize: "50px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: "300px",
                                            height: "300px",
                                        }}
                                    >
                                        <SyncOutlined
                                            spin={isLoading}
                                            style={{
                                                cursor: isLoading
                                                    ? "wait"
                                                    : "pointer",
                                            }}
                                            onClick={
                                                !isLoading
                                                    ? () => init()
                                                    : undefined
                                            }
                                        />
                                    </div>
                                )}

                                {countdown <= 20 && !isLoading && (
                                    <p>
                                        <LoadingOutlined
                                            style={{
                                                color: "red",
                                                fontSize: "25px",
                                                marginRight: "15px",
                                            }}
                                        />
                                        QR will refresh in{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            {countdown}
                                        </span>{" "}
                                        seconds
                                    </p>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card>
            <div
                style={{
                    marginTop: "40px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Button
                    style={{
                        border: "1px solid #2B61C4",
                        color: "#2B61C4",
                        padding: "15px",
                        height: "fit-content",
                        minWidth: "160px",
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: "bold",
                        background: "transparent",
                    }}
                    loading={isLoading}
                    shape="round"
                    onClick={(e) => {
                        e.preventDefault();
                        goBack();
                    }}
                >
                    <Image src={arrow_back} preview={false} />
                    BACK
                </Button>
            </div>
        </div>
    );
}
