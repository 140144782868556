import React, { useState, useEffect, useContext } from "react";
import { Input, Button, Form, Card, message, Progress } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
    check1midAccount,
    getRolesByUsername,
    updateEmailAndPassword,
    checkPhoneNumberLogin,
} from "../../services/api";
import { removeUserRole } from "../../services/local";
import { HideEyeIcon, ShowEyeIcon } from "../../components/CustomIcons";
import { useForm } from "antd/lib/form/Form";
import { APP_CONST, iv } from "../../constants";
import CryptoJS from "crypto-js";

import { SessionContext } from "../../App";
import { sendVerificationCode, logout } from "../../services/api";

import UpdateMobileNoComponent from "../../components/component/Layout/UpdateMobileNoComponent";

import "./index.css";
export default function PostLoginPage() {
    const [updateForm] = useForm();
    const [tacForm] = useForm();

    const [newEmail, setNewEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isUpdateEmailAndPassword, setIsUpdateEmailAndPassword] =
        useState(false);
    const [isEmailUpdate, setIsEmailUpdate] = useState(false);
    const [isPasswordUpdate, setIsPasswordUpdate] = useState(false);
    const [isUpdateMobileNo, setIsUpdateMobileNo] = useState(false);

    const passwordStrengthMap = {
        WEAK: {
            percent: 30,
            color: "red",
            message: "Weak",
            messageBody:
                "It will be easy for a computer to crack your password.",
        },
        NORMAL: {
            percent: 50,
            color: "orange",
            message: "Normal",
            messageBody:
                "It will take some time for a computer to crack your password.",
        },
        STRONG: {
            percent: 91,
            color: "green",
            message: "Strong",
            messageBody: (
                <>
                    <span style={{ fontWeight: "700", color: "#333333" }}>
                        Your password is good to go!
                    </span>{" "}
                    Just remember: Never use obvious passwords such as your
                    phone number or date of birth.
                </>
            ),
        },
    };
    const [showPasswordStrength, setShowPasswordStrength] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(
        passwordStrengthMap["WEAK"]
    );

    const { user, init } = useContext(SessionContext);
    const history = useHistory();

    useEffect(async () => {
        try {
            let [
                check1midAccountResponse,
                getRolesByUsernameResponse,
                checkPhoneNumberLoginResponse,
            ] = await Promise.all([
                check1midAccount(user?.nric),
                getRolesByUsername(user?.nric),
                checkPhoneNumberLogin(),
            ]);

            if (
                getRolesByUsernameResponse &&
                getRolesByUsernameResponse?.status
            ) {
                const foundAdmin = getRolesByUsernameResponse?.roles?.find(
                    (role) => role?.name === "MYEG_ADMIN"
                );
                if (foundAdmin) {
                    setIsAdmin(true);
                }
            }

            const { updateAccountEmail, updateAccountPassword, message } =
                check1midAccountResponse;

            const { isUpdateRequired: updateMobileNoRequired } =
                checkPhoneNumberLoginResponse;

            if (message) {
                throw new Error(message);
            } else if (updateAccountEmail && updateAccountPassword) {
                setIsUpdateEmailAndPassword(true);
            } else if (updateAccountEmail) {
                setIsEmailUpdate(true);
            } else if (updateAccountPassword) {
                setIsPasswordUpdate(true);
            } else if (updateMobileNoRequired) {
                // Need to update mobile number (possible reason due to mobileNo are same to upper level user)
                setIsUpdateMobileNo(true);
            } else {
                //No need to update email or password => push to /selectbranch
                history.push("/selectbranch");
            }
        } catch (error) {
            message.error(`${error}`);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const [submitDisabled, setSubmitDisabled] = useState(true);

    useEffect(() => {
        updateForm
            .validateFields({ validateOnly: true })
            .then(() => {
                setSubmitDisabled(false);
            })
            .catch(() => {
                setSubmitDisabled(true);
            });
    }, [updateForm.getFieldsValue()]);

    const [showTAc, setShowTAC] = useState(false);

    const requestCode = async () => {
        const newPasswordCiphertext = CryptoJS.AES.encrypt(
            newPassword,
            iv
        ).toString();

        let reBody = {
            userFullName: user?.name,
            oldEmail: user?.email,
            ...(newEmail && { newEmail }),
            ...(newPassword && { newPassword: newPasswordCiphertext }),
            isMyegAdmin: isAdmin ? true : false,
            oldUsername: user?.username
        };

        try {
            return await sendVerificationCode(reBody);
        } catch (error) {
            throw new Error(error);
        }
    };

    const onSubmit = async () => {
        setIsLoading(true);

        try {
            const response = await requestCode();
            if (response && response?.status) {
                message.success(response?.message);

                //Show TAC Ui
                setShowTAC(true);
            } else {
                throw new Error(`${response?.message}`);
            }
        } catch (error) {
            message.error(`${error}`);
        } finally {
            setIsLoading(false);
            setCountdown(300);
        }
    };

    const checkPasswordStrength = () => {
        const { newPassword } = updateForm.getFieldsValue();
        // Minimum eight, maximum 12 characters, at least one uppercase letter, one lowercase letter and one number:
        const passwordNormalRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+|~`\-=\[\]{}\\;:'",.<>\/\?\*]{8,12}$/;
        // Minimum eight, maximum 12 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
        const passwordStrongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+|~`\-=\[\]{}\\;:'",.<>\/\?\*])[A-Za-z\d!@#$%^&*()_+|~`\-=\[\]{}\\;:'",.<>\/\?\*]{8,12}$/;

        // re-render PasswordStrengthComponent everytime strength is changed
        if (newPassword) {
            setShowPasswordStrength(true);

            // if password matches normal strength regex, change strength state to normal
            passwordNormalRegex.test(newPassword) &&
                setPasswordStrength(passwordStrengthMap["NORMAL"]);
            // if password matches strong strength regex, change stregth state to strong
            passwordStrongRegex.test(newPassword) &&
                setPasswordStrength(passwordStrengthMap["STRONG"]);
            // otherwise, change strength to weak
            !passwordNormalRegex.test(newPassword) &&
                !passwordStrongRegex.test(newPassword) &&
                setPasswordStrength(passwordStrengthMap["WEAK"]);
        } else {
            setShowPasswordStrength(false);
        }
    };

    const PasswordStrengthComponent = () => {
        return showPasswordStrength ? (
            <>
                <Progress
                    percent={passwordStrength.percent}
                    strokeColor={passwordStrength.color}
                    showInfo={false}
                />
                <p style={{ textAlign: "left" }}>
                    <span
                        style={{
                            fontWeight: "bold",
                            color: passwordStrength.color,
                        }}
                    >
                        {passwordStrength.message}
                    </span>
                    <br />

                    {passwordStrength.messageBody}
                </p>
            </>
        ) : (
            <p
                style={{
                    fontWeight: "400",
                    textAlign: "left",
                    fontSize: "14px",
                    lineHeight: "20px",
                }}
            >
                <span style={{ fontWeight: "700", color: "#949494" }}>
                    Tips
                </span>
                <br />
                <span style={{ fontWeight: "700" }}>
                    Please create a strong password to proceed
                </span>
                . Your password must have at least 9 characters (including
                space).
            </p>
        );
    };

    const updateEmailAndPasswordComp = (
        <div
            style={{
                maxWidth: "20vw",
            }}
        >
            <div
                style={{
                    textAlign: "left",
                }}
            >
                <p
                    style={{
                        fontWeight: 700,
                        fontSize: 24,
                        lineHeight: "30.24px",
                        marginBottom: "0px",
                        marginTop: "0px",
                        textAlign: "center",
                    }}
                >
                    {isUpdateEmailAndPassword && "Update email & password"}
                    {isEmailUpdate && "Update email"}
                    {isPasswordUpdate && "Create new password"}
                </p>

                <p
                    style={{
                        fontWeight: 400,
                        fontSize: 16,
                        lineHeight: "24px",
                        marginBottom: "16px",
                    }}
                >
                    {isUpdateEmailAndPassword &&
                        "To enhance your account security, please enter your email and create a new password."}
                    {isEmailUpdate &&
                        "To enhance your account security, please enter your email address."}
                    {isPasswordUpdate &&
                        "To enhance your account security, please create a strong password."}
                </p>
            </div>

            <Form
                layout={"vertical"}
                onFinish={onSubmit}
                form={updateForm}
                requiredMark={false}
            >
                {/* Hidden dummy fields for hiding auto populate*/}
                <div style={{ display: "none" }}>
                    <input type="text" autoComplete="username" />
                    <input type="password" autoComplete="current-password" />
                </div>
                <Form.Item
                    label="Email"
                    name="newEmail"
                    style={{ color: "grey", marginBottom: "16px" }}
                    requiredMark={"optional"}
                    hidden={isPasswordUpdate}
                    rules={[
                        {
                            required: isUpdateEmailAndPassword || isEmailUpdate,
                            message: "Please input your email",
                        },
                        {
                            min: 6,
                            message: "Email must be at least 6 characters long",
                        },
                        {
                            max: 50,
                            message:
                                "Email cannot be longer than 50 characters",
                        },
                        {
                            pattern:
                                /^[A-Za-z0-9]+([~!$%^&*_\-=+}|{'?#/`]|[.](?![.])|[A-Za-z0-9])*[A-Za-z0-9]+@[A-Za-z0-9]+([._-]?[A-Za-z0-9])*\.[A-Za-z]{2,}$/,
                            message: "Please enter a valid email format",
                        },
                        {
                            validator: (_, value) => {
                                if (value) {
                                    // Check for consecutive dots
                                    if (value.includes("..")) {
                                        return Promise.reject(
                                            "Email cannot contain consecutive dots"
                                        );
                                    }

                                    // Split email into local and domain parts
                                    const [localPart, domainPart] =
                                        value.split("@");

                                    // Local part validation
                                    if (
                                        localPart.startsWith(".") ||
                                        localPart.endsWith(".")
                                    ) {
                                        return Promise.reject(
                                            "Username portion cannot start or end with a dot"
                                        );
                                    }

                                    // Domain part validation
                                    if (domainPart) {
                                        if (
                                            domainPart.startsWith("-") ||
                                            domainPart.endsWith("-") ||
                                            domainPart.startsWith("_") ||
                                            domainPart.endsWith("_") ||
                                            domainPart.startsWith(".") ||
                                            domainPart.endsWith(".")
                                        ) {
                                            return Promise.reject(
                                                "Domain cannot start or end with hyphen, underscore, or dot"
                                            );
                                        }
                                    }
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Input
                        value={newEmail}
                        placeholder={"Enter New Email"}
                        type={"email"}
                        onChange={(e) => setNewEmail(e.target.value.trim())}
                        onPressEnter={() => {}}
                        style={{
                            border: "1px solid #2B61C4",
                            borderRadius: "4px",
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="New Password"
                    placeholder={"Password"}
                    name="newPassword"
                    requiredMark={"optional"}
                    style={{ color: "grey" }}
                    hidden={isEmailUpdate}
                    rules={[
                        {
                          required: isUpdateEmailAndPassword || isPasswordUpdate,
                          message: "Please input your new password",
                        },
                        {
                          max: 12,
                          min: 8,
                          message: "Password must have 8 - 12 characters",
                        },
                        {
                          pattern: /^(?=.*[A-Z])[A-Za-z\d!@#$%^&*()_+|~`\-=\[\]{}\\;:'",.<>\/\?\*]{1,}$/,
                          message: "New password must have at least one capital letter",
                        },
                        {
                          pattern: /^(?=.*[a-z])[A-Za-z\d!@#$%^&*()_+|~`\-=\[\]{}\\;:'",.<>\/\?\*]{1,}$/,
                          message: "New password must have at least one alphanumerical character",
                        },
                        {
                          pattern: /^(?=.*\d)[A-Za-z\d!@#$%^&*()_+|~`\-=\[\]{}\\;:'",.<>\/\?\*]{1,}$/,
                          message: "New password must have at least a number",
                        },
                      ]}
                      
                >
                    <Input.Password
                        iconRender={(visible) =>
                            visible ? ShowEyeIcon() : HideEyeIcon()
                        }
                        value={newPassword}
                        placeholder={"Enter password"}
                        onChange={(e) => {
                            checkPasswordStrength();
                            setNewPassword(e.target.value.trim());
                        }}
                        onPressEnter={() => {}}
                        style={{
                            border: "1px solid #2B61C4",
                            borderRadius: "4px",
                        }}
                    />
                </Form.Item>
                {(isUpdateEmailAndPassword || isPasswordUpdate) &&
                    PasswordStrengthComponent()}
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={submitDisabled}
                        className="custom-disabled-button"
                    >
                        {isUpdateEmailAndPassword &&
                            (submitDisabled
                                ? "Enter Details"
                                : "Update Email & Password")}
                        {isEmailUpdate &&
                            (submitDisabled ? "Enter Details" : "Update Email")}
                        {isPasswordUpdate &&
                            (submitDisabled
                                ? "Enter Details"
                                : "Update Password")}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );

    const onSubmitTac = async (data) => {
        const { otp } = data;

        const newPasswordCiphertext = CryptoJS.AES.encrypt(
            newPassword,
            iv
        ).toString();
        try {
            setIsLoading(true);

            const res = await updateEmailAndPassword({
                userFullName: user?.name,
                currentEmail: user?.email,
                ...(newEmail && { newEmail }),
                ...(newPassword && { newPassword: newPasswordCiphertext }),
                isMyegAdmin: isAdmin ? true : false,
                verificationCode: otp,
                nric: user?.nric,
                currentUsername: user?.username,
            });

            if (res && res?.status == false) {
                throw new Error(`${res?.message}`);
            }

            //Timeout just for user experience since we are reloading the whole app.
            setTimeout(async () => {
                // Will logout user for all cases since changing email will invalidate the token and changing password will prompt user to revalidate with new password
                await logout();
                await removeUserRole();

                window.location.pathname = "/login";
            }, 3000);

            isUpdateEmailAndPassword &&
                message.success(
                    "Email and password update successful. Please log in again to continue."
                );

            isEmailUpdate &&
                message.success(
                    "Email update successful. Please log in again to continue."
                );

            isPasswordUpdate &&
                message.success(
                    "Password update successful. Please log in again to continue."
                );
        } catch (error) {
            message.error(
                `Failed to verify code, Try Again. Error: ${error?.message}`
            );
            setIsLoading(false);
        }
    };

    const [submitCodeDisabled, setSubmitCodeDisabled] = useState(true);

    const handleChange = (value) => {
        if (value && value.length == 4) {
            setSubmitCodeDisabled(false);
        } else {
            setSubmitCodeDisabled(true);
        }
    };

    const [countdown, setCountdown] = useState(300);
    const [canResend, setCanResend] = useState(false);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        } else {
            setCanResend(true);
        }

        return () => clearInterval(timer);
    }, [countdown]);

    const handleResend = async () => {
        if (canResend) {
            try {
                await requestCode();
                setCountdown(300);
                setCanResend(false);
            } catch (error) {
                message.error("Failed to resend:", error, " Try again");
            }
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} MIN ${remainingSeconds
            .toString()
            .padStart(2, "0")} SEC`;
    };

    const postMobileUpdate = async () => {
        await init();

        //push to select branch page
        history.push("/selectbranch");
    };

    const tac4DigitsComp = (
        <div>
            <div
                style={{
                    maxWidth: "20vw",
                }}
            >
                <div
                    style={{
                        textAlign: "left",
                    }}
                >
                    <p
                        style={{
                            fontWeight: 700,
                            fontSize: 24,
                            lineHeight: "30.24px",
                            marginBottom: "0px",
                            marginTop: "0px",
                            textAlign: "center",
                        }}
                    >
                        Verify code
                    </p>

                    <p
                        style={{
                            fontWeight: 400,
                            fontSize: 16,
                            lineHeight: "24px",
                            marginBottom: "16px",
                            wordBreak: "break-word",
                        }}
                    >
                        We have sent a verification code to your email{" "}
                        <span style={{ fontWeight: "600", color: "#333333" }}>
                            {newEmail || user?.email}
                        </span>
                    </p>
                </div>

                <Form
                    layout={"vertical"}
                    onFinish={onSubmitTac}
                    form={tacForm}
                    requiredMark={false}
                >
                    <Form.Item
                        label="Verification code"
                        name="otp"
                        style={{
                            color: "grey",
                            fontStyle: "italic",
                            textAlign: "left",
                        }}
                        requiredMark={"optional"}
                        rules={[
                            {
                                required: true,
                                message: "Please input your verification code",
                            },
                        ]}
                    >
                        <Input.OTP
                            autoFocus
                            length={4}
                            mask="⚫"
                            onInput={handleChange}
                        />
                    </Form.Item>
                    {
                        <div
                            style={{
                                textAlign: "left",
                                marginTop: "16px",
                                marginBottom: "30px",
                            }}
                        >
                            <a
                                onClick={handleResend}
                                style={{
                                    cursor: canResend ? "pointer" : "default",
                                    color: canResend ? "#2B61C4" : "grey",
                                    textDecoration: "none",
                                    userSelect: "none",
                                }}
                            >
                                {canResend
                                    ? "RESEND CODE"
                                    : `RESEND CODE IN ${formatTime(countdown)}`}
                            </a>
                        </div>
                    }
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={submitCodeDisabled}
                            className="custom-disabled-button"
                        >
                            {submitCodeDisabled ? "Enter Code" : "Verify Code"}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Card
                style={{
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: 8,
                    marginTop: 72,
                }}
                className="custom-postlogin-card"
            >
                {isLoading && (
                    <div
                        style={{
                            minWidth: 200,
                            minHeight: "30vh",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadingOutlined
                            style={{
                                fontSize: 50,
                            }}
                        />
                    </div>
                )}

                {!isLoading &&
                    !showTAc &&
                    (isUpdateEmailAndPassword ||
                        isEmailUpdate ||
                        isPasswordUpdate) &&
                    updateEmailAndPasswordComp}

                {!isLoading && showTAc && tac4DigitsComp}

                {!isLoading && isUpdateMobileNo && (
                    <UpdateMobileNoComponent
                        postMobileUpdate={postMobileUpdate}
                        currentMobileNo={user?.mobileno}
                        currentNric={user?.nric}
                    />
                )}
            </Card>
        </div>
    );
}
