import React, { useContext, useState, useEffect } from 'react';
import { Form, Input, Button, Radio, Card, Row, Col, Select, Alert, Checkbox, Modal, Spin } from 'antd';
import { CalendarOutlined, PlusCircleFilled } from '@ant-design/icons';
import icon_save from "../../../assets/icon-save-20.png";
import moment from 'moment';
import { roadTaxInquiry, getAddress } from '../../../services/api';
import { SessionContext } from "../../../App";

import './index.less';

function RoadTaxPurchasePage({ handleNext, requiresFormFill, vehicleNumber, ownerIdNumber, handleRoadTaxSubmit, renewalPrices, }) {
    const [form] = Form.useForm();
    const [renewalPeriod, setRenewalPeriod] = useState(12); // Default value: 6 months
    const [selectedFormat, setSelectedFormat] = useState('physical_digital'); // Default selection
    const [isChecked, setIsChecked] = useState(true); // Checkbox state
    const [collectionMethod, setCollectionMethod] = useState("delivery"); // Default to delivery
    const [showOwnerDetails, setShowOwnerDetails] = useState(true); // Default: Show Owner's Details
    const [recipientLabel, setRecipientLabel] = useState("Recipient's Contact Details"); // Default label
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const { company, branch } = useContext(SessionContext);


    const handleSubmit = async (values) => {
        setIsLoading(true); // Show modal on submit
        console.log('Road Tax Form Data', values);

        const formData = {
            ...values,
            lamination_fee: isChecked,
            renewalPeriod: renewalPeriod,
        }

        console.log("Final Data Submitted:", formData);
        console.log("Passing Validity Period:", validityPeriod);

        //  Constructing the request body for API call
        const updatedRequestBody = {
            address1: values.address1 || "-",
            address2: values.address2 || "-",
            category: "1",
            contactNo: values.mobileNumber || "-",
            email: "-",
            idNo: ownerIdNumber,
            name: "dummyName",
            postcode: values.postcode || "-",
            state: values.roadTaxFormat === "44"
                ? "44" //  Digital-only state code
                : (values.roadTaxFormat === "physical_digital" && values.collectionMethod === "00")
                    ? "00"
                    : values.state || "00",
            vehicleNo: vehicleNumber,
            lamination_fee: isChecked || false,
            renewalPeriod: values.renewalPeriod || "6", // Default to 6 months
            dealerCompanyRoc: company.coRegNo,
        };


        console.log("🚀 Sending API updatedRequestBody from roadTaxPurchase:", JSON.stringify(updatedRequestBody, null, 2));

        try {
            //  Call API but do NOT store the response
            const getResponse = await roadTaxInquiry(updatedRequestBody, true);
            console.log("📥 API Response Received (Not stored):", JSON.stringify(getResponse, null, 2));

            handleRoadTaxSubmit(formData, validityPeriod, updatedRequestBody, getResponse); //  Send data to parent

        } catch (error) {
            console.error(" API Request Failed:", error);
        }


        // Simulate an API call delay
        setTimeout(() => {
            setIsLoading(false); // Hide modal after process is done
            handleNext(); // Proceed to next step
        }, 2000);
    };




    const handleFormatChange = (e) => {
        const format = e.target.value;
        setSelectedFormat(format);

        if (format === "44") {
            setShowOwnerDetails(false); // Hide Owner's Delivery Details
            setRecipientLabel("Owner's Contact Details"); // Update label
            form.resetFields(["postcode", "city", "state", ...addressLines]); // Reset unnecessary fields
            setIsChecked(false); //  Automatically uncheck lamination
        } else {
            setShowOwnerDetails(true); // Show Owner's Delivery Details
            setRecipientLabel("Recipient's Contact Details"); // Reset label
        }
    };


    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleFormChange = async (changedValues, allValues) => {
        const isValid = allValues.renewalPeriod && allValues.roadTaxFormat && allValues.mobileNumber;
        setIsFormFilled(isValid);

        if (changedValues?.postcode && changedValues.postcode.length >= 5) {
            try {
                const res = await getAddress({ postcode: changedValues.postcode });
                const area = res?.areas?.[0];

                if (area) {
                    form.setFieldsValue({
                        city: area.city || "",
                        state: res.statecode || "", // Use the state code, not state name
                    });
                }
            } catch (error) {
                console.error("❌ Error fetching address:", error);
            }
        }
    };





    const handleRenewalPeriodChange = (e) => {
        setRenewalPeriod(e.target.value); // Update the renewal period based on user selection
    };

    const calculateValidityPeriod = () => {
        const startDate = moment(); // Current date
        const endDate = moment().add(renewalPeriod, 'months'); // Add selected months
        return {
            start: startDate.format('DD MMM YYYY'),
            end: endDate.format('DD MMM YYYY'),
        };
    };

    const validityPeriod = calculateValidityPeriod();


    const [addressLines, setAddressLines] = useState(['address1']); // Initial address line

    const addAddressLine = () => {
        setAddressLines((prev) => {
            if (prev.length >= 2) return prev; // Limit to 2 lines max
            return [...prev, `address${prev.length + 1}`];
        });
    };


    const handleCollectionMethodChange = (e) => {
        setCollectionMethod(e.target.value);
    };



    return (
        <Card
            className="roadtaxPurchase"
            style={{ width: '1196px', marginTop: '60px', backgroundColor: 'transparent', border: 0 }}
        >
            <Form
                className='formPurchase'
                form={form}
                layout="vertical"
                initialValues={{
                    renewalPeriod: 12,
                    roadTaxFormat: "physical_digital",
                    collectionMethod: "delivery",
                }}
                onValuesChange={handleFormChange}
                onFinish={handleSubmit}
            >
                <Card className="roadtaxDetails">
                    <h2 className='cardTitle' >
                        {vehicleNumber}'s Road Tax Details
                    </h2>
                    <p className='indicatorText'>
                        <span style={{ color: 'red' }}>*</span> indicates required field
                    </p>

                    <Col >
                        <Form.Item
                            name="renewalPeriod"
                            label="Renewal Period"
                            rules={[{ required: true, message: '' }]}

                        >
                            <Row>
                                <Col style={{ width: '566px' }}>
                                    <Radio.Group
                                        defaultValue={12}
                                        className="radio-group-custom"
                                        onChange={handleRenewalPeriodChange}
                                        style={{
                                            position: 'relative',
                                            zIndex: 1,
                                            boxShadow: '0px 4px 8px 0px #33333340'

                                        }}
                                    >
                                        <Radio value={6}>
                                            <p
                                                style={{
                                                    width: '219px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <span>6 months</span>
                                                <span>RM {renewalPrices["6"] ? renewalPrices["6"].toFixed(2) : "Loading..."}</span>
                                            </p>
                                        </Radio>
                                        <Radio value={12}>
                                            <p
                                                style={{
                                                    width: '219px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <span>12 months</span>
                                                <span>RM {renewalPrices["12"] ? renewalPrices["12"].toFixed(2) : "Loading..."}</span>
                                            </p>
                                        </Radio>
                                    </Radio.Group>
                                    <Alert
                                        message={
                                            <span>
                                                Your road tax will be <b>valid from{' '}
                                                    {validityPeriod.start}</b> to <b>{validityPeriod.end}</b>.
                                            </span>
                                        }
                                        type="warning"
                                        showIcon
                                        icon={
                                            <CalendarOutlined style={{ color: 'black', fontSize: '24px', marginRight: '12.67px' }} />
                                        }
                                        style={{
                                            marginBottom: 16,
                                            backgroundColor: '#FFF9A2',
                                            padding: '0 16.67px',
                                            fontSize: '16px',
                                            fontFamily: 'Plus Jakarta Sans',
                                            lineHeight: '24px',
                                            borderRadius: 4,
                                            textAlign: 'start',
                                            width: '566px',
                                            height: '52px',
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                            position: 'relative',
                                            top: '-1px',
                                            zIndex: 0
                                        }}
                                    />
                                </Col>

                                <Col style={{ width: '50%' }}>
                                    <p style={{ color: '#737373', fontSize: '16px', lineHeight: '24px', letterSpacing: '-0.5%', marginTop: '12px', marginLeft: '16px' }}>
                                        The prices displayed do not include processing fees.
                                    </p>
                                </Col>

                            </Row>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="roadTaxFormat"
                            label="Road Tax Format"
                            rules={[{ required: true, message: '' }]}

                        >
                            <Row>
                                <Col style={{ width: '566px' }}>
                                    <Radio.Group
                                        defaultValue={"physical_digital"}
                                        className="radio-group-custom"
                                        style={{ boxShadow: '0px 4px 8px 0px #33333340' }}
                                        onChange={handleFormatChange}
                                    >
                                        <Radio value="physical_digital">Physical & Digital</Radio>
                                        <Radio value="44">Digital Only</Radio>
                                    </Radio.Group>

                                    {selectedFormat === 'physical_digital' && (
                                        <Checkbox
                                            onChange={handleCheckboxChange}
                                            checked={isChecked}
                                            disabled={selectedFormat === "44"}
                                            style={{ marginTop: '8px', display: 'flex', flexDirection: 'row', lineHeight: '24px' }}
                                        >
                                            Protect roadtax slip with lamination for RM 1.08 (including 8% SST).
                                        </Checkbox>
                                    )}
                                </Col>
                                <Col style={{ width: '50%' }}>
                                    <p style={{ color: '#737373', fontSize: '16px', lineHeight: '24px', letterSpacing: '-0.5%', marginTop: 0, marginLeft: '16px' }}>
                                        Upon renewal, the vehicle owner can access the digital road tax within 24 hours through the MyJPJ app, available on Google Play Store, Apple App Store, or Huawei App Gallery.
                                    </p>
                                </Col>
                            </Row>

                        </Form.Item>
                    </Col>

                </Card>
                {/* Owner's Delivery Details */}
                {showOwnerDetails && (
                    <Card>
                        <h2 className='cardTitle'>Owner's Delivery Details</h2>
                        <p className='indicatorText'>
                            <span style={{ color: 'red' }}>*</span> indicates required field
                        </p>

                        <Row style={{ alignItems: 'center' }}>
                            <Col style={{ width: '566px', height: '80px' }}>
                                <Form.Item
                                    name="collectionMethod"
                                    label="Collection Method"
                                    rules={[{ required: true, message: '' }]}

                                >
                                    <Radio.Group
                                        className="radio-group-custom"
                                        onChange={handleCollectionMethodChange}
                                        style={{ boxShadow: '0px 4px 8px 0px #33333340' }}
                                    >
                                        <Radio value="delivery">Delivery</Radio>
                                        <Radio value="00">Self-collect at MYEG Tower</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {/* Display Self-collect Form */}
                            {collectionMethod === "00" && (
                                <><Col style={{ width: '574px', height: '80px' }}>
                                    <p style={{ fontSize: "16px", lineHeight: "24px", letterSpacing: '-0.5%', fontWeight: 400, paddingLeft: '16px', marginTop: '32px' }}>The road tax will be ready for collection <b>three working days after</b> the <b>payment</b> is successfuly made.</p>
                                </Col>
                                    <Row>
                                        <Col span={24}>
                                            <div style={{ marginTop: "16px", display: "flex", gap: "16px" }}>
                                                {/* Map */}
                                                <div style={{ flex: "1", minWidth: "566px", minHeight: '256.28px' }}>
                                                    <iframe
                                                        title="MYEG Tower Map"
                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15919.455545124553!2d101.6296012689517!3d3.1569360967081357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc49b8ec8bfc81%3A0x6a3b72e8b383569!2sMYEG%20Tower!5e0!3m2!1sen!2smy!4v1670836797468!5m2!1sen!2smy"
                                                        width="100%"
                                                        height="100%"
                                                        style={{ border: "0" }}
                                                        allowFullScreen=""
                                                        loading="lazy"
                                                    ></iframe>
                                                </div>
                                                {/* Text Details */}
                                                <div style={{ flex: "2" }}>
                                                    <p style={{ fontSize: "16px", lineHeight: "24px", marginTop: 0 }}>
                                                        Collection address: <br />
                                                        <b>MyEG Customer Service Center</b> <br />
                                                        Level UG, MYEG Tower, Empire City, No 8, Jalan Damansara,
                                                        PJU 8, 47820 Petaling Jaya, Selangor.
                                                    </p>
                                                    <p style={{ fontSize: "16px", lineHeight: "24px" }}>
                                                        <b>Monday – Friday: 9am – 5pm</b> (closed on public
                                                        holidays)
                                                    </p>
                                                    <p style={{ fontSize: "16px", lineHeight: "24px" }}>
                                                        Vehicle owners can collect their road tax with their
                                                        MyKad or appoint <br /> someone to collect it on their behalf.
                                                        The <b>appointed person</b> must bring the <br /> <b>photocopy of the
                                                            owner's MyKad</b> and <b>road tax receipt.</b>
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row></>
                            )}
                        </Row>

                        {/* Display Delivery Form */}
                        {collectionMethod === "delivery" && (
                            <>
                                <Col>
                                    <p
                                        style={{
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            letterSpacing: "0.5%",
                                            fontWeight: "400",
                                            marginBottom: 0,
                                        }}
                                    >
                                        Country
                                    </p>
                                    <p
                                        style={{
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            letterSpacing: "0.5%",
                                            fontWeight: "600",
                                            marginTop: "8px",
                                        }}
                                    >
                                        Malaysia
                                    </p>
                                </Col>
                                <Row style={{ gap: "16px" }}>
                                    <Col>
                                        <Form.Item
                                            name="postcode"
                                            label="Postcode"
                                            rules={[{ required: true, message: '' }]}
                                            style={{ width: "90px", height: "48px" }}
                                        >
                                            <Input style={{ boxShadow: '0px 4px 8px 0px #33333340', borderRadius: '4px' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="city"
                                            label="City"
                                            rules={[{ required: true, message: '' }]}
                                            style={{ width: "222px", height: "48px" }}
                                        >
                                            <Input style={{ boxShadow: '0px 4px 8px 0px #33333340', borderRadius: '4px' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="state"
                                            label="State"
                                            rules={[{ required: true, message: '' }]}
                                        >
                                            <Select
                                                style={{
                                                    width: "222px",
                                                    height: "48px",
                                                    boxShadow: "0px 4px 8px 0px #33333340",
                                                    borderRadius: "4px",
                                                }}
                                                placeholder="Select State"
                                            >
                                                <Select.Option value="01">Johor</Select.Option>
                                                <Select.Option value="02">Kedah</Select.Option>
                                                <Select.Option value="03">Kelantan</Select.Option>
                                                <Select.Option value="04">Malacca</Select.Option>
                                                <Select.Option value="05">Negeri Sembilan</Select.Option>
                                                <Select.Option value="06">Pahang</Select.Option>
                                                <Select.Option value="07">P. Pinang</Select.Option>
                                                <Select.Option value="08">Perak</Select.Option>
                                                <Select.Option value="09">Perlis</Select.Option>
                                                <Select.Option value="10">Selangor</Select.Option>
                                                <Select.Option value="11">Terengganu</Select.Option>
                                                <Select.Option value="12">Sabah</Select.Option>
                                                <Select.Option value="13">Sarawak</Select.Option>
                                                <Select.Option value="14">Kuala Lumpur</Select.Option>
                                                <Select.Option value="15">Labuan</Select.Option>
                                                <Select.Option value="16">Putrajaya</Select.Option>
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                </Row>
                                {addressLines.map((line, index) => (
                                    <Form.Item
                                        key={line}
                                        name={line}
                                        label={index === 0 ? "Address" : undefined}
                                        rules={[{ required: index === 0 }]}
                                        style={{ marginBottom: "17px" }}
                                    >
                                        <Input.TextArea
                                            autoSize={false}
                                            style={{ width: "566px", height: "48px", boxShadow: '0px 4px 8px 0px #33333340', borderRadius: '4px', resize: 'none' }}
                                        />
                                    </Form.Item>
                                ))}
                                {addressLines.length < 2 && (
                                    <Button
                                        type="link"
                                        icon={<PlusCircleFilled style={{ color: "#2B61C4" }} />}
                                        style={{
                                            padding: 0,
                                            fontSize: "14px",
                                            fontWeight: 700,
                                            lineHeight: "17.64px",
                                            letterSpacing: "-0.5%",
                                            color: "#2B61C4",
                                        }}
                                        onClick={addAddressLine}
                                    >
                                        ADD ADDRESS LINE {addressLines.length + 1}
                                    </Button>
                                )}
                            </>
                        )}



                    </Card>
                )}

                {/* Recipient's Contact Details */}
                <Card>
                    <h2 className='cardTitle'>{recipientLabel}</h2>
                    <p className='indicatorText'>
                        <span style={{ color: 'red' }}>*</span> indicates required field
                    </p>
                    <Row style={{ gap: '16px', height: '100%' }}>
                        <Col>
                            <Form.Item
                                name="mobileNumber"
                                label="Mobile number"
                                rules={[
                                    { required: true, message: '' },
                                    { pattern: /^\d{9,11}$/, message: '' },
                                ]}
                                style={{ width: '275px', height: '80px', marginBottom: 0 }}
                            >
                                <Input placeholder="Enter mobile number" style={{ boxShadow: '0px 4px 8px 0px #33333340', borderRadius: '4px', height: '48px' }} />
                            </Form.Item>
                            <p style={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', letterSpacing: '-0.5%', marginTop: '8px', marginBottom: 0, color: '#737373' }}>MYEG delivery personnel will contact <br /> this number</p>
                        </Col>
                        {/* <Col>
                            <Form.Item
                                name="email"
                                label="Email address"
                                rules={[
                                    { required: true, message: '' },
                                    { type: 'email', message: '' },
                                ]}
                                style={{ width: '275px', height: '80px', marginBottom: 0 }}
                            >
                                <Input placeholder="Enter email address" style={{ boxShadow: '0px 4px 8px 0px #33333340', borderRadius: '4px', height: '48px' }} />
                            </Form.Item>
                        </Col> */}
                    </Row>

                </Card>
                {/* Buttons */}
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 16 }}>
                    {/* <Button type="default" style={{ width: 288, height: 48, backgroundColor: 'transparent', borderRadius: '40px', border: '1px solid #2B61C4 ', color: '#2B61C4', fontSize: '16px', fontWeight: 600 }}>
                        <img src={icon_save} alt='save' tyle={{ width: 28, height: 28, objectFit: 'cover', marginRight: '8px' }} />
                        Save & Continue Later
                    </Button> */}
                    <Button
                        className="next-button"
                        type="primary"
                        htmlType="submit"
                        style={{ width: 345, height: 56 }}
                        disabled={requiresFormFill && !isFormFilled}
                    >
                        {isFormFilled ? "View Summary" : "Enter Details"}
                    </Button>
                </div>
            </Form>
            {/* Loading Modal */}
            <Modal
                className='roadtax-modal'
                open={isLoading}
                footer={null}
                closable={false}
                centered
                style={{ textAlign: 'center', padding: '30px', borderRadius: 8 }}
            >
                <Spin percent="auto" />
                <p style={{ fontSize: '16px', margin: '16px 0px 0px', lineHeight: '24px', fontWeight: 400, letterSpacing: '-0.08px' }}>Please wait...</p>
            </Modal>

        </Card>

    );
}

export default RoadTaxPurchasePage;
